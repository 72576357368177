import type {TLanguages, TLocale} from '@mcal/core';
import {createAsyncThunk} from '@reduxjs/toolkit';
import type {IAsyncThunkConfig} from '../../../defines/redux.types.js';
import type {II18nConfig} from '../../../modules/i18n/i18n.js';
import {i18n} from '../../../modules/i18n/i18n.js';
import {getState} from '../../../utils/get-state/get-state.js';
import {updateGeneralSettings} from '../../app-node-auth/user/user.thunks.js';
import * as services from './languages.services.js';

const initI18n = createAsyncThunk<void, II18nConfig, IAsyncThunkConfig>(
    'languages/initI18n',
    async (args) => {
        await services.initI18n(args);
    }
);

const getLanguages = createAsyncThunk<TLanguages, void, IAsyncThunkConfig>(
    'languages/getLanguages',
    (_, thunkAPI) => {
        return services.getLanguages(thunkAPI.signal);
    }
);

const changeLanguage = createAsyncThunk<void, TLocale, IAsyncThunkConfig>(
    'languages/changeLanguage',
    async (language, thunkAPI) => {
        const session = getState(thunkAPI, 'session');

        const prevLanguage = i18n.language;

        const change = services
            .changeLanguage({key: language}, thunkAPI.signal)
            .then(() => undefined);

        const promises: Promise<void>[] = [change];

        if (session.status === 'AUTHENTICATED') {
            promises.push(
                thunkAPI
                    .dispatch(updateGeneralSettings({locale: language}))
                    .then(async (res) => {
                        if (res.meta.requestStatus === 'rejected') {
                            await change;
                            await services.changeLanguage(
                                {key: prevLanguage},
                                thunkAPI.signal
                            );
                        }
                    })
            );
        }

        await Promise.all(promises);
    }
);

export {changeLanguage, getLanguages, initI18n};
