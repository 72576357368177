import {useTheme} from '@mcal/core-react';
import type {FC} from 'react';
import {useMemo} from 'react';

interface IColors {
    colorA: string;
    colorB: string;
}

interface IDistanceSvgProps {
    height?: number | string;
    width?: number | string;
    fill?: string;
}

const DistanceSvg: FC<IDistanceSvgProps> = ({fill, ...props}) => {
    const {type} = useTheme();

    const {colorA, colorB} = useMemo<IColors>(() => {
        if (type === 'dark') {
            return {
                colorA: '#AAAEB4',
                colorB: '#D9D9D9'
            };
        } else {
            return {
                colorA: '#555C69',
                colorB: '#D9D9D9'
            };
        }
    }, [type]);
    return (
        <svg
            xmlns={'http://www.w3.org/2000/svg'}
            width={'20'}
            height={'20'}
            viewBox={'0 0 20 20'}
            fill={'none'}
            {...props}
        >
            <mask
                id={'mask0_80_945'}
                maskUnits={'userSpaceOnUse'}
                x={'0'}
                y={'0'}
                width={'20'}
                height={'20'}
            >
                <rect width={'20'} height={'20'} fill={colorB} />
            </mask>
            <g mask={'url(#mask0_80_945)'}>
                <path
                    d={
                        'M10 18C8.5 18 7.29167 17.7812 6.375 17.3438C5.45833 16.9062 5 16.3333 5 15.625C5 15.1806 5.19444 14.7847 5.58333 14.4375C5.97222 14.0903 6.52778 13.8125 7.25 13.6042L7.66667 15.0417C7.38889 15.1389 7.16319 15.2292 6.98958 15.3125C6.81597 15.3958 6.65278 15.5069 6.5 15.6458C6.61111 15.8403 7.04167 16.0312 7.79167 16.2188C8.54167 16.4062 9.27778 16.5 10 16.5C10.7778 16.5 11.5347 16.4062 12.2708 16.2188C13.0069 16.0312 13.4167 15.8194 13.5 15.5833C13.3056 15.4444 13.1215 15.3333 12.9479 15.25C12.7743 15.1667 12.5694 15.0903 12.3333 15.0208L12.75 13.5833C13.4722 13.7917 14.0278 14.0729 14.4167 14.4271C14.8056 14.7813 15 15.1806 15 15.625C15 16.3333 14.5417 16.9062 13.625 17.3438C12.7083 17.7812 11.5 18 10 18ZM10 15.5C9.86111 15.5 9.73264 15.4583 9.61458 15.375C9.49653 15.2917 9.40972 15.1736 9.35417 15.0208C9.03472 14.0625 8.64931 13.2674 8.19792 12.6354C7.74653 12.0035 7.29861 11.4028 6.85417 10.8333C6.42361 10.2639 6.05208 9.67014 5.73958 9.05208C5.42708 8.43403 5.27083 7.65972 5.27083 6.72917C5.27083 5.38194 5.72222 4.25694 6.625 3.35417C7.52778 2.45139 8.65278 2 10 2C11.3472 2 12.4722 2.45139 13.375 3.35417C14.2778 4.25694 14.7292 5.38194 14.7292 6.72917C14.7292 7.65972 14.5764 8.43403 14.2708 9.05208C13.9653 9.67014 13.5972 10.2639 13.1667 10.8333C12.7361 11.4028 12.2917 12 11.8333 12.625C11.375 13.25 10.9861 14.0417 10.6667 15C10.6111 15.1528 10.5243 15.2743 10.4062 15.3646C10.2882 15.4549 10.1528 15.5 10 15.5ZM10 8.41667C10.4722 8.41667 10.8715 8.25347 11.1979 7.92708C11.5243 7.60069 11.6875 7.20139 11.6875 6.72917C11.6875 6.25694 11.5243 5.85417 11.1979 5.52083C10.8715 5.1875 10.4722 5.02083 10 5.02083C9.52778 5.02083 9.12847 5.1875 8.80208 5.52083C8.47569 5.85417 8.3125 6.25694 8.3125 6.72917C8.3125 7.20139 8.47569 7.60069 8.80208 7.92708C9.12847 8.25347 9.52778 8.41667 10 8.41667Z'
                    }
                    fill={fill || colorA}
                />
            </g>
        </svg>
    );
};

export {DistanceSvg};
