import type {
    IDSAllTokens,
    ITheme,
    TThemeType
} from '../../defines/theme.types.js';
import {createElevationDescriptor} from '../create-elevation-descriptor/create-elevation-descriptor.js';
import {createPalette} from '../create-palette/create-palette.js';

interface IThemeOptions {
    key: string;
    type: TThemeType;
    tokens: IDSAllTokens;
}

function createTheme({key, type, tokens}: IThemeOptions): ITheme {
    const {color, size, opacity, breakpoint, depth, corner, padding, margin} =
        tokens;

    const palette = createPalette(color, type);

    return {
        tokens,
        key,
        type,
        palette,
        size,

        elevation: {
            e0: createElevationDescriptor('e0', palette.neutral.shadow),
            e1: createElevationDescriptor('e1', palette.neutral.shadow),
            e2: createElevationDescriptor('e2', palette.neutral.shadow),
            e3: createElevationDescriptor('e3', palette.neutral.shadow),
            e4: createElevationDescriptor('e4', palette.neutral.shadow),
            e5: createElevationDescriptor('e5', palette.neutral.shadow)
        },

        opacity,
        breakpoint,

        query: {
            xs: '@media (min-width: 0px)',
            s: `@media (min-width: ${breakpoint.xs}px)`,
            m: `@media (min-width: ${breakpoint.s}px)`,
            l: `@media (min-width: ${breakpoint.m}px)`,
            xl: `@media (min-width: ${breakpoint.l}px)`,
            xxl: `@media (min-width: ${breakpoint.xl}px)`,

            rtl: '[dir="rtl"] &'
        },

        depth,

        typography: {
            variant: {
                h1: {
                    component: 'h1',
                    typeface: 'normal',
                    styling: 'normal',
                    size: size.font.s7,
                    weight: 400,
                    align: undefined,
                    color: palette.accent.primary.high.base,
                    decoration: 'none',
                    shouldWrap: true,
                    lineHeight: 'normal',
                    letterSpacing: 'normal',
                    stretch: 'normal'
                },
                h2: {
                    component: 'h2',
                    typeface: 'normal',
                    styling: 'normal',
                    size: size.font.s6,
                    weight: 400,
                    align: undefined,
                    color: palette.accent.primary.high.base,
                    decoration: 'none',
                    shouldWrap: true,
                    lineHeight: 'normal',
                    letterSpacing: 'normal',
                    stretch: 'normal'
                },
                h3: {
                    component: 'h3',
                    typeface: 'normal',
                    styling: 'normal',
                    size: size.font.s5,
                    weight: 400,
                    align: undefined,
                    color: palette.accent.primary.high.base,
                    decoration: 'none',
                    shouldWrap: true,
                    lineHeight: 'normal',
                    letterSpacing: 'normal',
                    stretch: 'normal'
                },
                h4: {
                    component: 'h4',
                    typeface: 'normal',
                    styling: 'normal',
                    size: size.font.s4,
                    weight: 400,
                    align: undefined,
                    color: palette.surface.onBase.high,
                    decoration: 'none',
                    shouldWrap: true,
                    lineHeight: 'normal',
                    letterSpacing: 'normal',
                    stretch: 'normal'
                },
                h5: {
                    component: 'h5',
                    typeface: 'normal',
                    styling: 'normal',
                    size: size.font.s3,
                    weight: 400,
                    align: undefined,
                    color: palette.surface.onBase.low,
                    decoration: 'none',
                    shouldWrap: true,
                    lineHeight: 'normal',
                    letterSpacing: 'normal',
                    stretch: 'normal'
                },
                h6: {
                    component: 'h6',
                    typeface: 'normal',
                    styling: 'normal',
                    size: size.font.s2,
                    weight: 400,
                    align: undefined,
                    color: palette.surface.onBase.low,
                    decoration: 'none',
                    shouldWrap: true,
                    lineHeight: 'normal',
                    letterSpacing: 'normal',
                    stretch: 'normal'
                },
                p: {
                    component: 'p',
                    typeface: 'normal',
                    styling: 'normal',
                    size: size.font.s3,
                    weight: 400,
                    align: undefined,
                    color: palette.surface.onBase.high,
                    decoration: 'none',
                    shouldWrap: true,
                    lineHeight: 'normal',
                    letterSpacing: 'normal',
                    stretch: 'normal'
                },
                span: {
                    component: 'span',
                    typeface: 'normal',
                    styling: 'normal',
                    size: size.font.s3,
                    weight: 400,
                    align: undefined,
                    color: palette.surface.onBase.high,
                    decoration: 'none',
                    shouldWrap: true,
                    lineHeight: 'normal',
                    letterSpacing: 'normal',
                    stretch: 'normal'
                },
                caption: {
                    component: 'span',
                    typeface: 'normal',
                    styling: 'normal',
                    size: size.font.s4,
                    weight: 400,
                    align: undefined,
                    color: palette.surface.onBase.high,
                    decoration: 'none',
                    shouldWrap: true,
                    lineHeight: 'normal',
                    letterSpacing: 'normal',
                    stretch: 'normal'
                },
                button: {
                    component: 'label',
                    typeface: 'normal',
                    styling: 'normal',
                    size: size.font.s4,
                    weight: 400,
                    align: undefined,
                    color: palette.surface.onBase.high,
                    decoration: 'none',
                    shouldWrap: true,
                    lineHeight: 'normal',
                    letterSpacing: 'normal',
                    stretch: 'normal'
                },
                tooltip: {
                    component: 'span',
                    typeface: 'normal',
                    styling: 'normal',
                    size: size.font.s4,
                    weight: 400,
                    align: undefined,
                    color: palette.surface.onBase.high,
                    decoration: 'none',
                    shouldWrap: true,
                    lineHeight: 'normal',
                    letterSpacing: 'normal',
                    stretch: 'normal'
                },
                monospace: {
                    component: 'span',
                    typeface: 'monospace',
                    styling: 'normal',
                    size: size.font.s3,
                    weight: 400,
                    align: undefined,
                    color: palette.surface.onBase.high,
                    decoration: 'none',
                    shouldWrap: true,
                    lineHeight: 'normal',
                    letterSpacing: 'normal',
                    stretch: 'normal'
                }
            },
            base: {
                typeface: 'normal',
                styling: 'normal',
                size: '16px',
                weight: 400,
                color: palette.surface.onBase.high
            }
        },

        shape: {
            corner,
            padding,
            margin
        },

        state: {
            enabled: {
                elevation: createElevationDescriptor(
                    'e0',
                    palette.neutral.shadow
                ),
                opacity: {
                    base: opacity.o10,
                    overlay: opacity.o0
                }
            },
            disabled: {
                elevation: createElevationDescriptor(
                    'e0',
                    palette.neutral.shadow
                ),
                opacity: {
                    base: opacity.o3,
                    overlay: opacity.o0
                }
            },
            hovered: {
                elevation: createElevationDescriptor(
                    'e1',
                    palette.neutral.shadow
                ),
                opacity: {
                    base: opacity.o9,
                    overlay: opacity.o1
                }
            },
            focused: {
                elevation: createElevationDescriptor(
                    'e0',
                    palette.neutral.shadow
                ),
                opacity: {
                    base: opacity.o9,
                    overlay: opacity.o2
                }
            },
            activated: {
                elevation: createElevationDescriptor(
                    'e0',
                    palette.neutral.shadow
                ),
                opacity: {
                    base: opacity.o9,
                    overlay: opacity.o2
                }
            },
            pressed: {
                elevation: createElevationDescriptor(
                    'e0',
                    palette.neutral.shadow
                ),
                opacity: {
                    base: opacity.o9,
                    overlay: opacity.o2
                }
            },
            dragged: {
                elevation: createElevationDescriptor(
                    'e4',
                    palette.neutral.shadow
                ),
                opacity: {
                    base: opacity.o8,
                    overlay: opacity.o2
                }
            }
        },

        animation: {
            presets: {
                standard: {
                    mass: 1,
                    tension: 170,
                    friction: 26,
                    precision: 9
                },
                gentle: {
                    mass: 5,
                    tension: 120,
                    friction: 4,
                    precision: 9
                },
                wobbly: {
                    mass: 5,
                    tension: 180,
                    friction: 12,
                    precision: 9
                },
                stiff: {
                    mass: 5,
                    tension: 210,
                    friction: 20,
                    precision: 9
                },
                slow: {
                    mass: 5,
                    tension: 280,
                    friction: 60,
                    precision: 9
                },
                molasses: {
                    mass: 5,
                    tension: 280,
                    friction: 120,
                    precision: 9
                }
            }
        }
    };
}

export {createTheme};
