import type {INotification} from '../../defines/platform.types.js';

interface INotificationsSliceState {
    notifications: INotification[];
}

const initialState = {
    notifications: []
} satisfies INotificationsSliceState as INotificationsSliceState;

export type {INotificationsSliceState};
export {initialState};
