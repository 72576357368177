import type {
    ILabelBaseProps,
    TComponentColor,
    TPaletteEmphasis
} from '@mcal/core-react';
import {cn} from '@mcal/core-react';
import type {FC} from 'react';
import {createTestIDs} from '../../dev/index.js';
import {StyledRoot} from './label.styles.js';

const ownTestIDs = createTestIDs('Label', ['root']);

interface ILabelProps extends ILabelBaseProps<typeof ownTestIDs> {
    accent?: TComponentColor;
    emphasis?: TPaletteEmphasis;
    level?: 'base' | 'onBase';
    className?: string;
    classes?: {
        root?: string;
    };
}

const Label: FC<ILabelProps> = ({
    children,
    className = '',
    classes = {},
    testIDs = {},
    type = 'small',
    lineHeight,
    ...rest
}) => {
    return (
        <StyledRoot
            testIDs={testIDs.root ? testIDs : ownTestIDs}
            className={cn(className, classes.root)}
            type={type}
            variant={'span'}
            $lineHeight={lineHeight}
            {...rest}
        >
            {children}
        </StyledRoot>
    );
};

export type {ILabelProps};
export {Label, ownTestIDs as testIDs};
