import {ObjectUtils} from '@mcal/core';
import type {
    ICornerDescriptor,
    IDSAllTokens,
    TDSBreakpointTokens,
    TDSColor,
    TDSColorTokens,
    TDSCorner,
    TDSDepthTokens,
    TDSMarginTokens,
    TDSOpacityTokens,
    TDSPaddingTokens,
    TDSPaletteInput,
    TDSSizeTokens
} from '../../defines/theme.types.js';
import {createColorScales} from '../create-color-scales/create-color-scales.js';
import {tokens} from './defines/default-tokens.js';

interface ITokensOverrides {
    color?: {
        [k in TDSColor]?: Partial<TDSColorTokens>;
    };
    size?: {
        root: Partial<TDSSizeTokens>;
        parent: Partial<TDSSizeTokens>;
        fixed: Partial<TDSSizeTokens>;
        font: Partial<TDSSizeTokens>;
    };
    opacity?: Partial<TDSOpacityTokens>;
    breakpoint?: Partial<TDSBreakpointTokens>;
    depth?: {
        global: Partial<TDSDepthTokens>;
        local: Partial<TDSDepthTokens>;
    };
    corner?: {
        rounded: {
            [k in TDSCorner]?: Partial<ICornerDescriptor>;
        };
    };
    padding?: Partial<TDSPaddingTokens>;
    margin?: Partial<TDSMarginTokens>;
}

function createTokens(
    colors: TDSPaletteInput,
    overrides: ITokensOverrides = {}
): IDSAllTokens {
    const color = createColorScales(colors);

    return ObjectUtils.deepMerge(
        {
            ...tokens,
            color
        },
        overrides
    );
}

export type {ITokensOverrides};
export {createTokens};
