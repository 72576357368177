import {useTheme} from '@mcal/core-react';
import type {FC} from 'react';
import {useMemo} from 'react';

interface IColors {
    colorA: string;
}

interface IDropdownSvgProps {
    height?: number | string;
    width?: number | string;
    direction?: 'left' | 'right' | 'top' | 'bottom';
    fill?: string;
}

const DIRECTIONS = {
    bottom: 'rotate(90deg)',
    left: 'rotate(180deg)',
    top: 'rotate(270deg)',
    right: 'rotate(0)'
};
const DropdownSvg: FC<IDropdownSvgProps> = ({
    direction = 'right',
    fill,
    ...props
}) => {
    const {type} = useTheme();

    const {colorA} = useMemo<IColors>(() => {
        if (type === 'dark') {
            return {
                colorA: '#AAAEB4'
            };
        } else {
            return {
                colorA: '#555C69'
            };
        }
    }, [type]);
    return (
        <svg
            xmlns={'http://www.w3.org/2000/svg'}
            style={{transform: DIRECTIONS[direction]}}
            width={'7'}
            height={'10'}
            viewBox={'0 0 7 10'}
            fill={fill}
            {...props}
        >
            <g clipPath={'url(#clip0_1379_801)'}>
                <path
                    d={'M6 3.75L3.52513 1.27513L1.0503 3.74996'}
                    stroke={fill || colorA}
                />
                <path
                    d={'M6 6.47485L3.52513 8.94973L1.0503 6.4749'}
                    stroke={fill || colorA}
                />
            </g>
            <defs>
                <clipPath id={'clip0_1379_801'}>
                    <rect width={'7'} height={'10'} fill={'white'} />
                </clipPath>
            </defs>
        </svg>
    );
};

export {DropdownSvg};
