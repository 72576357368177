import {useTheme} from '@mcal/core-react';
import type {FC} from 'react';
import {useMemo} from 'react';

interface IDark {
    colorA: string;
    colorB: string;
    colorC: string;
    colorD: string;
    colorE: string;
    colorF: string;
}

interface IDarkSvgProps {
    height?: number | string;
    width?: number | string;
}

const DarkSvg: FC<IDarkSvgProps> = (props) => {
    const {type} = useTheme();

    const {colorA, colorB, colorC, colorD, colorE, colorF} =
        useMemo<IDark>(() => {
            if (type === 'dark') {
                return {
                    colorA: '#0A0B0D',
                    colorB: '#0F1013',
                    colorC: '#D9D9D9',
                    colorD: '#22252A',
                    colorE: '#33373F',
                    colorF: '#777D87'
                };
            } else {
                return {
                    colorA: '#0A0B0D',
                    colorB: '#0F1013',
                    colorC: '#D9D9D9',
                    colorD: '#22252A',
                    colorE: '#33373F',
                    colorF: '#777D87'
                };
            }
        }, [type]);

    return (
        <svg
            xmlns={'http://www.w3.org/2000/svg'}
            width={'64'}
            height={'40'}
            viewBox={'0 0 64 40'}
            fill={'none'}
            {...props}
        >
            <rect
                x={'0.5'}
                y={'0.5'}
                width={'63'}
                height={'39'}
                rx={'3.5'}
                fill={colorA}
                stroke={colorB}
            />
            <mask
                id={'mask0_968_2808'}
                maskUnits={'userSpaceOnUse'}
                x={'1'}
                y={'1'}
                width={'62'}
                height={'38'}
            >
                <rect
                    x={'1'}
                    y={'1'}
                    width={'62'}
                    height={'38'}
                    rx={'3'}
                    fill={colorC}
                />
            </mask>
            <g mask={'url(#mask0_968_2808)'}>
                <rect
                    x={'8.5'}
                    y={'8.5'}
                    width={'60'}
                    height={'36'}
                    rx={'1.5'}
                    fill={colorD}
                    stroke={colorE}
                />
                <rect
                    x={'12'}
                    y={'12'}
                    width={'34'}
                    height={'7'}
                    rx={'2'}
                    fill={colorF}
                />
                <rect
                    x={'12'}
                    y={'24'}
                    width={'48'}
                    height={'2'}
                    fill={colorF}
                />
                <rect
                    x={'12'}
                    y={'29'}
                    width={'48'}
                    height={'2'}
                    fill={colorF}
                />
                <rect
                    x={'12'}
                    y={'34'}
                    width={'48'}
                    height={'2'}
                    fill={colorF}
                />
            </g>
        </svg>
    );
};

export {DarkSvg};
