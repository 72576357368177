import type {TReadingDirection} from '../../defines/common.types.js';
import {useTranslation} from '../use-translation/use-translation.js';

function useDirection(): TReadingDirection {
    const {i18n} = useTranslation();

    return i18n.dir();
}

export {useDirection};
