import type {ICommonHeaders, IResponseMeta} from '@mcal/core';
import {ObjectUtils} from '@mcal/core';
import type {
    ISliceRemote,
    IStateWithRemotes,
    TRemoteKeys,
    TRemoteUpdateTargets
} from '../../defines/redux.types.js';
import {ESliceRemoteStatus} from '../../defines/redux.types.js';

interface IFulfilledMeta {
    arg: object | void;
    requestId: string;
    requestStatus: 'fulfilled';
}

interface IFulfilledPayload<TData> {
    headers: ICommonHeaders;
    error: null;
    status: number;
    meta: IResponseMeta;
    data?: TData;
}

const updateMetadata = (remote: ISliceRemote<object>, now: number): void => {
    if (!Object.keys(remote.updates).length) {
        remote.status = ESliceRemoteStatus.Ready;
    }

    remote.updatedAt = now;
    remote.errorCode = null;
};

type TTargets<TState extends IStateWithRemotes, TData> = (
    | TRemoteUpdateTargets<TState, TData>
    | TRemoteKeys<TState>
)[];

interface IFulfilledRemoteUpdateConfig<
    TState extends IStateWithRemotes,
    TData
> {
    state: TState;
    meta: IFulfilledMeta;
    payload: IFulfilledPayload<TData>;
    targets: TTargets<TState, TData>;
}

function fulfilledRemoteUpdate<TState extends IStateWithRemotes, TData>({
    state,
    meta,
    payload,
    targets
}: IFulfilledRemoteUpdateConfig<TState, TData>): void {
    const {requestId} = meta;

    const data = payload.data as TData;

    const now = Date.now();

    for (const target of targets) {
        if (typeof target === 'object') {
            // CASE: FRESH DATA AVAILABLE

            const remote = state.remotes[target.key];

            if ('mutator' in target) {
                // HERE WE EXPECT THE MUTATOR TO MUTATE THE CURRENT STATE
                target.mutator(data, remote.current);
            } else if ('replacer' in target) {
                // HERE WE EXPECT THE REPLACER TO RETURN A NEW COMPLETE OBJECT
                remote.current = target.replacer(data, remote.current);
            } else {
                // HERE WE MUTATE THE CURRENT STATE DEEPLY WITH THE OPTIMISTIC UPDATE
                remote.current = ObjectUtils.deepAssign(
                    remote.current,
                    target.merger(data, remote.current),
                    {
                        knownOnly: true,
                        skipUndefined: true
                    }
                );
            }

            delete remote.updates[requestId];

            updateMetadata(remote, now);
        } else {
            // CASE: NO FRESH DATA AVAILABLE

            const remote = state.remotes[target];

            delete remote.updates[requestId];

            updateMetadata(remote, now);
        }
    }
}

export {fulfilledRemoteUpdate};
