enum EWSServerMessageType {
    // CONNECTION
    Ping = 'server-ping',
    Pong = 'server-pong',
    Error = 'server-error',
    Handover = 'server-handover',
    Subscriptions = 'server-subscriptions',

    // ELEVATOR
    ElevatorTripStarted = 'ElevatorTripStarted',
    ElevatorTripReported = 'ElevatorTripReported',
    ElevatorTripEnded = 'ElevatorTripEnded',
    ElevatorDoorOpened = 'ElevatorDoorOpened',
    ElevatorDoorClosed = 'ElevatorDoorClosed',
    ElevatorIssueStarted = 'ElevatorIssueStarted',
    ElevatorIssueReported = 'ElevatorIssueReported',
    ElevatorIssueEnded = 'ElevatorIssueEnded',
    ElevatorStatusChanged = 'ElevatorStatusChanged',
    ElevatorDeviceStatusChanged = 'ElevatorDeviceStatusChanged',
    ElevatorAnomalyReported = 'ElevatorAnomalyReported',
    ElevatorOperationModeChanged = 'ElevatorOperationModeChanged',
    ElevatorShadowUpdated = 'ElevatorShadowUpdated',
    ElevatorDeviceICUDUpdated = 'ElevatorDeviceICUDUpdated',
    ElevatorDeviceHUFDUpdated = 'ElevatorDeviceHUFDUpdated',
    ElevatorDeviceSensorsUpdated = 'ElevatorDeviceSensorsUpdated',
    ElevatorEmergencyAcknowledge = 'ElevatorEmergencyAcknowledge',
    ElevatorEmergencyRestored = 'ElevatorEmergencyRestored',
    ElevatorEmergencyReported = 'ElevatorEmergencyReported',
    ElevatorGeneralSettingsUpdated = 'ElevatorGeneralSettingsUpdated',

    // SITE
    ElevatorRegistered = 'ElevatorRegistered',

    // KPIS
    KPIRTReported = 'KPIRTReported'
}

enum EWSClientMessageType {
    // CONNECTION
    Ping = 'client-ping',
    Pong = 'client-pong',
    Restore = 'client-restore',
    Subscribe = 'client-subscribe',
    Unsubscribe = 'client-unsubscribe',
    Subscriptions = 'client-subscriptions'
}

type TWSMessageType = EWSServerMessageType | EWSClientMessageType;

enum EWSChannel {
    Connection = 'Connection',
    ElevatorState = 'ElevatorState',
    ElevatorKpis = 'ElevatorKpis',
    ElevatorSetup = 'ElevatorSetup',
    SiteState = 'SiteState'
}

interface IWSMessage<
    TType extends TWSMessageType,
    TEntityId extends string | null,
    TChannel extends EWSChannel,
    TPayload extends object | void
> {
    id: string;
    type: TType;
    timestamp: number;
    channel: TChannel;
    entityId: TEntityId;
    payload: TPayload;
}

type TUnknownWSMessage = IWSMessage<
    TWSMessageType,
    string | null,
    EWSChannel,
    object | void
>;

type TUnknownWSServerMessage = IWSMessage<
    EWSServerMessageType,
    string | null,
    EWSChannel,
    object | void
>;

type TUnknownWSClientMessage = IWSMessage<
    EWSClientMessageType,
    string | null,
    EWSChannel,
    object | void
>;

type TUnknownWSEntityMessage = IWSMessage<
    EWSServerMessageType,
    string,
    EWSChannel,
    object | void
>;

type TWSMessagesConfig<TEvents extends TUnknownWSMessage[]> = {
    [k in TEvents[number] as k['type']]: k;
};

interface IWSEntitySubscription {
    entityId: string;
    channels: EWSChannel[];
}

interface IWSUpgradeQuery {
    restore?: string;
}

export type {
    IWSEntitySubscription,
    IWSMessage,
    IWSUpgradeQuery,
    TUnknownWSClientMessage,
    TUnknownWSEntityMessage,
    TUnknownWSMessage,
    TUnknownWSServerMessage,
    TWSMessageType,
    TWSMessagesConfig
};
export {EWSChannel, EWSClientMessageType, EWSServerMessageType};
