import type {
    TAPIRequestContent,
    TAPIResponse,
    TLogInAPI,
    TLogInChallengeAPI
} from '@mcal/core';
import {request} from '../../../utils/request/request.js';

const logIn = (
    {query, body}: TAPIRequestContent<TLogInAPI>,
    signal: AbortSignal
): TAPIResponse<TLogInAPI> => {
    return request<TLogInAPI>({
        method: 'POST',
        url: '/log-in',
        signal,
        query,
        body
    });
};

const logInChallenge = (
    {query, body}: TAPIRequestContent<TLogInChallengeAPI>,
    signal: AbortSignal
): TAPIResponse<TLogInChallengeAPI> => {
    return request<TLogInChallengeAPI>({
        method: 'POST',
        url: '/log-in/challenge',
        signal,
        query,
        body
    });
};

export {logIn, logInChallenge};
