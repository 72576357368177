import {
    changeLanguage,
    initI18n
} from '../../services/locize/languages/languages.thunks.js';
import {selectAppLanguages, selectAppStatus} from './app.selectors.js';
import {appSlice} from './app.slice.js';
import {loadApp} from './app.thunks.js';

const appActions = appSlice.actions;

const appSelectors = {
    selectAppStatus,
    selectAppLanguages
};

const appThunks = {
    loadApp,
    changeLanguage,
    initI18n
};

export {appActions, appSelectors, appSlice, appThunks};
