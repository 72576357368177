import type {TNestedPartial} from '@mcal/core';
import type {IPartialState} from '../../defines/redux.types.js';

type TFullState = Required<IPartialState>;

type TGetter =
    | IPartialState
    | {
          getState: () => IPartialState;
      };

function getState<TKey extends keyof TFullState>(
    getter: TGetter,
    key: TKey
): TFullState[TKey];

function getState<
    TKey extends keyof TFullState,
    TDef extends TNestedPartial<TFullState[TKey]>
>(getter: TGetter, key: TKey, defaultValue: TDef): TFullState[TKey] | TDef;

function getState<
    TKey extends keyof TFullState,
    TDef extends TNestedPartial<TFullState[TKey]>
>(getter: TGetter, key: TKey, defaultValue?: TDef): TFullState[TKey] | TDef {
    let state: IPartialState;

    if ('getState' in getter) {
        state = getter.getState();
    } else {
        state = getter;
    }

    if (state[key]) {
        return state[key] as TFullState[TKey];
    } else if (typeof defaultValue !== 'undefined') {
        return defaultValue;
    } else {
        throw new Error(`Expected ${key} to be defined in the state.`);
    }
}

export {getState};
