import {useContext, useId, useLayoutEffect, useMemo} from 'react';
import type {TSpinnerConditions} from '../../contexts/spinner-store/spinner-store.js';
import {SpinnerContext} from '../../contexts/spinner-store/spinner-store.js';

function useSpinner(conditions: TSpinnerConditions): void {
    const {subscribe, unsubscribe} = useContext(SpinnerContext);

    const id = useId();

    const shouldSubscribe = useMemo<boolean>(() => {
        if (Array.isArray(conditions)) {
            return conditions.some(Boolean);
        }

        return conditions;
    }, [conditions]);

    useLayoutEffect(() => {
        if (shouldSubscribe) {
            subscribe(id);
        }

        return () => {
            unsubscribe(id);
        };
    }, [id, shouldSubscribe, subscribe, unsubscribe]);
}

export {useSpinner};
