import type {IResolvedPermissions, TPermission} from '@mcal/core';
import {EEntityType, PermissionUtils} from '@mcal/core';
import {useCallback, useMemo} from 'react';
import type {TSelector} from '../../defines/redux.types.js';
import {selectElevatorPermissions} from '../../slices/elevator/elevator.selectors.js';
import {selectServiceCompanyPermissions} from '../../slices/service-company/service-company.selectors.js';
import {selectSitePermissions} from '../../slices/site/site.selectors.js';
import {useSelector} from '../use-selector/use-selector.js';

type TEntity =
    | EEntityType.ServiceCompany
    | EEntityType.Site
    | EEntityType.Elevator;

interface ISelectors {
    permissionsSelector: TSelector<IResolvedPermissions>;
}

const selectorsMap: Record<TEntity, ISelectors> = {
    [EEntityType.ServiceCompany]: {
        permissionsSelector: selectServiceCompanyPermissions
    },
    [EEntityType.Site]: {
        permissionsSelector: selectSitePermissions
    },
    [EEntityType.Elevator]: {
        permissionsSelector: selectElevatorPermissions
    }
};

// MUST BE DECLARED HERE SO IT DOES NOT CREATE MEMOIZATION ISSUES
const empty: IResolvedPermissions = {
    permissions: [],
    grants: [],
    access: [],
    isOwner: false,
    isPlatformMember: false,
    trace: []
};

const noop: ISelectors = {
    permissionsSelector: (): IResolvedPermissions => empty
};

interface IOutput<T> extends IResolvedPermissions {
    has: (r: TPermission[]) => boolean;
    result: T;
}

function usePermissions(
    entityType: EEntityType | null,
    required: TPermission[]
): IOutput<boolean>;

function usePermissions(entityType: EEntityType | null): IOutput<null>;

function usePermissions(
    entityType: EEntityType | null,
    required?: TPermission[]
): IOutput<boolean | null> {
    const {permissionsSelector} = useMemo<ISelectors>(() => {
        if (entityType && entityType in selectorsMap) {
            return selectorsMap[entityType as TEntity];
        } else {
            return noop;
        }
    }, [entityType]);

    const {permissions, ...rest} = useSelector(permissionsSelector);

    const has = useCallback(
        (r: TPermission[]): boolean => {
            return PermissionUtils.hasPermission(permissions, r);
        },
        [permissions]
    );

    return useMemo(() => {
        if (required) {
            return {
                permissions,
                has,
                result: has(required),
                ...rest
            };
        } else {
            return {
                permissions,
                has,
                result: null,
                ...rest
            };
        }
    }, [has, permissions, required, rest]);
}

export {usePermissions};
