import {useTheme} from '@mcal/core-react';
import type {FC} from 'react';
import {useMemo} from 'react';

interface IColors {
    colorA: string;
}

interface IDoubleChevronSvgProps {
    height?: number | string;
    width?: number | string;
    color?: string;
    direction?: 'left' | 'right' | 'top' | 'bottom';
}

const DIRECTIONS = {
    bottom: 'rotate(90deg)',
    left: 'rotate(180deg)',
    top: 'rotate(270deg)',
    right: 'rotate(0)'
};

const DoubleChevronSvg: FC<IDoubleChevronSvgProps> = ({
    direction = 'left',
    color,
    ...props
}) => {
    const {type} = useTheme();

    const {colorA} = useMemo<IColors>(() => {
        if (type === 'dark') {
            return {
                colorA: '#AAAEB4'
            };
        } else {
            return {
                colorA: '#555C69'
            };
        }
    }, [type]);

    return (
        <svg
            xmlns={'http://www.w3.org/2000/svg'}
            width={'10'}
            height={'10'}
            viewBox={'0 0 10 10'}
            fill={'none'}
            style={{transform: DIRECTIONS[direction]}}
            {...props}
        >
            <g>
                <path
                    fillRule={'evenodd'}
                    clipRule={'evenodd'}
                    d={
                        'M0.853516 1.1109L0.146409 1.81801L3.32839 4.99999L0.146409 8.18197L0.853516 8.88908L4.7426 4.99999L0.853516 1.1109Z'
                    }
                    fill={color || colorA}
                />
                <path
                    fillRule={'evenodd'}
                    clipRule={'evenodd'}
                    d={
                        'M5.85352 1.1109L5.14641 1.81801L8.32839 4.99999L5.14641 8.18197L5.85352 8.88908L9.7426 4.99999L5.85352 1.1109Z'
                    }
                    fill={color || colorA}
                />
            </g>
            <defs>
                <clipPath id={'clip0_657_4167'}>
                    <rect
                        width={'10'}
                        height={'9'}
                        fill={'white'}
                        transform={'matrix(-1 0 0 1 5 0.5)'}
                    />
                </clipPath>
            </defs>
        </svg>
    );
};

export type {IDoubleChevronSvgProps};
export {DoubleChevronSvg};

//<path
//fillRule={'evenodd'}
//clipRule={'evenodd'}
//d={'M9.14648 1.1109L9.85359 1.81801L6.67161 4.99999L9.85359 8.18197L9.14648 8.88908L5.2574 4.99999L9.14648 1.1109Z'}
//fill={colorA}
///>
