import {useCallback} from 'react';
import {generatePath, useParams} from 'react-router-dom';

const useGeneratePath = (): ((link: string) => string) => {
    const params = useParams();

    return useCallback(
        (link: string) => {
            const paramsKeys = Object.keys(params);

            if (
                !paramsKeys.length ||
                !paramsKeys.some((key) => link.includes(`:${key}`))
            ) {
                return link;
            }

            return generatePath(link, params);
        },
        [params]
    );
};

export {useGeneratePath};
