import type {ICssBaselineBaseProps} from '@mcal/core-react';
import {useDirection, useFontsStyles, useTheme} from '@mcal/core-react';
import type {FC} from 'react';
import {useLayoutEffect} from 'react';
import {GlobalStyles} from '../../utils/global-styles/global-styles.js';

interface ICssBaselineProps extends ICssBaselineBaseProps {}

const transitionSelectors = {
    main: 'html, body, div:not([data-no-transition]), span:not([data-no-transition])',
    svg: 'svg, svg *'
};

const hideSelectors = {
    mapbox: '.mapboxgl-control-container'
};

const CssBaseline: FC<ICssBaselineProps> = () => {
    const {palette, typography} = useTheme();

    const direction = useDirection();

    const fontsStyles = useFontsStyles(typography.base.typeface);

    useLayoutEffect(() => {
        const body = document.getElementsByTagName('BODY');

        if (body.length) {
            body[0].setAttribute('dir', direction);
        }
    }, [direction]);

    return (
        <GlobalStyles
            styles={{
                'html, body': {
                    ...fontsStyles,

                    fontStyle: typography.base.styling,
                    fontSize: typography.base.size,
                    fontWeight: typography.base.weight,
                    color: typography.base.color,

                    boxSizing: 'border-box',
                    margin: 0,
                    padding: 0,
                    backgroundColor: palette.background.normal,
                    WebkitFontSmoothing: 'antialiased',
                    MozOsxFontSmoothing: 'grayscale'
                },

                '*': {
                    boxSizing: 'border-box'
                },

                [transitionSelectors.main]: {
                    transition: 'background 300ms linear, color 300ms linear'
                },

                [transitionSelectors.svg]: {
                    transition: 'fill 300ms linear, color 300ms linear'
                },

                [hideSelectors.mapbox]: {
                    display: 'none'
                }
            }}
        />
    );
};

export type {ICssBaselineProps};
export {CssBaseline};
