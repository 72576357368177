import {useTheme} from '@mcal/core-react';
import type {FC} from 'react';
import {useMemo} from 'react';

interface IColors {
    colorA: string;
}

interface ISyncSvgProps {
    height?: number | string;
    width?: number | string;
    reverse?: boolean;
    fill?: string;
}

const SyncSvg: FC<ISyncSvgProps> = ({fill, reverse, ...props}) => {
    const {type} = useTheme();

    const {colorA} = useMemo<IColors>(() => {
        if (type === 'dark') {
            return {
                colorA: reverse ? '#2406CD' : '#111215'
            };
        } else {
            return {
                colorA: reverse ? '#111215' : '#2406CD'
            };
        }
    }, [reverse, type]);

    return (
        <svg
            width={'24'}
            height={'24'}
            viewBox={'0 0 24 24'}
            fill={'none'}
            xmlns={'http://www.w3.org/2000/svg'}
            {...props}
        >
            <path
                d={
                    'M18 6.5L16.5 6.5L16.5 8.04167C15.9444 7.41667 15.2813 6.92014 14.5104 6.55208C13.7396 6.18403 12.9028 6 12 6C10.5972 6 9.36458 6.42361 8.30208 7.27083C7.23958 8.11806 6.54167 9.19444 6.20833 10.5L7.77083 10.5C8.09028 9.625 8.62847 8.90625 9.38542 8.34375C10.1424 7.78125 11.0139 7.5 12 7.5C12.6667 7.5 13.2847 7.63542 13.8542 7.90625C14.4236 8.17708 14.9097 8.54167 15.3125 9L14 9L14 10.5L18 10.5L18 6.5ZM17.7917 13.5L16.2292 13.5C15.9097 14.375 15.3715 15.0937 14.6146 15.6562C13.8576 16.2187 12.9861 16.5 12 16.5C11.3333 16.5 10.7153 16.3646 10.1458 16.0937C9.57639 15.8229 9.09028 15.4583 8.6875 15L10 15L10 13.5L6 13.5L6 17.5L7.5 17.5L7.5 15.9583C8.05556 16.5833 8.71875 17.0799 9.48958 17.4479C10.2604 17.816 11.0972 18 12 18C13.4028 18 14.6354 17.5764 15.6979 16.7292C16.7604 15.8819 17.4583 14.8056 17.7917 13.5Z'
                }
                fill={fill || colorA}
            />
        </svg>
    );
};

export {SyncSvg};
