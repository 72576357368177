import {
    appSlice,
    axios,
    configureStore,
    elevatorSlice,
    logInSlice,
    notificationsMiddleware,
    notificationsSlice,
    platformSlice,
    serviceCompanySlice,
    sessionSlice,
    sessionThunks,
    signUpSlice,
    siteSlice,
    socketsSlice,
    userSlice
} from '@mcal/core-react';
import {fontsMiddleware, socketsMiddleware} from '@mcal/core-react-web';

const store = configureStore({
    reducer: {
        [appSlice.reducerPath]: appSlice.reducer,
        [notificationsSlice.reducerPath]: notificationsSlice.reducer,
        [sessionSlice.reducerPath]: sessionSlice.reducer,
        [logInSlice.reducerPath]: logInSlice.reducer,
        [signUpSlice.reducerPath]: signUpSlice.reducer,
        [userSlice.reducerPath]: userSlice.reducer,
        [siteSlice.reducerPath]: siteSlice.reducer,
        [elevatorSlice.reducerPath]: elevatorSlice.reducer,
        [serviceCompanySlice.reducerPath]: serviceCompanySlice.reducer,
        [platformSlice.reducerPath]: platformSlice.reducer,
        [socketsSlice.reducerPath]: socketsSlice.reducer
    },
    middleware: (getDefaultMiddleware) => {
        return getDefaultMiddleware()
            .prepend(notificationsMiddleware)
            .prepend(socketsMiddleware)
            .prepend(fontsMiddleware);
    },
    devTools: process.env.ENV !== 'prd'
});

axios.setup({
    baseUrl: process.env.BASE_URL,
    ignore: ['/session/refresh-token'],
    onTokenRefresh: async () => {
        await store.dispatch(sessionThunks.refreshToken()).unwrap();
    }
});

export {store};
