import {createGradient} from '@mcal/core-react';
import {animated} from '@react-spring/web';
import {styled} from '../../utils/styled/styled.js';

const Container = styled('div')(({theme}) => ({
    position: 'fixed',
    zIndex: theme.depth.global.z8,
    width: 'auto',
    bottom: '30px',
    margin: '0 auto',
    left: '30px',
    right: '30px',
    display: 'flex',
    flexDirection: 'column',
    pointerEvents: 'none',
    alignItems: 'center',

    [theme.query.s]: {
        alignItems: 'flex-end'
    }
}));

const Message = styled(animated.div)(({theme}) => ({
    boxSizing: 'border-box',
    position: 'relative',
    overflow: 'hidden',
    width: '100%',

    [theme.query.s]: {
        width: '40ch'
    }
}));

const Content = styled('div')(({theme}) => ({
    color: theme.palette.neutral.onBase,
    background: theme.palette.neutral.base,
    opacity: 0.9,
    padding: '12px 22px',
    fontSize: '1em',
    display: 'grid',
    gridTemplateColumns: '1fr auto',
    gridGap: '10px',
    overflow: 'hidden',
    height: 'auto',
    borderRadius: '3px',
    marginTop: '10px'
}));

const Button = styled('button')(({theme}) => ({
    cursor: 'pointer',
    pointerEvents: 'all',
    outline: 0,
    border: 'none',
    background: 'transparent',
    display: 'flex',
    alignSelf: 'flex-end',
    overflow: 'hidden',
    margin: 0,
    padding: 0,
    paddingBottom: '14px',
    color: theme.palette.neutral.onBase,
    opacity: theme.opacity.o7,

    '&:hover': {
        opacity: theme.opacity.o10
    }
}));

const Life = styled(animated.div)(({theme}) => ({
    position: 'absolute',
    bottom: 0,
    left: 0,
    width: 'auto',
    backgroundImage: createGradient({
        type: 'linear',
        angle: 130,
        colorA: theme.palette.accent.info.low.base,
        colorB: theme.palette.accent.success.low.base
    }),
    height: '5px'
}));

export {Button, Container, Content, Life, Message};
