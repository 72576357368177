import type {
    TAPIRequestContent,
    TAPIResponse,
    TPlatformTeamDeleteAPI,
    TPlatformTeamGetAPI,
    TPlatformTeamUpdateAPI
} from '@mcal/core';
import {request} from '../../../utils/request/request.js';

const getPlatformTeam = (
    {query}: TAPIRequestContent<TPlatformTeamGetAPI>,
    signal: AbortSignal
): TAPIResponse<TPlatformTeamGetAPI> => {
    return request<TPlatformTeamGetAPI>({
        method: 'GET',
        url: '/platform/team',
        signal,
        query
    });
};

const updatePlatformTeam = (
    {query, body}: TAPIRequestContent<TPlatformTeamUpdateAPI>,
    signal: AbortSignal
): TAPIResponse<TPlatformTeamUpdateAPI> => {
    return request<TPlatformTeamUpdateAPI>({
        method: 'PATCH',
        url: '/platform/team',
        signal,
        query,
        body
    });
};

const deletePlatformTeam = (
    {query, body}: TAPIRequestContent<TPlatformTeamDeleteAPI>,
    signal: AbortSignal
): TAPIResponse<TPlatformTeamDeleteAPI> => {
    return request<TPlatformTeamDeleteAPI>({
        method: 'DELETE',
        url: '/platform/team',
        signal,
        query,
        body
    });
};

export {deletePlatformTeam, getPlatformTeam, updatePlatformTeam};
