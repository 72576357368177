import type {TPermission} from '@mcal/core';
import {EEntityType, PermissionUtils} from '@mcal/core';
import type {ILink} from '@mcal/core-react';
import {usePermissions, useSelector, userSelectors} from '@mcal/core-react';
import type {FC} from 'react';
import {useEffect, useMemo} from 'react';
import {Outlet, useNavigate} from 'react-router-dom';
import {Menu} from '../../../../components/menu/menu.js';
import {RouteWrapper} from '../../../../components/route-wrapper/route-wrapper.js';
import {SpinnerStore} from '../../../../contexts/spinner-store/spinner-store.js';
import {StyledOutletContainer, StyledRoot} from './menu-wrapper.styles.js';

interface IMenuLink extends ILink {
    permissions: TPermission[];
}

interface ILinkContainer {
    entityType: EEntityType | null;
    links: IMenuLink[];
}

interface IMenuWrapperProps {
    linksContainer: ILinkContainer;
}

const MenuWrapper: FC<IMenuWrapperProps> = ({linksContainer}) => {
    const isLogged = useSelector(userSelectors.selectUserIsLogged);
    const navigate = useNavigate();
    const {permissions} = usePermissions(linksContainer.entityType);

    useEffect(() => {
        if (!isLogged) {
            navigate('/log-in');
        }
    });

    const entityType = useMemo(() => {
        if (linksContainer.entityType === EEntityType.Site) {
            return EEntityType.Site;
        }
        if (linksContainer.entityType === EEntityType.ServiceCompany) {
            return EEntityType.ServiceCompany;
        }

        return null;
    }, [linksContainer.entityType]);

    const content = (
        <StyledRoot>
            <Menu
                links={linksContainer.links.filter((link) => {
                    return PermissionUtils.hasPermission(
                        permissions,
                        link.permissions,
                        'some'
                    );
                })}
            />

            <SpinnerStore component={StyledOutletContainer} suspense={true}>
                <Outlet />
            </SpinnerStore>
        </StyledRoot>
    );

    if (entityType) {
        return <RouteWrapper entityType={entityType}>{content}</RouteWrapper>;
    }

    return content;
};

export type {ILinkContainer, IMenuLink, IMenuWrapperProps};
export {MenuWrapper};
