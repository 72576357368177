import type {
    EEntityType,
    IElevatorSummary,
    IResolvedPermissions,
    IServiceCompanyGeneralSettings,
    IServiceCompanyProfile,
    IServiceCompanyState,
    IServiceCompanySummary,
    ISiteSummary,
    TTechnicalParametersTemplate
} from '@mcal/core';
import {createSelector} from '@reduxjs/toolkit';
import type {ITeamMember} from '../../defines/platform.types.js';
import type {
    IPartialState,
    TEntitySliceStatus
} from '../../defines/redux.types.js';
import {composeTeam} from '../../utils/compose-team/compose-team.js';
import type {IServiceCompanySliceState} from './service-company.state.js';

const serviceCompanySelector = (
    state: IPartialState
): IServiceCompanySliceState => {
    if (!state.serviceCompany) {
        throw new Error('ServiceCompany slice is not available.');
    }

    return state.serviceCompany;
};

const selectServiceCompanySummary = createSelector(
    [serviceCompanySelector],
    (serviceCompany): IServiceCompanySummary => {
        return serviceCompany.remotes.serviceCompanySummary.current;
    }
);

const selectServiceCompanyProfile = createSelector(
    [serviceCompanySelector],
    (serviceCompany): IServiceCompanyProfile => {
        return serviceCompany.remotes.serviceCompanyProfile.current;
    }
);

const selectServiceCompanyGeneralSettings = createSelector(
    [serviceCompanySelector],
    (serviceCompany): IServiceCompanyGeneralSettings => {
        return serviceCompany.remotes.serviceCompanyGeneralSettings.current;
    }
);

const selectServiceCompanyStatus = createSelector(
    [serviceCompanySelector],
    (serviceCompany): TEntitySliceStatus => {
        return serviceCompany.status;
    }
);

const selectServiceCompanyState = createSelector(
    [serviceCompanySelector],
    (serviceCompany): IServiceCompanyState => {
        return serviceCompany.remotes.serviceCompanyState.current;
    }
);

const selectServiceCompanyTemplates = createSelector(
    [serviceCompanySelector],
    (serviceCompany): TTechnicalParametersTemplate[] => {
        return serviceCompany.remotes.templates.current;
    }
);

const selectSites = createSelector(
    [serviceCompanySelector],
    (serviceCompany): ISiteSummary[] => {
        return serviceCompany.remotes.sites.current;
    }
);

const selectServiceCompanyPermissions = createSelector(
    [serviceCompanySelector],
    (serviceCompany): IResolvedPermissions => {
        return serviceCompany.remotes.permissions.current;
    }
);

const selectServiceCompanyTeam = createSelector(
    [serviceCompanySelector],
    (serviceCompany): ITeamMember<EEntityType.ServiceCompany>[] => {
        return composeTeam({
            team: serviceCompany.remotes.team.current,
            memberships: serviceCompany.remotes.memberships.current,
            invites: serviceCompany.remotes.invites.current
        });
    }
);

const selectElevators = createSelector(
    [serviceCompanySelector],
    (serviceCompany): IElevatorSummary[] => {
        return serviceCompany.remotes.elevators.current;
    }
);

export {
    selectElevators,
    selectServiceCompanyGeneralSettings,
    selectServiceCompanyPermissions,
    selectServiceCompanyProfile,
    selectServiceCompanyState,
    selectServiceCompanyStatus,
    selectServiceCompanySummary,
    selectServiceCompanyTeam,
    selectServiceCompanyTemplates,
    selectSites
};
