import type {IBoxBaseProps} from '@mcal/core-react';
import {cn, resolveShapeValue} from '@mcal/core-react';
import {forwardRef} from 'react';
import {createTestIDs} from '../../dev/index.js';
import {StyledRoot} from './box.styles.js';

const ownTestIDs = createTestIDs('Box', ['root']);

interface IBoxProps extends IBoxBaseProps<typeof ownTestIDs> {
    className?: string;
    classes?: {
        root?: string;
    };
}

const Box = forwardRef<HTMLDivElement, IBoxProps>(
    (
        {
            children,
            margin = 'm0',
            padding = 'p0',
            elevation = 'e0',
            corner = 'c0',
            level = undefined,
            className = '',
            classes = {},
            testIDs = {},
            ...rest
        },
        ref
    ) => {
        return (
            <StyledRoot
                {...rest}
                ref={ref}
                data-testid={testIDs.root || ownTestIDs.root}
                className={cn(className, classes.root)}
                margin={resolveShapeValue('m0', margin)}
                padding={resolveShapeValue('p0', padding)}
                elevation={elevation}
                corner={corner}
                level={level}
            >
                {children}
            </StyledRoot>
        );
    }
);

Box.displayName = 'Box';

export type {IBoxProps};
export {Box, ownTestIDs as testIDs};
