type TPattern = string | number | RegExp;

function checkPattern(
    pattern: TPattern,
    key: string | number,
    token: string
): boolean {
    if (typeof pattern === 'string') {
        if (typeof key === 'string') {
            return pattern === key;
        }
    } else if (typeof pattern === 'number') {
        if (typeof key === 'number') {
            return pattern === key;
        }
    } else {
        return pattern.test(token);
    }

    return false;
}

export type {TPattern};
export {checkPattern};
