import type {MutableRefObject} from 'react';
import {useCallback, useEffect, useRef} from 'react';

type TOnClickOutsideCallback = (e: MouseEvent | TouchEvent) => void;

interface IUseOnClickOutsideReturn<T extends HTMLElement> {
    ref: MutableRefObject<T | null>;
}

const useOnClickOutside = <T extends HTMLElement>(
    cb?: TOnClickOutsideCallback,
    disable?: boolean
): IUseOnClickOutsideReturn<T> => {
    const ref = useRef<T | null>(null);
    const isOutsideRef = useRef<boolean | null>(null);

    const onStartHandler = useCallback((e: MouseEvent | TouchEvent) => {
        if (e.type === 'touchstart') {
            if ((e as TouchEvent).touches.length !== 1) {
                // RESET WHEN MORE THAN ONE TOUCH POINT IS DETECTED
                isOutsideRef.current = false;
                return;
            }
        } else {
            if ((e as MouseEvent).button !== 0) {
                // RESET WHEN MOUSE BUTTONS OTHER THAN PRIMARY ARE DETECTED
                isOutsideRef.current = false;
                return;
            }
        }

        isOutsideRef.current =
            ref.current && !ref.current.contains(e.target as Node);
    }, []);

    const onEndHandler = useCallback(
        (e: MouseEvent | TouchEvent) => {
            if (!isOutsideRef.current) {
                return;
            }

            let target: EventTarget | null;

            if (e.type === 'touchend') {
                target = document.elementFromPoint(
                    (e as TouchEvent).changedTouches[0].clientX,
                    (e as TouchEvent).changedTouches[0].clientY
                );
            } else {
                target = e.target;
            }

            if (ref.current && !ref.current.contains(target as Node)) {
                if (cb) {
                    cb(e);
                }
            }

            isOutsideRef.current = false;
        },
        [cb]
    );

    useEffect(() => {
        if (disable) {
            return (): void => undefined;
        } else {
            const opts = {capture: true};

            window.addEventListener('touchstart', onStartHandler, opts);
            window.addEventListener('mousedown', onStartHandler, opts);
            window.addEventListener('touchend', onEndHandler, opts);
            window.addEventListener('mouseup', onEndHandler, opts);

            return () => {
                window.removeEventListener('touchstart', onStartHandler, opts);
                window.removeEventListener('mousedown', onStartHandler, opts);
                window.removeEventListener('touchend', onEndHandler, opts);
                window.removeEventListener('mouseup', onEndHandler, opts);
            };
        }
    }, [disable, onStartHandler, onEndHandler]);

    return {
        ref
    };
};

export type {IUseOnClickOutsideReturn, TOnClickOutsideCallback};
export {useOnClickOutside};
