import type {TRequestStatus} from '@mcal/core';
import {useContext, useMemo, useSyncExternalStore} from 'react';
import {FormatsContext} from '../../contexts/formats-store/formats-store.js';
import type {
    ISnapshot,
    IStoreAPI
} from '../../modules/object-urls/object-urls.js';
import {objectURLs} from '../../modules/object-urls/object-urls.js';

interface IUseObjectURLsOptions {
    baseURL?: string;
    withCredentials?: boolean;
    keep?: boolean;
    progress?: boolean;
    invariant?: boolean;
}

interface IUseObjectURLsResult {
    objectURL: string | null;
    status: TRequestStatus;
    progress: number | null;
}

function useObjectURLs(
    src: string,
    options: IUseObjectURLsOptions = {}
): IUseObjectURLsResult {
    const {locale} = useContext(FormatsContext);

    const {subscribe, getSnapshot} = useMemo<IStoreAPI>(() => {
        return objectURLs.createStoreAPI(src, {
            baseURL: options.baseURL,
            locale,
            withCredentials: options.withCredentials,
            progress: options.progress,
            invariant: options.invariant
        });
    }, [
        locale,
        options.baseURL,
        options.invariant,
        options.progress,
        options.withCredentials,
        src
    ]);

    const {objectURL, status, progress} = useSyncExternalStore<ISnapshot>(
        subscribe,
        getSnapshot
    );

    return {
        objectURL,
        progress,
        status
    };
}

export type {IUseObjectURLsOptions, IUseObjectURLsResult};
export {useObjectURLs};
