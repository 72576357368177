import type {CSSObject} from '@emotion/react';
import {autoPlacement, autoUpdate, useFloating} from '@floating-ui/react-dom';
import type {IOption} from '@mcal/core';
import type {TTestIDs} from '@mcal/core-react';
import {cn} from '@mcal/core-react';
import type {ElementType, FC, MouseEvent} from 'react';
import {useCallback, useMemo, useState} from 'react';
import {createTestIDs} from '../../dev/index.js';
import type {TOnChange} from '../../hooks/use-input-control/use-input-control.js';
import {useInputControl} from '../../hooks/use-input-control/use-input-control.js';
import {useOnClickOutside} from '../../hooks/use-on-click-outside/use-on-click-outside.js';
import {DropdownSvg} from '../../icons/dropdown.svg.js';
import {Label} from '../label/label.js';
import {
    StyledLabelContainer,
    StyledOption,
    StyledOptionsContainer,
    StyledRoot
} from './select.styles.js';

const ownTestIDs = createTestIDs('Select', ['root']);

type TSelectPosition = 'bottomLeft' | 'bottomRight' | 'topLeft' | 'topRight';
interface ISelectPosition extends CSSObject {
    left?: string;
    top?: string;
    bottom?: string;
    right?: string;
}

interface ISelectProps {
    className?: string;
    size?: 'small' | 'medium' | 'large';
    defaultValue?: IOption | string;
    label?: string;
    onChange?: TOnChange<IOption>;
    onClick?: (option: IOption) => void;
    onContextMenu?: (option: IOption) => void;
    onSelect?: (option: IOption) => void;
    options: IOption[];
    placeholder?: string;
    testIDs?: TTestIDs<typeof ownTestIDs>;
    trigger?: 'CLICK' | 'CONTEXT-MENU';
    position?: TSelectPosition | ISelectPosition;
    disabled?: boolean;
    value?: IOption | string;
    variant?: 'text';
    PlaceholderComponent?: ElementType;
    activateFloating?: boolean;
    classes?: {
        root?: string;
    };
}

const Select: FC<ISelectProps> = ({
    variant,
    value,
    size,
    defaultValue,
    options,
    onChange,
    onClick,
    onSelect,
    onContextMenu,
    placeholder = '',
    trigger = 'CLICK',
    testIDs = {},
    className = '',
    disabled,
    classes = {},
    position,
    activateFloating = false,
    PlaceholderComponent
}) => {
    const placeholderOption = useMemo(() => {
        return {
            label: placeholder,
            value: ''
        };
    }, [placeholder]);

    const serializer = useCallback((value: string | IOption) => {
        if (typeof value === 'string') {
            return value;
        } else {
            return value.value;
        }
    }, []);

    const parser = useCallback(
        (value: string | IOption) => {
            if (typeof value === 'string') {
                const match = options.find((option) => option.value === value);

                if (match) {
                    return match;
                } else {
                    return placeholderOption;
                }
            } else {
                return value;
            }
        },
        [options, placeholderOption]
    );

    const control = useInputControl<IOption>({
        value,
        defaultValue: defaultValue || placeholderOption,
        onChange,
        serializer,
        parser
    });

    const [isOpen, setIsOpen] = useState<boolean>(false);

    const onSelectionHandler = useCallback(
        (o: IOption): void => {
            if (onSelect) {
                onSelect(o);
                setIsOpen(false);
                return;
            }
            control.setValue(o);
            setIsOpen(false);
        },
        [control, onSelect]
    );

    const onClickHandler = useCallback((): void => {
        if (onClick) {
            onClick(control.value);
        }

        if (trigger === 'CLICK') {
            setIsOpen((currentOpen) => !currentOpen);
        }
    }, [control.value, onClick, trigger]);

    const onContextMenuHandler = useCallback(
        (e: MouseEvent): boolean => {
            if (onContextMenu) {
                onContextMenu(control.value);
            }

            if (trigger === 'CONTEXT-MENU') {
                e.preventDefault();

                setIsOpen((currentOpen) => !currentOpen);

                return false;
            }

            return true;
        },
        [control.value, onContextMenu, trigger]
    );

    const {refs, floatingStyles} = useFloating({
        middleware: [autoPlacement()],
        whileElementsMounted: autoUpdate,
        open: isOpen
    });

    const onClickOutsideHandler = useCallback(() => {
        setIsOpen(false);
    }, []);

    const bind = useOnClickOutside<HTMLDivElement>(onClickOutsideHandler);

    const Placeholder = useCallback(() => {
        return PlaceholderComponent ? (
            <PlaceholderComponent />
        ) : (
            <>
                <Label type={'small'}>{control.defaultValue.label}</Label>
                <DropdownSvg />
            </>
        );
    }, [PlaceholderComponent, control.defaultValue.label]);

    return (
        <StyledRoot
            data-testid={testIDs.root || ownTestIDs.root}
            className={cn(className, classes.root)}
            open={isOpen}
            disabled={disabled}
            {...bind}
        >
            <StyledLabelContainer
                size={size}
                variant={variant}
                onClick={onClickHandler}
                onContextMenu={onContextMenuHandler}
                ref={refs.setReference}
            >
                {control.value.value !== '' ? (
                    <>
                        <Label type={'small'}>{control.value.label}</Label>
                        <DropdownSvg />
                    </>
                ) : (
                    <Placeholder />
                )}
            </StyledLabelContainer>

            {isOpen && (
                <StyledOptionsContainer
                    ref={refs.setFloating}
                    style={activateFloating ? floatingStyles : {}}
                    position={position}
                >
                    {options.map((option) => {
                        return (
                            <StyledOption
                                key={option.value}
                                onClick={() => {
                                    onSelectionHandler({
                                        value: option.value,
                                        label: option.label
                                    });
                                }}
                            >
                                {option.label}
                            </StyledOption>
                        );
                    })}
                </StyledOptionsContainer>
            )}
        </StyledRoot>
    );
};

export type {ISelectPosition, ISelectProps, TSelectPosition};
export {Select, ownTestIDs as testIDs};
