import {styled} from '../../utils/styled/styled.js';

const StyledRoot = styled('div')(({theme}) => ({
    backgroundColor: theme.palette.surface.base.high,
    color: theme.palette.accent.primary.high.base,
    padding: `${theme.size.fixed.s1} ${theme.size.fixed.s1} ${theme.size.fixed.s1} 0px`,
    marginBottom: ''
}));

const StyledContainer = styled('div')(() => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
}));

const StyledLogoViewportLarge = styled('div')(({theme}) => ({
    display: 'none',
    [theme.query.xl]: {
        display: 'inline',
        padding: '10px 0px 10px 28px',
        width: '220px'
    }
}));

const StyledLogoViewportSmall = styled('div')(({theme}) => ({
    width: '60px',
    height: '40px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    [theme.query.xl]: {
        display: 'none'
    }
}));

const StyledProfile = styled('div')(({theme}) => ({
    display: 'flex',
    alignItems: 'center',
    borderRadius: '999px',
    border: `1px solid ${theme.palette.neutral.divide}`,
    width: '72px',
    padding: '4px 12px 4px 4px',
    height: '40px',
    justifyContent: 'space-between',
    cursor: 'pointer'
}));

const StyledViewportLarge = styled('div')(({theme}) => ({
    display: 'none',
    [theme.query.l]: {
        display: 'inline'
    }
}));

const StyledViewportSmall = styled('div')(({theme}) => ({
    [theme.query.l]: {
        display: 'none'
    }
}));

export {
    StyledContainer,
    StyledLogoViewportLarge,
    StyledLogoViewportSmall,
    StyledProfile,
    StyledRoot,
    StyledViewportLarge,
    StyledViewportSmall
};
