type TDecimalSeparator = '.' | ',';

const defaultDecimalSeparator: TDecimalSeparator = '.';

type TMeasureSystem = 'metric-mks' | 'metric-cgs' | 'imperial' | 'us-customary';

const defaultMeasureSystem: TMeasureSystem = 'metric-mks';

type TMeasureUnitKey =
    | 'temperature'
    | 'length'
    | 'time'
    | 'mass'
    | 'force'
    | 'energy'
    | 'power'
    | 'pressure'
    | 'area'
    | 'volume';

interface IMeasureUnit {
    key: string;
    symbol: string;
}

type TMeasureUnits = Record<TMeasureUnitKey, IMeasureUnit>;

const deg = String.fromCharCode(176);
const sup3 = String.fromCharCode(179);
const sup2 = String.fromCharCode(178);

type TMeasureUnitsSet = Record<TMeasureSystem, TMeasureUnits>;

const measureUnitsSet: TMeasureUnitsSet = {
    'metric-mks': {
        temperature: {key: 'celsius', symbol: `${deg}C`},
        length: {key: 'meters', symbol: 'm'},
        time: {key: 'seconds', symbol: 's'},
        mass: {key: 'kilograms', symbol: 'kg'},
        force: {key: 'newtons', symbol: 'N'},
        energy: {key: 'joules', symbol: 'J'},
        power: {key: 'watts', symbol: 'W'},
        pressure: {key: 'pascal', symbol: 'Pa'},
        area: {key: 'square-meters', symbol: `m${sup2}`},
        volume: {key: 'cubic-meters', symbol: `m${sup3}`}
    },
    'metric-cgs': {
        temperature: {key: 'celsius', symbol: `${deg}C`},
        length: {key: 'centimeters', symbol: 'cm'},
        time: {key: 'seconds', symbol: 's'},
        mass: {key: 'grams', symbol: 'g'},
        force: {key: 'dynes', symbol: 'dyn'},
        energy: {key: 'ergs', symbol: 'erg'},
        power: {key: 'ergs-per-second', symbol: 'erg/s'},
        pressure: {key: 'barye', symbol: 'Ba'},
        area: {key: 'square-centimeters', symbol: `cm${sup2}`},
        volume: {key: 'cubic-centimeters', symbol: `cm${sup3}`}
    },
    imperial: {
        temperature: {key: 'fahrenheit', symbol: `${deg}F`},
        length: {key: 'feet', symbol: 'ft'},
        time: {key: 'seconds', symbol: 's'},
        mass: {key: 'pounds', symbol: 'lbs'},
        force: {key: 'pounds-force', symbol: 'lbf'},
        energy: {key: 'foot-pounds', symbol: 'ft·lb'},
        power: {key: 'horsepower', symbol: 'hp'},
        pressure: {key: 'pounds-per-square-inch', symbol: 'psi'},
        area: {key: 'square-feet', symbol: `ft${sup2}`},
        volume: {key: 'cubic-feet', symbol: `ft${sup3}`}
    },
    'us-customary': {
        temperature: {key: 'fahrenheit', symbol: `${deg}F`},
        length: {key: 'inches', symbol: 'in'},
        time: {key: 'seconds', symbol: 's'},
        mass: {key: 'pounds', symbol: 'lbs'},
        force: {key: 'pounds-force', symbol: 'lbf'},
        energy: {key: 'foot-pounds', symbol: 'ft·lb'},
        power: {key: 'horsepower', symbol: 'hp'},
        pressure: {key: 'pounds-per-square-inch', symbol: 'psi'},
        area: {key: 'square-feet', symbol: `ft${sup2}`},
        volume: {key: 'gallons', symbol: 'gal'}
    }
};

type TChronosVariant =
    | 'shortDate'
    | 'longDate'
    | 'shortTime'
    | 'longTime'
    | 'fullChronos';

type TChronosVariantOptions = Record<TChronosVariant, string[]>;

type TChronosVariants = Record<TChronosVariant, string>;

const chronosVariantOptions: TChronosVariantOptions = {
    shortDate: [
        '[MM]/[DD]/[YYYY]',
        '[DD]/[MM]/[YYYY]',
        '[YYYY]/[MM]/[DD]',
        '[MM]/[DD]/[YY]',
        '[DD]/[MM]/[YY]',
        '[YY]/[MM]/[DD]'
    ],
    longDate: ['[DAY], [AMONTH] [DDS] [YYYY]'],
    shortTime: ['[hh]:[mm] [AM/PM]'],
    longTime: ['[hh]:[mm]:[ss] [AM/PM]'],
    fullChronos: ['[DAY], [MONTH] [DDS] [YYYY], [hh]:[mm]:[ss] [AM/PM]']
};

const defaultChronosVariants: TChronosVariants = {
    shortDate: '[MM]/[DD]/[YYYY]',
    longDate: '[DAY], [AMONTH] [DDS] [YYYY]',
    shortTime: '[hh]:[mm] [AM/PM]',
    longTime: '[hh]:[mm]:[ss] [AM/PM]',
    fullChronos: '[DAY], [MONTH] [DDS] [YYYY], [hh]:[mm]:[ss] [AM/PM]'
};

type TTimeZone = 'AUTO' | 'UTC' | 'US/Eastern' | 'Europe/Rome';

const defaultTimeZone: TTimeZone = 'AUTO';

type TCurrency = 'EUR' | 'USD';

const defaultCurrency: TCurrency = 'USD';

type TLocale = string;

const defaultLocale: TLocale = 'en-US';

interface IPhoneCode {
    name: string;
    code: string;
    emoji: string;
    unicode: string;
    prefix: string;
}

export type {
    IMeasureUnit,
    IPhoneCode,
    TChronosVariant,
    TChronosVariantOptions,
    TChronosVariants,
    TCurrency,
    TDecimalSeparator,
    TLocale,
    TMeasureSystem,
    TMeasureUnitKey,
    TMeasureUnits,
    TMeasureUnitsSet,
    TTimeZone
};
export {
    chronosVariantOptions,
    defaultChronosVariants,
    defaultCurrency,
    defaultDecimalSeparator,
    defaultLocale,
    defaultMeasureSystem,
    defaultTimeZone,
    measureUnitsSet
};
