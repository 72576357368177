import {defaultLocale, logger} from '@mcal/core';
import type {InitOptions, TFunction} from 'i18next';
import {default as i18n} from 'i18next';
import {default as LocizeBackend} from 'i18next-locize-backend';
import {initReactI18next} from 'react-i18next';

const log = logger.withCaller('i18n');

interface II18nConfig {
    backend: {
        LOCIZE_PROJECT_ID?: string;
        LOCIZE_API_KEY?: string;
    };
    options?: Partial<InitOptions>;
}

type TDefaultTFunction = TFunction<'core', undefined>;

function initI18n({backend, options}: II18nConfig): Promise<TDefaultTFunction> {
    const common = {
        lng: defaultLocale,
        fallbackLng: defaultLocale,
        ns: ['core', 'kpi'],
        fallbackNS: 'core',
        defaultNS: 'core',
        load: 'currentOnly',
        interpolation: {
            escapeValue: false // NOT NEEDED FOR REACT AS IT ESCAPES BY DEFAULT
        },
        react: {
            useSuspense: false
        }
    } satisfies InitOptions;

    if (i18n.isInitializing) {
        log.warn('i18n IS ALREADY INITIALIZING')();
        return Promise.resolve(i18n.t);
    }

    if (i18n.isInitialized) {
        log.warn('i18n IS ALREADY INITIALIZED')();
        return Promise.resolve(i18n.t);
    }

    if (!backend.LOCIZE_PROJECT_ID) {
        return i18n.use(initReactI18next).init({
            ...common,
            ...options
        });
    } else {
        return i18n
            .use(LocizeBackend)
            .use(initReactI18next)
            .init({
                backend: {
                    version: 'latest',
                    projectId: backend.LOCIZE_PROJECT_ID,
                    apiKey: backend.LOCIZE_API_KEY,
                    referenceLng: 'en-US'
                },
                ...common,
                ...options
            });
    }
}

export type {II18nConfig, TDefaultTFunction};
export {i18n, initI18n};
