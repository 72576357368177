import type {TDSBreakpoint} from '@mcal/core-react';
import {useTheme} from '@mcal/core-react';
import type {FC, ReactNode} from 'react';
import {
    createContext,
    useCallback,
    useLayoutEffect,
    useMemo,
    useState
} from 'react';

type TMediaQueryStrings = {
    [k in TDSBreakpoint]: string;
};

type TMediaQueryLists = {
    [k in TDSBreakpoint]: MediaQueryList;
};

interface IMediaQueriesContext {
    breakpoint: TDSBreakpoint | null;
}

const defaultValues: IMediaQueriesContext = {
    breakpoint: null
};

const MediaQueriesContext = createContext<IMediaQueriesContext>(defaultValues);

interface IMediaQueriesStoreProps {
    children: ReactNode;
}

const MediaQueriesStore: FC<IMediaQueriesStoreProps> = ({children}) => {
    const theme = useTheme();

    const mediaQueryStrings = useMemo<TMediaQueryStrings>(() => {
        return {
            xs: '(min-width: 0px)',
            s: `(min-width: ${theme.breakpoint.xs}px)`,
            m: `(min-width: ${theme.breakpoint.s}px)`,
            l: `(min-width: ${theme.breakpoint.m}px)`,
            xl: `(min-width: ${theme.breakpoint.l}px)`,
            xxl: `(min-width: ${theme.breakpoint.xl}px)`
        };
    }, [
        theme.breakpoint.l,
        theme.breakpoint.m,
        theme.breakpoint.s,
        theme.breakpoint.xl,
        theme.breakpoint.xs
    ]);

    const mediaQueryLists = useMemo<TMediaQueryLists>(() => {
        return {
            xs: window.matchMedia(mediaQueryStrings.xs),
            s: window.matchMedia(mediaQueryStrings.s),
            m: window.matchMedia(mediaQueryStrings.m),
            l: window.matchMedia(mediaQueryStrings.l),
            xl: window.matchMedia(mediaQueryStrings.xl),
            xxl: window.matchMedia(mediaQueryStrings.xxl)
        };
    }, [
        mediaQueryStrings.l,
        mediaQueryStrings.m,
        mediaQueryStrings.s,
        mediaQueryStrings.xl,
        mediaQueryStrings.xs,
        mediaQueryStrings.xxl
    ]);

    const getBreakpoint = useCallback((): TDSBreakpoint | null => {
        const entries = Object.entries(mediaQueryLists).reverse();

        const match = entries.find(([, v]) => {
            return v.matches;
        });

        if (match) {
            return match[0] as TDSBreakpoint;
        } else {
            return null;
        }
    }, [mediaQueryLists]);

    const [breakpoint, setBreakpoint] = useState<TDSBreakpoint | null>(
        getBreakpoint
    );

    const onChangeHandler = useCallback(() => {
        setBreakpoint(getBreakpoint());
    }, [getBreakpoint]);

    useLayoutEffect(() => {
        const lists = Object.values(mediaQueryLists);

        lists.forEach((list) => {
            list.addEventListener('change', onChangeHandler);
        });

        return () => {
            lists.forEach((list) => {
                list.removeEventListener('change', onChangeHandler);
            });
        };
    }, [mediaQueryLists, onChangeHandler]);

    const value = useMemo<IMediaQueriesContext>(() => {
        return {
            breakpoint
        };
    }, [breakpoint]);

    return (
        <MediaQueriesContext.Provider value={value}>
            {children}
        </MediaQueriesContext.Provider>
    );
};

export type {IMediaQueriesContext, IMediaQueriesStoreProps};
export {MediaQueriesContext, MediaQueriesStore};
