import {logger} from '../../../classes/logger/logger.js';
import {array} from '../../array-utils/index.js';
import type {TPattern} from './internals/check-pattern/check-pattern.js';
import {getFilteredEntries} from './internals/get-filtered-entries/get-filtered-entries.js';
import {getNextToken} from './internals/get-next-token/get-next-token.js';
import type {IConditions} from './internals/should-skip/should-skip.js';
import {shouldSkip} from './internals/should-skip/should-skip.js';

interface IForEachCallbackArgs {
    item: object;
    key: string | number;
    value: unknown;
    token: string;
    depth: number;
    isIterable: boolean;
    terminate: () => void;
    skip: () => void;
}

type TForEachCallback<TContext> = (
    args: IForEachCallbackArgs,
    context: TContext
) => TContext | void;

interface IForEachOptions<TContext> {
    backwards?: boolean;
    ignore?: TPattern | TPattern[];
    only?: TPattern | TPattern[];
    maxDepth?: number;
    skipRefs?: boolean;
    context?: TContext;
}

const log = logger.withCaller('ObjectUtils.forEach');

function forEach<TInput extends object, TContext>(
    input: TInput,
    callback: TForEachCallback<TContext>,
    options: IForEachOptions<TContext> = {}
): TInput {
    const state = {
        terminate: false,
        skip: false
    };

    const {only, ignore, skipRefs = true} = options;

    const conditions: IConditions = {
        only: typeof only === 'undefined' ? undefined : array(only),
        ignore: typeof ignore === 'undefined' ? undefined : array(ignore)
    };

    const refs = new WeakMap<object, number>([[input, 1]]);

    const recurse = (step: {
        item: object;
        entries: [string | number, unknown][];
        token: string;
        depth: number;
        context: TContext;
    }): void => {
        for (let i = 0; i < step.entries.length; i++) {
            if (state.terminate) {
                return;
            }

            const [key, value] = step.entries[i];

            const token = getNextToken(step.token, key);

            if (shouldSkip(conditions, key, token)) {
                continue;
            }

            const entries = getFilteredEntries(value);

            const isIterable = !!entries.length;

            const args = {
                item: step.item,
                key,
                value,
                token,
                depth: step.depth,
                isIterable,
                terminate(): void {
                    state.terminate = true;
                },
                skip(): void {
                    state.skip = true;
                }
            };

            let context = step.context;

            if (!options.backwards) {
                const result = callback(args, step.context) || step.context;

                if (state.terminate) {
                    return;
                } else if (state.skip) {
                    state.skip = false;
                    continue;
                } else if (result !== step.context) {
                    context = result;
                }
            }

            if (isIterable) {
                const item = value as object;

                if (!options.maxDepth || step.depth < options.maxDepth) {
                    const ref = refs.get(item);

                    if (ref === 0 && skipRefs) {
                        // KNOWN REFERENCE
                        continue;
                    }

                    if (ref === 1) {
                        // CIRCULAR REFERENCE
                        refs.set(item, 0);
                        continue;
                    }

                    refs.set(item, 1);

                    recurse({
                        item,
                        entries,
                        token,
                        depth: step.depth + 1,
                        context
                    });

                    refs.set(item, 0);
                }
            }

            if (options.backwards && !state.terminate) {
                const result = callback(args, step.context) || step.context;

                if (state.terminate) {
                    return;
                } else if (state.skip) {
                    state.skip = false;
                    log.warn(
                        'CANNOT SKIP BRANCH RECURSION IN BACKWARDS MODE'
                    )();
                } else if (result !== step.context) {
                    log.warn('CANNOT UPDATE CONTEXT IN BACKWARDS MODE')();
                }
            }
        }
    };

    recurse({
        item: input,
        entries: getFilteredEntries(input),
        token: '',
        depth: 0,
        context: options.context || ({} as TContext)
    });

    return input;
}

export type {IForEachCallbackArgs, IForEachOptions};
export {forEach};
