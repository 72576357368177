import {getNanoid} from '@mcal/core';
import type {PayloadAction} from '@reduxjs/toolkit';
import {createSlice} from '@reduxjs/toolkit';
import type {
    INotification,
    INotificationAdd,
    INotificationRemove
} from '../../defines/platform.types.js';
import {initialState} from './notifications.state.js';

const notificationsSlice = createSlice({
    name: 'notifications',
    initialState,
    reducers: {
        send: {
            reducer(state, action: PayloadAction<INotification>) {
                state.notifications.push(action.payload);
            },
            prepare({
                title,
                message,
                options = [],
                life = true
            }: INotificationAdd) {
                return {
                    payload: {
                        id: getNanoid(),
                        title,
                        message,
                        options,
                        life
                    }
                };
            }
        },
        remove(state, action: PayloadAction<INotificationRemove>) {
            state.notifications = state.notifications.filter(({id}) => {
                return id !== action.payload.id;
            });
        }
    }
});

export {notificationsSlice};
