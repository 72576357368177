import type {
    TAPIResponseSuccess,
    TSignUpCompleteAPI,
    TSignUpStartAPI,
    TSignUpVerifyEmailAPI
} from '@mcal/core';
import {MESSAGES, logger} from '@mcal/core';
import {createAsyncThunk} from '@reduxjs/toolkit';
import type {
    TAPIAsyncThunkArgs,
    TAPIAsyncThunkConfig
} from '../../../defines/redux.types.js';
import {getRejectValue} from '../../../utils/get-reject-value/get-reject-value.js';
import {getState} from '../../../utils/get-state/get-state.js';
import {getUser} from '../user/user.thunks.js';
import * as services from './sign-up.services.js';

const log = logger.withCaller('sign-up.thunks');

const start = createAsyncThunk<
    TAPIResponseSuccess<TSignUpStartAPI>,
    TAPIAsyncThunkArgs<TSignUpStartAPI>,
    TAPIAsyncThunkConfig<TSignUpStartAPI>
>('signUp/start', async (args, thunkAPI) => {
    const signUp = getState(thunkAPI, 'signUp');

    if (signUp.status !== 'INIT') {
        log.error(MESSAGES.ERROR.CLIENT.INTERNAL_ERROR.CODE)();

        return thunkAPI.rejectWithValue(getRejectValue<TSignUpStartAPI>());
    }

    const user = getState(thunkAPI, 'user');

    return await services
        .start(
            {
                query: {
                    locale: user.remotes.userGeneralSettings.current.locale
                },
                body: {
                    data: {
                        email: args.email
                    }
                }
            },
            thunkAPI.signal
        )
        .then((response) => {
            if (response.error) {
                return thunkAPI.rejectWithValue(response);
            } else {
                return thunkAPI.fulfillWithValue(response);
            }
        });
});

const verifyEmail = createAsyncThunk<
    TAPIResponseSuccess<TSignUpVerifyEmailAPI>,
    TAPIAsyncThunkArgs<
        TSignUpVerifyEmailAPI,
        void,
        'locale' | 'themePack' | 'themeType'
    >,
    TAPIAsyncThunkConfig<TSignUpVerifyEmailAPI>
>('signUp/verifyEmail', async (args, thunkAPI) => {
    const signUp = getState(thunkAPI, 'signUp');

    if (signUp.status !== 'VERIFY-EMAIL') {
        log.error(MESSAGES.ERROR.CLIENT.INTERNAL_ERROR.CODE)();

        return thunkAPI.rejectWithValue(
            getRejectValue<TSignUpVerifyEmailAPI>()
        );
    }

    const user = getState(thunkAPI, 'user');

    const generalSettings = user.remotes.userGeneralSettings.current;

    return await services
        .verifyEmail(
            {
                query: {locale: generalSettings.locale},
                body: {
                    data: {
                        email: args.email,
                        otp: args.otp,
                        locale: generalSettings.locale,
                        themePack: generalSettings.themePack,
                        themeType: generalSettings.themeType
                    }
                }
            },
            thunkAPI.signal
        )
        .then((response) => {
            if (response.error) {
                return thunkAPI.rejectWithValue(response);
            } else {
                return thunkAPI.fulfillWithValue(response);
            }
        });
});

const complete = createAsyncThunk<
    TAPIResponseSuccess<TSignUpCompleteAPI>,
    TAPIAsyncThunkArgs<TSignUpCompleteAPI>,
    TAPIAsyncThunkConfig<TSignUpCompleteAPI>
>('signUp/complete', async (args, thunkAPI) => {
    const signUp = getState(thunkAPI, 'signUp');

    if (signUp.status !== 'COMPLETE') {
        log.error(MESSAGES.ERROR.CLIENT.INTERNAL_ERROR.CODE)();

        return thunkAPI.rejectWithValue(getRejectValue<TSignUpCompleteAPI>());
    }

    const user = getState(thunkAPI, 'user');

    return await services
        .complete(
            {
                query: {
                    locale: user.remotes.userGeneralSettings.current.locale
                },
                body: {
                    data: {
                        email: args.email,
                        password: args.password
                    }
                }
            },
            thunkAPI.signal
        )
        .then((response) => {
            if (response.error) {
                return thunkAPI.rejectWithValue(response);
            } else {
                return Promise.all([thunkAPI.dispatch(getUser())]).then(() => {
                    return thunkAPI.fulfillWithValue(response);
                });
            }
        });
});

export {complete, start, verifyEmail};
