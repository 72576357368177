import {createSelector} from '@reduxjs/toolkit';
import type {INotification} from '../../defines/platform.types.js';
import type {IPartialState} from '../../defines/redux.types.js';
import type {INotificationsSliceState} from './notifications.state.js';

const notificationsSelector = (
    state: IPartialState
): INotificationsSliceState => {
    if (!state.notifications) {
        throw new Error('Notifications slice is not available.');
    }

    return state.notifications;
};

const selectNotifications = createSelector(
    [notificationsSelector],
    (notifications): INotification[] => {
        return notifications.notifications;
    }
);

export {selectNotifications};
