import {logger} from '@mcal/core';
import type {
    IDispatch,
    IDispatchExtEmpty,
    IFontResource,
    IPartialState
} from '@mcal/core-react';
import {
    appActions,
    appThunks,
    fontsResources,
    getState,
    i18n,
    userThunks
} from '@mcal/core-react';
import type {Middleware} from '@reduxjs/toolkit';

const log = logger.withCaller('fontsMiddleware');

interface IContext {
    lng: string | null;
    isLoading: boolean;
}

type TJob =
    | {
          task: 'ADD';
          font: IFontResource;
      }
    | {
          task: 'REMOVE';
          font: FontFace;
      };

const fontsMiddleware: Middleware<
    IDispatchExtEmpty,
    IPartialState,
    IDispatch // WE ASSUME THIS MIDDLEWARE TO BE USED ALONG ALL BASE MIDDLEWARES
> = (store) => (next) => {
    const ctx: IContext = {
        lng: null,
        isLoading: false
    };

    const onLoadingStartHandler = (): void => {
        if (!ctx.isLoading) {
            log.debug('FONTS STARTED LOADING')();

            store.dispatch(appActions.setLoadFontsStatus('LOADING'));

            ctx.isLoading = true;
        }
    };

    const onLoadingEndHandler = (): void => {
        if (ctx.isLoading) {
            log.debug('FONTS FINISHED LOADING')();

            store.dispatch(appActions.setLoadFontsStatus('IDLE'));

            ctx.isLoading = false;
        }
    };

    const onLoadingErrorHandler = (): void => {
        log.error('FONTS LOADING FAILED')();
    };

    // THE FOLLOWING EVENT LISTENERS ARE MEANT FOR REDUNDANCY AND EXTERNAL/AUTO FONT LOADING HANDLING
    document.fonts.addEventListener('loading', onLoadingStartHandler);
    document.fonts.addEventListener('loadingdone', onLoadingEndHandler);
    document.fonts.addEventListener('loadingerror', onLoadingErrorHandler);

    const run = (lng: string): void => {
        if (ctx.lng === lng) {
            return;
        } else {
            ctx.lng = lng;
        }

        log.debug(`LANGUAGE CHANGED: ${lng}`)();

        const app = getState(store, 'app');

        const config = app.config.fonts;

        const jobs: TJob[] = [];

        const fonts = fontsResources[lng] || fontsResources.default;

        Array.from(document.fonts.values()).forEach((f1) => {
            const match = fonts.find((f2) => {
                return (
                    f1.family === f2.family &&
                    f1.weight === f2.descriptors.weight
                );
            });

            if (!match) {
                jobs.push({
                    font: f1,
                    task: 'REMOVE'
                });
            }
        });

        fonts.forEach((f1) => {
            const match = Array.from(document.fonts.values()).find((f2) => {
                return (
                    f1.family === f2.family &&
                    f1.descriptors.weight === f2.weight
                );
            });

            if (!match) {
                jobs.push({
                    font: f1,
                    task: 'ADD'
                });
            }
        });

        if (!jobs.length) {
            return;
        }

        jobs.forEach(({task, font}): void => {
            switch (task) {
                case 'ADD': {
                    log.debug(`ADDING: ${font.id}`)();

                    const formats = Object.entries(font.formats);

                    const segments = formats.map(([format, url]) => {
                        return `url(${url}) format('${format}')`;
                    });

                    segments.unshift(`local('${font.family}')`);

                    const source = segments.join(', ');

                    const fontFace = new FontFace(
                        font.family,
                        source,
                        font.descriptors
                    );

                    document.fonts.add(fontFace);

                    switch (config.load) {
                        case 'ALL': {
                            void fontFace.load();

                            onLoadingStartHandler();

                            break;
                        }

                        case 'ESSENTIAL': {
                            if (font.essential) {
                                log.debug(`PRE-LOADING: ${font.id}`)();

                                void fontFace.load();

                                onLoadingStartHandler();
                            }

                            break;
                        }

                        default: {
                            break;
                        }
                    }

                    break;
                }

                case 'REMOVE': {
                    switch (config.unload) {
                        case 'ALL': {
                            log.debug(
                                `REMOVING: ${font.family}-${font.weight}`
                            )();

                            document.fonts.delete(font);

                            break;
                        }

                        default: {
                            break;
                        }
                    }

                    break;
                }

                default: {
                    throw new Error('UNKNOWN TASK');
                }
            }
        });

        void document.fonts.ready.finally(onLoadingEndHandler);
    };

    return (action) => {
        const result = next(action);

        if (appThunks.changeLanguage.pending.match(action)) {
            run(action.meta.arg);
        } else if (
            appThunks.changeLanguage.fulfilled.match(action) ||
            appThunks.initI18n.fulfilled.match(action) ||
            userThunks.getUser.fulfilled.match(action)
        ) {
            if (i18n.isInitialized) {
                run(i18n.language);
            }
        }

        return result;
    };
};

export {fontsMiddleware};
