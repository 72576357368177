interface IGetUserInitialsInput {
    line1?: string;
    line2?: string;
    firstName: string | null;
    lastName: string | null;
}

function getUserInitials({
    line1,
    line2,
    firstName,
    lastName
}: IGetUserInitialsInput): string {
    const words = line1 ? [line1] : [firstName || '', lastName || ''];

    const firstLetter = words[0].charAt(0).toUpperCase();

    let secondLetter = '';

    if (words.length > 1) {
        secondLetter = words[1].charAt(0).toUpperCase();
    } else if (line2) {
        secondLetter = line2.charAt(0).toUpperCase();
    }

    return `${firstLetter}${secondLetter}`;
}

export type {IGetUserInitialsInput};
export {getUserInitials};
