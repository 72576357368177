import type {INotificationAdd} from '@mcal/core-react';
import {
    notificationsActions,
    notificationsSelectors,
    useDispatch,
    useSelector
} from '@mcal/core-react';
import {useTransition} from '@react-spring/web';
import type {FC, MouseEvent} from 'react';
import {useMemo} from 'react';
import {X} from 'react-feather';
import {
    Button,
    Container,
    Content,
    Life,
    Message
} from './notifications.styles.js';

interface INotificationsProps {
    config?: {
        tension: number;
        friction: number;
        precision: number;
    };
    timeout?: number;
}

const Notifications: FC<INotificationsProps> = ({
    config = {tension: 125, friction: 20, precision: 0.1},
    timeout = 3000
}) => {
    const dispatch = useDispatch();

    const notifications = useSelector(
        notificationsSelectors.selectNotifications
    );

    const refMap = useMemo(
        () => new WeakMap<INotificationAdd, HTMLDivElement>(),
        []
    );

    const cancelMap = useMemo(
        () => new WeakMap<INotificationAdd, () => void>(),
        []
    );

    const transitions = useTransition(notifications, {
        from: {opacity: 0, height: 0, life: '100%'},

        keys: (item) => item.id,

        enter: (item) => async (next, cancel) => {
            cancelMap.set(item, cancel);

            const ref = refMap.get(item);

            if (!ref) {
                return;
            }

            await next({opacity: 1, height: ref.offsetHeight});

            if (item.life) {
                await next({life: '0%'});
            }
        },

        leave: [{opacity: 0}, {height: 0}],

        onRest: (result, __, item) => {
            if (item.life) {
                if (result.cancelled || result.value.opacity) {
                    dispatch(
                        notificationsActions.remove({
                            id: item.id,
                            reason: null
                        })
                    );
                }
            }
        },

        config: (_, __, phase) => (key) => {
            if (phase === 'enter' && key === 'life') {
                return {duration: timeout};
            } else {
                return config;
            }
        }
    });

    return (
        <Container>
            {transitions(({life, ...style}, item) => (
                <Message style={style}>
                    <Content
                        ref={(ref: HTMLDivElement) => {
                            return ref && refMap.set(item, ref);
                        }}
                    >
                        {item.life ? <Life style={{right: life}} /> : null}

                        <p>{item.message}</p>

                        <Button
                            onClick={(e: MouseEvent) => {
                                e.stopPropagation();

                                if (item.life) {
                                    const cancel = cancelMap.get(item);

                                    if (cancel && life.get() !== '0%') {
                                        cancel();
                                    }
                                } else {
                                    dispatch(
                                        notificationsActions.remove({
                                            id: item.id,
                                            reason: null
                                        })
                                    );
                                }
                            }}
                        >
                            <X size={18} />
                        </Button>
                    </Content>
                </Message>
            ))}
        </Container>
    );
};

export type {INotificationsProps};
export {Notifications};
