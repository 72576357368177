import type {TStore} from '@mcal/core-react';
import {
    FormatsStore,
    I18nStore,
    ProfileStore,
    ReduxStore,
    SocketStore,
    ThemeStore,
    TranslationsStore,
    appSelectors,
    useSelector,
    useThemePack,
    userSelectors
} from '@mcal/core-react';
import type {FC, ReactNode} from 'react';
import {MediaQueriesStore} from '../../contexts/media-queries-store/media-queries-store.js';
import {SpinnerStore} from '../../contexts/spinner-store/spinner-store.js';
import {useSpinner} from '../../hooks/use-spinner/use-spinner.js';
import {useThemeType} from '../../hooks/use-theme-type/use-theme-type.js';
import {CssBaseline} from '../css-baseline/css-baseline.js';
import {Notifications} from '../notifications/notifications.js';

interface ISubAppLayerProps {
    children: ReactNode;
}

const SubAppLayer: FC<ISubAppLayerProps> = ({children}) => {
    return (
        <SocketStore>
            <TranslationsStore>
                <SpinnerStore suspense={true} passthrough={true}>
                    {children}
                </SpinnerStore>
            </TranslationsStore>
        </SocketStore>
    );
};

interface IAppLayerProps {
    children: ReactNode;
}

const AppLayer: FC<IAppLayerProps> = ({children}) => {
    const appStatus = useSelector(appSelectors.selectAppStatus);

    useSpinner([
        appStatus === 'INIT',
        appStatus === 'LOADING',
        appStatus === 'UPDATING'
    ]);

    if (appStatus === 'INIT' || appStatus === 'LOADING') {
        return null;
    }

    if (appStatus === 'IDLE' || appStatus === 'UPDATING') {
        return <SubAppLayer>{children}</SubAppLayer>;
    }

    return <>{'ERROR'}</>;
};

interface ISubReduxLayerProps {
    children: ReactNode;
}

const SubReduxLayer: FC<ISubReduxLayerProps> = ({children}) => {
    const profile = useSelector(userSelectors.selectUserProfile);
    const generalSettings = useSelector(
        userSelectors.selectUserGeneralSettings
    );

    const themePack = useThemePack();
    const themeType = useThemeType();

    return (
        <I18nStore>
            <ThemeStore themePack={themePack} themeType={themeType}>
                <MediaQueriesStore>
                    <FormatsStore
                        formats={{
                            decimalSeparator: generalSettings.decimalSeparator,
                            measureSystem: generalSettings.measureSystem,
                            chronosVariants: {
                                shortDate: generalSettings.shortDate,
                                longDate: generalSettings.longDate,
                                shortTime: generalSettings.shortTime,
                                longTime: generalSettings.longTime,
                                fullChronos: generalSettings.fullChronos
                            },
                            locale: generalSettings.locale
                        }}
                    >
                        <ProfileStore
                            profile={{
                                firstName: profile.firstName,
                                lastName: profile.lastName,
                                photo: profile.photo
                            }}
                        >
                            <CssBaseline />
                            <Notifications />

                            <SpinnerStore
                                position={'fixed'}
                                z={'z9'}
                                variant={'skew'}
                                size={'medium'}
                            >
                                <AppLayer>{children}</AppLayer>
                            </SpinnerStore>
                        </ProfileStore>
                    </FormatsStore>
                </MediaQueriesStore>
            </ThemeStore>
        </I18nStore>
    );
};

interface IApplicationManagerProps {
    children: ReactNode;
    store: TStore;
}

const ApplicationManager: FC<IApplicationManagerProps> = ({
    children,
    store
}) => {
    return (
        <ReduxStore store={store}>
            <SubReduxLayer>{children}</SubReduxLayer>
        </ReduxStore>
    );
};

export type {IApplicationManagerProps};
export {ApplicationManager};
