import type {
    TAPIResponseSuccess,
    TElevatorConfigurationsAPI,
    TElevatorDeviceRebootAPI,
    TElevatorEmergenciesAPI,
    TElevatorEmergencyRestoreAPI,
    TElevatorFullHandoffAPI,
    TElevatorGetAPI,
    TElevatorGetAnomaliesAPI,
    TElevatorGetDestinationsAPI,
    TElevatorGetDeviceConfigAPI,
    TElevatorGetDevicesAPI,
    TElevatorGetIssuesAPI,
    TElevatorGetStateAPI,
    TElevatorGetStatusAPI,
    TElevatorGetStopsAPI,
    TElevatorGetSystemConfigAPI,
    TElevatorGetTechnicalParametersAPI,
    TElevatorGetTripsAPI,
    TElevatorOperationModeAPI,
    TElevatorRebootAPI,
    TElevatorRemoteControlAPI,
    TElevatorRemoteControlCallAPI,
    TElevatorRemoteControlMoveAPI,
    TElevatorServiceRoutineAPI,
    TElevatorTeamDeleteAPI,
    TElevatorTeamGetAPI,
    TElevatorTeamUpdateAPI,
    TElevatorUpdateConfigurationSensorsAPI,
    TElevatorUpdateConfigurationSystemPlateAPI,
    TElevatorUpdateDestinationsAPI,
    TElevatorUpdateDeviceConfigAPI,
    TElevatorUpdateProfileAPI,
    TElevatorUpdateStopsAPI,
    TElevatorUpdateSystemConfigAPI,
    TElevatorUpdateTechnicalParametersAPI,
    TElevatorsListAPI,
    TElevatorsRegisterAPI
} from '@mcal/core';
import {EElevatorOperationMode} from '@mcal/core';
import {createAsyncThunk} from '@reduxjs/toolkit';
import type {
    TAPIAsyncThunkArgs,
    TAPIAsyncThunkConfig
} from '../../../defines/redux.types.js';
import {notificationsActions} from '../../../slices/notifications/notifications.index.js';
import {getState} from '../../../utils/get-state/get-state.js';
// import {getPermissions} from '../../app-gateway/permissions/permissions.thunks.js';
import {getUsersMemberships} from '../../app-node-auth/users/users.thunks.js';
import * as services from './elevators.services.js';

const registerElevators = createAsyncThunk<
    TAPIResponseSuccess<TElevatorsRegisterAPI>,
    TAPIAsyncThunkArgs<TElevatorsRegisterAPI>,
    TAPIAsyncThunkConfig<TElevatorsRegisterAPI>
>('elevators/registerElevators', async (args, thunkAPI) => {
    const user = getState(thunkAPI, 'user');

    return await services
        .registerElevators(
            {
                query: {
                    locale: user.remotes.userGeneralSettings.current.locale
                },
                body: {
                    data: {
                        elevatorGroupId: args.elevatorGroupId,
                        elevatorName: args.elevatorName,
                        installationType: args.installationType,
                        destinationsLayout: args.destinationsLayout,
                        stopsLayout: args.stopsLayout,
                        sensors: args.sensors,
                        configuration: args.configuration,
                        systemPlate: args.systemPlate
                    }
                }
            },
            thunkAPI.signal
        )
        .then((response) => {
            if (response.error) {
                void thunkAPI.dispatch(
                    notificationsActions.send({
                        title: 'Error',
                        message: 'Failed to register elevators'
                    })
                );
                return thunkAPI.rejectWithValue(response);
            } else {
                void thunkAPI.dispatch(
                    notificationsActions.send({
                        title: 'Success',
                        message: 'Elevators registered successfully'
                    })
                );
                return thunkAPI.fulfillWithValue(response);
            }
        });
});

const listElevators = createAsyncThunk<
    TAPIResponseSuccess<TElevatorsListAPI>,
    TAPIAsyncThunkArgs<TElevatorsListAPI>,
    TAPIAsyncThunkConfig<TElevatorsListAPI>
>('elevators/listElevators', async (args, thunkAPI) => {
    const user = getState(thunkAPI, 'user');

    return await services
        .listElevators(
            {
                query: {
                    locale: user.remotes.userGeneralSettings.current.locale,
                    scope: args.scope,
                    target: args.target
                }
            },
            thunkAPI.signal
        )
        .then((response) => {
            if (response.error) {
                return thunkAPI.rejectWithValue(response);
            } else {
                return thunkAPI.fulfillWithValue(response);
            }
        });
});

const getElevatorDevices = createAsyncThunk<
    TAPIResponseSuccess<TElevatorGetDevicesAPI>,
    TAPIAsyncThunkArgs<TElevatorGetDevicesAPI>,
    TAPIAsyncThunkConfig<TElevatorGetDevicesAPI>
>('elevators/getElevatorDevices', async (args, thunkAPI) => {
    const user = getState(thunkAPI, 'user');

    return await services
        .getElevatorDevices(
            {
                query: {
                    locale: user.remotes.userGeneralSettings.current.locale,
                    elevatorId: args.elevatorId
                }
            },
            thunkAPI.signal
        )
        .then((response) => {
            if (response.error) {
                return thunkAPI.rejectWithValue(response);
            } else {
                return thunkAPI.fulfillWithValue(response);
            }
        });
});

const getElevatorStatus = createAsyncThunk<
    TAPIResponseSuccess<TElevatorGetStatusAPI>,
    TAPIAsyncThunkArgs<TElevatorGetStatusAPI>,
    TAPIAsyncThunkConfig<TElevatorGetStatusAPI>
>('elevators/elevatorStatus', async (args, thunkAPI) => {
    const user = getState(thunkAPI, 'user');

    return await services
        .getElevatorStatus(
            {
                query: {
                    locale: user.remotes.userGeneralSettings.current.locale,
                    elevatorId: args.elevatorId,
                    deviceId: args.deviceId
                }
            },
            thunkAPI.signal
        )
        .then((response) => {
            if (response.error) {
                return thunkAPI.rejectWithValue(response);
            } else {
                return thunkAPI.fulfillWithValue(response);
            }
        });
});

const listElevatorDestinations = createAsyncThunk<
    TAPIResponseSuccess<TElevatorGetDestinationsAPI>,
    TAPIAsyncThunkArgs<TElevatorGetDestinationsAPI>,
    TAPIAsyncThunkConfig<TElevatorGetDestinationsAPI>
>('elevators/listElevatorDestinations', async (args, thunkAPI) => {
    const user = getState(thunkAPI, 'user');

    return await services
        .listElevatorDestinations(
            {
                query: {
                    locale: user.remotes.userGeneralSettings.current.locale,
                    elevatorId: args.elevatorId
                }
            },
            thunkAPI.signal
        )
        .then((response) => {
            if (response.error) {
                return thunkAPI.rejectWithValue(response);
            } else {
                return thunkAPI.fulfillWithValue(response);
            }
        });
});

const listElevatorStops = createAsyncThunk<
    TAPIResponseSuccess<TElevatorGetStopsAPI>,
    TAPIAsyncThunkArgs<TElevatorGetStopsAPI>,
    TAPIAsyncThunkConfig<TElevatorGetStopsAPI>
>('elevators/listElevatorStops', async (args, thunkAPI) => {
    const user = getState(thunkAPI, 'user');

    return await services
        .listElevatorStops(
            {
                query: {
                    locale: user.remotes.userGeneralSettings.current.locale,
                    elevatorId: args.elevatorId
                }
            },
            thunkAPI.signal
        )
        .then((response) => {
            if (response.error) {
                return thunkAPI.rejectWithValue(response);
            } else {
                return thunkAPI.fulfillWithValue(response);
            }
        });
});

const updateElevatorStops = createAsyncThunk<
    TAPIResponseSuccess<TElevatorUpdateStopsAPI>,
    TAPIAsyncThunkArgs<TElevatorUpdateStopsAPI>,
    TAPIAsyncThunkConfig<TElevatorUpdateStopsAPI>
>('elevators/updateElevatorStops', async (args, thunkAPI) => {
    const user = getState(thunkAPI, 'user');

    return await services
        .updateElevatorStops(
            {
                query: {
                    locale: user.remotes.userGeneralSettings.current.locale
                },
                body: {
                    data: {
                        content: args.content
                    }
                }
            },
            thunkAPI.signal
        )
        .then((response) => {
            if (response.error) {
                void thunkAPI.dispatch(
                    notificationsActions.send({
                        title: 'Error',
                        message: 'Failed to update stop'
                    })
                );
                return thunkAPI.rejectWithValue(response);
            } else {
                void thunkAPI.dispatch(
                    notificationsActions.send({
                        title: 'Success',
                        message: 'Stop updated successfully'
                    })
                );
                return thunkAPI.fulfillWithValue(response);
            }
        });
});

const updateElevatorDestinations = createAsyncThunk<
    TAPIResponseSuccess<TElevatorUpdateDestinationsAPI>,
    TAPIAsyncThunkArgs<TElevatorUpdateDestinationsAPI>,
    TAPIAsyncThunkConfig<TElevatorUpdateDestinationsAPI>
>('elevators/updateElevatorDestinations', async (args, thunkAPI) => {
    const user = getState(thunkAPI, 'user');

    return await services
        .updateElevatorDestinations(
            {
                query: {
                    locale: user.remotes.userGeneralSettings.current.locale
                },
                body: {
                    data: {
                        elevatorId: args.elevatorId,
                        content: args.content
                    }
                }
            },
            thunkAPI.signal
        )
        .then((response) => {
            if (response.error) {
                void thunkAPI.dispatch(
                    notificationsActions.send({
                        title: 'Error',
                        message: 'Failed to update destination'
                    })
                );
                return thunkAPI.rejectWithValue(response);
            } else {
                void thunkAPI.dispatch(
                    notificationsActions.send({
                        title: 'Success',
                        message: 'Destination updated successfully'
                    })
                );
                return thunkAPI.fulfillWithValue(response);
            }
        });
});

const getElevator = createAsyncThunk<
    TAPIResponseSuccess<TElevatorGetAPI>,
    TAPIAsyncThunkArgs<TElevatorGetAPI>,
    TAPIAsyncThunkConfig<TElevatorGetAPI>
>('elevators/getElevator', async (args, thunkAPI) => {
    const user = getState(thunkAPI, 'user');

    const result = await services
        .getElevator(
            {
                query: {
                    locale: user.remotes.userGeneralSettings.current.locale,
                    elevatorId: args.elevatorId
                }
            },
            thunkAPI.signal
        )
        .then((response) => {
            if (response.error) {
                return thunkAPI.rejectWithValue(response);
            } else {
                return thunkAPI.fulfillWithValue(response);
            }
        });

    await Promise.all([
        result

        // thunkAPI.dispatch(
        //     getPermissions({
        //         entityId: args.elevatorId,
        //         affinity: null
        //     })
        // )
    ]);

    return result;
});

const getElevatorState = createAsyncThunk<
    TAPIResponseSuccess<TElevatorGetStateAPI>,
    TAPIAsyncThunkArgs<TElevatorGetStateAPI>,
    TAPIAsyncThunkConfig<TElevatorGetStateAPI>
>('elevators/getElevatorState', async (args, thunkAPI) => {
    const user = getState(thunkAPI, 'user');

    return await services
        .getElevatorState(
            {
                query: {
                    locale: user.remotes.userGeneralSettings.current.locale,
                    elevatorId: args.elevatorId
                }
            },
            thunkAPI.signal
        )
        .then((response) => {
            if (response.error) {
                return thunkAPI.rejectWithValue(response);
            } else {
                return thunkAPI.fulfillWithValue(response);
            }
        });
});

const getElevatorIssues = createAsyncThunk<
    TAPIResponseSuccess<TElevatorGetIssuesAPI>,
    TAPIAsyncThunkArgs<TElevatorGetIssuesAPI>,
    TAPIAsyncThunkConfig<TElevatorGetIssuesAPI>
>('elevators/getElevatorIssues', async (args, thunkAPI) => {
    const user = getState(thunkAPI, 'user');

    return await services
        .getElevatorIssues(
            {
                query: {
                    locale: user.remotes.userGeneralSettings.current.locale,
                    elevatorId: args.elevatorId
                }
            },
            thunkAPI.signal
        )
        .then((response) => {
            if (response.error) {
                return thunkAPI.rejectWithValue(response);
            } else {
                return thunkAPI.fulfillWithValue(response);
            }
        });
});

const getElevatorTrips = createAsyncThunk<
    TAPIResponseSuccess<TElevatorGetTripsAPI>,
    TAPIAsyncThunkArgs<TElevatorGetTripsAPI>,
    TAPIAsyncThunkConfig<TElevatorGetTripsAPI>
>('elevators/getElevatorTrips', async (args, thunkAPI) => {
    const user = getState(thunkAPI, 'user');

    return await services
        .getElevatorTrips(
            {
                query: {
                    locale: user.remotes.userGeneralSettings.current.locale,
                    elevatorId: args.elevatorId
                }
            },
            thunkAPI.signal
        )
        .then((response) => {
            if (response.error) {
                return thunkAPI.rejectWithValue(response);
            } else {
                return thunkAPI.fulfillWithValue(response);
            }
        });
});

const getElevatorAnomalies = createAsyncThunk<
    TAPIResponseSuccess<TElevatorGetAnomaliesAPI>,
    TAPIAsyncThunkArgs<TElevatorGetAnomaliesAPI>,
    TAPIAsyncThunkConfig<TElevatorGetAnomaliesAPI>
>('elevators/getElevatorAnomalies', async (args, thunkAPI) => {
    const user = getState(thunkAPI, 'user');

    return await services
        .getElevatorAnomalies(
            {
                query: {
                    locale: user.remotes.userGeneralSettings.current.locale,
                    elevatorId: args.elevatorId
                }
            },
            thunkAPI.signal
        )
        .then((response) => {
            if (response.error) {
                return thunkAPI.rejectWithValue(response);
            } else {
                return thunkAPI.fulfillWithValue(response);
            }
        });
});

const getElevatorTechnicalParameters = createAsyncThunk<
    TAPIResponseSuccess<TElevatorGetTechnicalParametersAPI>,
    TAPIAsyncThunkArgs<TElevatorGetTechnicalParametersAPI>,
    TAPIAsyncThunkConfig<TElevatorGetTechnicalParametersAPI>
>('elevators/getElevatorTechnicalParameters', async (args, thunkAPI) => {
    const user = getState(thunkAPI, 'user');

    return await services
        .getElevatorTechnicalParameters(
            {
                query: {
                    locale: user.remotes.userGeneralSettings.current.locale,
                    elevatorId: args.elevatorId,
                    deviceId: args.deviceId
                }
            },
            thunkAPI.signal
        )
        .then((response) => {
            if (response.error) {
                return thunkAPI.rejectWithValue(response);
            } else {
                return thunkAPI.fulfillWithValue(response);
            }
        });
});

const updateElevatorTechnicalParameters = createAsyncThunk<
    TAPIResponseSuccess<TElevatorUpdateTechnicalParametersAPI>,
    TAPIAsyncThunkArgs<TElevatorUpdateTechnicalParametersAPI>,
    TAPIAsyncThunkConfig<TElevatorUpdateTechnicalParametersAPI>
>('elevators/updateElevatorTechnicalParameters', async (args, thunkAPI) => {
    const user = getState(thunkAPI, 'user');

    return await services
        .updateElevatorTechnicalParameters(
            {
                query: {
                    locale: user.remotes.userGeneralSettings.current.locale
                },
                body: {
                    data: {
                        elevatorId: args.elevatorId,
                        deviceId: args.deviceId,
                        technicalParameters: args.technicalParameters
                    }
                }
            },
            thunkAPI.signal
        )
        .then((response) => {
            if (response.error) {
                void thunkAPI.dispatch(
                    notificationsActions.send({
                        title: 'Error',
                        message: 'Failed to update technical parameters'
                    })
                );

                return thunkAPI.rejectWithValue(response);
            } else {
                void thunkAPI.dispatch(
                    notificationsActions.send({
                        title: 'Success',
                        message: 'Technical parameters updated successfully'
                    })
                );
                return thunkAPI.fulfillWithValue(response);
            }
        });
});

const getElevatorConfigurationTemplate = createAsyncThunk<
    TAPIResponseSuccess<TElevatorConfigurationsAPI>,
    TAPIAsyncThunkArgs<TElevatorConfigurationsAPI>,
    TAPIAsyncThunkConfig<TElevatorConfigurationsAPI>
>('elevators/getElevatorConfigurationTemplate', async (args, thunkAPI) => {
    const user = getState(thunkAPI, 'user');

    return await services
        .getElevatorConfigurationTemplate(
            {
                query: {
                    locale: user.remotes.userGeneralSettings.current.locale,
                    deviceId: args.deviceId
                }
            },
            thunkAPI.signal
        )
        .then((response) => {
            if (response.error) {
                return thunkAPI.rejectWithValue(response);
            } else {
                return thunkAPI.fulfillWithValue(response);
            }
        });
});

const updateElevatorSystemPlate = createAsyncThunk<
    TAPIResponseSuccess<TElevatorUpdateConfigurationSystemPlateAPI>,
    TAPIAsyncThunkArgs<TElevatorUpdateConfigurationSystemPlateAPI>,
    TAPIAsyncThunkConfig<TElevatorUpdateConfigurationSystemPlateAPI>
>('elevators/updateElevatorSystemPlate', async (args, thunkAPI) => {
    const user = getState(thunkAPI, 'user');

    return await services
        .updateElevatorSystemPlate(
            {
                query: {
                    locale: user.remotes.userGeneralSettings.current.locale
                },
                body: {
                    data: {
                        elevatorId: args.elevatorId,
                        deviceId: args.deviceId,
                        systemPlate: args.systemPlate
                    }
                }
            },
            thunkAPI.signal
        )
        .then((response) => {
            if (response.error) {
                void thunkAPI.dispatch(
                    notificationsActions.send({
                        title: 'Error',
                        message: 'Failed to update technical parameters'
                    })
                );

                return thunkAPI.rejectWithValue(response);
            } else {
                void thunkAPI.dispatch(
                    notificationsActions.send({
                        title: 'Success',
                        message: 'Technical parameters updated successfully'
                    })
                );
                return thunkAPI.fulfillWithValue(response);
            }
        });
});

const updateElevatorSensors = createAsyncThunk<
    TAPIResponseSuccess<TElevatorUpdateConfigurationSensorsAPI>,
    TAPIAsyncThunkArgs<TElevatorUpdateConfigurationSensorsAPI>,
    TAPIAsyncThunkConfig<TElevatorUpdateConfigurationSensorsAPI>
>('elevators/updateElevatorSensors', async (args, thunkAPI) => {
    const user = getState(thunkAPI, 'user');

    return await services
        .updateElevatorSensors(
            {
                query: {
                    locale: user.remotes.userGeneralSettings.current.locale
                },
                body: {
                    data: {
                        elevatorId: args.elevatorId,
                        sensors: args.sensors
                    }
                }
            },
            thunkAPI.signal
        )
        .then((response) => {
            if (response.error) {
                void thunkAPI.dispatch(
                    notificationsActions.send({
                        title: 'Error',
                        message: 'Failed to update technical parameters'
                    })
                );

                return thunkAPI.rejectWithValue(response);
            } else {
                void thunkAPI.dispatch(
                    notificationsActions.send({
                        title: 'Success',
                        message: 'Technical parameters updated successfully'
                    })
                );
                return thunkAPI.fulfillWithValue(response);
            }
        });
});

const getElevatorDeviceConfig = createAsyncThunk<
    TAPIResponseSuccess<TElevatorGetDeviceConfigAPI>,
    TAPIAsyncThunkArgs<TElevatorGetDeviceConfigAPI>,
    TAPIAsyncThunkConfig<TElevatorGetDeviceConfigAPI>
>('elevators/getElevatorDeviceConfig', async (args, thunkAPI) => {
    const user = getState(thunkAPI, 'user');

    return await services
        .getElevatorDeviceConfig(
            {
                query: {
                    locale: user.remotes.userGeneralSettings.current.locale,
                    deviceId: args.deviceId
                }
            },
            thunkAPI.signal
        )
        .then((response) => {
            if (response.error) {
                return thunkAPI.rejectWithValue(response);
            } else {
                return thunkAPI.fulfillWithValue(response);
            }
        });
});

const getElevatorSystemConfig = createAsyncThunk<
    TAPIResponseSuccess<TElevatorGetSystemConfigAPI>,
    TAPIAsyncThunkArgs<TElevatorGetSystemConfigAPI>,
    TAPIAsyncThunkConfig<TElevatorGetSystemConfigAPI>
>('elevators/getElevatorSystemConfig', async (args, thunkAPI) => {
    const user = getState(thunkAPI, 'user');

    return await services
        .getElevatorSystemConfig(
            {
                query: {
                    locale: user.remotes.userGeneralSettings.current.locale,
                    elevatorId: args.elevatorId
                }
            },
            thunkAPI.signal
        )
        .then((response) => {
            if (response.error) {
                return thunkAPI.rejectWithValue(response);
            } else {
                return thunkAPI.fulfillWithValue(response);
            }
        });
});

const updateElevatorDeviceConfig = createAsyncThunk<
    TAPIResponseSuccess<TElevatorUpdateDeviceConfigAPI>,
    TAPIAsyncThunkArgs<TElevatorUpdateDeviceConfigAPI>,
    TAPIAsyncThunkConfig<TElevatorUpdateDeviceConfigAPI>
>('elevators/updateElevatorDeviceConfig', async (args, thunkAPI) => {
    const user = getState(thunkAPI, 'user');

    return await services
        .updateElevatorDeviceConfig(
            {
                query: {
                    locale: user.remotes.userGeneralSettings.current.locale
                },
                body: {
                    data: args
                }
            },
            thunkAPI.signal
        )
        .then((response) => {
            if (response.error) {
                void thunkAPI.dispatch(
                    notificationsActions.send({
                        title: 'Error',
                        message: 'Failed to update device config'
                    })
                );

                return thunkAPI.rejectWithValue(response);
            } else {
                void thunkAPI.dispatch(
                    notificationsActions.send({
                        title: 'Success',
                        message: 'Update config updated successfully'
                    })
                );
                return thunkAPI.fulfillWithValue(response);
            }
        });
});

const updateElevatorSystemConfig = createAsyncThunk<
    TAPIResponseSuccess<TElevatorUpdateSystemConfigAPI>,
    TAPIAsyncThunkArgs<TElevatorUpdateSystemConfigAPI>,
    TAPIAsyncThunkConfig<TElevatorUpdateSystemConfigAPI>
>('elevators/updateElevatorSystemConfig', async (args, thunkAPI) => {
    const user = getState(thunkAPI, 'user');

    return await services
        .updateElevatorSystemConfig(
            {
                query: {
                    locale: user.remotes.userGeneralSettings.current.locale
                },
                body: {
                    data: args
                }
            },
            thunkAPI.signal
        )
        .then((response) => {
            if (response.error) {
                void thunkAPI.dispatch(
                    notificationsActions.send({
                        title: 'Error',
                        message: 'Failed to update system config'
                    })
                );

                return thunkAPI.rejectWithValue(response);
            } else {
                void thunkAPI.dispatch(
                    notificationsActions.send({
                        title: 'Success',
                        message: 'System config updated successfully'
                    })
                );
                return thunkAPI.fulfillWithValue(response);
            }
        });
});

const updateElevatorProfile = createAsyncThunk<
    TAPIResponseSuccess<TElevatorUpdateProfileAPI>,
    TAPIAsyncThunkArgs<TElevatorUpdateProfileAPI>,
    TAPIAsyncThunkConfig<TElevatorUpdateProfileAPI>
>('elevators/profile', async (args, thunkAPI) => {
    const user = getState(thunkAPI, 'user');

    return await services
        .updateElevatorProfile(
            {
                query: {
                    locale: user.remotes.userGeneralSettings.current.locale
                },
                body: {
                    data: {
                        elevatorId: args.elevatorId,
                        profile: args.profile
                    }
                }
            },
            thunkAPI.signal
        )
        .then((response) => {
            if (response.error) {
                void thunkAPI.dispatch(
                    notificationsActions.send({
                        title: 'Error',
                        message: 'Failed to update elevators profile'
                    })
                );

                return thunkAPI.rejectWithValue(response);
            } else {
                void thunkAPI.dispatch(
                    notificationsActions.send({
                        title: 'Success',
                        message: 'Elevators profile updated successfully'
                    })
                );

                return thunkAPI.fulfillWithValue(response);
            }
        });
});

const updateElevatorOperationMode = createAsyncThunk<
    TAPIResponseSuccess<TElevatorOperationModeAPI>,
    TAPIAsyncThunkArgs<TElevatorOperationModeAPI>,
    TAPIAsyncThunkConfig<TElevatorOperationModeAPI>
>('elevator/operation-mode', async (args, thunkAPI) => {
    const user = getState(thunkAPI, 'user');

    return await services
        .updateElevatorOperationMode(
            {
                query: {
                    locale: user.remotes.userGeneralSettings.current.locale
                },
                body: {
                    data:
                        args.mode === EElevatorOperationMode.Normal
                            ? {
                                  elevatorId: args.elevatorId,
                                  mode: EElevatorOperationMode.Normal,
                                  downtime: null
                              }
                            : {
                                  elevatorId: args.elevatorId,
                                  mode: EElevatorOperationMode.Service,
                                  downtime: args.downtime || 0
                              }
                }
            },
            thunkAPI.signal
        )
        .then((response) => {
            if (response.error) {
                void thunkAPI.dispatch(
                    notificationsActions.send({
                        title: 'Error',
                        message: 'Failed to update elevator operation mode'
                    })
                );

                return thunkAPI.rejectWithValue(response);
            } else {
                void thunkAPI.dispatch(
                    notificationsActions.send({
                        title: 'Success',
                        message: 'Elevator operation mode updated successfully'
                    })
                );

                return thunkAPI.fulfillWithValue(response);
            }
        });
});

const updateElevatorHandoff = createAsyncThunk<
    TAPIResponseSuccess<TElevatorFullHandoffAPI>,
    TAPIAsyncThunkArgs<TElevatorFullHandoffAPI>,
    TAPIAsyncThunkConfig<TElevatorFullHandoffAPI>
>('/elevator/full-handoff', async (args, thunkAPI) => {
    const user = getState(thunkAPI, 'user');

    return await services
        .updateElevatorHandoff(
            {
                query: {
                    locale: user.remotes.userGeneralSettings.current.locale
                },
                body: {
                    data: {
                        elevatorId: args.elevatorId,
                        enable: args.enable
                    }
                }
            },
            thunkAPI.signal
        )
        .then((response) => {
            if (response.error) {
                void thunkAPI.dispatch(
                    notificationsActions.send({
                        title: 'Error',
                        message: 'Failed to update elevator operation mode'
                    })
                );

                return thunkAPI.rejectWithValue(response);
            } else {
                void thunkAPI.dispatch(
                    notificationsActions.send({
                        title: 'Success',
                        message: 'Elevator operation mode updated successfully'
                    })
                );

                return thunkAPI.fulfillWithValue(response);
            }
        });
});

const updateRemoteControlMode = createAsyncThunk<
    TAPIResponseSuccess<TElevatorRemoteControlAPI>,
    TAPIAsyncThunkArgs<TElevatorRemoteControlAPI>,
    TAPIAsyncThunkConfig<TElevatorRemoteControlAPI>
>('/elevator/remote-control', async (args, thunkAPI) => {
    const user = getState(thunkAPI, 'user');

    return await services
        .updateRemoteControlMode(
            {
                query: {
                    locale: user.remotes.userGeneralSettings.current.locale
                },
                body: {
                    data: {
                        elevatorId: args.elevatorId,
                        enable: args.enable
                    }
                }
            },
            thunkAPI.signal
        )
        .then((response) => {
            if (response.error) {
                void thunkAPI.dispatch(
                    notificationsActions.send({
                        title: 'Error',
                        message: 'Failed to enable elevator remote control'
                    })
                );

                return thunkAPI.rejectWithValue(response);
            } else {
                void thunkAPI.dispatch(
                    notificationsActions.send({
                        title: 'Success',
                        message: 'Elevator remote control enabled successfully'
                    })
                );

                return thunkAPI.fulfillWithValue(response);
            }
        });
});

const getElevatorTeam = createAsyncThunk<
    TAPIResponseSuccess<TElevatorTeamGetAPI>,
    TAPIAsyncThunkArgs<TElevatorTeamGetAPI>,
    TAPIAsyncThunkConfig<TElevatorTeamGetAPI>
>('elevators/getElevatorTeam', async (args, thunkAPI) => {
    const user = getState(thunkAPI, 'user');

    return await services
        .getElevatorTeam(
            {
                query: {
                    locale: user.remotes.userGeneralSettings.current.locale,
                    elevatorId: args.elevatorId,
                    affinityId: args.affinityId
                }
            },
            thunkAPI.signal
        )
        .then((response) => {
            if (response.error) {
                return thunkAPI.rejectWithValue(response);
            } else {
                void thunkAPI.dispatch(
                    getUsersMemberships({
                        entityId: args.elevatorId,
                        affinityId: args.affinityId,
                        members: response.data.map(({userId}) => userId)
                    })
                );

                return thunkAPI.fulfillWithValue(response);
            }
        });
});

const updateElevatorTeam = createAsyncThunk<
    TAPIResponseSuccess<TElevatorTeamUpdateAPI>,
    TAPIAsyncThunkArgs<TElevatorTeamUpdateAPI>,
    TAPIAsyncThunkConfig<TElevatorTeamUpdateAPI>
>('elevators/updateElevatorTeam', async (args, thunkAPI) => {
    const user = getState(thunkAPI, 'user');

    return await services
        .updateElevatorTeam(
            {
                query: {
                    locale: user.remotes.userGeneralSettings.current.locale
                },
                body: {
                    data: {
                        elevatorId: args.elevatorId,
                        affinityId: args.affinityId,
                        userId: args.userId,
                        role: args.role
                    }
                }
            },
            thunkAPI.signal
        )
        .then((response) => {
            if (response.error) {
                return thunkAPI.rejectWithValue(response);
            } else {
                return thunkAPI.fulfillWithValue(response);
            }
        });
});

const deleteElevatorTeam = createAsyncThunk<
    TAPIResponseSuccess<TElevatorTeamDeleteAPI>,
    TAPIAsyncThunkArgs<TElevatorTeamDeleteAPI>,
    TAPIAsyncThunkConfig<TElevatorTeamDeleteAPI>
>('elevators/deleteElevatorTeam', async (args, thunkAPI) => {
    const user = getState(thunkAPI, 'user');

    return await services
        .deleteElevatorTeam(
            {
                query: {
                    locale: user.remotes.userGeneralSettings.current.locale
                },
                body: {
                    data: {
                        elevatorId: args.elevatorId,
                        affinityId: args.affinityId,
                        userId: args.userId
                    }
                }
            },
            thunkAPI.signal
        )
        .then((response) => {
            if (response.error) {
                return thunkAPI.rejectWithValue(response);
            } else {
                return thunkAPI.fulfillWithValue(response);
            }
        });
});

const serviceRoutine = createAsyncThunk<
    TAPIResponseSuccess<TElevatorServiceRoutineAPI>,
    TAPIAsyncThunkArgs<TElevatorServiceRoutineAPI>,
    TAPIAsyncThunkConfig<TElevatorServiceRoutineAPI>
>('/elevator/reboot', async (args, thunkAPI) => {
    const user = getState(thunkAPI, 'user');

    return await services
        .serviceRoutine(
            {
                query: {
                    locale: user.remotes.userGeneralSettings.current.locale
                },
                body: {
                    data: {
                        elevatorId: args.elevatorId,
                        serviceRoutine: args.serviceRoutine
                    }
                }
            },
            thunkAPI.signal
        )
        .then((response) => {
            if (response.error) {
                return thunkAPI.rejectWithValue(response);
            } else {
                void thunkAPI.dispatch(
                    notificationsActions.send({
                        title: 'Success',
                        message: `${args.serviceRoutine} elevator command successfully sent to the cloud`
                    })
                );

                return thunkAPI.fulfillWithValue(response);
            }
        });
});

const remoteControlCall = createAsyncThunk<
    TAPIResponseSuccess<TElevatorRemoteControlCallAPI>,
    TAPIAsyncThunkArgs<TElevatorRemoteControlCallAPI>,
    TAPIAsyncThunkConfig<TElevatorRemoteControlCallAPI>
>('/elevator/remote-control', async (args, thunkAPI) => {
    const user = getState(thunkAPI, 'user');

    return await services
        .remoteControlCall(
            {
                query: {
                    locale: user.remotes.userGeneralSettings.current.locale
                },
                body: {
                    data: {
                        elevatorId: args.elevatorId,
                        action: args.action,
                        fromKey: args.fromKey,
                        toKey: args.toKey,
                        priority: args.priority
                    }
                }
            },
            thunkAPI.signal
        )
        .then((response) => {
            if (response.error) {
                return thunkAPI.rejectWithValue(response);
            } else {
                void thunkAPI.dispatch(
                    notificationsActions.send({
                        title: 'Success',
                        message: `${args.action} elevator command successfully sent to the cloud`
                    })
                );

                return thunkAPI.fulfillWithValue(response);
            }
        });
});

const remoteMove = createAsyncThunk<
    TAPIResponseSuccess<TElevatorRemoteControlMoveAPI>,
    TAPIAsyncThunkArgs<TElevatorRemoteControlMoveAPI>,
    TAPIAsyncThunkConfig<TElevatorRemoteControlMoveAPI>
>('/elevator/remote-move', async (args, thunkAPI) => {
    const user = getState(thunkAPI, 'user');

    return await services
        .remoteMove(
            {
                query: {
                    locale: user.remotes.userGeneralSettings.current.locale
                },
                body: {
                    data: {
                        elevatorId: args.elevatorId,
                        toKey: args.toKey
                    }
                }
            },
            thunkAPI.signal
        )
        .then((response) => {
            if (response.error) {
                return thunkAPI.rejectWithValue(response);
            } else {
                void thunkAPI.dispatch(
                    notificationsActions.send({
                        title: 'Success',
                        message: `Move elevator command successfully sent to the cloud`
                    })
                );

                return thunkAPI.fulfillWithValue(response);
            }
        });
});

//EMERGENCIES

const getElevatorEmergency = createAsyncThunk<
    TAPIResponseSuccess<TElevatorEmergenciesAPI>,
    TAPIAsyncThunkArgs<TElevatorEmergenciesAPI>,
    TAPIAsyncThunkConfig<TElevatorEmergenciesAPI>
>('elevators/getElevatorEmergency', async (args, thunkAPI) => {
    const user = getState(thunkAPI, 'user');

    return await services
        .getElevatorEmergency(
            {
                query: {
                    locale: user.remotes.userGeneralSettings.current.locale,
                    elevatorId: args.elevatorId
                }
            },
            thunkAPI.signal
        )
        .then((response) => {
            if (response.error) {
                return thunkAPI.rejectWithValue(response);
            } else {
                return thunkAPI.fulfillWithValue(response);
            }
        });
});

const restoreElevatorEmergency = createAsyncThunk<
    TAPIResponseSuccess<TElevatorEmergencyRestoreAPI>,
    TAPIAsyncThunkArgs<TElevatorEmergencyRestoreAPI>,
    TAPIAsyncThunkConfig<TElevatorEmergencyRestoreAPI>
>('elevators/restoreElevatorEmergency', async (args, thunkAPI) => {
    const user = getState(thunkAPI, 'user');

    return await services
        .restoreElevatorEmergency(
            {
                query: {
                    locale: user.remotes.userGeneralSettings.current.locale
                },
                body: {
                    data: {
                        elevatorId: args.elevatorId
                    }
                }
            },
            thunkAPI.signal
        )
        .then((response) => {
            if (response.error) {
                void thunkAPI.dispatch(
                    notificationsActions.send({
                        title: 'Error',
                        message: 'Failed to restore elevator emergency'
                    })
                );
                return thunkAPI.rejectWithValue(response);
            } else {
                void thunkAPI.dispatch(
                    notificationsActions.send({
                        title: 'Success',
                        message: 'Elevator emergency restore successfully'
                    })
                );
                return thunkAPI.fulfillWithValue(response);
            }
        });
});

const updateElevatorAdvanced = createAsyncThunk<
    TAPIResponseSuccess<TElevatorUpdateDeviceConfigAPI>,
    TAPIAsyncThunkArgs<TElevatorUpdateDeviceConfigAPI>,
    TAPIAsyncThunkConfig<TElevatorUpdateDeviceConfigAPI>
>('elevators/updateElevatorAdvanced', async (args, thunkAPI) => {
    const user = getState(thunkAPI, 'user');

    return await services
        .updateElevatorAdvanced(
            {
                query: {
                    locale: user.remotes.userGeneralSettings.current.locale
                },
                body: {
                    data: {
                        elevatorId: args.elevatorId,
                        deviceId: args.deviceId,
                        configuration: args.configuration
                    }
                }
            },
            thunkAPI.signal
        )
        .then((response) => {
            if (response.error) {
                void thunkAPI.dispatch(
                    notificationsActions.send({
                        title: 'Error',
                        message: 'Failed to update technical parameters'
                    })
                );

                return thunkAPI.rejectWithValue(response);
            } else {
                void thunkAPI.dispatch(
                    notificationsActions.send({
                        title: 'Success',
                        message: 'Technical parameters updated successfully'
                    })
                );
                return thunkAPI.fulfillWithValue(response);
            }
        });
});

const rebootElevatorDevice = createAsyncThunk<
    TAPIResponseSuccess<TElevatorDeviceRebootAPI>,
    TAPIAsyncThunkArgs<TElevatorDeviceRebootAPI>,
    TAPIAsyncThunkConfig<TElevatorDeviceRebootAPI>
>('elevators/rebootElevatorDevice', async (args, thunkAPI) => {
    const user = getState(thunkAPI, 'user');

    return await services
        .rebootElevatorDevice(
            {
                query: {
                    locale: user.remotes.userGeneralSettings.current.locale
                },
                body: {
                    data: {
                        elevatorId: args.elevatorId,
                        deviceId: args.deviceId
                    }
                }
            },
            thunkAPI.signal
        )
        .then((response) => {
            if (response.error) {
                void thunkAPI.dispatch(
                    notificationsActions.send({
                        title: 'Error',
                        message: 'Failed to restore elevator emergency'
                    })
                );
                return thunkAPI.rejectWithValue(response);
            } else {
                void thunkAPI.dispatch(
                    notificationsActions.send({
                        title: 'Success',
                        message: 'Elevator emergency restore successfully'
                    })
                );
                return thunkAPI.fulfillWithValue(response);
            }
        });
});

const rebootElevator = createAsyncThunk<
    TAPIResponseSuccess<TElevatorRebootAPI>,
    TAPIAsyncThunkArgs<TElevatorRebootAPI>,
    TAPIAsyncThunkConfig<TElevatorRebootAPI>
>('elevators/rebootElevator', async (args, thunkAPI) => {
    const user = getState(thunkAPI, 'user');

    return await services
        .rebootElevator(
            {
                query: {
                    locale: user.remotes.userGeneralSettings.current.locale
                },
                body: {
                    data: {
                        elevatorId: args.elevatorId
                    }
                }
            },
            thunkAPI.signal
        )
        .then((response) => {
            if (response.error) {
                void thunkAPI.dispatch(
                    notificationsActions.send({
                        title: 'Error',
                        message: 'Failed to restore elevator emergency'
                    })
                );
                return thunkAPI.rejectWithValue(response);
            } else {
                void thunkAPI.dispatch(
                    notificationsActions.send({
                        title: 'Success',
                        message: 'Elevator rebooted successfully'
                    })
                );
                return thunkAPI.fulfillWithValue(response);
            }
        });
});

export {
    deleteElevatorTeam,
    getElevator,
    getElevatorAnomalies,
    getElevatorConfigurationTemplate,
    getElevatorDeviceConfig,
    getElevatorDevices,
    getElevatorEmergency,
    getElevatorIssues,
    getElevatorState,
    getElevatorStatus,
    getElevatorSystemConfig,
    getElevatorTeam,
    getElevatorTechnicalParameters,
    getElevatorTrips,
    listElevatorDestinations,
    listElevatorStops,
    listElevators,
    rebootElevator,
    rebootElevatorDevice,
    registerElevators,
    remoteControlCall,
    remoteMove,
    restoreElevatorEmergency,
    serviceRoutine,
    updateElevatorAdvanced,
    updateElevatorDestinations,
    updateElevatorDeviceConfig,
    updateElevatorHandoff,
    updateElevatorOperationMode,
    updateElevatorProfile,
    updateElevatorSensors,
    updateElevatorStops,
    updateElevatorSystemConfig,
    updateElevatorSystemPlate,
    updateElevatorTeam,
    updateElevatorTechnicalParameters,
    updateRemoteControlMode
};
