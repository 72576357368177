import type {TComponentSize, TDSDepth, TPosition} from '@mcal/core-react';
import {useTheme} from '@mcal/core-react';
import {forwardRef} from 'react';
import {FadeLoader, SkewLoader} from 'react-spinners';
import {Positioner} from '../positioner/positioner.js';

const components = {
    skew: SkewLoader,
    fade: FadeLoader
} as const;

const sizes = {
    small: 30,
    medium: 60,
    large: 90
} as const;

type TSpinnerVariant = 'skew' | 'fade';

interface ISpinnerProps {
    hide?: boolean;
    position?: TPosition;
    z?: TDSDepth | null;
    variant?: TSpinnerVariant;
    size?: TComponentSize;
}

const Spinner = forwardRef<HTMLElement, ISpinnerProps>(
    (
        {
            hide = false,
            position = 'absolute',
            z = 'z6',
            variant = 'fade',
            size = 'small'
        },
        ref
    ) => {
        const {palette} = useTheme();

        const Component = components[variant];

        if (hide) {
            return null;
        }

        return (
            <Positioner
                ref={ref}
                component={'span'}
                position={position}
                alignment={'center-center'}
                z={z}
            >
                <Component
                    color={palette.accent.primary.high.base}
                    size={sizes[size]}
                />
            </Positioner>
        );
    }
);

Spinner.displayName = 'Spinner';

export type {ISpinnerProps};
export {Spinner};
