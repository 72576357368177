import type {FC, ReactNode} from 'react';
import {createContext, useMemo} from 'react';

interface IProfileContext {
    firstName: string | null;
    lastName: string | null;
    photo: string | null;
}

const defaultProfileContext: IProfileContext = {
    firstName: null,
    lastName: null,
    photo: null
};

const ProfileContext = createContext<IProfileContext>(defaultProfileContext);

interface IProfileStoreProps {
    profile?: Partial<IProfileContext>;
    children: ReactNode;
}

const ProfileStore: FC<IProfileStoreProps> = ({profile = {}, children}) => {
    const value = useMemo<IProfileContext>(() => {
        return {
            firstName: profile.firstName || defaultProfileContext.firstName,
            lastName: profile.lastName || defaultProfileContext.lastName,
            photo: profile.photo || defaultProfileContext.photo
        };
    }, [profile.firstName, profile.lastName, profile.photo]);

    return (
        <ProfileContext.Provider value={value}>
            {children}
        </ProfileContext.Provider>
    );
};

export type {IProfileContext, IProfileStoreProps};
export {ProfileContext, ProfileStore, defaultProfileContext};
