import {fromError} from '../../utils/string-utils/index.js';

type TLogLevel = 'none' | 'fatal' | 'error' | 'warning' | 'info' | 'debug';

type TLogMethod = 'fatal' | 'error' | 'warn' | 'info' | 'debug';

type TNativeLogMethod = 'error' | 'warn' | 'info' | 'debug' | 'log';

type TConsoleBind = () => void;

interface IRawLog {
    tag: string;
    caller: string;
    message: unknown;
    color: string;
    backgroundColor: string;
    method: TNativeLogMethod;
}

interface ILog extends IRawLog {
    date: Date;
}

interface ILogger {
    key: string;
    dispatch: (log: ILog) => Promise<void>;
}

interface IConsoleDispatchOptions {
    styles: boolean;
}

interface IConsole {
    dispatch: (log: ILog, options: IConsoleDispatchOptions) => TConsoleBind;
}

type TSerializer = (message: unknown) => string;

interface IBoundMethods {
    fatal: (message: unknown, serializer?: TSerializer) => TConsoleBind;
    error: (message: unknown, serializer?: TSerializer) => TConsoleBind;
    warn: (message: unknown, serializer?: TSerializer) => TConsoleBind;
    info: (message: unknown, serializer?: TSerializer) => TConsoleBind;
    debug: (message: unknown, serializer?: TSerializer) => TConsoleBind;
}

interface ILoggerOptions {
    loggers?: Record<string, ILogger>;
    console?: IConsole;
    logLevel?: TLogLevel;
    sizes?: {
        tag?: number;
        caller?: number;
    };
    styles?: boolean;
}

const caller = 'Logger';

class Logger {
    private readonly levels: TLogLevel[];

    private readonly loggers: Record<string, ILogger>;

    private console: IConsole | null;

    private logLevel: TLogLevel;

    private readonly sizes: {tag: number; caller: number};

    private styles: boolean;

    constructor(options: ILoggerOptions = {}) {
        const {
            loggers = {},
            console = null,
            logLevel = 'none',
            sizes = {},
            styles = true
        } = options;

        const {tag = 15, caller = 50} = sizes;

        this.levels = ['none', 'fatal', 'error', 'warning', 'info', 'debug'];

        this.loggers = loggers;
        this.console = console;

        this.logLevel = logLevel;

        this.sizes = {tag, caller};

        this.styles = styles;
    }

    fatal(
        caller: string,
        message: unknown,
        serializer?: TSerializer
    ): TConsoleBind {
        if (this.levels.indexOf(this.logLevel) >= 1) {
            return this.dispatch(
                {
                    tag: '[FATAL]',
                    color: '#ffffff',
                    backgroundColor: '#ff0000',
                    caller,
                    message,
                    method: 'error'
                },
                serializer
            );
        } else {
            return () => undefined;
        }
    }

    error(
        caller: string,
        message: unknown,
        serializer?: TSerializer
    ): TConsoleBind {
        if (this.levels.indexOf(this.logLevel) >= 2) {
            return this.dispatch(
                {
                    tag: '[ERROR]',
                    color: '#990000',
                    backgroundColor: 'transparent',
                    caller,
                    message,
                    method: 'error'
                },
                serializer
            );
        } else {
            return () => undefined;
        }
    }

    warn(
        caller: string,
        message: unknown,
        serializer?: TSerializer
    ): TConsoleBind {
        if (this.levels.indexOf(this.logLevel) >= 3) {
            return this.dispatch(
                {
                    tag: '[WARNING]',
                    color: '#cc9900',
                    backgroundColor: 'transparent',
                    caller,
                    message,
                    method: 'warn'
                },
                serializer
            );
        } else {
            return () => undefined;
        }
    }

    info(
        caller: string,
        message: unknown,
        serializer?: TSerializer
    ): TConsoleBind {
        if (this.levels.indexOf(this.logLevel) >= 4) {
            return this.dispatch(
                {
                    tag: '[INFO]',
                    color: '#000099',
                    backgroundColor: 'transparent',
                    caller,
                    message,
                    method: 'info'
                },
                serializer
            );
        } else {
            return () => undefined;
        }
    }

    debug(
        caller: string,
        message: unknown,
        serializer?: TSerializer
    ): TConsoleBind {
        if (this.levels.indexOf(this.logLevel) >= 5) {
            return this.dispatch(
                {
                    tag: '[DEBUG]',
                    color: '#ff66ff',
                    backgroundColor: 'transparent',
                    caller,
                    message,
                    method: 'debug'
                },
                serializer
            );
        } else {
            return () => undefined;
        }
    }

    withCaller(caller: string): IBoundMethods {
        return {
            fatal: (
                message: unknown,
                serializer?: TSerializer
            ): TConsoleBind => {
                if (serializer) {
                    return this.fatal(caller, message, serializer);
                } else {
                    return this.fatal(caller, message);
                }
            },

            error: (
                message: unknown,
                serializer?: TSerializer
            ): TConsoleBind => {
                if (message instanceof Error) {
                    return this.error(caller, fromError(message));
                }

                if (serializer) {
                    return this.error(caller, message, serializer);
                } else {
                    return this.error(caller, message);
                }
            },

            warn: (
                message: unknown,
                serializer?: TSerializer
            ): TConsoleBind => {
                if (serializer) {
                    return this.warn(caller, message, serializer);
                } else {
                    return this.warn(caller, message);
                }
            },

            info: (
                message: unknown,
                serializer?: TSerializer
            ): TConsoleBind => {
                if (serializer) {
                    return this.info(caller, message, serializer);
                } else {
                    return this.info(caller, message);
                }
            },

            debug: (
                message: unknown,
                serializer?: TSerializer
            ): TConsoleBind => {
                if (serializer) {
                    return this.debug(caller, message, serializer);
                } else {
                    return this.debug(caller, message);
                }
            }
        };
    }

    setConsole(c: IConsole | null): void {
        this.console = c;
        this.info(caller, 'CONSOLE SET')();
    }

    addLogger(logger: ILogger): void {
        this.loggers[logger.key] = logger;
    }

    removeLogger(logger: ILogger): void {
        delete this.loggers[logger.key];
    }

    setLogLevel(logLevel: TLogLevel): void {
        this.logLevel = logLevel;
    }

    private dispatch(log: IRawLog, serializer?: TSerializer): TConsoleBind {
        const date = new Date();

        log.tag = log.tag.padEnd(this.sizes.tag - log.tag.length);
        log.caller = log.caller.padEnd(this.sizes.caller - log.caller.length);

        if (serializer) {
            log.message = serializer(log.message);
        }

        for (const key in this.loggers) {
            void this.loggers[key].dispatch({
                ...log,
                date
            });
        }

        if (this.console) {
            return this.console.dispatch(
                {
                    ...log,
                    date
                },
                {
                    styles: this.styles
                }
            );
        } else {
            return () => undefined;
        }
    }
}

const logger = new Logger();

export type {
    IConsole,
    IConsoleDispatchOptions,
    ILog,
    ILogger,
    ILoggerOptions,
    TLogLevel,
    TLogMethod,
    TNativeLogMethod
};
export {Logger, logger};
