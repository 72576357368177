import type {PayloadAction} from '@reduxjs/toolkit';
import {createSlice} from '@reduxjs/toolkit';
import {
    complete,
    start,
    verifyEmail
} from '../../services/app-node-auth/sign-up/sign-up.thunks.js';
import type {TSignUpSliceStatus} from './sign-up.state.js';
import {initialState} from './sign-up.state.js';

const signUpSlice = createSlice({
    name: 'signUp',
    initialState,
    reducers: {
        setStep(state, action: PayloadAction<TSignUpSliceStatus>) {
            state.status = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(start.pending, (state) => {
            state.sources.start.status = 'LOADING';
        });
        builder.addCase(start.fulfilled, (state) => {
            state.sources.start.status = 'IDLE';
            state.status = 'VERIFY-EMAIL';
        });
        builder.addCase(start.rejected, (state) => {
            state.sources.start.status = 'FAILED';
        });
        builder.addCase(verifyEmail.pending, (state) => {
            state.sources.verifyEmail.status = 'LOADING';
        });
        builder.addCase(verifyEmail.fulfilled, (state) => {
            state.sources.verifyEmail.status = 'IDLE';
            state.status = 'COMPLETE';
        });
        builder.addCase(verifyEmail.rejected, (state) => {
            state.sources.verifyEmail.status = 'FAILED';
        });
        builder.addCase(complete.pending, (state) => {
            state.sources.complete.status = 'LOADING';
        });
        builder.addCase(complete.fulfilled, (state) => {
            state.sources.complete.status = 'IDLE';
            state.status = 'FINISHED';
        });
        builder.addCase(complete.rejected, (state) => {
            state.sources.complete.status = 'FAILED';
        });
    }
});

export {signUpSlice};
