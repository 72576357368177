import type {TRequestStatus} from '@mcal/core';
import type {ISliceSource} from '../../defines/redux.types.js';
import {resolveStatus} from '../resolve-status/resolve-status.js';

interface IBaseState {
    status: string;
    sources: Record<string, ISliceSource<unknown>>;
}

type TMap = Partial<Record<TRequestStatus, string>>;

type TMapArg<T> = TMap | ((state: T) => TMap);

function getStatusReducer<T extends IBaseState>(map?: TMapArg<T>) {
    return (state: T): void => {
        state.status = resolveStatus(
            state.sources,
            typeof map === 'function' ? map(state) : map
        );
    };
}

export {getStatusReducer};
