import type {
    TAPIRequestContent,
    TAPIResponse,
    TSignUpCompleteAPI,
    TSignUpStartAPI,
    TSignUpVerifyEmailAPI
} from '@mcal/core';
import {request} from '../../../utils/request/request.js';

const start = (
    {query, body}: TAPIRequestContent<TSignUpStartAPI>,
    signal: AbortSignal
): TAPIResponse<TSignUpStartAPI> => {
    return request<TSignUpStartAPI>({
        method: 'POST',
        url: '/sign-up/start',
        signal,
        query,
        body
    });
};

const verifyEmail = (
    {query, body}: TAPIRequestContent<TSignUpVerifyEmailAPI>,
    signal: AbortSignal
): TAPIResponse<TSignUpVerifyEmailAPI> => {
    return request<TSignUpVerifyEmailAPI>({
        method: 'POST',
        url: '/sign-up/verify-email',
        signal,
        query,
        body
    });
};

const complete = (
    {query, body}: TAPIRequestContent<TSignUpCompleteAPI>,
    signal: AbortSignal
): TAPIResponse<TSignUpCompleteAPI> => {
    return request<TSignUpCompleteAPI>({
        method: 'POST',
        url: '/sign-up/complete',
        signal,
        query,
        body
    });
};

export {complete, start, verifyEmail};
