import {useTheme} from '@mcal/core-react';
import type {FC} from 'react';
import {useMemo} from 'react';

interface IColors {
    colorA: string;
}

interface IMenuSvgProps {
    height?: number | string;
    width?: number | string;
}

const MenuSvg: FC<IMenuSvgProps> = (props) => {
    const {type} = useTheme();

    const {colorA} = useMemo<IColors>(() => {
        if (type === 'dark') {
            return {
                colorA: '#AAAEB4'
            };
        } else {
            return {
                colorA: '#555C69'
            };
        }
    }, [type]);

    return (
        <svg
            xmlns={'http://www.w3.org/2000/svg'}
            width={'14'}
            height={'9'}
            viewBox={'0 0 14 9'}
            fill={'none'}
            {...props}
        >
            <path
                d={'M0 9V7.5H14V9H0ZM0 5.25V3.75H14V5.25H0ZM0 1.5V0H14V1.5H0Z'}
                fill={colorA}
            />
        </svg>
    );
};

export {MenuSvg};
