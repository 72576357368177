import type {
    TAPIRequestContent,
    TAPIResponse,
    TTechnicianProfileCreateAPI,
    TTechnicianProfileUploadDocumentsAPI,
    TUserDeleteProfilePhotoAPI,
    TUserGetAPI,
    TUserGetProfilePhotoAPI,
    TUserUpdateEmailAPI,
    TUserUpdateGeneralSettingsAPI,
    TUserUpdatePasswordAPI,
    TUserUpdatePhoneNumberAPI,
    TUserUpdateProfileAPI,
    TUserUpdateProfilePhotoAPI,
    TUserVerifyEmailAPI,
    TUserVerifyPhoneNumberAPI
} from '@mcal/core';
import {request} from '../../../utils/request/request.js';

const getUser = (
    {query}: TAPIRequestContent<TUserGetAPI>,
    signal: AbortSignal
): TAPIResponse<TUserGetAPI> => {
    return request<TUserGetAPI>({
        method: 'GET',
        url: '/user',
        signal,
        query
    });
};

const getProfilePhoto = (
    {query}: TAPIRequestContent<TUserGetProfilePhotoAPI>,
    signal: AbortSignal
): TAPIResponse<TUserGetProfilePhotoAPI> => {
    return request<TUserGetProfilePhotoAPI>({
        method: 'GET',
        url: '/user/profile-photo',
        signal,
        query
    });
};

const updatePassword = (
    {query, body}: TAPIRequestContent<TUserUpdatePasswordAPI>,
    signal: AbortSignal
): TAPIResponse<TUserUpdatePasswordAPI> => {
    return request<TUserUpdatePasswordAPI>({
        method: 'PATCH',
        url: '/user/update-password',
        signal,
        query,
        body
    });
};

const updateEmail = (
    {query, body}: TAPIRequestContent<TUserUpdateEmailAPI>,
    signal: AbortSignal
): TAPIResponse<TUserUpdateEmailAPI> => {
    return request<TUserUpdateEmailAPI>({
        method: 'PATCH',
        url: '/user/update-email',
        signal,
        query,
        body
    });
};

const verifyEmail = (
    {query, body}: TAPIRequestContent<TUserVerifyEmailAPI>,
    signal: AbortSignal
): TAPIResponse<TUserVerifyEmailAPI> => {
    return request<TUserVerifyEmailAPI>({
        method: 'POST',
        url: '/user/verify-email',
        signal,
        query,
        body
    });
};

const updatePhoneNumber = (
    {query, body}: TAPIRequestContent<TUserUpdatePhoneNumberAPI>,
    signal: AbortSignal
): TAPIResponse<TUserUpdatePhoneNumberAPI> => {
    return request<TUserUpdatePhoneNumberAPI>({
        method: 'PATCH',
        url: '/user/update-phone-number',
        signal,
        query,
        body
    });
};

const verifyPhoneNumber = (
    {query, body}: TAPIRequestContent<TUserVerifyPhoneNumberAPI>,
    signal: AbortSignal
): TAPIResponse<TUserVerifyPhoneNumberAPI> => {
    return request<TUserVerifyPhoneNumberAPI>({
        method: 'POST',
        url: '/user/verify-phone-number',
        signal,
        query,
        body
    });
};

const updateGeneralSettings = (
    {query, body}: TAPIRequestContent<TUserUpdateGeneralSettingsAPI>,
    signal: AbortSignal
): TAPIResponse<TUserUpdateGeneralSettingsAPI> => {
    return request<TUserUpdateGeneralSettingsAPI>({
        method: 'PATCH',
        url: '/user/general-settings',
        signal,
        query,
        body
    });
};

const updateProfilePhoto = (
    {query, body}: TAPIRequestContent<TUserUpdateProfilePhotoAPI>,
    signal: AbortSignal
): TAPIResponse<TUserUpdateProfilePhotoAPI> => {
    return request<TUserUpdateProfilePhotoAPI>({
        method: 'PUT',
        url: '/user/profile-photo',
        signal,
        query,
        body
    });
};

const deleteProfilePhoto = (
    {query}: TAPIRequestContent<TUserDeleteProfilePhotoAPI>,
    signal: AbortSignal
): TAPIResponse<TUserDeleteProfilePhotoAPI> => {
    return request<TUserDeleteProfilePhotoAPI>({
        method: 'DELETE',
        url: '/user/profile-photo',
        signal,
        query
    });
};

const updateProfile = (
    {query, body}: TAPIRequestContent<TUserUpdateProfileAPI>,
    signal: AbortSignal
): TAPIResponse<TUserUpdateProfileAPI> => {
    return request<TUserUpdateProfileAPI>({
        method: 'PATCH',
        url: '/user/profile',
        signal,
        query,
        body
    });
};

const createTechnicianProfile = (
    {query, body}: TAPIRequestContent<TTechnicianProfileCreateAPI>,
    signal: AbortSignal
): TAPIResponse<TTechnicianProfileCreateAPI> => {
    return request<TTechnicianProfileCreateAPI>({
        method: 'PUT',
        url: '/technician-profile',
        signal,
        query,
        body
    });
};

const uploadTechnicianProfileDocuments = (
    {query, body}: TAPIRequestContent<TTechnicianProfileUploadDocumentsAPI>,
    signal: AbortSignal
): TAPIResponse<TTechnicianProfileUploadDocumentsAPI> => {
    return request<TTechnicianProfileUploadDocumentsAPI>({
        method: 'PUT',
        url: '/technician-profile/documents',
        signal,
        query,
        body
    });
};

export {
    createTechnicianProfile,
    deleteProfilePhoto,
    getProfilePhoto,
    getUser,
    updateEmail,
    updateGeneralSettings,
    updatePassword,
    updatePhoneNumber,
    updateProfile,
    updateProfilePhoto,
    uploadTechnicianProfileDocuments,
    verifyEmail,
    verifyPhoneNumber
};
