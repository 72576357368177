import type {ISliceSource} from '../../defines/redux.types.js';

type TLogInSliceStatus =
    | 'INIT'
    | 'LOGGING-IN'
    | 'CHALLENGE-REQUIRED'
    | 'LOGGED-IN'
    | 'FAILED'
    | 'START'
    | 'COMPLETE'
    | 'FINISHED';

interface ILogInSliceState {
    status: TLogInSliceStatus;
    sources: {
        logIn: ISliceSource<null>;
        start: ISliceSource<null>;
        complete: ISliceSource<null>;
    };
}

const initialState = {
    status: 'INIT',
    sources: {
        logIn: {
            status: 'INIT',
            prev: null
        },
        start: {
            status: 'INIT',
            prev: null
        },
        complete: {
            status: 'INIT',
            prev: null
        }
    }
} satisfies ILogInSliceState as ILogInSliceState;

export type {ILogInSliceState, TLogInSliceStatus};
export {initialState};
