import type {
    TAPIResponseSuccess,
    TAdminTechnicianProfilePendingReviewAPI,
    TAdminTechnicianProfilesListAPI,
    TAdminTechnicianProfilesListPerDateAPI,
    TAdminTechnicianProfilesPendingRecordsListAPI,
    TAdminUsersListAPI,
    TUsersGetMembershipsAPI
} from '@mcal/core';
import {createAsyncThunk} from '@reduxjs/toolkit';
import type {
    TAPIAsyncThunkArgs,
    TAPIAsyncThunkConfig
} from '../../../defines/redux.types.js';
import {getState} from '../../../utils/get-state/get-state.js';
import * as services from './users.services.js';

const getUsersMemberships = createAsyncThunk<
    TAPIResponseSuccess<TUsersGetMembershipsAPI>,
    TAPIAsyncThunkArgs<TUsersGetMembershipsAPI>,
    TAPIAsyncThunkConfig<TUsersGetMembershipsAPI>
>('users/getUsersMemberships', async (args, thunkAPI) => {
    const user = getState(thunkAPI, 'user');

    return await services
        .getUsersMemberships(
            {
                query: {
                    locale: user.remotes.userGeneralSettings.current.locale,
                    entityId: args.entityId,
                    affinityId: args.affinityId,
                    members: args.members
                }
            },
            thunkAPI.signal
        )
        .then((response) => {
            if (response.error) {
                return thunkAPI.rejectWithValue(response);
            } else {
                return thunkAPI.fulfillWithValue(response);
            }
        });
});

const getUsersAdmin = createAsyncThunk<
    TAPIResponseSuccess<TAdminUsersListAPI>,
    TAPIAsyncThunkArgs<TAdminUsersListAPI>,
    TAPIAsyncThunkConfig<TAdminUsersListAPI>
>('users/getUsersAdmin', async (query, thunkAPI) => {
    const user = getState(thunkAPI, 'user');

    return await services
        .getUsersAdmin(
            {
                query: {
                    email: query.email,
                    locale: user.remotes.userGeneralSettings.current.locale
                }
            },
            thunkAPI.signal
        )
        .then((response) => {
            if (response.error) {
                return thunkAPI.rejectWithValue(response);
            } else {
                return thunkAPI.fulfillWithValue(response);
            }
        });
});

const getTechniciansAdmin = createAsyncThunk<
    TAPIResponseSuccess<TAdminTechnicianProfilesListAPI>,
    TAPIAsyncThunkArgs<TAdminTechnicianProfilesListAPI>,
    TAPIAsyncThunkConfig<TAdminTechnicianProfilesListAPI>
>('users/getTechniciansAdmin', async (query, thunkAPI) => {
    const user = getState(thunkAPI, 'user');

    return await services
        .getTechniciansAdmin(
            {
                query: {
                    locale: user.remotes.userGeneralSettings.current.locale,
                    pendingMonth: query.pendingMonth,
                    pendingYear: query.pendingYear,
                    email: query.email
                }
            },
            thunkAPI.signal
        )
        .then((response) => {
            if (response.error) {
                return thunkAPI.rejectWithValue(response);
            } else {
                return thunkAPI.fulfillWithValue(response);
            }
        });
});

const getPendingTechniciansAdmin = createAsyncThunk<
    TAPIResponseSuccess<TAdminTechnicianProfilesListPerDateAPI>,
    TAPIAsyncThunkArgs<TAdminTechnicianProfilesListPerDateAPI>,
    TAPIAsyncThunkConfig<TAdminTechnicianProfilesListPerDateAPI>
>('users/getPendingTechniciansAdmin', async (query, thunkAPI) => {
    const user = getState(thunkAPI, 'user');

    return await services
        .getPendingTechniciansAdmin(
            {
                query: {
                    locale: user.remotes.userGeneralSettings.current.locale,
                    pendingMonth: query.pendingMonth,
                    pendingYear: query.pendingYear
                }
            },
            thunkAPI.signal
        )
        .then((response) => {
            if (response.error) {
                return thunkAPI.rejectWithValue(response);
            } else {
                return thunkAPI.fulfillWithValue(response);
            }
        });
});

const listPendingTechniciansRecordsAdmin = createAsyncThunk<
    TAPIResponseSuccess<TAdminTechnicianProfilesPendingRecordsListAPI>,
    TAPIAsyncThunkArgs<TAdminTechnicianProfilesPendingRecordsListAPI>,
    TAPIAsyncThunkConfig<TAdminTechnicianProfilesPendingRecordsListAPI>
>('users/getPendingTechniciansRecordsAdmin', async (_, thunkAPI) => {
    const user = getState(thunkAPI, 'user');

    return await services
        .listTechniciansPendingRecordAdmin(
            {
                query: {
                    locale: user.remotes.userGeneralSettings.current.locale
                }
            },
            thunkAPI.signal
        )
        .then((response) => {
            if (response.error) {
                return thunkAPI.rejectWithValue(response);
            } else {
                return thunkAPI.fulfillWithValue(response);
            }
        });
});

const reviewTechnicianAdmin = createAsyncThunk<
    TAPIResponseSuccess<TAdminTechnicianProfilePendingReviewAPI>,
    TAPIAsyncThunkArgs<TAdminTechnicianProfilePendingReviewAPI>,
    TAPIAsyncThunkConfig<TAdminTechnicianProfilePendingReviewAPI>
>('users/approveTechnician', async ({userId, result}, thunkAPI) => {
    const user = getState(thunkAPI, 'user');

    return await services
        .reviewTechnicianAdmin(
            {
                query: {
                    locale: user.remotes.userGeneralSettings.current.locale
                },
                body: {
                    data: {
                        userId,
                        result
                    }
                }
            },
            thunkAPI.signal
        )
        .then((response) => {
            if (response.error) {
                return thunkAPI.rejectWithValue(response);
            } else {
                void thunkAPI.dispatch(
                    getTechniciansAdmin({
                        email: '',
                        pendingMonth: undefined,
                        pendingYear: undefined
                    })
                );

                return thunkAPI.fulfillWithValue(response);
            }
        });
});

export {
    getPendingTechniciansAdmin,
    getTechniciansAdmin,
    getUsersAdmin,
    getUsersMemberships,
    listPendingTechniciansRecordsAdmin,
    reviewTechnicianAdmin
};
