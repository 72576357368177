import type {
    EElevatorOperationMode,
    ESiteRole,
    IElevatorDestination,
    IElevatorGroupSummary,
    IElevatorState,
    IElevatorSummary,
    IEntityInviteEntry,
    IIoTStateRecord,
    IResolvedPermissions,
    ISiteActivityEntry,
    ISiteGeneralSettings,
    ISiteProfile,
    ISiteState,
    ISiteSummary,
    TAffinityProvider,
    TNestedPartial
} from '@mcal/core';
import {EAffiliationStatus, EElevatorStatus, EEntityType} from '@mcal/core';
import type {PayloadAction} from '@reduxjs/toolkit';
import {createSlice} from '@reduxjs/toolkit';
import {getPermissions} from '../../services/app-gateway/permissions/permissions.thunks.js';
import {logOut} from '../../services/app-node-auth/session/session.thunks.js';
import {getUsersMemberships} from '../../services/app-node-auth/users/users.thunks.js';
import {
    listElevators,
    updateElevatorProfile
} from '../../services/app-node-elevators/elevators/elevators.thunks.js';
import {
    addMember,
    cancelInvite,
    listEntityInvites,
    sendInvite
} from '../../services/app-node-invites/invites/invites.thunks.js';
import {getMileageTotal} from '../../services/app-node-kpis/kpis/kpis.thunks.js';
import {getServiceCompanyTeam} from '../../services/app-node-service-companies/service-companies/service-companies.thunks.js';
import {
    addElevatorGroup,
    deleteElevatorGroup,
    deleteSiteTeam,
    getSite,
    getSiteState,
    getSiteTeam,
    listElevatorGroups,
    loadMoreSiteActivity,
    updateElevatorGroupProfile,
    updateSiteProfile,
    updateSiteTeam
} from '../../services/app-node-sites/sites/sites.thunks.js';
import {fulfilledRemoteUpdate} from '../../utils/fulfilled-remote-update/fulfilled-remote-update.js';
import {pendingRemoteUpdate} from '../../utils/pending-remote-update/pending-remote-update.js';
import {rejectedRemoteUpdate} from '../../utils/rejected-remote-update/rejected-remote-update.js';
import type {TInvites} from './site.state.js';
import {initialState} from './site.state.js';

const siteSlice = createSlice({
    name: 'site',
    initialState,
    reducers: {
        updateSiteElevatorFloor: (
            state,
            {payload}: PayloadAction<IElevatorDestination>
        ) => {
            state.remotes.elevators.current =
                state.remotes.elevators.current.map((elevator) => {
                    if (elevator.entityId === payload.elevatorId) {
                        return {
                            ...elevator,
                            currentStopKey: payload.number
                        };
                    }

                    return elevator;
                });
        },
        updateSiteElevatorStatus: (
            state,
            {payload}: PayloadAction<Partial<IElevatorState>>
        ) => {
            state.remotes.elevators.current =
                state.remotes.elevators.current.map((elevator) => {
                    if (elevator.entityId === payload.elevatorId) {
                        return {
                            ...elevator,
                            status: payload.elevatorStatus as EElevatorStatus,
                            operationMode:
                                payload.elevatorStatus ===
                                EElevatorStatus.Normal
                                    ? 0
                                    : elevator.operationMode
                        };
                    }

                    return elevator;
                });
        },
        updateReportedGeneralSettings: (
            state,
            {
                payload
            }: PayloadAction<{
                operationMode: IIoTStateRecord<EElevatorOperationMode>;
                remoteControl: IIoTStateRecord<boolean>;
                elevatorId: string;
            }>
        ) => {
            state.remotes.elevators.current =
                state.remotes.elevators.current.map((elevator) => {
                    if (elevator.entityId === payload.elevatorId) {
                        return {
                            ...elevator,
                            remoteControl: payload.remoteControl.reported,
                            operationMode:
                                payload.operationMode.reported !== null
                                    ? payload.operationMode.reported
                                    : elevator.operationMode
                        };
                    }

                    return elevator;
                });
        },
        resetSlice: () => {
            return initialState;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getSite.pending, (state, {meta}) => {
            pendingRemoteUpdate({
                state,
                meta,
                targets: [
                    'siteSummary',
                    'siteState',
                    'siteProfile',
                    'siteGeneralSettings'
                ]
            });
        });
        builder.addCase(getSite.fulfilled, (state, {payload, meta}) => {
            fulfilledRemoteUpdate({
                state,
                meta,
                payload,
                targets: [
                    {
                        key: 'siteSummary',
                        replacer: (data): ISiteSummary => {
                            return data.summary;
                        }
                    },
                    {
                        key: 'siteProfile',
                        replacer: (data): ISiteProfile => {
                            return data.profile;
                        }
                    },
                    {
                        key: 'siteGeneralSettings',
                        replacer: (data): ISiteGeneralSettings => {
                            return data.generalSettings;
                        }
                    },
                    {
                        key: 'siteState',
                        replacer: (data): ISiteState => {
                            return data.state;
                        }
                    }
                ]
            });
        });
        builder.addCase(getSite.rejected, (state, {meta, payload}) => {
            rejectedRemoteUpdate({
                state,
                meta,
                payload,
                targets: [
                    'siteSummary',
                    'siteState',
                    'siteProfile',
                    'siteGeneralSettings'
                ]
            });
        });

        builder.addCase(getSiteState.pending, (state, {meta}) => {
            pendingRemoteUpdate({
                state,
                meta,
                targets: ['siteState']
            });
        });
        builder.addCase(getSiteState.fulfilled, (state, {payload, meta}) => {
            fulfilledRemoteUpdate({
                state,
                meta,
                payload,
                targets: [
                    {
                        key: 'siteState',
                        replacer: (data): ISiteState => {
                            return data;
                        }
                    }
                ]
            });
        });
        builder.addCase(getSiteState.rejected, (state, {meta, payload}) => {
            rejectedRemoteUpdate({
                state,
                meta,
                payload,
                targets: ['siteState']
            });
        });

        builder.addCase(getSiteTeam.pending, (state, {meta}) => {
            if (
                meta.arg.siteId !==
                    state.remotes.siteSummary.current.entityId ||
                !meta.arg.affinityId
            ) {
                return;
            }

            pendingRemoteUpdate({
                state,
                meta,
                targets: ['teams']
            });
        });
        builder.addCase(getSiteTeam.fulfilled, (state, {payload, meta}) => {
            if (
                meta.arg.siteId !==
                    state.remotes.siteSummary.current.entityId ||
                !meta.arg.affinityId
            ) {
                return;
            }

            fulfilledRemoteUpdate({
                state,
                meta,
                payload,
                targets: [
                    {
                        key: 'teams',
                        mutator: (arg, current): void => {
                            current[meta.arg.affinityId] = arg;
                        }
                    }
                ]
            });
        });
        builder.addCase(getSiteTeam.rejected, (state, {meta, payload}) => {
            rejectedRemoteUpdate({
                state,
                meta,
                payload,
                targets: ['teams']
            });
        });

        builder.addCase(loadMoreSiteActivity.pending, (state, {meta}) => {
            if (
                state.remotes.siteSummary.current.entityId &&
                meta.arg.siteId !== state.remotes.siteSummary.current.entityId
            ) {
                return;
            }

            pendingRemoteUpdate({
                state,
                meta,
                targets: ['activity']
            });
        });
        builder.addCase(
            loadMoreSiteActivity.fulfilled,
            (state, {payload, meta}) => {
                if (
                    state.remotes.siteSummary.current.entityId &&
                    meta.arg.siteId !==
                        state.remotes.siteSummary.current.entityId
                ) {
                    return;
                }

                if (
                    !state.remotes.activity.current.data.length ||
                    state.remotes.activity.current.data[0].siteId !==
                        meta.arg.siteId
                ) {
                    fulfilledRemoteUpdate({
                        state,
                        meta,
                        payload,
                        targets: [
                            {
                                key: 'activity',
                                replacer: (
                                    data
                                ): {
                                    currentCursor: string | null;
                                    nextCursor: string | null;
                                    data: ISiteActivityEntry[];
                                } => {
                                    return data;
                                }
                            }
                        ]
                    });

                    return;
                }

                fulfilledRemoteUpdate({
                    state,
                    meta,
                    payload,
                    targets: [
                        {
                            key: 'activity',
                            mutator: (arg, current): void => {
                                current.data = [...current.data, ...arg.data];

                                current.currentCursor =
                                    payload.data.currentCursor;
                                current.nextCursor = payload.data.nextCursor;
                            }
                        }
                    ]
                });
            }
        );
        builder.addCase(
            loadMoreSiteActivity.rejected,
            (state, {meta, payload}) => {
                if (
                    state.remotes.siteSummary.current.entityId &&
                    meta.arg.siteId !==
                        state.remotes.siteSummary.current.entityId
                ) {
                    return;
                }

                if (!meta.aborted) {
                    rejectedRemoteUpdate({
                        state,
                        meta,
                        payload,
                        targets: ['activity']
                    });
                }
            }
        );

        builder.addCase(getUsersMemberships.pending, (state, {meta}) => {
            if (
                meta.arg.entityId !==
                    state.remotes.siteSummary.current.entityId ||
                !meta.arg.affinityId
            ) {
                return;
            }

            pendingRemoteUpdate({
                state,
                meta,
                targets: ['memberships', 'globalMemberships']
            });
        });
        builder.addCase(
            getUsersMemberships.fulfilled,
            (state, {payload, meta}) => {
                const delegator = state.remotes.permissions.current.access.find(
                    (access) => {
                        return access.entityId === meta.arg.affinityId;
                    }
                );

                if (delegator && delegator.isDelegated) {
                    fulfilledRemoteUpdate({
                        state,
                        meta,
                        payload,
                        targets: [
                            {
                                key: 'globalMemberships',
                                mutator: (arg, current): void => {
                                    current[meta.arg.entityId] = arg;
                                }
                            }
                        ]
                    });
                }

                if (
                    meta.arg.entityId !==
                        state.remotes.siteSummary.current.entityId ||
                    !meta.arg.affinityId
                ) {
                    return;
                }

                fulfilledRemoteUpdate({
                    state,
                    meta,
                    payload,
                    targets: [
                        {
                            key: 'memberships',
                            mutator: (arg, current): void => {
                                current[meta.arg.affinityId] = arg;
                            }
                        }
                    ]
                });
            }
        );
        builder.addCase(
            getUsersMemberships.rejected,
            (state, {meta, payload}) => {
                if (
                    meta.arg.entityId !==
                    state.remotes.siteSummary.current.entityId
                ) {
                    return;
                }

                rejectedRemoteUpdate({
                    state,
                    meta,
                    payload,
                    targets: ['memberships', 'globalMemberships']
                });
            }
        );

        builder.addCase(listEntityInvites.pending, (state, {meta}) => {
            if (
                meta.arg.entityId !== state.remotes.siteSummary.current.entityId
            ) {
                return;
            }

            pendingRemoteUpdate({
                state,
                meta,
                targets: ['invites']
            });
        });
        builder.addCase(
            listEntityInvites.fulfilled,
            (state, {payload, meta}) => {
                if (
                    meta.arg.entityId !==
                    state.remotes.siteSummary.current.entityId
                ) {
                    return;
                }

                fulfilledRemoteUpdate({
                    state,
                    meta,
                    payload,
                    targets: [
                        {
                            key: 'invites',
                            replacer: (
                                data
                            ): IEntityInviteEntry<EEntityType.Site>[] => {
                                return data as IEntityInviteEntry<EEntityType.Site>[];
                            }
                        }
                    ]
                });
            }
        );
        builder.addCase(
            listEntityInvites.rejected,
            (state, {meta, payload}) => {
                if (
                    meta.arg.entityId !==
                    state.remotes.siteSummary.current.entityId
                ) {
                    return;
                }

                rejectedRemoteUpdate({
                    state,
                    meta,
                    payload,
                    targets: ['invites']
                });
            }
        );

        // HANDLING SITE PROFILE UPDATE
        builder.addCase(updateSiteProfile.pending, (state, {meta}) => {
            pendingRemoteUpdate({
                state,
                meta,
                targets: [
                    {
                        key: 'siteProfile',
                        merger: (arg): TNestedPartial<ISiteProfile> => {
                            return arg.profile;
                        }
                    }
                ]
            });
        });
        builder.addCase(
            updateSiteProfile.fulfilled,
            (state, {payload, meta}) => {
                fulfilledRemoteUpdate({
                    state,
                    meta,
                    payload,
                    targets: ['siteProfile']
                });
            }
        );
        builder.addCase(
            updateSiteProfile.rejected,
            (state, {meta, payload}) => {
                rejectedRemoteUpdate({
                    state,
                    meta,
                    payload,
                    targets: ['siteProfile']
                });
            }
        );

        // HANDLING CANCEL INVITE
        builder.addCase(cancelInvite.pending, (state, {meta}) => {
            const isAffected = state.remotes.invites.current.some((invite) => {
                return invite.inviteId === meta.arg.inviteId;
            });

            if (!isAffected) {
                return;
            }

            pendingRemoteUpdate({
                state,
                meta,
                targets: ['invites']
            });
        });
        builder.addCase(cancelInvite.fulfilled, (state, {meta, payload}) => {
            fulfilledRemoteUpdate({
                state,
                meta,
                payload,
                targets: [
                    {
                        key: 'invites',
                        replacer:
                            (): IEntityInviteEntry<EEntityType.Site>[] => {
                                return state.remotes.invites.current.filter(
                                    (invite) =>
                                        invite.inviteId !== meta.arg.inviteId
                                );
                            }
                    }
                ]
            });
        });
        builder.addCase(cancelInvite.rejected, (state, {meta, payload}) => {
            const isAffected = state.remotes.invites.current.some((invite) => {
                return invite.inviteId === meta.arg.inviteId;
            });

            if (!isAffected) {
                return;
            }

            rejectedRemoteUpdate({
                state,
                meta,
                payload,
                targets: ['invites']
            });
        });

        builder.addCase(listElevatorGroups.pending, (state, {meta}) => {
            if (
                state.remotes.siteSummary.current.entityId &&
                meta.arg.target !== state.remotes.siteSummary.current.entityId
            ) {
                return;
            }

            pendingRemoteUpdate({
                state,
                meta,
                targets: ['elevatorGroups']
            });
        });
        builder.addCase(
            listElevatorGroups.fulfilled,
            (state, {payload, meta}) => {
                if (
                    state.remotes.siteSummary.current.entityId &&
                    meta.arg.target !==
                        state.remotes.siteSummary.current.entityId
                ) {
                    return;
                }

                fulfilledRemoteUpdate({
                    state,
                    meta,
                    payload,
                    targets: [
                        {
                            key: 'elevatorGroups',
                            replacer: (data): IElevatorGroupSummary[] => {
                                return data;
                            }
                        }
                    ]
                });
            }
        );
        builder.addCase(
            listElevatorGroups.rejected,
            (state, {meta, payload}) => {
                if (
                    state.remotes.siteSummary.current.entityId &&
                    meta.arg.target !==
                        state.remotes.siteSummary.current.entityId
                ) {
                    return;
                }

                rejectedRemoteUpdate({
                    state,
                    meta,
                    payload,
                    targets: ['elevatorGroups']
                });
            }
        );

        builder.addCase(addElevatorGroup.pending, (state, {meta}) => {
            if (
                state.remotes.siteSummary.current.entityId &&
                meta.arg.siteId !== state.remotes.siteSummary.current.entityId
            ) {
                return;
            }

            pendingRemoteUpdate({
                state,
                meta,
                targets: ['elevatorGroups']
            });
        });
        builder.addCase(
            addElevatorGroup.fulfilled,
            (state, {payload, meta}) => {
                if (
                    state.remotes.siteSummary.current.entityId &&
                    meta.arg.siteId !==
                        state.remotes.siteSummary.current.entityId
                ) {
                    return;
                }

                fulfilledRemoteUpdate({
                    state,
                    meta,
                    payload,
                    targets: [
                        {
                            key: 'elevatorGroups',
                            replacer: (
                                data,
                                current
                            ): IElevatorGroupSummary[] => {
                                return [...current, data.summary];
                            }
                        }
                    ]
                });
            }
        );
        builder.addCase(addElevatorGroup.rejected, (state, {meta, payload}) => {
            if (
                state.remotes.siteSummary.current.entityId &&
                meta.arg.siteId !== state.remotes.siteSummary.current.entityId
            ) {
                return;
            }

            rejectedRemoteUpdate({
                state,
                meta,
                payload,
                targets: ['elevatorGroups']
            });
        });

        builder.addCase(deleteElevatorGroup.pending, (state, {meta}) => {
            // if (
            //     state.remotes.siteSummary.current.entityId &&
            //     meta.arg.siteId !== state.remotes.siteSummary.current.entityId
            // ) {
            //     return;
            // }

            pendingRemoteUpdate({
                state,
                meta,
                targets: ['elevatorGroups']
            });
        });
        builder.addCase(
            deleteElevatorGroup.fulfilled,
            (state, {payload, meta}) => {
                fulfilledRemoteUpdate({
                    state,
                    meta,
                    payload,
                    targets: [
                        {
                            key: 'elevatorGroups',
                            replacer: (_, current): IElevatorGroupSummary[] => {
                                return current.filter(
                                    (elevatorGroup) =>
                                        elevatorGroup.entityId !==
                                        meta.arg.elevatorGroupId
                                );
                            }
                        }
                    ]
                });
            }
        );
        builder.addCase(
            deleteElevatorGroup.rejected,
            (state, {meta, payload}) => {
                rejectedRemoteUpdate({
                    state,
                    meta,
                    payload,
                    targets: ['elevatorGroups']
                });
            }
        );

        builder.addCase(updateElevatorGroupProfile.pending, (state, {meta}) => {
            pendingRemoteUpdate({
                state,
                meta,
                targets: [
                    {
                        key: 'elevatorGroups',
                        replacer: (data, current): IElevatorGroupSummary[] => {
                            const elevatorGroupsIndex = current.findIndex(
                                (elevatorGroup) =>
                                    elevatorGroup.entityId ===
                                    meta.arg.elevatorGroupId
                            );
                            const elevatorGroups: IElevatorGroupSummary[] = [
                                ...(current || [])
                            ];

                            elevatorGroups[elevatorGroupsIndex] = {
                                ...elevatorGroups[elevatorGroupsIndex],
                                name:
                                    data.profile.name ||
                                    elevatorGroups[elevatorGroupsIndex].name
                            };
                            return elevatorGroups;
                        }
                    }
                ]
            });
        });
        builder.addCase(
            updateElevatorGroupProfile.fulfilled,
            (state, {payload, meta}) => {
                fulfilledRemoteUpdate({
                    state,
                    meta,
                    payload,
                    targets: ['elevatorGroups']
                });
            }
        );
        builder.addCase(
            updateElevatorGroupProfile.rejected,
            (state, {meta, payload}) => {
                rejectedRemoteUpdate({
                    state,
                    meta,
                    payload,
                    targets: ['elevatorGroups']
                });
            }
        );

        builder.addCase(listElevators.pending, (state, {meta}) => {
            if (
                state.remotes.siteSummary.current.entityId &&
                meta.arg.target !== state.remotes.siteSummary.current.entityId
            ) {
                return;
            }

            pendingRemoteUpdate({
                state,
                meta,
                targets: ['elevators']
            });
        });
        builder.addCase(listElevators.fulfilled, (state, {payload, meta}) => {
            if (
                state.remotes.siteSummary.current.entityId &&
                meta.arg.target !== state.remotes.siteSummary.current.entityId
            ) {
                return;
            }

            fulfilledRemoteUpdate({
                state,
                meta,
                payload,
                targets: [
                    {
                        key: 'elevators',
                        replacer: (data): IElevatorSummary[] => {
                            return data;
                        }
                    }
                ]
            });
        });
        builder.addCase(listElevators.rejected, (state, {meta, payload}) => {
            if (
                state.remotes.siteSummary.current.entityId &&
                meta.arg.target !== state.remotes.siteSummary.current.entityId
            ) {
                return;
            }

            rejectedRemoteUpdate({
                state,
                meta,
                payload,
                targets: ['elevators']
            });
        });

        builder.addCase(getMileageTotal.pending, (state, {meta}) => {
            if (
                state.remotes.siteSummary.current.entityId &&
                meta.arg.target !== state.remotes.siteSummary.current.entityId
            ) {
                return;
            }

            pendingRemoteUpdate({
                state,
                meta,
                targets: ['kpis']
            });
        });
        builder.addCase(getMileageTotal.fulfilled, (state, {payload, meta}) => {
            if (
                state.remotes.siteSummary.current.entityId &&
                meta.arg.target !== state.remotes.siteSummary.current.entityId
            ) {
                return;
            }

            fulfilledRemoteUpdate({
                state,
                meta,
                payload,
                targets: [
                    {
                        key: 'kpis',
                        mutator: (arg, current): void => {
                            current.mileageTotal = arg;
                        }
                    }
                ]
            });
        });
        builder.addCase(getMileageTotal.rejected, (state, {meta, payload}) => {
            if (
                meta.arg.target !== state.remotes.siteSummary.current.entityId
            ) {
                return;
            }

            rejectedRemoteUpdate({
                state,
                meta,
                payload,
                targets: ['kpis']
            });
        });

        builder.addCase(getPermissions.pending, (state, {meta}) => {
            if (
                meta.arg.entityId !== state.remotes.siteSummary.current.entityId
            ) {
                return;
            }

            pendingRemoteUpdate({
                state,
                meta,
                targets: ['permissions']
            });
        });
        builder.addCase(getPermissions.fulfilled, (state, {payload, meta}) => {
            if (
                meta.arg.entityId !== state.remotes.siteSummary.current.entityId
            ) {
                return;
            }

            fulfilledRemoteUpdate({
                state,
                meta,
                payload,
                targets: [
                    {
                        key: 'permissions',
                        replacer: (data): IResolvedPermissions => {
                            return data;
                        }
                    }
                ]
            });
        });
        builder.addCase(getPermissions.rejected, (state, {meta, payload}) => {
            if (
                meta.arg.entityId !== state.remotes.siteSummary.current.entityId
            ) {
                return;
            }

            rejectedRemoteUpdate({
                state,
                meta,
                payload,
                targets: ['permissions']
            });
        });

        builder.addCase(sendInvite.pending, (state, {meta}) => {
            if (
                meta.arg.entityId !== state.remotes.siteSummary.current.entityId
            ) {
                return;
            }

            pendingRemoteUpdate({
                state,
                meta,
                targets: [
                    {
                        key: 'invites',
                        replacer: (arg, current): TInvites => {
                            const now = Date.now();

                            const newInvites: TInvites = arg.assignments.map(
                                (assignment) => {
                                    return {
                                        inviteId: '',
                                        userId: null,
                                        userEmail: assignment.userEmail,
                                        role: assignment.role as ESiteRole,
                                        createdAt: now
                                    };
                                }
                            );

                            return [...current, ...newInvites];
                        }
                    }
                ]
            });
        });
        builder.addCase(sendInvite.fulfilled, (state, {meta, payload}) => {
            if (
                meta.arg.entityId !== state.remotes.siteSummary.current.entityId
            ) {
                return;
            }

            fulfilledRemoteUpdate({
                state,
                meta,
                payload,
                targets: ['invites']
            });
        });
        builder.addCase(sendInvite.rejected, (state, {meta, payload}) => {
            if (
                meta.arg.entityId !== state.remotes.siteSummary.current.entityId
            ) {
                return;
            }

            rejectedRemoteUpdate({
                state,
                meta,
                payload,
                targets: ['invites']
            });
        });

        builder.addCase(updateSiteTeam.pending, (state, {meta}) => {
            if (
                meta.arg.siteId !== state.remotes.siteSummary.current.entityId
            ) {
                return;
            }

            pendingRemoteUpdate({
                state,
                meta,
                targets: [
                    {
                        key: 'teams',
                        mutator: (arg, current): void => {
                            current[arg.affinityId] = current[
                                arg.affinityId
                            ].map((member) => {
                                if (member.userId === arg.userId) {
                                    return {
                                        ...member,
                                        role: arg.role
                                    };
                                } else {
                                    return member;
                                }
                            });
                        }
                    }
                ]
            });
        });
        builder.addCase(updateSiteTeam.fulfilled, (state, {meta, payload}) => {
            if (
                meta.arg.siteId !== state.remotes.siteSummary.current.entityId
            ) {
                return;
            }

            fulfilledRemoteUpdate({
                state,
                meta,
                payload,
                targets: ['teams']
            });
        });
        builder.addCase(updateSiteTeam.rejected, (state, {meta, payload}) => {
            if (
                meta.arg.siteId !== state.remotes.siteSummary.current.entityId
            ) {
                return;
            }

            rejectedRemoteUpdate({
                state,
                meta,
                payload,
                targets: ['teams']
            });
        });

        builder.addCase(addMember.pending, (state, {meta}) => {
            if (
                meta.arg.entityId !==
                    state.remotes.siteSummary.current.entityId ||
                !meta.arg.affinityId
            ) {
                return;
            }

            pendingRemoteUpdate({
                state,
                meta,
                targets: [
                    {
                        key: 'teams',
                        mutator: (arg, current): void => {
                            current[arg.affinityId] = [
                                ...current[arg.affinityId],
                                ...arg.assignments.map((user) => ({
                                    userId: user.userId,
                                    status: EAffiliationStatus.Active,
                                    role: user.role as ESiteRole,
                                    affinityId: arg.affinityId,
                                    affinityType:
                                        EEntityType.ServiceCompany as TAffinityProvider,
                                    attributes: []
                                }))
                            ];
                        }
                    }
                ]
            });
        });
        builder.addCase(addMember.fulfilled, (state, {meta, payload}) => {
            if (
                meta.arg.entityId !== state.remotes.siteSummary.current.entityId
            ) {
                return;
            }

            fulfilledRemoteUpdate({
                state,
                meta,
                payload,
                targets: ['teams']
            });
        });
        builder.addCase(addMember.rejected, (state, {meta, payload}) => {
            if (
                meta.arg.entityId !== state.remotes.siteSummary.current.entityId
            ) {
                return;
            }

            rejectedRemoteUpdate({
                state,
                meta,
                payload,
                targets: ['teams']
            });
        });

        builder.addCase(deleteSiteTeam.pending, (state, {meta}) => {
            if (
                meta.arg.siteId !== state.remotes.siteSummary.current.entityId
            ) {
                return;
            }

            pendingRemoteUpdate({
                state,
                meta,
                targets: [
                    {
                        key: 'teams',
                        mutator: (arg, current): void => {
                            state.remotes.teams.current[arg.affinityId] =
                                current[arg.affinityId].filter((member) => {
                                    return member.userId !== arg.userId;
                                });
                        }
                    }
                ]
            });
        });
        builder.addCase(deleteSiteTeam.fulfilled, (state, {meta, payload}) => {
            if (
                meta.arg.siteId !== state.remotes.siteSummary.current.entityId
            ) {
                return;
            }

            fulfilledRemoteUpdate({
                state,
                meta,
                payload,
                targets: ['teams']
            });
        });
        builder.addCase(deleteSiteTeam.rejected, (state, {meta, payload}) => {
            if (
                meta.arg.siteId !== state.remotes.siteSummary.current.entityId
            ) {
                return;
            }

            rejectedRemoteUpdate({
                state,
                meta,
                payload,
                targets: ['teams']
            });
        });

        builder.addCase(getServiceCompanyTeam.pending, (state, {meta}) => {
            const delegator = state.remotes.permissions.current.access.find(
                (access) => {
                    return access.entityId === meta.arg.serviceCompanyId;
                }
            );

            if (!delegator || !delegator.isDelegated) {
                return;
            }

            pendingRemoteUpdate({
                state,
                meta,
                targets: ['globalTeams']
            });
        });
        builder.addCase(
            getServiceCompanyTeam.fulfilled,
            (state, {meta, payload}) => {
                const delegator = state.remotes.permissions.current.access.find(
                    (access) => {
                        return access.entityId === meta.arg.serviceCompanyId;
                    }
                );

                if (!delegator || !delegator.isDelegated) {
                    return;
                }

                fulfilledRemoteUpdate({
                    state,
                    meta,
                    payload,
                    targets: [
                        {
                            key: 'globalTeams',
                            mutator: (arg, current): void => {
                                current[meta.arg.serviceCompanyId] = arg;
                            }
                        }
                    ]
                });
            }
        );
        builder.addCase(
            getServiceCompanyTeam.rejected,
            (state, {meta, payload}) => {
                const delegator = state.remotes.permissions.current.access.find(
                    (access) => {
                        return access.entityId === meta.arg.serviceCompanyId;
                    }
                );

                if (!delegator || !delegator.isDelegated) {
                    return;
                }

                rejectedRemoteUpdate({
                    state,
                    meta,
                    payload,
                    targets: ['globalTeams']
                });
            }
        );

        // UPDATE elevator name on elevators
        builder.addCase(
            updateElevatorProfile.fulfilled,
            (state, {meta, payload}) => {
                if (!meta.arg.profile.name) {
                    return;
                }

                fulfilledRemoteUpdate({
                    state,
                    meta,
                    payload,
                    targets: [
                        {
                            key: 'elevators',
                            mutator: (_, current): void => {
                                const elevatorIndex = current.findIndex(
                                    (elevator) => {
                                        return (
                                            elevator.entityId ===
                                            meta.arg.elevatorId
                                        );
                                    }
                                );

                                if (meta.arg.profile.name) {
                                    current[elevatorIndex].name =
                                        meta.arg.profile.name;
                                }
                            }
                        }
                    ]
                });
            }
        );

        builder.addCase(logOut.fulfilled, () => {
            return initialState;
        });
    }
});

export {siteSlice};
