import {styled} from '../../../../utils/styled/styled.js';
import {Icon} from '../../../icon/icon.js';
import {Label} from '../../../label/label.js';
import {Link} from '../../../link/link.js';

interface IStyledBottomBarItemProps {
    $active?: boolean;
}

const StyledRoot = styled(Link)<IStyledBottomBarItemProps>(() => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    position: 'relative'
}));

const StyledContainer = styled('div')<IStyledBottomBarItemProps>(
    ({theme, $active}) => ({
        padding: `${theme.size.fixed.s0} ${theme.size.fixed.s0}`,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'space-between',
        height: '100%',
        width: '75px',
        background: $active ? theme.palette.surface.base.lowest : '',
        borderRadius: theme.shape.corner.rounded.c0.borderRadius
    })
);

const StyledLabel = styled(Label)<IStyledBottomBarItemProps>(
    ({theme, $active}) => ({
        fontWeight: $active ? 500 : 400,
        color: $active
            ? theme.palette.surface.onBase.high
            : theme.palette.neutral.outline
    })
);

const StyledActiveIndicator = styled('div')<IStyledBottomBarItemProps>(
    ({theme, $active}) => ({
        position: 'absolute',
        top: 0,
        height: '3px',
        width: '20px',
        borderRadius: '3px 0px 0px 3px',
        background: $active ? theme.palette.accent.primary.high.base : ''
    })
);

const StyledIcon = styled(Icon)(() => ({
    width: '100%',
    height: '100%',
    svg: {
        width: '24px',
        height: '24px'
    }
}));

export {
    StyledActiveIndicator,
    StyledContainer,
    StyledIcon,
    StyledLabel,
    StyledRoot
};
