import type {TThemeType} from '@mcal/core-react';
import {useSelector, userSelectors} from '@mcal/core-react';
import {useSystemThemeType} from '../use-system-theme-type/use-system-theme-type.js';

function useThemeType(): TThemeType {
    const userGeneralSettings = useSelector(
        userSelectors.selectUserGeneralSettings
    );

    const userThemeType = userGeneralSettings.themeType;

    const systemThemeType = useSystemThemeType();

    return userThemeType === 'auto' ? systemThemeType : userThemeType;
}

export {useThemeType};
