import {unicodeRanges} from '@mcal/core';
import type {TTypeFace} from './theme.types.js';

type TFontDisplay = 'auto' | 'block' | 'fallback' | 'optional' | 'swap';

interface IFontFaceDescriptors {
    ascentOverride?: string;
    descentOverride?: string;
    display?: TFontDisplay;
    featureSettings?: string;
    lineGapOverride?: string;
    stretch?: string;
    style?: string;
    unicodeRange?: string;
    variant?: string;
    weight?: string;
}

interface IFontResource {
    id: string;
    family: string;
    formats: Record<string, string>;
    essential?: boolean;
    descriptors: IFontFaceDescriptors;
}

const fontsResources: Record<string, IFontResource[]> = {
    'ar-SA': [
        {
            id: 'noto-sans-arabic-300',
            family: 'Noto Sans Arabic',
            formats: {
                woff2: 'https://fonts.gstatic.com/s/notosansarabic/v18/nwpxtLGrOAZMl5nJ_wfgRg3DrWFZWsnVBJ_sS6tlqHHFlhQ5l3sQWIHPqzCflmyfuXqANwfKi0ZX.woff2'
            },
            descriptors: {
                style: 'normal',
                weight: '300',
                stretch: '100%',
                display: 'swap',
                unicodeRange: unicodeRanges.arabic
            }
        },
        {
            id: 'noto-sans-arabic-400',
            family: 'Noto Sans Arabic',
            formats: {
                woff2: 'https://fonts.gstatic.com/s/notosansarabic/v18/nwpxtLGrOAZMl5nJ_wfgRg3DrWFZWsnVBJ_sS6tlqHHFlhQ5l3sQWIHPqzCfyGyfuXqANwfKi0ZX.woff2'
            },
            essential: true,
            descriptors: {
                style: 'normal',
                weight: '400',
                stretch: '100%',
                display: 'swap',
                unicodeRange: unicodeRanges.arabic
            }
        },
        {
            id: 'noto-sans-arabic-500',
            family: 'Noto Sans Arabic',
            formats: {
                woff2: 'https://fonts.gstatic.com/s/notosansarabic/v18/nwpxtLGrOAZMl5nJ_wfgRg3DrWFZWsnVBJ_sS6tlqHHFlhQ5l3sQWIHPqzCf-myfuXqANwfKi0ZX.woff2'
            },
            descriptors: {
                style: 'normal',
                weight: '500',
                stretch: '100%',
                display: 'swap',
                unicodeRange: unicodeRanges.arabic
            }
        },
        {
            id: 'noto-sans-arabic-600',
            family: 'Noto Sans Arabic',
            formats: {
                woff2: 'https://fonts.gstatic.com/s/notosansarabic/v18/nwpxtLGrOAZMl5nJ_wfgRg3DrWFZWsnVBJ_sS6tlqHHFlhQ5l3sQWIHPqzCfFmufuXqANwfKi0ZX.woff2'
            },
            descriptors: {
                style: 'normal',
                weight: '600',
                stretch: '100%',
                display: 'swap',
                unicodeRange: unicodeRanges.arabic
            }
        },
        {
            id: 'noto-sans-arabic-700',
            family: 'Noto Sans Arabic',
            formats: {
                woff2: 'https://fonts.gstatic.com/s/notosansarabic/v18/nwpxtLGrOAZMl5nJ_wfgRg3DrWFZWsnVBJ_sS6tlqHHFlhQ5l3sQWIHPqzCfL2ufuXqANwfKi0ZX.woff2'
            },
            descriptors: {
                style: 'normal',
                weight: '700',
                stretch: '100%',
                display: 'swap',
                unicodeRange: unicodeRanges.arabic
            }
        }
    ],
    default: [
        {
            id: 'inter-300',
            family: 'Inter',
            formats: {
                woff2: 'https://fonts.gstatic.com/s/inter/v13/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuOKfAZ9hiJ-Ek-_EeA.woff2'
            },
            descriptors: {
                style: 'normal',
                weight: '300',
                display: 'swap',
                unicodeRange: unicodeRanges.latin
            }
        },
        {
            id: 'inter-400',
            family: 'Inter',
            formats: {
                woff2: 'https://fonts.gstatic.com/s/inter/v13/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuLyfAZ9hiJ-Ek-_EeA.woff2'
            },
            essential: true,
            descriptors: {
                style: 'normal',
                weight: '400',
                display: 'swap',
                unicodeRange: unicodeRanges.latin
            }
        },
        {
            id: 'inter-500',
            family: 'Inter',
            formats: {
                woff2: 'https://fonts.gstatic.com/s/inter/v13/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuI6fAZ9hiJ-Ek-_EeA.woff2'
            },
            descriptors: {
                style: 'normal',
                weight: '500',
                display: 'swap',
                unicodeRange: unicodeRanges.latin
            }
        },
        {
            id: 'inter-600',
            family: 'Inter',
            formats: {
                woff2: 'https://fonts.gstatic.com/s/inter/v13/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuGKYAZ9hiJ-Ek-_EeA.woff2'
            },
            descriptors: {
                style: 'normal',
                weight: '600',
                display: 'swap',
                unicodeRange: unicodeRanges.latin
            }
        },
        {
            id: 'inter-700',
            family: 'Inter',
            formats: {
                woff2: 'https://fonts.gstatic.com/s/inter/v13/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuFuYAZ9hiJ-Ek-_EeA.woff2'
            },
            descriptors: {
                style: 'normal',
                weight: '700',
                display: 'swap',
                unicodeRange: unicodeRanges.latin
            }
        },
        {
            id: 'source-code-pro-400',
            family: 'Source Code Pro',
            formats: {
                woff2: 'https://fonts.gstatic.com/s/sourcecodepro/v23/HI_diYsKILxRpg3hIP6sJ7fM7PqPMcMnZFqUwX28DMyQtMlrTFcZZJmOpw.woff2'
            },
            descriptors: {
                style: 'normal',
                weight: '400',
                display: 'swap',
                unicodeRange: unicodeRanges.latin
            }
        }
    ]
};

interface IFontStyles {
    fontFamily: string;
    fontOpticalSizing?:
        | 'auto'
        | 'none'
        | 'inherit'
        | 'initial'
        | 'unset'
        | 'revert'
        | 'revert-layer';
    fontVariationSettings?: string;
}

const fontsStyles: Record<string, Record<TTypeFace, IFontStyles>> = {
    'ar-SA': {
        normal: {
            fontFamily: '"Noto Sans Arabic", sans-serif',
            fontOpticalSizing: 'auto',
            fontVariationSettings: '"wdth" 100'
        },
        monospace: {
            fontFamily: '"Noto Sans Arabic", sans-serif',
            fontOpticalSizing: 'auto',
            fontVariationSettings: '"wdth" 100'
        }
    },
    default: {
        normal: {
            fontFamily: '"Inter", sans-serif',
            fontOpticalSizing: 'auto',
            fontVariationSettings: '"slnt" 0'
        },
        monospace: {
            fontFamily: '"Source Code Pro", monospace',
            fontOpticalSizing: 'auto'
        }
    }
};

export type {IFontResource, IFontStyles};
export {fontsResources, fontsStyles};
