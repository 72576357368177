import type {TUnknownWSServerMessage} from '@mcal/core';
import {EWSClientMessageType, logger} from '@mcal/core';

const log = logger.withCaller('socketStore');

const WS_ENDPOINT =
    process.env.WS_ENDPOINT || process.env.EXPO_PUBLIC_WS_ENDPOINT;

class SocketInstance {
    private static connectionPromise: Promise<WebSocket> | null = null;
    private static currentSocket: WebSocket | null = null;
    private static pingInterval: ReturnType<typeof setInterval> | null = null;

    private static startPingInterval(socket: WebSocket): void {
        if (this.pingInterval) {
            clearInterval(this.pingInterval);
        }

        socket.send(
            JSON.stringify({
                type: EWSClientMessageType.Ping
            })
        );

        this.pingInterval = setInterval(() => {
            socket.send(
                JSON.stringify({
                    type: EWSClientMessageType.Ping
                })
            );
        }, 30000);
    }

    private static createConnection(
        customWsEndpoint: string | null
    ): Promise<WebSocket> {
        return new Promise((resolve, reject) => {
            if (!WS_ENDPOINT)
                throw new Error('MISSING ENV VARIABLE "WS_ENDPOINT"');

            const socket = new WebSocket(customWsEndpoint || WS_ENDPOINT);

            socket.addEventListener('open', () => {
                this.currentSocket = socket;
                this.startPingInterval(socket);
                resolve(socket);
            });

            socket.addEventListener('error', (error) => {
                reject(error);
            });

            socket.addEventListener('close', () => {
                log.debug(
                    'WebSocket connection closed, attempting to reconnect...'
                )();

                if (this.pingInterval) {
                    clearInterval(this.pingInterval);
                }

                this.currentSocket = null;
                this.connectionPromise = null;

                setTimeout(() => {
                    void this.getInstance(customWsEndpoint).catch(
                        (error: Error) => {
                            log.error(
                                `Reconnection attempt failed: ${error.message}`
                            )();
                        }
                    );
                }, 3000);
            });

            socket.addEventListener('message', (event: {data: string}) => {
                const data = JSON.parse(event.data) as TUnknownWSServerMessage;

                if (data.type === 'server-pong') {
                    log.debug('Received server pong')();
                }
            });
        });
    }

    public static async getInstance(
        customWsEndpoint: string | null
    ): Promise<WebSocket> {
        if (!this.connectionPromise) {
            this.connectionPromise = this.createConnection(customWsEndpoint);
        }

        return this.connectionPromise;
    }
}

export const getSocketInstance = (
    customWsEndpoint: string | null
): Promise<WebSocket> => SocketInstance.getInstance(customWsEndpoint);
