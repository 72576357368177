import type {
    IPalette,
    IPaletteBackground,
    IPaletteLink,
    IPaletteNeutral,
    IPaletteSurface,
    TDSColorScales,
    TDSColorTone,
    TThemeType
} from '../../defines/theme.types.js';
import {createAccent} from '../create-accent/create-accent.js';

type TMap = {
    [k in TThemeType]: {
        neutral: IPaletteNeutral<TDSColorTone>;
        surface: IPaletteSurface<TDSColorTone>;
        background: IPaletteBackground<TDSColorTone>;
        link: IPaletteLink<TDSColorTone>;
    };
};

const map: TMap = {
    light: {
        neutral: {
            base: '20',
            onBase: '95',
            overlay: '95',
            state: {
                enabled: {
                    base: '15',
                    onBase: '95'
                },
                disabled: {
                    base: '30',
                    onBase: '90'
                },
                hovered: {
                    base: '15',
                    onBase: '95'
                },
                focused: {
                    base: '15',
                    onBase: '95'
                },
                activated: {
                    base: '15',
                    onBase: '95'
                },
                pressed: {
                    base: '15',
                    onBase: '95'
                },
                dragged: {
                    base: '15',
                    onBase: '95'
                }
            },
            accent: '80',
            outline: '50',
            divide: '80',
            shadow: '0'
        },
        surface: {
            base: {
                lowest: '100',
                low: '96',
                mid: '94',
                high: '92',
                highest: '90'
            },
            onBase: {
                high: '10',
                low: '30'
            }
        },
        background: {
            dim: '87',
            normal: '98',
            bright: '98',
            scrim: '0'
        },
        link: {
            enabled: '20',
            disabled: '80',
            hovered: '10',
            focused: '10',
            pressed: '10',
            visited: '30'
        }
    },
    dark: {
        neutral: {
            base: '90',
            onBase: '20',
            overlay: '20',
            state: {
                enabled: {
                    base: '95',
                    onBase: '20'
                },
                disabled: {
                    base: '80',
                    onBase: '30'
                },
                hovered: {
                    base: '95',
                    onBase: '20'
                },
                focused: {
                    base: '95',
                    onBase: '20'
                },
                activated: {
                    base: '95',
                    onBase: '20'
                },
                pressed: {
                    base: '95',
                    onBase: '20'
                },
                dragged: {
                    base: '95',
                    onBase: '20'
                }
            },
            accent: '40',
            outline: '60',
            divide: '30',
            shadow: '0'
        },
        surface: {
            base: {
                lowest: '4',
                low: '10',
                mid: '12',
                high: '17',
                highest: '22'
            },
            onBase: {
                high: '90',
                low: '80'
            }
        },
        background: {
            dim: '6',
            normal: '6',
            bright: '24',
            scrim: '0'
        },
        link: {
            enabled: '80',
            disabled: '20',
            hovered: '90',
            focused: '90',
            pressed: '90',
            visited: '70'
        }
    }
};

function createPalette(scales: TDSColorScales, type: TThemeType): IPalette {
    const {neutral, surface, background, link} = map[type];

    return {
        accent: {
            primary: createAccent(scales, 'primary', type),
            secondary: createAccent(scales, 'secondary', type),
            tertiary: createAccent(scales, 'tertiary', type),
            error: createAccent(scales, 'error', type),
            warning: createAccent(scales, 'warning', type),
            success: createAccent(scales, 'success', type),
            info: createAccent(scales, 'info', type)
        },
        neutral: {
            base: scales.neutral[neutral.base],
            onBase: scales.neutral[neutral.onBase],
            overlay: scales.neutral[neutral.overlay],
            state: {
                enabled: {
                    base: scales.neutral[neutral.state.enabled.base],
                    onBase: scales.neutral[neutral.state.enabled.onBase]
                },
                disabled: {
                    base: scales.neutral[neutral.state.disabled.base],
                    onBase: scales.neutral[neutral.state.disabled.onBase]
                },
                hovered: {
                    base: scales.neutral[neutral.state.hovered.base],
                    onBase: scales.neutral[neutral.state.hovered.onBase]
                },
                focused: {
                    base: scales.neutral[neutral.state.focused.base],
                    onBase: scales.neutral[neutral.state.focused.onBase]
                },
                activated: {
                    base: scales.neutral[neutral.state.activated.base],
                    onBase: scales.neutral[neutral.state.activated.onBase]
                },
                pressed: {
                    base: scales.neutral[neutral.state.pressed.base],
                    onBase: scales.neutral[neutral.state.pressed.onBase]
                },
                dragged: {
                    base: scales.neutral[neutral.state.dragged.base],
                    onBase: scales.neutral[neutral.state.dragged.onBase]
                }
            },
            accent: scales.neutral[neutral.accent],
            outline: scales.neutral[neutral.outline],
            divide: scales.neutral[neutral.divide],
            shadow: scales.neutral[neutral.shadow]
        },
        surface: {
            base: {
                lowest: scales.neutral[surface.base.lowest],
                low: scales.neutral[surface.base.low],
                mid: scales.neutral[surface.base.mid],
                high: scales.neutral[surface.base.high],
                highest: scales.neutral[surface.base.highest]
            },
            onBase: {
                high: scales.neutral[surface.onBase.high],
                low: scales.divergent[surface.onBase.low]
            }
        },
        background: {
            dim: scales.neutral[background.dim],
            normal: scales.neutral[background.normal],
            bright: scales.neutral[background.bright],
            scrim: scales.neutral[background.scrim]
        },
        link: {
            enabled: scales.neutral[link.enabled],
            disabled: scales.neutral[link.disabled],
            hovered: scales.neutral[link.hovered],
            focused: scales.neutral[link.focused],
            pressed: scales.neutral[link.pressed],
            visited: scales.neutral[link.visited]
        }
    };
}

export {createPalette};
