import {
    EEntityType,
    EServiceCompanyPermission,
    ESitePermission
} from '@mcal/core';
import type {ILinkContainer} from '@mcal/core-react-web';

const PLATFORM_MANAGEMENT_LINKS: ILinkContainer = {
    entityType: EEntityType.Platform,
    links: [
        {
            icon: 'explore',
            label: 'Explore',
            link: '',
            permissions: []
        },
        {
            icon: 'servicesToolbox',
            label: 'Services companies',
            link: '/service-companies',
            permissions: []
        },
        {
            icon: 'user',
            label: 'Users',
            link: '/users',
            permissions: []
        },
        {
            icon: 'technician',
            label: 'Technicians',
            link: '/technicians',
            permissions: []
        },
        {
            icon: 'settings',
            label: 'Settings',
            link: '/settings',
            permissions: []
        }
    ]
};

const SERVICE_COMPANY_LINKS: ILinkContainer = {
    entityType: EEntityType.ServiceCompany,
    links: [
        {
            icon: 'explore',
            label: 'Explore',
            link: '/service-companies/:serviceCompanyId/overview',
            permissions: []
        },
        {
            icon: 'apartment',
            label: 'Sites',
            link: '/service-companies/:serviceCompanyId/sites',
            permissions: []
        },
        {
            icon: 'team',
            label: 'Teams',
            link: '/service-companies/:serviceCompanyId/teams',
            permissions: [EServiceCompanyPermission.TeamView]
        },
        {
            icon: 'settings',
            label: 'Settings',
            link: '/service-companies/:serviceCompanyId/settings',
            permissions: [
                EServiceCompanyPermission.SettingsGeneralView,
                EServiceCompanyPermission.SettingsGeneralEdit,
                EServiceCompanyPermission.SettingsDocumentsView,
                EServiceCompanyPermission.SettingsDocumentsEdit,
                EServiceCompanyPermission.SettingsNotificationsView,
                EServiceCompanyPermission.SettingsNotificationsEdit,
                EServiceCompanyPermission.SettingsFeatureModulesView,
                EServiceCompanyPermission.SettingsFeatureModulesEdit,
                EServiceCompanyPermission.SettingsBillingView,
                EServiceCompanyPermission.SettingsBillingEdit
            ]
        },
        {
            icon: 'back',
            label: 'Back to Admin',
            link: '/service-companies',
            alwaysInactive: true,
            permissions: []
        }
    ]
};

const SITE_LINKS: ILinkContainer = {
    entityType: EEntityType.Site,
    links: [
        {
            icon: 'apartment',
            label: 'Site',
            link: '/site/:siteId/overview',
            permissions: []
        },
        {
            icon: 'doorSliding',
            label: 'Elevators',
            link: '/site/:siteId/elevators',
            permissions: []
        },
        {
            icon: 'team',
            label: 'Teams',
            link: '/site/:siteId/teams',
            permissions: [ESitePermission.TeamView]
        },
        {
            icon: 'settings',
            label: 'Settings',
            link: '/site/:siteId/settings',
            permissions: [
                ESitePermission.SettingsGeneralView,
                ESitePermission.SettingsGeneralEdit,
                ESitePermission.SettingsNotificationsView,
                ESitePermission.SettingsNotificationsEdit,
                ESitePermission.SettingsFeatureModulesView,
                ESitePermission.SettingsFeatureModulesEdit,
                ESitePermission.SettingsBillingView,
                ESitePermission.SettingsBillingEdit
            ]
        }
    ]
};

export {PLATFORM_MANAGEMENT_LINKS, SERVICE_COMPANY_LINKS, SITE_LINKS};
