import {styled} from '../../../../utils/styled/styled.js';

const StyledRoot = styled('div')(({theme}) => ({
    [theme.query.m]: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',
        padding: '0 8px',
        marginBottom: theme.size.fixed.s1,
        cursor: 'pointer',
        position: 'sticky',
        bottom: theme.size.fixed.s1
    },
    [theme.query.l]: {
        padding: '0 16px'
    }
}));

export {StyledRoot};
