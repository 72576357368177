import {forEach} from '../for-each/for-each.js';

interface IFlattenOptions {
    arrays?: boolean;
    keepEmptyArrays?: boolean;
    keepEmptyObjects?: boolean;
}

function flatten(
    input: object,
    options: IFlattenOptions = {}
): Record<string, unknown> {
    const output: Record<string, unknown> = {};

    forEach(
        input,
        ({value, token, skip, isIterable}) => {
            if (Array.isArray(value)) {
                if (options.arrays) {
                    if (!isIterable && options.keepEmptyArrays) {
                        output[token] = [];
                    }
                } else {
                    output[token] = value;
                    skip();
                }
            } else if (typeof value !== 'object' || value === null) {
                output[token] = value;
            } else if (!isIterable && options.keepEmptyObjects) {
                output[token] = {};
            }
        },
        {skipRefs: false}
    );

    return output;
}

export type {IFlattenOptions};
export {flatten};
