import {styled} from '../../../../utils/styled/styled.js';

const StyledRoot = styled('div')(({theme}) => ({
    background: theme.palette.surface.base.low,
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100dvh',
    paddingBottom: '70px',

    [theme.query.l]: {
        paddingBottom: '0',
        display: 'grid',
        gridTemplateColumns: 'auto 1fr',
        gridTemplateRows: 'max-content',

        '& > div:first-of-type': {
            gridColumn: '1/3'
        }
    }
}));

const StyledOutletContainer = styled('div')(({theme}) => ({
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    paddingBottom: '70px',

    [theme.query.l]: {
        paddingBottom: '0'
    }
}));

export {StyledOutletContainer, StyledRoot};
