import type {ICommonHeaders, IRequestError, IResponseMeta} from '@mcal/core';
import {MESSAGES} from '@mcal/core';
import type {
    IRemoteUpdate,
    ISliceRemote,
    IStateWithRemotes,
    TRemoteKeys
} from '../../defines/redux.types.js';
import {ESliceRemoteStatus} from '../../defines/redux.types.js';

interface IRejectedMeta {
    arg: object | void;
    requestId: string;
    requestStatus: 'rejected';
    aborted: boolean;
    condition: boolean;
}

interface IRejectedPayload {
    headers: ICommonHeaders;
    error: IRequestError;
    status: number;
    meta: IResponseMeta;
    data?: null;
}

const updateMetadata = (
    remote: ISliceRemote<object>,
    meta: IRejectedMeta,
    payload: IRejectedPayload | undefined,
    update: IRemoteUpdate<object>
): void => {
    if (!Object.keys(remote.updates).length) {
        if (meta.aborted && update) {
            remote.status = update.startingStatus;
        } else {
            remote.status = ESliceRemoteStatus.Error;
        }
    }

    if (payload && payload.meta && payload.meta.code) {
        remote.errorCode = payload.meta.code;
    } else {
        remote.errorCode = MESSAGES.ERROR.GENERAL.UNKNOWN.CODE;
    }
};

interface IRejectedRemoteUpdateConfig<TState extends IStateWithRemotes> {
    state: TState;
    meta: IRejectedMeta;
    payload: IRejectedPayload | undefined;
    targets: TRemoteKeys<TState>[];
}

function rejectedRemoteUpdate<TState extends IStateWithRemotes>({
    state,
    meta,
    payload,
    targets
}: IRejectedRemoteUpdateConfig<TState>): void {
    const {requestId} = meta;

    for (const target of targets) {
        const remote = state.remotes[target];

        const update = remote.updates[requestId];

        if (update && update.rollback !== null) {
            remote.current = update.rollback;
        }

        delete remote.updates[requestId];

        updateMetadata(remote, meta, payload, update);
    }
}

export {rejectedRemoteUpdate};
