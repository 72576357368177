function serialize(input: unknown): string {
    if (typeof input === 'string') {
        return input;
    }

    if (input === null) {
        return 'null';
    }

    if (typeof input === 'undefined') {
        return 'undefined';
    }

    if (typeof input === 'object' && 'toString' in input) {
        return input.toString();
    }

    const prototype = Object.getPrototypeOf(input) as object | null;

    if (prototype && 'toString' in prototype) {
        return input.toString();
    }

    return '';
}

export {serialize};
