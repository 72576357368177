import type {IButtonBaseProps} from '@mcal/core-react';
import {cn} from '@mcal/core-react';
import type {AriaRole, ElementType, FC, MouseEvent} from 'react';
import {createTestIDs} from '../../dev/index.js';
import {StyledButton} from './button.styles.js';

const ownTestIDs = createTestIDs('Button', ['root']);

interface IButtonProps extends IButtonBaseProps<typeof ownTestIDs> {
    component?: ElementType;
    type?: 'button' | 'submit' | 'reset';
    role?: AriaRole;
    onClick: (e: MouseEvent<HTMLButtonElement>) => void;
    className?: string;
    classes?: {
        root?: string;
    };
}

const Button: FC<IButtonProps> = ({
    component: Component = 'button',
    role = undefined,
    type = 'button',
    label,
    onClick,
    variant = 'filled',
    disabled = false,
    size = 'medium',
    color = 'primary',
    emphasis = 'high',
    className = '',
    width = 'auto',
    classes = {},
    testIDs = {}
}) => {
    return (
        <StyledButton
            as={Component}
            role={role}
            data-testid={testIDs.root || ownTestIDs.root}
            className={cn(className, classes.root)}
            $disabled={disabled}
            disabled={disabled}
            $width={width}
            type={type}
            $variant={variant}
            $size={size}
            $color={color}
            $emphasis={emphasis}
            onClick={onClick}
        >
            {label}
        </StyledButton>
    );
};

export type {IButtonProps};
export {Button, ownTestIDs as testIDs};
