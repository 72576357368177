import type {
    ILabelBaseProps,
    TComponentColor,
    TDSSize,
    TPaletteEmphasis
} from '@mcal/core-react';
import {getColorPalette} from '@mcal/core-react';
import {styled} from '../../utils/styled/styled.js';
import {Text} from '../text/text.js';

interface IFont {
    fontSize: TDSSize;
    lineHeight: TDSSize;
}

interface ILabelSettings
    extends Record<NonNullable<ILabelBaseProps<never>['type']>, IFont> {}

const LABEL_SETTINGS: ILabelSettings = {
    large: {
        fontSize: 's5',
        lineHeight: 's5'
    },

    medium: {
        fontSize: 's4',
        lineHeight: 's4'
    },

    small: {
        fontSize: 's3',
        lineHeight: 's3'
    },

    extraSmall: {
        fontSize: 's2',
        lineHeight: 's3'
    }
};

interface IStyledLabel {
    type: NonNullable<ILabelBaseProps<never>['type']>;
    accent?: TComponentColor;
    emphasis?: TPaletteEmphasis;
    level?: 'base' | 'onBase';
    $lineHeight?: NonNullable<ILabelBaseProps<never>['lineHeight']>;
}

const StyledRoot = styled(Text)<IStyledLabel>(({
    theme,
    type = 'medium',
    emphasis = 'high',
    accent = 'neutral',
    level = 'base',
    $lineHeight
}) => {
    const {fontSize, lineHeight} = LABEL_SETTINGS[type];

    return {
        margin: 0,
        padding: 0,
        fontSize: type === 'small' ? '14px' : theme.size.fixed[fontSize],
        lineHeight: $lineHeight || theme.size.fixed[lineHeight],
        color: getColorPalette(theme, accent, emphasis)[level],
        letterSpacing: '0em'
    };
});

export {StyledRoot};
