import type {ISliceRemote} from '../../defines/redux.types.js';
import {ESliceRemoteStatus} from '../../defines/redux.types.js';

function createRemote<T extends object>(initialValue: T): ISliceRemote<T> {
    return {
        status: ESliceRemoteStatus.Init,
        current: initialValue,
        updatedAt: -1,
        errorCode: null,
        updates: {}
    };
}

export {createRemote};
