import {getPermissions} from '../../services/app-gateway/permissions/permissions.thunks.js';
import {getUsersMemberships} from '../../services/app-node-auth/users/users.thunks.js';
import {
    cancelInvite,
    listEntityInvites,
    sendInvite
} from '../../services/app-node-invites/invites/invites.thunks.js';
import {getMileageTotal} from '../../services/app-node-kpis/kpis/kpis.thunks.js';
import {
    deleteServiceCompanyTeam,
    getServiceCompany,
    getServiceCompanyState,
    getServiceCompanyTeam,
    listPendingServiceCompanies,
    listServiceCompanies,
    listServiceCompaniesAdmin,
    listServiceCompaniesPendingRecords,
    listServiceCompanyConfigurationsTemplates,
    registerServiceCompany,
    updateServiceCompanyGeneralSettings,
    updateServiceCompanyProfile,
    updateServiceCompanyTeam,
    updateServiceCompanyTechnicalParametersTemplates,
    uploadServiceCompanyDocuments
} from '../../services/app-node-service-companies/service-companies/service-companies.thunks.js';
import {
    createSite,
    listSites
} from '../../services/app-node-sites/sites/sites.thunks.js';
import {
    selectElevators,
    selectServiceCompanyGeneralSettings,
    selectServiceCompanyPermissions,
    selectServiceCompanyProfile,
    selectServiceCompanyState,
    selectServiceCompanyStatus,
    selectServiceCompanySummary,
    selectServiceCompanyTeam,
    selectServiceCompanyTemplates,
    selectSites
} from './service-company.selectors.js';
import {serviceCompanySlice} from './service-company.slice.js';

const serviceCompanyActions = serviceCompanySlice.actions;

const serviceCompanySelectors = {
    selectElevators,
    selectServiceCompanyGeneralSettings,
    selectServiceCompanyPermissions,
    selectServiceCompanyProfile,
    selectServiceCompanyState,
    selectServiceCompanyStatus,
    selectServiceCompanySummary,
    selectServiceCompanyTeam,
    selectServiceCompanyTemplates,
    selectSites
};

const serviceCompanyThunks = {
    cancelInvite,
    createSite,
    deleteServiceCompanyTeam,
    getMileageTotal,
    getPermissions,
    getServiceCompany,
    getServiceCompanyState,
    getServiceCompanyTeam,
    getUsersMemberships,
    listEntityInvites,
    listServiceCompanies,
    listServiceCompaniesAdmin,
    listPendingServiceCompanies,
    listServiceCompaniesPendingRecords,
    listServiceCompanyConfigurationsTemplates,
    listSites,
    registerServiceCompany,
    sendInvite,
    updateServiceCompanyGeneralSettings,
    updateServiceCompanyProfile,
    updateServiceCompanyTeam,
    updateServiceCompanyTechnicalParametersTemplates,
    uploadServiceCompanyDocuments
};

export {
    serviceCompanyActions,
    serviceCompanySelectors,
    serviceCompanySlice,
    serviceCompanyThunks
};
