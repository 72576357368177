type TObj = Record<string, unknown>;

function remove<T, TKey extends keyof T | void = void>(
    array: T[],
    item: TKey extends keyof T ? T[TKey] | T[TKey][] : T | T[],
    key?: TKey extends string ? TKey : never
): T[] {
    const parts = Array.isArray(item) ? item : [item];

    parts.forEach((part) => {
        if (key) {
            const index = array.findIndex((i) => {
                return (i as TObj)[key] === part;
            });

            if (index !== -1) {
                array.splice(index, 1);
            }
        } else {
            const index = array.indexOf(part as T);

            if (index !== -1) {
                array.splice(index, 1);
            }
        }
    });

    return array;
}

export {remove};
