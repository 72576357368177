import type {
    TAPIResponseSuccess,
    TPlatformTeamDeleteAPI,
    TPlatformTeamGetAPI,
    TPlatformTeamUpdateAPI
} from '@mcal/core';
import {createAsyncThunk} from '@reduxjs/toolkit';
import type {
    TAPIAsyncThunkArgs,
    TAPIAsyncThunkConfig
} from '../../../defines/redux.types.js';
import {getState} from '../../../utils/get-state/get-state.js';
import {getUsersMemberships} from '../../app-node-auth/users/users.thunks.js';
import * as services from './platform.services.js';

const getPlatformTeam = createAsyncThunk<
    TAPIResponseSuccess<TPlatformTeamGetAPI>,
    TAPIAsyncThunkArgs<TPlatformTeamGetAPI>,
    TAPIAsyncThunkConfig<TPlatformTeamGetAPI>
>('platform/getPlatformTeam', async (_, thunkAPI) => {
    const user = getState(thunkAPI, 'user');

    return await services
        .getPlatformTeam(
            {
                query: {
                    locale: user.remotes.userGeneralSettings.current.locale
                }
            },
            thunkAPI.signal
        )
        .then((response) => {
            if (response.error) {
                return thunkAPI.rejectWithValue(response);
            } else {
                void thunkAPI.dispatch(
                    getUsersMemberships({
                        entityId: '',
                        affinityId: '',
                        members: response.data.map(({userId}) => userId)
                    })
                );

                return thunkAPI.fulfillWithValue(response);
            }
        });
});

const updatePlatformTeam = createAsyncThunk<
    TAPIResponseSuccess<TPlatformTeamUpdateAPI>,
    TAPIAsyncThunkArgs<TPlatformTeamUpdateAPI>,
    TAPIAsyncThunkConfig<TPlatformTeamUpdateAPI>
>('platform/updatePlatformTeam', async (args, thunkAPI) => {
    const user = getState(thunkAPI, 'user');

    return await services
        .updatePlatformTeam(
            {
                query: {
                    locale: user.remotes.userGeneralSettings.current.locale
                },
                body: {
                    data: {
                        userId: args.userId,
                        role: args.role
                    }
                }
            },
            thunkAPI.signal
        )
        .then((response) => {
            if (response.error) {
                return thunkAPI.rejectWithValue(response);
            } else {
                return thunkAPI.fulfillWithValue(response);
            }
        });
});

const deletePlatformTeam = createAsyncThunk<
    TAPIResponseSuccess<TPlatformTeamDeleteAPI>,
    TAPIAsyncThunkArgs<TPlatformTeamDeleteAPI>,
    TAPIAsyncThunkConfig<TPlatformTeamDeleteAPI>
>('platform/deletePlatformTeam', async (args, thunkAPI) => {
    const user = getState(thunkAPI, 'user');

    return await services
        .deletePlatformTeam(
            {
                query: {
                    locale: user.remotes.userGeneralSettings.current.locale
                },
                body: {
                    data: {
                        userId: args.userId
                    }
                }
            },
            thunkAPI.signal
        )
        .then((response) => {
            if (response.error) {
                return thunkAPI.rejectWithValue(response);
            } else {
                return thunkAPI.fulfillWithValue(response);
            }
        });
});

export {deletePlatformTeam, getPlatformTeam, updatePlatformTeam};
