import type {
    TAPIResponseSuccess,
    TLogInAPI,
    TLogInChallengeAPI
} from '@mcal/core';
import {createAsyncThunk} from '@reduxjs/toolkit';
import type {
    TAPIAsyncThunkArgs,
    TAPIAsyncThunkConfig
} from '../../../defines/redux.types.js';
import {getState} from '../../../utils/get-state/get-state.js';
import {getUser} from '../user/user.thunks.js';
import * as services from './log-in.services.js';

const logIn = createAsyncThunk<
    TAPIResponseSuccess<TLogInAPI>,
    TAPIAsyncThunkArgs<TLogInAPI>,
    TAPIAsyncThunkConfig<TLogInAPI>
>('logIn/logIn', async (args, thunkAPI) => {
    const user = getState(thunkAPI, 'user');

    return await services
        .logIn(
            {
                query: {
                    locale: user.remotes.userGeneralSettings.current.locale
                },
                body: {
                    data: {
                        email: args.email,
                        password: args.password
                    }
                }
            },
            thunkAPI.signal
        )
        .then((response) => {
            if (response.error) {
                return thunkAPI.rejectWithValue(response);
            } else {
                if (response.data.challengeName) {
                    return thunkAPI.fulfillWithValue(response);
                } else {
                    return Promise.all([thunkAPI.dispatch(getUser())]).then(
                        () => {
                            return thunkAPI.fulfillWithValue(response);
                        }
                    );
                }
            }
        });
});

const logInChallenge = createAsyncThunk<
    TAPIResponseSuccess<TLogInChallengeAPI>,
    TAPIAsyncThunkArgs<TLogInChallengeAPI>,
    TAPIAsyncThunkConfig<TLogInChallengeAPI>
>('logIn/challenge', async (args, thunkAPI) => {
    const user = getState(thunkAPI, 'user');

    return await services
        .logInChallenge(
            {
                query: {
                    locale: user.remotes.userGeneralSettings.current.locale
                },
                body: {
                    data: {
                        challengeName: args.challengeName,
                        email: args.email,
                        answer: args.answer,
                        session: args.session
                    }
                }
            },
            thunkAPI.signal
        )
        .then((response) => {
            if (response.error) {
                return thunkAPI.rejectWithValue(response);
            } else {
                if (response.data.challengeName) {
                    return thunkAPI.fulfillWithValue(response);
                } else {
                    return Promise.all([thunkAPI.dispatch(getUser())]).then(
                        () => {
                            return thunkAPI.fulfillWithValue(response);
                        }
                    );
                }
            }
        });
});

export {logIn, logInChallenge};
