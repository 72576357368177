import {selectNotifications} from './notifications.selectors.js';
import {notificationsSlice} from './notifications.slice.js';

const notificationsActions = notificationsSlice.actions;

const notificationsSelectors = {
    selectNotifications
};

export {notificationsActions, notificationsSelectors, notificationsSlice};
