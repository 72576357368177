import {MESSAGES} from '@mcal/core';
import type {PayloadAction} from '@reduxjs/toolkit';
import {createSlice} from '@reduxjs/toolkit';
import {
    forgotPasswordComplete,
    forgotPasswordStart
} from '../../services/app-node-auth/forgot-password/forgot-password.thunks.js';
import {
    logIn,
    logInChallenge
} from '../../services/app-node-auth/log-in/log-in.thunks.js';
import type {TLogInSliceStatus} from './log-in.state.js';
import {initialState} from './log-in.state.js';

const logInSlice = createSlice({
    name: 'logIn',
    initialState,
    reducers: {
        setStep(state, action: PayloadAction<TLogInSliceStatus>) {
            state.status = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(logIn.pending, (state) => {
            state.sources.logIn.status = 'LOADING';
            state.status = 'LOGGING-IN';
        });
        builder.addCase(logIn.fulfilled, (state, {payload}) => {
            if (
                payload.meta.code ===
                MESSAGES.NORMAL.SERVER.LOGGED_IN_CHALLENGE.CODE
            ) {
                state.sources.logIn.status = 'IDLE';
                state.status = 'CHALLENGE-REQUIRED';
            } else {
                state.sources.logIn.status = 'IDLE';
                state.status = 'LOGGED-IN';
            }
        });
        builder.addCase(logIn.rejected, (state) => {
            state.sources.logIn.status = 'FAILED';
            state.status = 'FAILED';
        });

        builder.addCase(logInChallenge.pending, (state) => {
            state.sources.logIn.status = 'LOADING';
            state.status = 'LOGGING-IN';
        });
        builder.addCase(logInChallenge.fulfilled, (state) => {
            state.sources.logIn.status = 'IDLE';
            state.status = 'LOGGED-IN';
        });
        builder.addCase(logInChallenge.rejected, (state) => {
            state.sources.logIn.status = 'FAILED';
            state.status = 'FAILED';
        });

        builder.addCase(forgotPasswordStart.pending, (state) => {
            state.sources.start.status = 'LOADING';
        });
        builder.addCase(forgotPasswordStart.rejected, (state) => {
            state.sources.start.status = 'FAILED';
        });

        builder.addCase(forgotPasswordComplete.pending, (state) => {
            state.sources.complete.status = 'LOADING';
        });
        builder.addCase(forgotPasswordComplete.fulfilled, (state) => {
            state.sources.complete.status = 'IDLE';
            state.status = 'FINISHED';
        });
        builder.addCase(forgotPasswordComplete.rejected, (state) => {
            state.sources.complete.status = 'FAILED';
        });
    }
});

export {logInSlice};
