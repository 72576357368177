import type {
    ISafeArea,
    TAlignment,
    TDSDepth,
    TPosition
} from '@mcal/core-react';
import {useTheme} from '@mcal/core-react';
import {useMemo} from 'react';
import type {TCSSObject} from '../../defines/styles.types.js';
import {css} from '../../utils/css/css.js';
import {useBreakpoints} from '../use-breakpoints/use-breakpoints.js';

interface IStyles {
    display: TCSSObject['display'];
    flexDirection?: TCSSObject['flexDirection'];
    justifyContent?: TCSSObject['justifyContent'];
    position: TPosition;
    left: string;
    top: string;
    transform: string;
    zIndex: number | null;
}

interface IUsePositionOptions {
    display?: TCSSObject['display'];
    flexDirection?: TCSSObject['flexDirection'];
    justifyContent?: TCSSObject['justifyContent'];
    position?: TPosition;
    alignment?: TAlignment;
    z?: TDSDepth | null;
    safeArea?: Partial<ISafeArea> | null;
}

const usePosition = ({
    display = 'inline-block',
    position = 'absolute',
    alignment = 'center-center',
    z = null,
    safeArea = null
}: IUsePositionOptions): string => {
    const {
        top = '0px',
        right = '0px',
        bottom = '0px',
        left = '0px'
    } = safeArea || {};

    const {depth} = useTheme();

    const isSmallViewPort = useBreakpoints({
        base: true,
        m: false
    });

    const zIndex = useMemo(() => {
        return z ? depth.global[z] : null;
    }, [depth.global, z]);

    const styles = useMemo<IStyles>(() => {
        switch (isSmallViewPort ? 'bottom-center' : alignment) {
            case 'top-left':
                return {
                    display,
                    position,
                    zIndex,
                    left,
                    top,
                    transform: 'translate(0, 0)'
                };
            case 'top-center':
                return {
                    display,
                    position,
                    zIndex,
                    left: '50%',
                    top,
                    transform: 'translate(-50%, 0)'
                };
            case 'top-right':
                return {
                    display,
                    position,
                    zIndex,
                    left: `calc(100% - ${right})`,
                    top,
                    transform: 'translate(-100%, 0)'
                };
            case 'center-left':
                return {
                    display,
                    position,
                    zIndex,
                    left,
                    top: '50%',
                    transform: 'translate(0, -50%)'
                };
            case 'center-center':
                return {
                    display,
                    position,
                    zIndex,
                    left: '50%',
                    top: '50%',
                    transform: 'translate(-50%, -50%)'
                };
            case 'center-right':
                return {
                    display,
                    position,
                    zIndex,
                    left: `calc(100% - ${right})`,
                    top: '50%',
                    transform: 'translate(-100%, -50%)'
                };
            case 'bottom-left':
                return {
                    display,
                    position,
                    zIndex,
                    left,
                    top: `calc(100% - ${bottom})`,
                    transform: 'translate(0, -100%)'
                };
            case 'bottom-center':
                return {
                    display: 'flex',
                    position,
                    zIndex,
                    left: '50%',
                    top: '0',
                    flexDirection: 'column',
                    justifyContent: 'flex-end',
                    transform: 'translate(-50%, 0)'
                };
            case 'bottom-right':
                return {
                    display,
                    position,
                    zIndex,
                    left: `calc(100% - ${right})`,
                    top: `calc(100% - ${bottom})`,
                    transform: 'translate(-100%, -100%)'
                };
            default:
                return {
                    display,
                    position,
                    zIndex,
                    left: '50%',
                    top: '50%',
                    transform: 'translate(-50%, -50%)'
                };
        }
    }, [
        alignment,
        bottom,
        display,
        isSmallViewPort,
        left,
        position,
        right,
        top,
        zIndex
    ]);

    return useMemo(() => css(styles as TCSSObject), [styles]);
};

export type {IUsePositionOptions};
export {usePosition};
