import {useTheme} from '@mcal/core-react';
import type {FC} from 'react';
import {useMemo} from 'react';

interface ISystem {
    colorA: string;
    colorB: string;
    colorC: string;
    colorD: string;
    colorE: string;
    colorF: string;
    colorG: string;
    colorH: string;
    colorI: string;
}

interface ISystemSvgProps {
    height?: number | string;
    width?: number | string;
}

const SystemSvg: FC<ISystemSvgProps> = (props) => {
    const {type} = useTheme();

    const {
        colorA,
        colorB,
        colorC,
        colorD,
        colorE,
        colorF,
        colorG,
        colorH,
        colorI
    } = useMemo<ISystem>(() => {
        if (type === 'dark') {
            return {
                colorA: '#F8F8F9',
                colorB: '#D9D9D9',
                colorC: '#EEEFF0',
                colorD: '#DDDEE1',
                colorE: '#0A0B0D',
                colorF: '#0F1013',
                colorG: '#22252A',
                colorH: '#33373F',
                colorI: '#777D87'
            };
        } else {
            return {
                colorA: '#F8F8F9',
                colorB: '#D9D9D9',
                colorC: '#EEEFF0',
                colorD: '#DDDEE1',
                colorE: '#0A0B0D',
                colorF: '#0F1013',
                colorG: '#22252A',
                colorH: '#33373F',
                colorI: '#777D87'
            };
        }
    }, [type]);

    return (
        <svg
            xmlns={'http://www.w3.org/2000/svg'}
            width={'64'}
            height={'40'}
            viewBox={'0 0 64 40'}
            fill={'none'}
            {...props}
        >
            <g clipPath={'url(#clip0_968_2788)'}>
                <rect width={'64'} height={'40'} rx={'4'} fill={colorA} />
                <mask
                    id={'mask0_968_2788'}
                    maskUnits={'userSpaceOnUse'}
                    x={'1'}
                    y={'1'}
                    width={'62'}
                    height={'38'}
                >
                    <rect
                        x={'1'}
                        y={'1'}
                        width={'62'}
                        height={'38'}
                        rx={'3'}
                        fill={colorB}
                    />
                </mask>
                <g mask={'url(#mask0_968_2788)'}>
                    <rect
                        x={'8.5'}
                        y={'8.5'}
                        width={'60'}
                        height={'36'}
                        rx={'1.5'}
                        fill={'white'}
                        stroke={colorC}
                    />
                    <rect
                        x={'12'}
                        y={'12'}
                        width={'34'}
                        height={'7'}
                        rx={'2'}
                        fill={colorD}
                    />
                    <rect
                        x={'12'}
                        y={'24'}
                        width={'48'}
                        height={'2'}
                        fill={colorD}
                    />
                    <rect
                        x={'12'}
                        y={'29'}
                        width={'48'}
                        height={'2'}
                        fill={colorD}
                    />
                    <rect
                        x={'12'}
                        y={'34'}
                        width={'48'}
                        height={'2'}
                        fill={colorD}
                    />
                </g>
                <rect
                    x={'30.5'}
                    y={'0.5'}
                    width={'63'}
                    height={'39'}
                    rx={'3.5'}
                    fill={colorE}
                    stroke={colorF}
                />
                <mask
                    id={'mask1_968_2788'}
                    maskUnits={'userSpaceOnUse'}
                    x={'31'}
                    y={'1'}
                    width={'62'}
                    height={'38'}
                >
                    <rect
                        x={'31'}
                        y={'1'}
                        width={'62'}
                        height={'38'}
                        rx={'3'}
                        fill={colorB}
                    />
                </mask>
                <g mask={'url(#mask1_968_2788)'}>
                    <rect
                        x={'38.5'}
                        y={'8.5'}
                        width={'60'}
                        height={'36'}
                        rx={'1.5'}
                        fill={colorG}
                        stroke={colorH}
                    />
                    <rect
                        x={'42'}
                        y={'12'}
                        width={'34'}
                        height={'7'}
                        rx={'2'}
                        fill={colorI}
                    />
                    <rect
                        x={'42'}
                        y={'24'}
                        width={'48'}
                        height={'2'}
                        fill={colorI}
                    />
                    <rect
                        x={'42'}
                        y={'29'}
                        width={'48'}
                        height={'2'}
                        fill={colorI}
                    />
                    <rect
                        x={'42'}
                        y={'34'}
                        width={'48'}
                        height={'2'}
                        fill={colorI}
                    />
                </g>
            </g>
            <defs>
                <clipPath id={'clip0_968_2788'}>
                    <rect width={'64'} height={'40'} rx={'4'} fill={'white'} />
                </clipPath>
            </defs>
        </svg>
    );
};

export {SystemSvg};
