import type {
    TAPIRequestContent,
    TAPIResponse,
    TElevatorGroupDeleteAPI,
    TElevatorGroupGetAPI,
    TElevatorGroupGetStateAPI,
    TElevatorGroupTeamDeleteAPI,
    TElevatorGroupTeamGetAPI,
    TElevatorGroupTeamUpdateAPI,
    TElevatorGroupUpdateProfileAPI,
    TElevatorGroupsCreateAPI,
    TElevatorGroupsListAPI,
    TSiteGetAPI,
    TSiteGetActivityAPI,
    TSiteGetStateAPI,
    TSiteTeamDeleteAPI,
    TSiteTeamGetAPI,
    TSiteTeamUpdateAPI,
    TSiteUpdateProfileAPI,
    TSitesCreateAPI,
    TSitesListAPI
} from '@mcal/core';
import {request} from '../../../utils/request/request.js';

const listSites = (
    {query}: TAPIRequestContent<TSitesListAPI>,
    signal: AbortSignal
): TAPIResponse<TSitesListAPI> => {
    return request<TSitesListAPI>({
        method: 'GET',
        url: '/sites',
        signal,
        query
    });
};

const getSite = (
    {query}: TAPIRequestContent<TSiteGetAPI>,
    signal: AbortSignal
): TAPIResponse<TSiteGetAPI> => {
    return request<TSiteGetAPI>({
        method: 'GET',
        url: '/site',
        signal,
        query
    });
};

const getSiteState = (
    {query}: TAPIRequestContent<TSiteGetStateAPI>,
    signal: AbortSignal
): TAPIResponse<TSiteGetStateAPI> => {
    return request<TSiteGetStateAPI>({
        method: 'GET',
        url: '/site/state',
        signal,
        query
    });
};

const getSiteTeam = (
    {query}: TAPIRequestContent<TSiteTeamGetAPI>,
    signal: AbortSignal
): TAPIResponse<TSiteTeamGetAPI> => {
    return request<TSiteTeamGetAPI>({
        method: 'GET',
        url: '/site/team',
        signal,
        query
    });
};

const updateSiteTeam = (
    {query, body}: TAPIRequestContent<TSiteTeamUpdateAPI>,
    signal: AbortSignal
): TAPIResponse<TSiteTeamUpdateAPI> => {
    return request<TSiteTeamUpdateAPI>({
        method: 'PATCH',
        url: '/site/team',
        signal,
        query,
        body
    });
};

const deleteSiteTeam = (
    {query, body}: TAPIRequestContent<TSiteTeamDeleteAPI>,
    signal: AbortSignal
): TAPIResponse<TSiteTeamDeleteAPI> => {
    return request<TSiteTeamDeleteAPI>({
        method: 'DELETE',
        url: '/site/team',
        signal,
        query,
        body
    });
};

const listElevatorGroups = (
    {query}: TAPIRequestContent<TElevatorGroupsListAPI>,
    signal: AbortSignal
): TAPIResponse<TElevatorGroupsListAPI> => {
    return request<TElevatorGroupsListAPI>({
        method: 'GET',
        url: '/elevator-groups',
        signal,
        query
    });
};

const getElevatorGroup = (
    {query}: TAPIRequestContent<TElevatorGroupGetAPI>,
    signal: AbortSignal
): TAPIResponse<TElevatorGroupGetAPI> => {
    return request<TElevatorGroupGetAPI>({
        method: 'GET',
        url: '/elevator-group',
        signal,
        query
    });
};

const addElevatorGroup = (
    {query, body}: TAPIRequestContent<TElevatorGroupsCreateAPI>,
    signal: AbortSignal
): TAPIResponse<TElevatorGroupsCreateAPI> => {
    return request<TElevatorGroupsCreateAPI>({
        method: 'PUT',
        url: '/elevator-groups',
        signal,
        query,
        body
    });
};

const updateElevatorGroupProfile = (
    {query, body}: TAPIRequestContent<TElevatorGroupUpdateProfileAPI>,
    signal: AbortSignal
): TAPIResponse<TElevatorGroupUpdateProfileAPI> => {
    return request<TElevatorGroupUpdateProfileAPI>({
        method: 'PATCH',
        url: '/elevator-group/profile',
        signal,
        query,
        body
    });
};

const deleteElevatorGroup = (
    {query, body}: TAPIRequestContent<TElevatorGroupDeleteAPI>,
    signal: AbortSignal
): TAPIResponse<TElevatorGroupDeleteAPI> => {
    return request<TElevatorGroupDeleteAPI>({
        method: 'DELETE',
        url: '/elevator-group',
        signal,
        query,
        body
    });
};

const getElevatorGroupState = (
    {query}: TAPIRequestContent<TElevatorGroupGetStateAPI>,
    signal: AbortSignal
): TAPIResponse<TElevatorGroupGetStateAPI> => {
    return request<TElevatorGroupGetStateAPI>({
        method: 'GET',
        url: '/elevator-group/state',
        signal,
        query
    });
};

const getElevatorGroupTeam = (
    {query}: TAPIRequestContent<TElevatorGroupTeamGetAPI>,
    signal: AbortSignal
): TAPIResponse<TElevatorGroupTeamGetAPI> => {
    return request<TElevatorGroupTeamGetAPI>({
        method: 'GET',
        url: '/elevator-group/team',
        signal,
        query
    });
};

const updateElevatorGroupTeam = (
    {query, body}: TAPIRequestContent<TElevatorGroupTeamUpdateAPI>,
    signal: AbortSignal
): TAPIResponse<TElevatorGroupTeamUpdateAPI> => {
    return request<TElevatorGroupTeamUpdateAPI>({
        method: 'PATCH',
        url: '/elevator-group/team',
        signal,
        query,
        body
    });
};

const deleteElevatorGroupTeam = (
    {query, body}: TAPIRequestContent<TElevatorGroupTeamDeleteAPI>,
    signal: AbortSignal
): TAPIResponse<TElevatorGroupTeamDeleteAPI> => {
    return request<TElevatorGroupTeamDeleteAPI>({
        method: 'DELETE',
        url: '/elevator-group/team',
        signal,
        query,
        body
    });
};

const getSiteActivity = (
    {query}: TAPIRequestContent<TSiteGetActivityAPI>,
    signal: AbortSignal
): TAPIResponse<TSiteGetActivityAPI> => {
    return request<TSiteGetActivityAPI>({
        method: 'GET',
        url: '/site/activity',
        signal,
        query
    });
};

const createSite = (
    {query, body}: TAPIRequestContent<TSitesCreateAPI>,
    signal: AbortSignal
): TAPIResponse<TSitesCreateAPI> => {
    return request<TSitesCreateAPI>({
        method: 'PUT',
        url: '/sites',
        signal,
        query,
        body
    });
};

const updateSiteProfile = (
    {query, body}: TAPIRequestContent<TSiteUpdateProfileAPI>,
    signal: AbortSignal
): TAPIResponse<TSiteUpdateProfileAPI> => {
    return request<TSiteUpdateProfileAPI>({
        method: 'PATCH',
        url: '/site/profile',
        signal,
        query,
        body
    });
};

export {
    addElevatorGroup,
    createSite,
    deleteElevatorGroup,
    deleteElevatorGroupTeam,
    deleteSiteTeam,
    getElevatorGroup,
    getElevatorGroupState,
    getElevatorGroupTeam,
    getSite,
    getSiteActivity,
    getSiteState,
    getSiteTeam,
    listElevatorGroups,
    listSites,
    updateElevatorGroupProfile,
    updateElevatorGroupTeam,
    updateSiteProfile,
    updateSiteTeam
};
