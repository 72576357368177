import {toKebabCase} from '../../../utils/string-utils/index.js';

const createTestIDs = <T extends string>(
    packageName: string,
    scopeName: string,
    keys: T[]
): Record<T, string> => {
    const output: Partial<Record<T, string>> = {};

    for (let i = 0; i < keys.length; i++) {
        const key = keys[i];
        output[key] = `${packageName}-${scopeName}-${toKebabCase(key)}`;
    }

    return output as Record<T, string>;
};

export {createTestIDs};
