import {useTheme} from '@mcal/core-react';
import type {FC} from 'react';
import {useMemo} from 'react';

interface IColors {
    colorA: string;
}

interface ICloseSvgProps {
    height?: number | string;
    width?: number | string;
}

const CloseSvg: FC<ICloseSvgProps> = (props) => {
    const {type} = useTheme();

    const {colorA} = useMemo<IColors>(() => {
        if (type === 'dark') {
            return {
                colorA: '#AAAEB4'
            };
        } else {
            return {
                colorA: '#555C69'
            };
        }
    }, [type]);

    return (
        <svg
            xmlns={'http://www.w3.org/2000/svg'}
            width={'24'}
            height={'24'}
            viewBox={'0 0 24 24'}
            fill={'none'}
            {...props}
        >
            <g clipPath={'url(#clip0_968_2809)'}>
                <path
                    fillRule={'evenodd'}
                    clipRule={'evenodd'}
                    d={
                        'M10.9393 11.7279L5.10571 17.5616L6.16637 18.6222L12 12.7886L17.8336 18.6222L18.8943 17.5616L13.0607 11.7279L18.8943 5.89429L17.8336 4.83363L12 10.6673L6.16637 4.83363L5.10571 5.89429L10.9393 11.7279Z'
                    }
                    fill={colorA}
                />
            </g>
            <defs>
                <clipPath id={'clip0_968_2809'}>
                    <rect width={'24'} height={'24'} fill={'white'} />
                </clipPath>
            </defs>
        </svg>
    );
};

export type {ICloseSvgProps};
export {CloseSvg};
