import type {TPattern} from '../check-pattern/check-pattern.js';
import {checkPattern} from '../check-pattern/check-pattern.js';

interface IConditions {
    ignore?: TPattern[];
    only?: TPattern[];
}

function shouldSkip(
    conditions: IConditions,
    key: string | number,
    token: string
): boolean {
    const {ignore, only} = conditions;

    if (ignore) {
        if (ignore.some((p) => checkPattern(p, key, token))) {
            return true;
        }
    }

    if (only) {
        if (!only.some((p) => checkPattern(p, key, token))) {
            return true;
        }
    }

    return false;
}

export type {IConditions};
export {shouldSkip};
