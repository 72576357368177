import type {TLanguages} from '@mcal/core';
import {createSelector} from '@reduxjs/toolkit';
import type {IPartialState} from '../../defines/redux.types.js';
import type {IAppSliceState, TAppSliceStatus} from './app.state.js';

const appSelector = (state: IPartialState): IAppSliceState => {
    if (!state.app) {
        throw new Error('App slice is not available.');
    }

    return state.app;
};

const selectAppStatus = createSelector(
    [appSelector],
    (app): TAppSliceStatus => {
        return app.status;
    }
);

const selectAppLanguages = createSelector([appSelector], (app): TLanguages => {
    return app.languages;
});

export {selectAppLanguages, selectAppStatus};
