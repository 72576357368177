import type {ICommonHeaders, TAxiosHeaders} from '@mcal/core';

const keys: (keyof ICommonHeaders)[] = ['content-type', 'content-length'];

function getCommonHeaders(headers: TAxiosHeaders): ICommonHeaders {
    const output: Partial<ICommonHeaders> = {};

    for (const key of keys) {
        if (key in headers && typeof headers[key] === 'string') {
            output[key] = headers[key] as ICommonHeaders[keyof ICommonHeaders];
        }
    }

    return output as ICommonHeaders;
}

export {getCommonHeaders};
