import type {TComponentColor} from '../../defines/common.types.js';
import type {
    IPaletteAccent,
    ITheme,
    TPaletteEmphasis
} from '../../defines/theme.types.js';

function getColorPalette(
    theme: ITheme,
    color: TComponentColor,
    emphasis: TPaletteEmphasis
): IPaletteAccent {
    switch (color) {
        case 'neutral':
            return theme.palette.neutral;
        default:
            return theme.palette.accent[color][emphasis];
    }
}

export {getColorPalette};
