import {
    logOut,
    refreshToken
} from '../../services/app-node-auth/session/session.thunks.js';
import {
    selectSessionExpiration,
    selectSessionStatus,
    selectSkipOnboarding
} from './session.selectors.js';
import {sessionSlice} from './session.slice.js';

const sessionActions = sessionSlice.actions;

const sessionSelectors = {
    selectSessionStatus,
    selectSessionExpiration,
    selectSkipOnboarding
};

const sessionThunks = {
    refreshToken,
    logOut
};

export {sessionActions, sessionSelectors, sessionSlice, sessionThunks};
