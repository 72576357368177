import type {
    EEntityType,
    IEntityInviteEntry,
    ITeamMembership,
    IUserMembership,
    TAffinityProvider
} from '@mcal/core';
import {EAffiliationStatus} from '@mcal/core';
import type {ITeamMember} from '../../defines/platform.types.js';

type TMappedTeam<T extends EEntityType> = {
    [k in T]: ITeamMembership<k>[];
}[T];

type TMappedInvites<T extends TAffinityProvider> = {
    [k in T]: IEntityInviteEntry<k>[];
}[T];

type TMappedTeamMember<T extends EEntityType> = {
    [k in T]: ITeamMember<k>[];
}[T];

interface IInput<T extends EEntityType> {
    team: TMappedTeam<T>;
    memberships: IUserMembership[];
    invites?: T extends TAffinityProvider ? TMappedInvites<T> : void;
}

function composeTeam<T extends EEntityType>(
    input: IInput<T>
): TMappedTeamMember<T> {
    const team: TMappedTeamMember<T> = [];

    // MERGE TEAM MEMBERSHIPS WITH USER MEMBERSHIPS WHEN AVAILABLE
    input.team.forEach((teamMembership) => {
        const userMembership = input.memberships.find((membership) => {
            return membership.userId === teamMembership.userId;
        });

        if (userMembership) {
            let displayName = teamMembership.userId;

            if (userMembership.firstName || userMembership.lastName) {
                displayName =
                    `${userMembership.firstName} ${userMembership.lastName}`.trim();
            }

            team.push({
                id: userMembership.userId,
                displayName,
                email: userMembership.email,
                photo: userMembership.photo,
                status: teamMembership.status,
                role: teamMembership.role,
                attributes: teamMembership.attributes,
                capabilities: userMembership.capabilities,
                inviteId: null
            });
        } else {
            team.push({
                id: teamMembership.userId,
                displayName: teamMembership.userId,
                email: null,
                photo: null,
                status: teamMembership.status,
                role: teamMembership.role,
                attributes: teamMembership.attributes,
                capabilities: [],
                inviteId: null
            });
        }
    });

    if (input.invites) {
        // ADD ENTITY INVITES
        input.invites.forEach((invite) => {
            team.push({
                id: invite.inviteId,
                displayName: null,
                email: invite.userEmail,
                photo: null,
                status: EAffiliationStatus.Pending,
                role: invite.role as ITeamMember<T>['role'],
                attributes: [],
                capabilities: [],
                inviteId: invite.inviteId
            });
        });
    }

    return team;
}

export {composeTeam};
