import type {
    TAPIResponseSuccess,
    TForgotPasswordCompleteAPI,
    TForgotPasswordStartAPI
} from '@mcal/core';
import {createAsyncThunk} from '@reduxjs/toolkit';
import type {
    TAPIAsyncThunkArgs,
    TAPIAsyncThunkConfig
} from '../../../defines/redux.types.js';
import {getState} from '../../../utils/get-state/get-state.js';
import * as services from './forgot-password.services.js';

const forgotPasswordStart = createAsyncThunk<
    TAPIResponseSuccess<TForgotPasswordStartAPI>,
    TAPIAsyncThunkArgs<TForgotPasswordStartAPI>,
    TAPIAsyncThunkConfig<TForgotPasswordStartAPI>
>('forgotPassword/start', async (args, thunkAPI) => {
    const user = getState(thunkAPI, 'user');

    return await services
        .start(
            {
                query: {
                    locale: user.remotes.userGeneralSettings.current.locale
                },
                body: {
                    data: {
                        email: args.email
                    }
                }
            },
            thunkAPI.signal
        )
        .then((response) => {
            if (response.error) {
                return thunkAPI.rejectWithValue(response);
            } else {
                return thunkAPI.fulfillWithValue(response);
            }
        });
});

const forgotPasswordComplete = createAsyncThunk<
    TAPIResponseSuccess<TForgotPasswordCompleteAPI>,
    TAPIAsyncThunkArgs<TForgotPasswordCompleteAPI>,
    TAPIAsyncThunkConfig<TForgotPasswordCompleteAPI>
>('forgotPassword/complete', async (args, thunkAPI) => {
    const user = getState(thunkAPI, 'user');

    return await services
        .complete(
            {
                query: {
                    locale: user.remotes.userGeneralSettings.current.locale
                },
                body: {
                    data: {
                        email: args.email,
                        password: args.password,
                        otp: args.otp
                    }
                }
            },
            thunkAPI.signal
        )
        .then((response) => {
            if (response.error) {
                return thunkAPI.rejectWithValue(response);
            } else {
                return thunkAPI.fulfillWithValue(response);
            }
        });
});

export {forgotPasswordComplete, forgotPasswordStart};
