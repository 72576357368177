import {logger} from '@mcal/core';
import type {ElementType, FC, ReactNode} from 'react';
import {
    Fragment,
    Suspense,
    createContext,
    useCallback,
    useContext,
    useId,
    useLayoutEffect,
    useMemo,
    useState
} from 'react';
import type {ISpinnerProps} from '../../components/spinner/spinner.js';
import {Spinner} from '../../components/spinner/spinner.js';

const log = logger.withCaller('SpinnerStore');

interface ISpinnerContext {
    subscribe: (key: string) => void;
    unsubscribe: (key: string) => void;
    root: boolean | null;
}

const noop = (): void => log.warn('USING CONTEXT OUTSIDE OF PROVIDER')();

const defaultValues: ISpinnerContext = {
    subscribe: noop,
    unsubscribe: noop,
    root: null
};

const SpinnerContext = createContext<ISpinnerContext>(defaultValues);

const SuspenseFallback: FC<ISpinnerContext> = ({subscribe, unsubscribe}) => {
    const id = useId();

    useLayoutEffect(() => {
        subscribe(id);

        return () => {
            unsubscribe(id);
        };
    }, [id, subscribe, unsubscribe]);

    return null;
};

type TSpinnerConditions = boolean | boolean[];

interface ISpinnerStoreProps extends ISpinnerProps {
    component?: ElementType;
    suspense?: boolean;
    passthrough?: boolean;
    conditions?: TSpinnerConditions;
    exclusive?: boolean;
    spinnerContainer?: ElementType;
    children: ReactNode;
}

const SpinnerStore: FC<ISpinnerStoreProps> = ({
    component: Component = Fragment,
    suspense,
    passthrough,
    conditions = false,
    exclusive = false,
    spinnerContainer: SpinnerContainer = Fragment,
    children,
    ...rest
}) => {
    const sup = useContext(SpinnerContext);

    const [subscribers, setSubscribers] = useState<string[]>([]);

    const subscribe = useCallback(
        (key: string): void => {
            if (passthrough) {
                sup.subscribe(key);

                return;
            }

            setSubscribers((prev) => {
                if (prev.includes(key)) {
                    return prev;
                } else {
                    return [...prev, key];
                }
            });
        },
        [passthrough, sup]
    );

    const unsubscribe = useCallback(
        (key: string): void => {
            if (passthrough) {
                sup.unsubscribe(key);

                return;
            }

            setSubscribers((prev) => prev.filter((k) => k !== key));
        },
        [passthrough, sup]
    );

    const value = useMemo<ISpinnerContext>(() => {
        return {
            subscribe,
            unsubscribe,
            root: sup.root === null
        };
    }, [subscribe, sup.root, unsubscribe]);

    const shouldShowSpinner = useMemo<boolean>(() => {
        if (passthrough) {
            return false;
        } else if (subscribers.length) {
            return true;
        } else {
            if (Array.isArray(conditions)) {
                return conditions.some(Boolean);
            }

            return conditions;
        }
    }, [conditions, passthrough, subscribers.length]);

    const content = shouldShowSpinner && exclusive ? null : children;

    const node = (
        <Component>
            {suspense ? (
                <Suspense fallback={<SuspenseFallback {...value} />}>
                    {content}
                </Suspense>
            ) : (
                content
            )}

            {shouldShowSpinner ? (
                <SpinnerContainer>
                    <Spinner {...rest} />
                </SpinnerContainer>
            ) : null}
        </Component>
    );

    if (passthrough) {
        return node;
    } else {
        return (
            <SpinnerContext.Provider value={value}>
                {node}
            </SpinnerContext.Provider>
        );
    }
};

export type {ISpinnerContext, ISpinnerStoreProps, TSpinnerConditions};
export {SpinnerContext, SpinnerStore};
