import {deepMerge, flatten} from '../../object-utils/index.js';
import {serialize} from '../../string-utils/index.js';
import {decodeQuery} from '../decode-query/decode-query.js';

function encodeQuery(url: string, query?: object): string {
    if (!query) {
        return url;
    }

    const decoded = decodeQuery(url);

    const merged = deepMerge(decoded, query);

    const pairs: string[] = [];

    const flat = flatten(merged, {
        arrays: true,
        keepEmptyArrays: true,
        keepEmptyObjects: true
    });

    Object.entries(flat).forEach(([token, value]) => {
        let str: string;

        if (Array.isArray(value)) {
            str = '[]';
        } else if (typeof value === 'object' && value !== null) {
            str = '{}';
        } else {
            str = serialize(value);

            if (str === '[]' || str === '{}') {
                throw new Error(`"${str}" IS A RESERVED VALUE`);
            }
        }

        pairs.push(`${encodeURIComponent(token)}=${encodeURIComponent(str)}`);
    });

    const queryString = pairs.join('&');

    const [base] = url.split('?');

    return `${base}?${queryString}`;
}

export {encodeQuery};
