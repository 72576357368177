import {useSelector, userSelectors} from '@mcal/core-react';
import type {FC} from 'react';
import {useEffect, useMemo} from 'react';
import {Outlet, useLocation, useNavigate} from 'react-router-dom';
import type {ILinkContainer} from './components/menu-wrapper/menu-wrapper.js';
import {MenuWrapper} from './components/menu-wrapper/menu-wrapper.js';

interface ILinksMap {
    serviceCompanies: ILinkContainer;
    sites: ILinkContainer;
    home: ILinkContainer;
}

interface IMainLayoutProps {
    linksMap: ILinksMap;
}

const MainLayout: FC<IMainLayoutProps> = ({linksMap}) => {
    const location = useLocation();
    const isLogged = useSelector(userSelectors.selectUserIsLogged);
    const navigate = useNavigate();

    const linksContainer = useMemo(() => {
        if (location.pathname.includes('/service-companies/')) {
            return linksMap.serviceCompanies;
        } else if (location.pathname.includes('/site/')) {
            return linksMap.sites;
        } else {
            return linksMap.home;
        }
    }, [
        linksMap.home,
        linksMap.serviceCompanies,
        linksMap.sites,
        location.pathname
    ]);

    useEffect(() => {
        if (!isLogged) {
            navigate('/log-in');
        }
    }, [isLogged, navigate]);

    const isAuthRoute = useMemo(
        () =>
            ['/log-in', '/sign-up', '/reset-password'].includes(
                location.pathname
            ),
        [location.pathname]
    );

    if (isAuthRoute) {
        return <Outlet />;
    }

    return <MenuWrapper linksContainer={linksContainer} />;
};

export type {ILinksMap, IMainLayoutProps};
export {MainLayout};
