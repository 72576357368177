import type {
    TAPIRequestContent,
    TAPIResponse,
    TAdminTechnicianProfilePendingReviewAPI,
    TAdminTechnicianProfilesListAPI,
    TAdminTechnicianProfilesListPerDateAPI,
    TAdminTechnicianProfilesPendingRecordsListAPI,
    TAdminUsersListAPI,
    TUsersGetMembershipsAPI
} from '@mcal/core';
import {request} from '../../../utils/request/request.js';

const getUsersMemberships = (
    {query}: TAPIRequestContent<TUsersGetMembershipsAPI>,
    signal: AbortSignal
): TAPIResponse<TUsersGetMembershipsAPI> => {
    return request<TUsersGetMembershipsAPI>({
        method: 'GET',
        url: '/users/memberships',
        signal,
        query
    });
};

const getUsersAdmin = (
    {query}: TAPIRequestContent<TAdminUsersListAPI>,
    signal: AbortSignal
): TAPIResponse<TAdminUsersListAPI> => {
    return request<TAdminUsersListAPI>({
        method: 'GET',
        url: '/admin/users',
        signal,
        query
    });
};

const getTechniciansAdmin = (
    {query}: TAPIRequestContent<TAdminTechnicianProfilesListAPI>,
    signal: AbortSignal
): TAPIResponse<TAdminTechnicianProfilesListAPI> => {
    return request<TAdminTechnicianProfilesListAPI>({
        method: 'GET',
        url: '/admin/technician-profiles',
        signal,
        query
    });
};

const getPendingTechniciansAdmin = (
    {query}: TAPIRequestContent<TAdminTechnicianProfilesListPerDateAPI>,
    signal: AbortSignal
): TAPIResponse<TAdminTechnicianProfilesListPerDateAPI> => {
    return request<TAdminTechnicianProfilesListPerDateAPI>({
        method: 'GET',
        url: '/admin/technician-profiles/pending',
        signal,
        query
    });
};

const listTechniciansPendingRecordAdmin = (
    {query}: TAPIRequestContent<TAdminTechnicianProfilesPendingRecordsListAPI>,
    signal: AbortSignal
): TAPIResponse<TAdminTechnicianProfilesPendingRecordsListAPI> => {
    return request<TAdminTechnicianProfilesPendingRecordsListAPI>({
        method: 'GET',
        url: '/admin/technician-profiles/pending/records',
        signal,
        query
    });
};

const reviewTechnicianAdmin = (
    {query, body}: TAPIRequestContent<TAdminTechnicianProfilePendingReviewAPI>,
    signal: AbortSignal
): TAPIResponse<TAdminTechnicianProfilePendingReviewAPI> => {
    return request<TAdminTechnicianProfilePendingReviewAPI>({
        method: 'POST',
        url: '/admin/technician-profile/pending/review',
        signal,
        query,
        body
    });
};

export {
    getPendingTechniciansAdmin,
    getTechniciansAdmin,
    getUsersAdmin,
    getUsersMemberships,
    listTechniciansPendingRecordAdmin,
    reviewTechnicianAdmin
};
