import type {ISliceSource} from '../../defines/redux.types.js';

type TSessionsSliceStatus = 'INIT' | 'NOT-AUTHENTICATED' | 'AUTHENTICATED';

interface ISessionSliceState {
    skipOnboarding: boolean | null;
    status: TSessionsSliceStatus;
    exp: number | null;
    sources: {
        refresh: ISliceSource<null>;
        logIn: ISliceSource<null>;
        logOut: ISliceSource<null>;
    };
}

const initialState = {
    skipOnboarding: null,
    status: 'INIT',
    exp: null,
    sources: {
        refresh: {
            status: 'INIT',
            prev: null
        },
        logIn: {
            status: 'INIT',
            prev: null
        },
        logOut: {
            status: 'INIT',
            prev: null
        }
    }
} satisfies ISessionSliceState as ISessionSliceState;

export type {ISessionSliceState, TSessionsSliceStatus};
export {initialState};
