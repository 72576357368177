import type {IAvatarBaseProps} from '@mcal/core-react';
import {getColorPalette} from '@mcal/core-react';
import {styled} from '../../utils/styled/styled.js';

interface IProps {
    size: NonNullable<IAvatarBaseProps<never>['size']>;
    color: NonNullable<IAvatarBaseProps<never>['color']>;
    emphasis: NonNullable<IAvatarBaseProps<never>['emphasis']>;
}

const StyledRoot = styled('div')<IProps>(
    ({theme, color, emphasis}) => {
        const main = getColorPalette(theme, color, emphasis).base;

        return {
            overflow: 'hidden',
            backgroundColor: main,
            borderRadius: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
        };
    },
    ({theme, size}) => {
        if (size === 'medium') {
            return {
                width: theme.size.root.s8,
                height: theme.size.root.s8
            };
        }
        if (size === 'large') {
            return {
                width: theme.size.root.s9,
                height: theme.size.root.s9
            };
        }

        return {
            width: theme.size.root.s6,
            height: theme.size.root.s6
        };
    }
);

const StyledLabel = styled('span')<IProps>(
    ({theme, color, emphasis}) => {
        const contrast = getColorPalette(theme, color, emphasis).onBase;

        return {
            color: contrast,
            fontWeight: 500,
            letterSpacing: 0,
            textAlign: 'center',
            textTransform: 'uppercase'
        };
    },
    ({theme, size}) => {
        if (size === 'medium') {
            return {
                fontSize: theme.size.font.s4,
                lineHeight: theme.size.font.s4
            };
        }

        if (size === 'large') {
            return {
                fontSize: theme.size.font.s5,
                lineHeight: theme.size.font.s5
            };
        }

        return {
            fontSize: theme.size.font.s2,
            lineHeight: theme.size.font.s2
        };
    }
);

export {StyledLabel, StyledRoot};
