import {styled} from '../../../../utils/styled/styled.js';
import {Label} from '../../../label/label.js';

interface IStyledSidebarItemProps {
    active?: boolean;
    expanded?: boolean;
}

const StyledContainer = styled('div')<IStyledSidebarItemProps>(
    ({theme, active}) => ({
        position: 'relative',
        padding: '12px',
        display: 'flex',
        alignItems: 'center',
        gap: '8px',
        width: '100%',
        background: active ? theme.palette.surface.base.lowest : '',
        borderRadius: theme.shape.corner.rounded.c0.borderRadius,
        ':hover': {
            background: theme.palette.surface.base.low
        }
    })
);

const StyledLabel = styled(Label)<IStyledSidebarItemProps>(({
    theme,
    active,
    expanded
}) => {
    if (!expanded) {
        return {display: 'none'};
    }
    return {
        fontWeight: active ? 500 : 400,
        color: active
            ? theme.palette.surface.onBase.high
            : theme.palette.neutral.outline
    };
});

const StyledActiveIndicator = styled('div')<{active: boolean}>(
    ({theme, active}) => ({
        position: 'absolute',
        left: 0,
        width: '3px',
        height: '20px',
        borderRadius: '0px 3px 3px 0px',
        background: active ? theme.palette.accent.primary.high.base : ''
    })
);

export type {IStyledSidebarItemProps};
export {StyledActiveIndicator, StyledContainer, StyledLabel};
