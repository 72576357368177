import type {IFontStyles} from '../../defines/fonts.js';
import {fontsStyles} from '../../defines/fonts.js';
import type {TTypeFace} from '../../defines/theme.types.js';
import {useTranslation} from '../use-translation/use-translation.js';

function useFontsStyles(typeface: TTypeFace): IFontStyles {
    const {i18n} = useTranslation();

    const match = fontsStyles[i18n.language] || fontsStyles.default;

    return match[typeface];
}

export {useFontsStyles};
