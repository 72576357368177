import {getPermissions} from '../../services/app-gateway/permissions/permissions.thunks.js';
import {getUsersMemberships} from '../../services/app-node-auth/users/users.thunks.js';
import {
    deleteElevatorTeam,
    getElevator,
    getElevatorAnomalies,
    getElevatorConfigurationTemplate,
    getElevatorDeviceConfig,
    getElevatorDevices,
    getElevatorEmergency,
    getElevatorIssues,
    getElevatorState,
    getElevatorStatus,
    getElevatorSystemConfig,
    getElevatorTeam,
    getElevatorTechnicalParameters,
    getElevatorTrips,
    listElevatorDestinations,
    listElevatorStops,
    rebootElevator,
    rebootElevatorDevice,
    registerElevators,
    remoteControlCall,
    remoteMove,
    restoreElevatorEmergency,
    serviceRoutine,
    updateElevatorAdvanced,
    updateElevatorDestinations,
    updateElevatorDeviceConfig,
    updateElevatorHandoff,
    updateElevatorOperationMode,
    updateElevatorProfile,
    updateElevatorSensors,
    updateElevatorStops,
    updateElevatorSystemConfig,
    updateElevatorSystemPlate,
    updateElevatorTeam,
    updateElevatorTechnicalParameters,
    updateRemoteControlMode
} from '../../services/app-node-elevators/elevators/elevators.thunks.js';
import {
    addMember,
    listEntityInvites
} from '../../services/app-node-invites/invites/invites.thunks.js';
import {
    getAnomaliesHistory,
    getDoorCyclesDensity,
    getDoorFaultsTotal,
    getEnergyConsumptionDenstiy,
    getFloorDistribution,
    getMileageDensity,
    getMileageTotal,
    getOutOfServiceTotal,
    getSafetyTestsTotal,
    getSanitizationRunsTotal,
    getStopsDensity,
    getTimeConsumptionDensity,
    getTripsHistory
} from '../../services/app-node-kpis/kpis/kpis.thunks.js';
import * as elevatorRemotes from './elevator.remotes.js';
import * as elevatorSelectors from './elevator.selectors.js';
import {elevatorSlice} from './elevator.slice.js';

const elevatorActions = elevatorSlice.actions;

const elevatorThunks = {
    addMember,
    deleteElevatorTeam,
    getAnomaliesHistory,
    getDoorFaultsTotal,
    getElevator,
    getElevatorAnomalies,
    getElevatorConfigurationTemplate,
    getElevatorDeviceConfig,
    getElevatorDevices,
    getElevatorEmergency,
    getElevatorIssues,
    getElevatorState,
    getElevatorStatus,
    getElevatorSystemConfig,
    getElevatorTeam,
    getElevatorTechnicalParameters,
    getElevatorTrips,
    getEnergyConsumptionDenstiy,
    getFloorDistribution,
    getMileageDensity,
    getDoorCyclesDensity,
    getMileageTotal,
    getOutOfServiceTotal,
    getPermissions,
    serviceRoutine,
    getSafetyTestsTotal,
    getSanitizationRunsTotal,
    getStopsDensity,
    getTimeConsumptionDensity,
    getTripsHistory,
    getUsersMemberships,
    listElevatorDestinations,
    listElevatorStops,
    listEntityInvites,
    rebootElevator,
    rebootElevatorDevice,
    registerElevators,
    remoteControlCall,
    remoteMove,
    restoreElevatorEmergency,
    updateElevatorAdvanced,
    updateElevatorDestinations,
    updateElevatorDeviceConfig,
    updateElevatorHandoff,
    updateElevatorOperationMode,
    updateElevatorProfile,
    updateElevatorSensors,
    updateElevatorStops,
    updateElevatorSystemConfig,
    updateElevatorSystemPlate,
    updateElevatorTeam,
    updateElevatorTechnicalParameters,
    updateRemoteControlMode
};

export {
    elevatorActions,
    elevatorRemotes,
    elevatorSelectors,
    elevatorSlice,
    elevatorThunks
};
