import {useTheme} from '@mcal/core-react';
import type {FC} from 'react';
import {useMemo} from 'react';

interface IColors {
    colorA: string;
}

interface ICheckSvgProps {
    height?: number | string;
    width?: number | string;
    fill?: string;
}

const CheckSvg: FC<ICheckSvgProps> = ({fill, ...props}) => {
    const {type} = useTheme();

    const {colorA} = useMemo<IColors>(() => {
        if (type === 'dark') {
            return {
                colorA: '#999DA5'
            };
        } else {
            return {
                colorA: '#555C69'
            };
        }
    }, [type]);

    return (
        <svg
            width={'20'}
            height={'20'}
            viewBox={'0 0 20 20'}
            fill={'none'}
            xmlns={'http://www.w3.org/2000/svg'}
            {...props}
        >
            <path
                d={
                    'M7.99213 14.6063L4 10.5906L5.1811 9.40945L7.99213 12.1969L14.8189 5.39371L16 6.59843L7.99213 14.6063Z'
                }
                fill={fill || colorA}
            />
        </svg>
    );
};

export {CheckSvg};
