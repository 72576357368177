import type {TThemeType} from '@mcal/core-react';
import {useCallback, useEffect, useMemo, useState} from 'react';

const useSystemThemeType = (): TThemeType => {
    const mediaQueryList = useMemo<MediaQueryList>(() => {
        return window.matchMedia('(prefers-color-scheme: dark)');
    }, []);

    const [type, setType] = useState<TThemeType>(() => {
        return mediaQueryList.matches ? 'dark' : 'light';
    });

    const onChangeHandler = useCallback(() => {
        if (mediaQueryList.matches) {
            setType('dark');
        } else {
            setType('light');
        }
    }, [mediaQueryList.matches, setType]);

    useEffect(() => {
        mediaQueryList.addEventListener('change', onChangeHandler);

        return (): void => {
            mediaQueryList.removeEventListener('change', onChangeHandler);
        };
    }, [mediaQueryList, onChangeHandler]);

    return type;
};

export {useSystemThemeType};
