import type {
    TAPIRequestContent,
    TAPIResponse,
    TAssignmentsGetAPI,
    TPermissionsGetAPI
} from '@mcal/core';
import {request} from '../../../utils/request/request.js';

const getPermissions = (
    {query}: TAPIRequestContent<TPermissionsGetAPI>,
    signal: AbortSignal
): TAPIResponse<TPermissionsGetAPI> => {
    return request<TPermissionsGetAPI>({
        method: 'GET',
        url: '/permissions',
        signal,
        query
    });
};

const getAssignments = (
    {query}: TAPIRequestContent<TAssignmentsGetAPI>,
    signal: AbortSignal
): TAPIResponse<TAssignmentsGetAPI> => {
    return request<TAssignmentsGetAPI>({
        method: 'GET',
        url: '/assignments',
        signal,
        query
    });
};

export {getAssignments, getPermissions};
