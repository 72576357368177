import {useMemo} from 'react';
import type {IThemePack} from '../../defines/theme.types.js';
import {serviceCompanySelectors} from '../../slices/service-company/service-company.index.js';
import {userSelectors} from '../../slices/user/user.index.js';
import {DEFAULT_THEME_PACK, THEME_PACKS} from '../../themes/index.js';
import {createThemePack} from '../../utils/create-theme-pack/create-theme-pack.js';
import {useSelector} from '../use-selector/use-selector.js';

function useThemePack(): IThemePack {
    const user = useSelector(userSelectors.selectUserGeneralSettings);

    const serviceCompany = useSelector(
        serviceCompanySelectors.selectServiceCompanyGeneralSettings
    );

    const themePack = useMemo(() => {
        if (serviceCompany.customColor) {
            return createThemePack({
                key: `custom-from-${serviceCompany.customColor}`,
                colors: {
                    source: serviceCompany.customColor,
                    error: '#CC1906',
                    warning: '#F58220',
                    success: '#06CC65',
                    info: '#2B8DFE'
                },
                // IMPORTANT TO NOT CREATE TOO MUCH MEMORY PRESSURE DURING "LIVE" CHANGES
                skipCacheCreation: true
            });
        } else {
            return THEME_PACKS.find(({key}) => key === user.themePack);
        }
    }, [serviceCompany.customColor, user.themePack]);

    if (themePack) {
        return themePack;
    } else {
        return DEFAULT_THEME_PACK;
    }
}

export {useThemePack};
