import type {
    TNestedPartial,
    TObj
} from '../../../defines/common/utility.types.js';
import {assign} from '../assign/assign.js';
import {forEach} from '../for-each/for-each.js';

interface IDeepAssignOptions {
    knownOnly?: boolean;
    skipUndefined?: boolean;
}

function deepAssign<T extends object>(
    target: T,
    from: TNestedPartial<T>,
    options: IDeepAssignOptions = {}
): T {
    const {knownOnly = false, skipUndefined = false} = options;

    forEach(
        from,
        ({token, value, skip}) => {
            if (Array.isArray(value)) {
                assign(target as TObj<unknown>, token, value, {knownOnly});
                skip();
            } else if (typeof value !== 'object' || value === null) {
                if (!skipUndefined || typeof value !== 'undefined') {
                    assign(target as TObj<unknown>, token, value, {knownOnly});
                }
            }
        },
        {skipRefs: false}
    );

    return target;
}

export {deepAssign};
