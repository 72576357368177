import {useTheme} from '@mcal/core-react';
import type {FC} from 'react';
import {useMemo} from 'react';

interface IColors {
    colorA: string;
    colorB: string;
}

interface IProblemSvgProps {
    height?: number | string;
    width?: number | string;
    fill?: string;
}

const ProblemSvg: FC<IProblemSvgProps> = ({fill, ...props}) => {
    const {type} = useTheme();

    const {colorA, colorB} = useMemo<IColors>(() => {
        if (type === 'dark') {
            return {
                colorA: '#AAAEB4',
                colorB: '#D9D9D9'
            };
        } else {
            return {
                colorA: '#555C69',
                colorB: '#D9D9D9'
            };
        }
    }, [type]);
    return (
        <svg
            xmlns={'http://www.w3.org/2000/svg'}
            width={'20'}
            height={'20'}
            viewBox={'0 0 20 20'}
            fill={'none'}
            {...props}
        >
            <mask
                id={'mask0_71_4055'}
                maskUnits={'userSpaceOnUse'}
                x={'0'}
                y={'0'}
                width={'20'}
                height={'20'}
            >
                <rect width={'20'} height={'20'} fill={colorB} />
            </mask>
            <g mask={'url(#mask0_71_4055)'}>
                <path
                    d={
                        'M5.74563 13C5.95688 13 6.13542 12.9285 6.28125 12.7856C6.42708 12.6427 6.5 12.4656 6.5 12.2544C6.5 12.0431 6.42854 11.8646 6.28562 11.7188C6.14271 11.5729 5.96563 11.5 5.75437 11.5C5.54312 11.5 5.36458 11.5715 5.21875 11.7144C5.07292 11.8573 5 12.0344 5 12.2456C5 12.4569 5.07146 12.6354 5.21438 12.7812C5.35729 12.9271 5.53438 13 5.74563 13ZM5.74563 10.5C5.95688 10.5 6.13542 10.4281 6.28125 10.2844C6.42708 10.1406 6.5 9.9625 6.5 9.75V7.75C6.5 7.5375 6.42854 7.35937 6.28562 7.21562C6.14271 7.07187 5.96563 7 5.75437 7C5.54312 7 5.36458 7.07187 5.21875 7.21562C5.07292 7.35937 5 7.5375 5 7.75V9.75C5 9.9625 5.07146 10.1406 5.21438 10.2844C5.35729 10.4281 5.53438 10.5 5.74563 10.5ZM9.74917 12.5H14.2442C14.4564 12.5 14.6354 12.4285 14.7812 12.2856C14.9271 12.1427 15 11.9656 15 11.7544C15 11.5431 14.9282 11.3646 14.7846 11.2188C14.641 11.0729 14.4631 11 14.2508 11H9.75583C9.54361 11 9.36458 11.0715 9.21875 11.2144C9.07292 11.3573 9 11.5344 9 11.7456C9 11.9569 9.07181 12.1354 9.21542 12.2812C9.35903 12.4271 9.53694 12.5 9.74917 12.5ZM9.74917 9H14.2442C14.4564 9 14.6354 8.92854 14.7812 8.78563C14.9271 8.64271 15 8.46563 15 8.25438C15 8.04313 14.9282 7.86458 14.7846 7.71875C14.641 7.57292 14.4631 7.5 14.2508 7.5H9.75583C9.54361 7.5 9.36458 7.57146 9.21875 7.71437C9.07292 7.85729 9 8.03437 9 8.24562C9 8.45687 9.07181 8.63542 9.21542 8.78125C9.35903 8.92708 9.53694 9 9.74917 9ZM3.5 16C3.0875 16 2.73438 15.8531 2.44063 15.5592C2.14688 15.2653 2 14.9119 2 14.4992V5.49417C2 5.08139 2.14688 4.72917 2.44063 4.4375C2.73438 4.14583 3.0875 4 3.5 4H16.5C16.9125 4 17.2656 4.14694 17.5594 4.44083C17.8531 4.73472 18 5.08806 18 5.50083V14.5058C18 14.9186 17.8531 15.2708 17.5594 15.5625C17.2656 15.8542 16.9125 16 16.5 16H3.5Z'
                    }
                    fill={fill || colorA}
                />
            </g>
        </svg>
    );
};

export {ProblemSvg};
