import type {ILink, TTestIDs} from '@mcal/core-react';
import {cn} from '@mcal/core-react';
import type {FC} from 'react';
import {useCallback} from 'react';
import {matchPath, useLocation} from 'react-router-dom';
import {createTestIDs} from '../../dev/index.js';
import {useGeneratePath} from '../../hooks/use-generate-path/use-generate-path.js';
import {StyledRoot} from './bottom-bar.styles.js';
import {BottomBarItem} from './component/bottom-bar-item/bottom-bar-item.js';

const ownTestIDs = createTestIDs('BottomBar', ['root']);

interface IBottomBarProps {
    expanded?: boolean;
    links: ILink[];
    testIDs?: TTestIDs<typeof ownTestIDs>;
    className?: string;
    classes?: {
        root?: string;
    };
}

const BottomBar: FC<IBottomBarProps> = ({
    className = '',
    classes = {},
    links = [],
    testIDs = ownTestIDs
}) => {
    const location = useLocation();

    const generatePath = useGeneratePath();

    const checkIfActive = useCallback(
        (link: ILink): boolean => {
            return (
                (!link.alwaysInactive &&
                    !!matchPath(generatePath(link.link), location.pathname)) ||
                (!!link && location.pathname.includes(generatePath(link.link)))
            );
        },
        [generatePath, location.pathname]
    );

    return (
        <StyledRoot
            data-testid={testIDs.root}
            className={cn(className, classes.root)}
        >
            {links.map((link, index) => (
                <BottomBarItem
                    icon={link.icon}
                    active={checkIfActive(link)}
                    key={index}
                    to={generatePath(link.link)}
                    label={link.label}
                />
            ))}
        </StyledRoot>
    );
};

export type {IBottomBarProps};
export {BottomBar, ownTestIDs as testIDs};
