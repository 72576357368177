import {splitByWord} from '../split-by-word/split-by-word.js';

function toKebabCase(input: string, strict: boolean = true): string {
    if (strict) {
        return splitByWord(input)
            .reduce((acc, word) => {
                acc += '-' + word.toLowerCase();
                return acc;
            }, '')
            .slice(1);
    } else {
        return splitByWord(input)
            .reduce((acc, word) => {
                acc += '-' + word;
                return acc;
            }, '')
            .slice(1);
    }
}

export {toKebabCase};
