import type {
    IAPI,
    IResponseMeta,
    TAPIResponseData,
    TAPIResponseMeta,
    TAPIResponseSuccess,
    TAPIUnknown,
    TBinaryPayload,
    TIsEmpty,
    TRequestMethod,
    TRequestPayload
} from '@mcal/core';
import {MESSAGES} from '@mcal/core';
import {getCommonHeaders} from '../get-common-headers/get-common-headers.js';

type TNoMeta<TAPI extends TAPIUnknown> = TIsEmpty<
    Omit<TAPIResponseMeta<TAPI>, keyof IResponseMeta>
>;

type TInput<TAPI extends TAPIUnknown> =
    TAPIResponseData<TAPI> extends TBinaryPayload | object
        ? TNoMeta<TAPI> extends true
            ? {
                  meta?: Partial<IResponseMeta>;
                  data: TAPIResponseData<TAPI>;
              }
            : {
                  meta: TAPIResponseMeta<TAPI>;
                  data: TAPIResponseData<TAPI>;
              }
        : TNoMeta<TAPI> extends true
          ? {
                meta?: Partial<IResponseMeta>;
            }
          : {
                meta: TAPIResponseMeta<TAPI>;
            };

type TAPIOptional = IAPI<
    TRequestMethod,
    string,
    TRequestPayload | void,
    void,
    object | void,
    string | void
>;

function getFulfillValue<TAPI extends TAPIUnknown>(
    input: TInput<TAPI>
): TAPIResponseSuccess<TAPI>;

function getFulfillValue<TAPI extends TAPIOptional>(
    input?: TInput<TAPI>
): TAPIResponseSuccess<TAPI>;

function getFulfillValue<TAPI extends TAPIUnknown>(
    input?: TInput<TAPI>
): TAPIResponseSuccess<TAPI> {
    const output = {
        headers: getCommonHeaders({}),
        error: null,
        status: -1,
        meta: {
            code: MESSAGES.NORMAL.CLIENT.OK.CODE,
            exp: -1,
            ...(input ? input.meta : {})
        }
    } as TAPIResponseSuccess<TAPI>;

    if (input && 'data' in input && typeof input.data !== 'undefined') {
        return {
            ...output,
            data: input.data
        };
    } else {
        return output;
    }
}

export {getFulfillValue};
