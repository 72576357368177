import type {IThemePack, TThemeType} from '../defines/theme.types.js';
import {CODISTICA_THEME_PACK} from './codistica/codistica.theme.js';
import {STANDARD_THEME_PACK} from './standard/standard.theme.js';

const THEME_PACKS: IThemePack[] = [STANDARD_THEME_PACK, CODISTICA_THEME_PACK];

const DEFAULT_THEME_PACK: IThemePack = STANDARD_THEME_PACK;

const THEME_TYPES: TThemeType[] = ['light', 'dark'];

const DEFAULT_THEME_TYPE: TThemeType = 'dark';

export {
    DEFAULT_THEME_PACK,
    DEFAULT_THEME_TYPE,
    STANDARD_THEME_PACK,
    THEME_PACKS,
    THEME_TYPES
};
