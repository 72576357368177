import type {
    TAPIResponseSuccess,
    TKpisAnomaliesHistoryAPI,
    TKpisDoorCyclesTotalAPI,
    TKpisDoorFaultsTotalAPI,
    TKpisEnergyConsumptionDensityAPI,
    TKpisFloorDistributionAPI,
    TKpisMileageDensityAPI,
    TKpisMileageTotalAPI,
    TKpisOutOfServiceTotalAPI,
    TKpisSafetyTestsTotalAPI,
    TKpisSanitizationRunsTotalAPI,
    TKpisStopsDensityAPI,
    TKpisTimeConsumptionDensityAPI,
    TKpisTripsHistoryAPI
} from '@mcal/core';

import {createAsyncThunk} from '@reduxjs/toolkit';
import type {
    TAPIAsyncThunkArgs,
    TAPIAsyncThunkConfig
} from '../../../defines/redux.types.js';
import {getState} from '../../../utils/get-state/get-state.js';
import * as services from './kpis.services.js';

const getMileageTotal = createAsyncThunk<
    TAPIResponseSuccess<TKpisMileageTotalAPI>,
    TAPIAsyncThunkArgs<TKpisMileageTotalAPI>,
    TAPIAsyncThunkConfig<TKpisMileageTotalAPI>
>('kpis/getMileageTotal', async (args, thunkAPI) => {
    const user = getState(thunkAPI, 'user');

    return await services
        .getMileageTotal(
            {
                query:
                    args.timeframe === 'custom'
                        ? {
                              locale: user.remotes.userGeneralSettings.current
                                  .locale,
                              key: args.key,
                              scope: args.scope,
                              target: args.target,
                              timeframe: args.timeframe,
                              from: args.from,
                              to: args.to
                          }
                        : {
                              locale: user.remotes.userGeneralSettings.current
                                  .locale,
                              key: args.key,
                              scope: args.scope,
                              target: args.target,
                              timeframe: args.timeframe
                          }
            },
            thunkAPI.signal
        )
        .then((response) => {
            if (response.error) {
                return thunkAPI.rejectWithValue(response);
            } else {
                return thunkAPI.fulfillWithValue(response);
            }
        });
});

const getFloorDistribution = createAsyncThunk<
    TAPIResponseSuccess<TKpisFloorDistributionAPI>,
    TAPIAsyncThunkArgs<TKpisFloorDistributionAPI>,
    TAPIAsyncThunkConfig<TKpisFloorDistributionAPI>
>('kpis/getFloorDistribution', async (args, thunkAPI) => {
    const user = getState(thunkAPI, 'user');

    return await services
        .getFloorDistribution(
            {
                query:
                    args.timeframe === 'custom'
                        ? {
                              locale: user.remotes.userGeneralSettings.current
                                  .locale,
                              key: args.key,
                              scope: args.scope,
                              target: args.target,
                              timeframe: args.timeframe,
                              from: args.from,
                              to: args.to
                          }
                        : {
                              locale: user.remotes.userGeneralSettings.current
                                  .locale,
                              key: args.key,
                              scope: args.scope,
                              target: args.target,
                              timeframe: args.timeframe
                          }
            },
            thunkAPI.signal
        )
        .then((response) => {
            if (response.error) {
                return thunkAPI.rejectWithValue(response);
            } else {
                return thunkAPI.fulfillWithValue(response);
            }
        });
});

const getTripsHistory = createAsyncThunk<
    TAPIResponseSuccess<TKpisTripsHistoryAPI>,
    TAPIAsyncThunkArgs<TKpisTripsHistoryAPI>,
    TAPIAsyncThunkConfig<TKpisTripsHistoryAPI>
>('kpis/getTripsHistory', async (args, thunkAPI) => {
    const user = getState(thunkAPI, 'user');

    return await services
        .getTripsHistory(
            {
                query:
                    args.timeframe === 'custom'
                        ? {
                              locale: user.remotes.userGeneralSettings.current
                                  .locale,
                              key: args.key,
                              scope: args.scope,
                              target: args.target,
                              timeframe: args.timeframe,
                              from: args.from,
                              to: args.to
                          }
                        : {
                              locale: user.remotes.userGeneralSettings.current
                                  .locale,
                              key: args.key,
                              scope: args.scope,
                              target: args.target,
                              timeframe: args.timeframe
                          }
            },
            thunkAPI.signal
        )
        .then((response) => {
            if (response.error) {
                return thunkAPI.rejectWithValue(response);
            } else {
                return thunkAPI.fulfillWithValue(response);
            }
        });
});

const getOutOfServiceTotal = createAsyncThunk<
    TAPIResponseSuccess<TKpisOutOfServiceTotalAPI>,
    TAPIAsyncThunkArgs<TKpisOutOfServiceTotalAPI>,
    TAPIAsyncThunkConfig<TKpisOutOfServiceTotalAPI>
>('kpis/getOutOfServiceTotal', async (args, thunkAPI) => {
    const user = getState(thunkAPI, 'user');

    return await services
        .getOutOfServiceTotal(
            {
                query:
                    args.timeframe === 'custom'
                        ? {
                              locale: user.remotes.userGeneralSettings.current
                                  .locale,
                              key: args.key,
                              scope: args.scope,
                              target: args.target,
                              timeframe: args.timeframe,
                              from: args.from,
                              to: args.to
                          }
                        : {
                              locale: user.remotes.userGeneralSettings.current
                                  .locale,
                              key: args.key,
                              scope: args.scope,
                              target: args.target,
                              timeframe: args.timeframe
                          }
            },
            thunkAPI.signal
        )
        .then((response) => {
            if (response.error) {
                return thunkAPI.rejectWithValue(response);
            } else {
                return thunkAPI.fulfillWithValue(response);
            }
        });
});
const getDoorFaultsTotal = createAsyncThunk<
    TAPIResponseSuccess<TKpisDoorFaultsTotalAPI>,
    TAPIAsyncThunkArgs<TKpisDoorFaultsTotalAPI>,
    TAPIAsyncThunkConfig<TKpisDoorFaultsTotalAPI>
>('kpis/getDoorFaultsTotal', async (args, thunkAPI) => {
    const user = getState(thunkAPI, 'user');

    return await services
        .getDoorFaultsTotal(
            {
                query:
                    args.timeframe === 'custom'
                        ? {
                              locale: user.remotes.userGeneralSettings.current
                                  .locale,
                              key: args.key,
                              scope: args.scope,
                              target: args.target,
                              timeframe: args.timeframe,
                              from: args.from,
                              to: args.to
                          }
                        : {
                              locale: user.remotes.userGeneralSettings.current
                                  .locale,
                              key: args.key,
                              scope: args.scope,
                              target: args.target,
                              timeframe: args.timeframe
                          }
            },
            thunkAPI.signal
        )
        .then((response) => {
            if (response.error) {
                return thunkAPI.rejectWithValue(response);
            } else {
                return thunkAPI.fulfillWithValue(response);
            }
        });
});
const getSafetyTestsTotal = createAsyncThunk<
    TAPIResponseSuccess<TKpisSafetyTestsTotalAPI>,
    TAPIAsyncThunkArgs<TKpisSafetyTestsTotalAPI>,
    TAPIAsyncThunkConfig<TKpisSafetyTestsTotalAPI>
>('kpis/getSafetyTestsTotal', async (args, thunkAPI) => {
    const user = getState(thunkAPI, 'user');

    return await services
        .getSafetyTestsTotal(
            {
                query:
                    args.timeframe === 'custom'
                        ? {
                              locale: user.remotes.userGeneralSettings.current
                                  .locale,
                              key: args.key,
                              scope: args.scope,
                              target: args.target,
                              timeframe: args.timeframe,
                              from: args.from,
                              to: args.to
                          }
                        : {
                              locale: user.remotes.userGeneralSettings.current
                                  .locale,
                              key: args.key,
                              scope: args.scope,
                              target: args.target,
                              timeframe: args.timeframe
                          }
            },
            thunkAPI.signal
        )
        .then((response) => {
            if (response.error) {
                return thunkAPI.rejectWithValue(response);
            } else {
                return thunkAPI.fulfillWithValue(response);
            }
        });
});
const getSanitizationRunsTotal = createAsyncThunk<
    TAPIResponseSuccess<TKpisSanitizationRunsTotalAPI>,
    TAPIAsyncThunkArgs<TKpisSanitizationRunsTotalAPI>,
    TAPIAsyncThunkConfig<TKpisSanitizationRunsTotalAPI>
>('kpis/getSanitizationRunsTotal', async (args, thunkAPI) => {
    const user = getState(thunkAPI, 'user');

    return await services
        .getSanitizationRunsTotal(
            {
                query:
                    args.timeframe === 'custom'
                        ? {
                              locale: user.remotes.userGeneralSettings.current
                                  .locale,
                              key: args.key,
                              scope: args.scope,
                              target: args.target,
                              timeframe: args.timeframe,
                              from: args.from,
                              to: args.to
                          }
                        : {
                              locale: user.remotes.userGeneralSettings.current
                                  .locale,
                              key: args.key,
                              scope: args.scope,
                              target: args.target,
                              timeframe: args.timeframe
                          }
            },
            thunkAPI.signal
        )
        .then((response) => {
            if (response.error) {
                return thunkAPI.rejectWithValue(response);
            } else {
                return thunkAPI.fulfillWithValue(response);
            }
        });
});

const getAnomaliesHistory = createAsyncThunk<
    TAPIResponseSuccess<TKpisAnomaliesHistoryAPI>,
    TAPIAsyncThunkArgs<TKpisAnomaliesHistoryAPI>,
    TAPIAsyncThunkConfig<TKpisAnomaliesHistoryAPI>
>('kpis/getAnomaliesHistory', async (args, thunkAPI) => {
    const user = getState(thunkAPI, 'user');

    return await services
        .getAnomaliesHistory(
            {
                query:
                    args.timeframe === 'custom'
                        ? {
                              locale: user.remotes.userGeneralSettings.current
                                  .locale,
                              key: args.key,
                              scope: args.scope,
                              target: args.target,
                              timeframe: args.timeframe,
                              from: args.from,
                              to: args.to
                          }
                        : {
                              locale: user.remotes.userGeneralSettings.current
                                  .locale,
                              key: args.key,
                              scope: args.scope,
                              target: args.target,
                              timeframe: args.timeframe
                          }
            },
            thunkAPI.signal
        )
        .then((response) => {
            if (response.error) {
                return thunkAPI.rejectWithValue(response);
            } else {
                return thunkAPI.fulfillWithValue(response);
            }
        });
});

const getMileageDensity = createAsyncThunk<
    TAPIResponseSuccess<TKpisMileageDensityAPI>,
    TAPIAsyncThunkArgs<TKpisMileageDensityAPI>,
    TAPIAsyncThunkConfig<TKpisMileageDensityAPI>
>('kpis/getMileageDensity', async (args, thunkAPI) => {
    const user = getState(thunkAPI, 'user');

    return await services
        .getMileageDensity(
            {
                query:
                    args.timeframe === 'custom'
                        ? {
                              locale: user.remotes.userGeneralSettings.current
                                  .locale,
                              key: args.key,
                              scope: args.scope,
                              target: args.target,
                              timeframe: args.timeframe,
                              from: args.from,
                              to: args.to
                          }
                        : {
                              locale: user.remotes.userGeneralSettings.current
                                  .locale,
                              key: args.key,
                              scope: args.scope,
                              target: args.target,
                              timeframe: args.timeframe
                          }
            },
            thunkAPI.signal
        )
        .then((response) => {
            if (response.error) {
                return thunkAPI.rejectWithValue(response);
            } else {
                return thunkAPI.fulfillWithValue(response);
            }
        });
});

// StopsDensityKPI
const getStopsDensity = createAsyncThunk<
    TAPIResponseSuccess<TKpisStopsDensityAPI>,
    TAPIAsyncThunkArgs<TKpisStopsDensityAPI>,
    TAPIAsyncThunkConfig<TKpisStopsDensityAPI>
>('kpis/getStopsDensity', async (args, thunkAPI) => {
    const user = getState(thunkAPI, 'user');

    return await services
        .getStopsDensity(
            {
                query:
                    args.timeframe === 'custom'
                        ? {
                              locale: user.remotes.userGeneralSettings.current
                                  .locale,
                              key: args.key,
                              scope: args.scope,
                              target: args.target,
                              timeframe: args.timeframe,
                              from: args.from,
                              to: args.to
                          }
                        : {
                              locale: user.remotes.userGeneralSettings.current
                                  .locale,
                              key: args.key,
                              scope: args.scope,
                              target: args.target,
                              timeframe: args.timeframe
                          }
            },
            thunkAPI.signal
        )
        .then((response) => {
            if (response.error) {
                return thunkAPI.rejectWithValue(response);
            } else {
                return thunkAPI.fulfillWithValue(response);
            }
        });
});
// EnergyConsumptionDensityKPI
const getEnergyConsumptionDenstiy = createAsyncThunk<
    TAPIResponseSuccess<TKpisEnergyConsumptionDensityAPI>,
    TAPIAsyncThunkArgs<TKpisEnergyConsumptionDensityAPI>,
    TAPIAsyncThunkConfig<TKpisEnergyConsumptionDensityAPI>
>('kpis/getEnergyConsumptionDenstiy', async (args, thunkAPI) => {
    const user = getState(thunkAPI, 'user');

    return await services
        .getEnergyConsumptionDenstiy(
            {
                query:
                    args.timeframe === 'custom'
                        ? {
                              locale: user.remotes.userGeneralSettings.current
                                  .locale,
                              key: args.key,
                              scope: args.scope,
                              target: args.target,
                              timeframe: args.timeframe,
                              from: args.from,
                              to: args.to
                          }
                        : {
                              locale: user.remotes.userGeneralSettings.current
                                  .locale,
                              key: args.key,
                              scope: args.scope,
                              target: args.target,
                              timeframe: args.timeframe
                          }
            },
            thunkAPI.signal
        )
        .then((response) => {
            if (response.error) {
                return thunkAPI.rejectWithValue(response);
            } else {
                return thunkAPI.fulfillWithValue(response);
            }
        });
});

// TimeConsumptionDensityKPI

const getTimeConsumptionDensity = createAsyncThunk<
    TAPIResponseSuccess<TKpisTimeConsumptionDensityAPI>,
    TAPIAsyncThunkArgs<TKpisTimeConsumptionDensityAPI>,
    TAPIAsyncThunkConfig<TKpisTimeConsumptionDensityAPI>
>('kpis/getTimeConsumptionDensity', async (args, thunkAPI) => {
    const user = getState(thunkAPI, 'user');

    return await services
        .getTimeConsumptionDensity(
            {
                query:
                    args.timeframe === 'custom'
                        ? {
                              locale: user.remotes.userGeneralSettings.current
                                  .locale,
                              key: args.key,
                              scope: args.scope,
                              target: args.target,
                              timeframe: args.timeframe,
                              from: args.from,
                              to: args.to
                          }
                        : {
                              locale: user.remotes.userGeneralSettings.current
                                  .locale,
                              key: args.key,
                              scope: args.scope,
                              target: args.target,
                              timeframe: args.timeframe
                          }
            },
            thunkAPI.signal
        )
        .then((response) => {
            if (response.error) {
                return thunkAPI.rejectWithValue(response);
            } else {
                return thunkAPI.fulfillWithValue(response);
            }
        });
});

const getDoorCyclesDensity = createAsyncThunk<
    TAPIResponseSuccess<TKpisDoorCyclesTotalAPI>,
    TAPIAsyncThunkArgs<TKpisDoorCyclesTotalAPI>,
    TAPIAsyncThunkConfig<TKpisDoorCyclesTotalAPI>
>('kpis/getDoorCyclesDensity', async (args, thunkAPI) => {
    const user = getState(thunkAPI, 'user');

    return await services
        .getDoorCyclesDensity(
            {
                query:
                    args.timeframe === 'custom'
                        ? {
                              locale: user.remotes.userGeneralSettings.current
                                  .locale,
                              key: args.key,
                              scope: args.scope,
                              target: args.target,
                              timeframe: args.timeframe,
                              from: args.from,
                              to: args.to
                          }
                        : {
                              locale: user.remotes.userGeneralSettings.current
                                  .locale,
                              key: args.key,
                              scope: args.scope,
                              target: args.target,
                              timeframe: args.timeframe
                          }
            },
            thunkAPI.signal
        )
        .then((response) => {
            if (response.error) {
                return thunkAPI.rejectWithValue(response);
            } else {
                return thunkAPI.fulfillWithValue(response);
            }
        });
});

export {
    getAnomaliesHistory,
    getDoorCyclesDensity,
    getDoorFaultsTotal,
    getEnergyConsumptionDenstiy,
    getFloorDistribution,
    getMileageDensity,
    getMileageTotal,
    getOutOfServiceTotal,
    getSafetyTestsTotal,
    getSanitizationRunsTotal,
    getStopsDensity,
    getTimeConsumptionDensity,
    getTripsHistory
};
