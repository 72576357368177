import * as socketsSelectors from './sockets.selectors.js';
import {
    socketsSlice,
    subscribeToElevatorStatus,
    subscribeToSiteStatus
} from './sockets.slice.js';

const socketsActions = {
    ...socketsSlice.actions,
    subscribeToElevatorStatus,
    subscribeToSiteStatus
};

export {socketsActions, socketsSelectors, socketsSlice};
