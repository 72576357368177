import {styled} from '../../utils/styled/styled.js';
import {Link} from '../link/link.js';

const StyledRoot = styled('span')(({theme}) => ({
    display: 'flex',
    gap: theme.size.fixed.s0,
    alignItems: 'center'
}));

const StyledLink = styled(Link)(({theme}) => ({
    color: theme.palette.surface.onBase.low,
    cursor: 'pointer',
    textDecoration: 'none',
    fontSize: theme.size.font.s3,
    display: 'flex',
    gap: theme.size.fixed.s1
}));

export {StyledLink, StyledRoot};
