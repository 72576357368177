import {useTheme} from '@mcal/core-react';
import type {FC} from 'react';
import {useMemo} from 'react';

interface IColors {
    colorA: string;
}

interface IApartmentSvgProps {
    height?: number | string;
    width?: number | string;
    fill?: string;
}

const ServicesToolboxSvg: FC<IApartmentSvgProps> = ({fill, ...props}) => {
    const {type} = useTheme();

    const {colorA} = useMemo<IColors>(() => {
        if (type === 'dark') {
            return {
                colorA: '#999DA5'
            };
        } else {
            return {
                colorA: '#555C69'
            };
        }
    }, [type]);
    return (
        <svg
            width={'20'}
            height={'20'}
            viewBox={'0 0 20 20'}
            xmlns={'http://www.w3.org/2000/svg'}
            fill={fill}
            {...props}
        >
            <mask
                id={'mask0_302_1590'}
                maskUnits={'userSpaceOnUse'}
                x={'0'}
                y={'0'}
                width={'20'}
                height={'20'}
            >
                <rect width={'20'} height={'20'} fill={'#D9D9D9'} />
            </mask>
            <g mask={'url(#mask0_302_1590)'}>
                <path
                    d={
                        'M3.5 16C3.0875 16 2.73438 15.8531 2.44063 15.5594C2.14688 15.2656 2 14.9125 2 14.5V11H6V11.25C6 11.4625 6.07271 11.6406 6.21813 11.7844C6.36368 11.9281 6.54396 12 6.75896 12C6.96132 12 7.13542 11.9271 7.28125 11.7812C7.42708 11.6354 7.5 11.4583 7.5 11.25V11H12.5V11.25C12.5 11.4625 12.5727 11.6406 12.7181 11.7844C12.8637 11.9281 13.044 12 13.259 12C13.4613 12 13.6354 11.9271 13.7812 11.7812C13.9271 11.6354 14 11.4583 14 11.25V11H18V14.5C18 14.9125 17.8531 15.2656 17.5594 15.5594C17.2656 15.8531 16.9125 16 16.5 16H3.5ZM2 10L3.625 5.9375C3.73611 5.65972 3.92174 5.43403 4.18187 5.26042C4.44201 5.08681 4.72167 5 5.02083 5H6V3.5C6.02778 3.09722 6.18444 2.74653 6.47 2.44792C6.75569 2.14931 7.09903 2 7.5 2H12.5C12.9011 2 13.2444 2.14583 13.53 2.4375C13.8156 2.72917 13.9722 3.08333 14 3.5V5H14.9792C15.2783 5 15.558 5.08681 15.8181 5.26042C16.0783 5.43403 16.2639 5.65972 16.375 5.9375L18 10H14V9.75C14 9.5375 13.9273 9.35938 13.7819 9.21562C13.6363 9.07187 13.456 9 13.241 9C13.0387 9 12.8646 9.07292 12.7188 9.21875C12.5729 9.36458 12.5 9.54167 12.5 9.75V10H7.5V9.75C7.5 9.5375 7.42729 9.35938 7.28188 9.21562C7.13632 9.07187 6.95604 9 6.74104 9C6.53868 9 6.36458 9.07292 6.21875 9.21875C6.07292 9.36458 6 9.54167 6 9.75V10H2ZM7.5 5H12.5V3.5H7.5V5Z'
                    }
                    fill={fill || colorA}
                />
            </g>
        </svg>
    );
};

export {ServicesToolboxSvg};
