import {useTheme} from '@mcal/core-react';
import type {FC} from 'react';
import {useMemo} from 'react';

interface IColors {
    colorA: string;
    colorB: string;
}

interface ITechnicianProps {
    height?: number | string;
    width?: number | string;
    fill?: string;
}

const TechnicianSvg: FC<ITechnicianProps> = ({fill, ...props}) => {
    const {type} = useTheme();

    const {colorA, colorB} = useMemo<IColors>(() => {
        if (type === 'dark') {
            return {
                colorA: '#999DA5',
                colorB: '#D9D9D9'
            };
        } else {
            return {
                colorA: '#555C69',
                colorB: '#D9D9D9'
            };
        }
    }, [type]);

    return (
        <svg
            width={'20'}
            height={'20'}
            viewBox={'0 0 20 20'}
            fill={'none'}
            xmlns={'http://www.w3.org/2000/svg'}
            {...props}
        >
            <mask
                id={'mask0_1797_10'}
                style={{maskType: 'alpha'}}
                maskUnits={'userSpaceOnUse'}
                x={'0'}
                y={'0'}
                width={'20'}
                height={'20'}
            >
                <rect width={'20'} height={'20'} fill={colorB} />
            </mask>
            <g mask={'url(#mask0_1797_10)'}>
                <path
                    d={
                        'M10 10C9.16667 10 8.45833 9.70833 7.875 9.125C7.29167 8.54167 7 7.83333 7 7C7 6.16667 7.29167 5.45833 7.875 4.875C8.45833 4.29167 9.16667 4 10 4C10.8333 4 11.5417 4.29167 12.125 4.875C12.7083 5.45833 13 6.16667 13 7C13 7.83333 12.7083 8.54167 12.125 9.125C11.5417 9.70833 10.8333 10 10 10ZM13 16V11.4792C13.3611 11.5903 13.7083 11.7188 14.0417 11.8646C14.375 12.0104 14.7014 12.1806 15.0208 12.375C15.3125 12.5417 15.5486 12.7708 15.7292 13.0625C15.9097 13.3542 16 13.6667 16 14V16H13ZM8.5 13.5V11.125C8.75 11.0833 9 11.0521 9.25 11.0312C9.5 11.0104 9.75 11 10 11C10.25 11 10.5 11.0104 10.75 11.0312C11 11.0521 11.25 11.0833 11.5 11.125V13.5H8.5ZM4 16V14C4 13.6626 4.09028 13.3526 4.27083 13.07C4.45139 12.7872 4.6875 12.5556 4.97917 12.375C5.29861 12.1806 5.625 12.0104 5.95833 11.8646C6.29167 11.7188 6.63889 11.5903 7 11.4792V16H4Z'
                    }
                    fill={colorA}
                />
            </g>
        </svg>
    );
};

export {TechnicianSvg};
