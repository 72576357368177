import type {
    TTechnicianProfileCreateAPI,
    TTechnicianProfileUploadDocumentsAPI,
    TUserGetAPI,
    TUserUpdateEmailAPI,
    TUserUpdateGeneralSettingsAPI,
    TUserUpdatePasswordAPI,
    TUserUpdatePhoneNumberAPI,
    TUserUpdateProfileAPI,
    TUserUpdateProfilePhotoAPI,
    TUserVerifyEmailAPI,
    TUserVerifyPhoneNumberAPI
} from '@mcal/core';
import {createAsyncThunk} from '@reduxjs/toolkit';
import type {
    TAPIAsyncThunkArgs,
    TAPIAsyncThunkConfig,
    TAPIAsyncThunkReturned
} from '../../../defines/redux.types.js';
import {i18n} from '../../../modules/i18n/i18n.js';
import {notificationsActions} from '../../../slices/notifications/notifications.index.js';
import {getState} from '../../../utils/get-state/get-state.js';
import {changeLanguage} from '../../locize/languages/languages.services.js';
import * as services from './user.services.js';

const getUser = createAsyncThunk<
    TAPIAsyncThunkReturned<TUserGetAPI>,
    TAPIAsyncThunkArgs<TUserGetAPI>,
    TAPIAsyncThunkConfig<TUserGetAPI>
>('user/getUser', async (_, thunkAPI) => {
    const user = getState(thunkAPI, 'user');

    return await services
        .getUser(
            {
                query: {locale: user.remotes.userGeneralSettings.current.locale}
            },
            thunkAPI.signal
        )
        .then(async (response) => {
            if (response.error) {
                return thunkAPI.rejectWithValue(response);
            } else {
                const promises: Promise<unknown>[] = [];

                const {locale} = response.data.generalSettings;

                if (i18n.isInitialized && locale !== i18n.language) {
                    promises.push(
                        changeLanguage({key: locale}, thunkAPI.signal)
                    );
                }

                await Promise.all(promises);

                return thunkAPI.fulfillWithValue(response);
            }
        });
});

const updatePassword = createAsyncThunk<
    TAPIAsyncThunkReturned<TUserUpdatePasswordAPI>,
    TAPIAsyncThunkArgs<TUserUpdatePasswordAPI>,
    TAPIAsyncThunkConfig<TUserUpdatePasswordAPI>
>('user/updatePassword', async (args, thunkAPI) => {
    const user = getState(thunkAPI, 'user');

    return await services
        .updatePassword(
            {
                query: {
                    locale: user.remotes.userGeneralSettings.current.locale
                },
                body: {
                    data: {
                        currentPassword: args.currentPassword,
                        newPassword: args.newPassword
                    }
                }
            },
            thunkAPI.signal
        )
        .then((response) => {
            if (response.error) {
                return thunkAPI.rejectWithValue(response);
            } else {
                return thunkAPI.fulfillWithValue(response);
            }
        });
});

const updateEmail = createAsyncThunk<
    TAPIAsyncThunkReturned<TUserUpdateEmailAPI>,
    TAPIAsyncThunkArgs<TUserUpdateEmailAPI>,
    TAPIAsyncThunkConfig<TUserUpdateEmailAPI>
>('user/updateEmail', async (args, thunkAPI) => {
    const user = getState(thunkAPI, 'user');

    return await services
        .updateEmail(
            {
                query: {
                    locale: user.remotes.userGeneralSettings.current.locale
                },
                body: {
                    data: {
                        email: args.email
                    }
                }
            },
            thunkAPI.signal
        )
        .then((response) => {
            if (response.error) {
                return thunkAPI.rejectWithValue(response);
            } else {
                return thunkAPI.fulfillWithValue(response);
            }
        });
});

const verifyEmail = createAsyncThunk<
    TAPIAsyncThunkReturned<TUserVerifyEmailAPI>,
    TAPIAsyncThunkArgs<TUserVerifyEmailAPI>,
    TAPIAsyncThunkConfig<TUserVerifyEmailAPI>
>('user/verifyEmail', async (args, thunkAPI) => {
    const user = getState(thunkAPI, 'user');

    return await services
        .verifyEmail(
            {
                query: {
                    locale: user.remotes.userGeneralSettings.current.locale
                },
                body: {
                    data: {
                        email: args.email,
                        otp: args.otp
                    }
                }
            },
            thunkAPI.signal
        )
        .then((response) => {
            if (response.error) {
                return thunkAPI.rejectWithValue(response);
            } else {
                void thunkAPI.dispatch(
                    notificationsActions.send({
                        title: 'Success',
                        message: 'Email updated successfully'
                    })
                );
                return thunkAPI.fulfillWithValue(response);
            }
        });
});

const updatePhoneNumber = createAsyncThunk<
    TAPIAsyncThunkReturned<TUserUpdatePhoneNumberAPI>,
    TAPIAsyncThunkArgs<TUserUpdatePhoneNumberAPI>,
    TAPIAsyncThunkConfig<TUserUpdatePhoneNumberAPI>
>('user/updatePhoneNumber', async (args, thunkAPI) => {
    const user = getState(thunkAPI, 'user');

    return await services
        .updatePhoneNumber(
            {
                query: {
                    locale: user.remotes.userGeneralSettings.current.locale
                },
                body: {
                    data: {
                        phoneNumber: args.phoneNumber
                    }
                }
            },
            thunkAPI.signal
        )
        .then((response) => {
            if (response.error) {
                return thunkAPI.rejectWithValue(response);
            } else {
                return thunkAPI.fulfillWithValue(response);
            }
        });
});

const verifyPhoneNumber = createAsyncThunk<
    TAPIAsyncThunkReturned<TUserVerifyPhoneNumberAPI>,
    TAPIAsyncThunkArgs<TUserVerifyPhoneNumberAPI>,
    TAPIAsyncThunkConfig<TUserVerifyPhoneNumberAPI>
>('user/verifyPhoneNumber', async (args, thunkAPI) => {
    const user = getState(thunkAPI, 'user');

    return await services
        .verifyPhoneNumber(
            {
                query: {
                    locale: user.remotes.userGeneralSettings.current.locale
                },
                body: {
                    data: {
                        phoneNumber: args.phoneNumber,
                        otp: args.otp
                    }
                }
            },
            thunkAPI.signal
        )
        .then((response) => {
            if (response.error) {
                return thunkAPI.rejectWithValue(response);
            } else {
                void thunkAPI.dispatch(
                    notificationsActions.send({
                        title: 'Success',
                        message: 'Phone number updated successfully'
                    })
                );
                return thunkAPI.fulfillWithValue(response);
            }
        });
});

const updateGeneralSettings = createAsyncThunk<
    TAPIAsyncThunkReturned<TUserUpdateGeneralSettingsAPI>,
    TAPIAsyncThunkArgs<TUserUpdateGeneralSettingsAPI, {locale?: string}>,
    TAPIAsyncThunkConfig<TUserUpdateGeneralSettingsAPI>
>('user/updateGeneralSettings', async (args, thunkAPI) => {
    const user = getState(thunkAPI, 'user');

    return await services
        .updateGeneralSettings(
            {
                query: {
                    locale: user.remotes.userGeneralSettings.current.locale
                },
                body: {
                    data: args
                }
            },
            thunkAPI.signal
        )
        .then((response) => {
            if (response.error) {
                void thunkAPI.dispatch(
                    notificationsActions.send({
                        title: 'Error',
                        message: 'Failed to update user settings'
                    })
                );

                return thunkAPI.rejectWithValue(response);
            } else {
                void thunkAPI.dispatch(
                    notificationsActions.send({
                        title: 'Success',
                        message: 'User settings updated successfully'
                    })
                );

                return thunkAPI.fulfillWithValue(response);
            }
        });
});

const updateProfile = createAsyncThunk<
    TAPIAsyncThunkReturned<TUserUpdateProfileAPI>,
    TAPIAsyncThunkArgs<TUserUpdateProfileAPI>,
    TAPIAsyncThunkConfig<TUserUpdateProfileAPI>
>('user/updateProfile', async (args, thunkAPI) => {
    const user = getState(thunkAPI, 'user');

    return await services
        .updateProfile(
            {
                query: {
                    locale: user.remotes.userGeneralSettings.current.locale
                },
                body: {
                    data: args
                }
            },
            thunkAPI.signal
        )
        .then((response) => {
            if (response.error) {
                void thunkAPI.dispatch(
                    notificationsActions.send({
                        title: 'Error',
                        message: 'Failed to update user settings'
                    })
                );

                return thunkAPI.rejectWithValue(response);
            } else {
                void thunkAPI.dispatch(
                    notificationsActions.send({
                        title: 'Success',
                        message: 'User settings updated successfully'
                    })
                );
                return thunkAPI.fulfillWithValue(response);
            }
        });
});

const updateProfilePhoto = createAsyncThunk<
    TAPIAsyncThunkReturned<TUserUpdateProfilePhotoAPI>,
    TAPIAsyncThunkArgs<TUserUpdateProfilePhotoAPI>,
    TAPIAsyncThunkConfig<TUserUpdateProfilePhotoAPI>
>('user/updateProfilePhoto', async (args, thunkAPI) => {
    const user = getState(thunkAPI, 'user');

    return await services
        .updateProfilePhoto(
            {
                query: {
                    locale: user.remotes.userGeneralSettings.current.locale
                },
                body: args
            },
            thunkAPI.signal
        )
        .then((response) => {
            if (response.error) {
                return thunkAPI.rejectWithValue(response);
            } else {
                return thunkAPI.fulfillWithValue(response);
            }
        });
});

const createTechnicianProfile = createAsyncThunk<
    TAPIAsyncThunkReturned<TTechnicianProfileCreateAPI>,
    TAPIAsyncThunkArgs<TTechnicianProfileCreateAPI>,
    TAPIAsyncThunkConfig<TTechnicianProfileCreateAPI>
>('/technician-profile', async (args, thunkAPI) => {
    const user = getState(thunkAPI, 'user');

    return await services
        .createTechnicianProfile(
            {
                query: {
                    locale: user.remotes.userGeneralSettings.current.locale
                },
                body: args
            },
            thunkAPI.signal
        )
        .then((response) => {
            if (response.error) {
                return thunkAPI.rejectWithValue(response);
            } else {
                return thunkAPI.fulfillWithValue(response);
            }
        });
});

const uploadTechnicianProfileDocuments = createAsyncThunk<
    TAPIAsyncThunkReturned<TTechnicianProfileUploadDocumentsAPI>,
    TAPIAsyncThunkArgs<TTechnicianProfileUploadDocumentsAPI>,
    TAPIAsyncThunkConfig<TTechnicianProfileUploadDocumentsAPI>
>('/technician-profile', async (args, thunkAPI) => {
    const user = getState(thunkAPI, 'user');

    return await services
        .uploadTechnicianProfileDocuments(
            {
                query: {
                    locale: user.remotes.userGeneralSettings.current.locale
                },
                body: args
            },
            thunkAPI.signal
        )
        .then((response) => {
            if (response.error) {
                return thunkAPI.rejectWithValue(response);
            } else {
                return thunkAPI.fulfillWithValue(response);
            }
        });
});

export {
    createTechnicianProfile,
    getUser,
    updateEmail,
    updateGeneralSettings,
    updatePassword,
    updatePhoneNumber,
    updateProfile,
    updateProfilePhoto,
    uploadTechnicianProfileDocuments,
    verifyEmail,
    verifyPhoneNumber
};
