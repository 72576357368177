import type {
    TAPIRequestContent,
    TAPIResponse,
    TInvitesAddMemberAPI,
    TInvitesCancelAPI,
    TInvitesListEntityInvitesAPI,
    TInvitesListUserInvitesAPI,
    TInvitesRespondAPI,
    TInvitesSendAPI
} from '@mcal/core';
import {request} from '../../../utils/request/request.js';

const sendInvite = (
    {query, body}: TAPIRequestContent<TInvitesSendAPI>,
    signal: AbortSignal
): TAPIResponse<TInvitesSendAPI> => {
    return request<TInvitesSendAPI>({
        method: 'POST',
        url: '/invites/send',
        signal,
        query,
        body
    });
};

const cancelInvite = (
    {query, body}: TAPIRequestContent<TInvitesCancelAPI>,
    signal: AbortSignal
): TAPIResponse<TInvitesCancelAPI> => {
    return request<TInvitesCancelAPI>({
        method: 'DELETE',
        url: '/invites/cancel',
        signal,
        query,
        body
    });
};

const addMember = (
    {query, body}: TAPIRequestContent<TInvitesAddMemberAPI>,
    signal: AbortSignal
): TAPIResponse<TInvitesAddMemberAPI> => {
    return request<TInvitesAddMemberAPI>({
        method: 'PUT',
        url: '/invites/add-member',
        signal,
        query,
        body
    });
};

const respondInvite = (
    {query, body}: TAPIRequestContent<TInvitesRespondAPI>,
    signal: AbortSignal
): TAPIResponse<TInvitesRespondAPI> => {
    return request<TInvitesRespondAPI>({
        method: 'POST',
        url: '/invites/respond',
        signal,
        query,
        body
    });
};

const listEntityInvites = (
    {query}: TAPIRequestContent<TInvitesListEntityInvitesAPI>,
    signal: AbortSignal
): TAPIResponse<TInvitesListEntityInvitesAPI> => {
    return request<TInvitesListEntityInvitesAPI>({
        method: 'GET',
        url: '/invites/entity',
        signal,
        query
    });
};

const listUserInvites = (
    {query}: TAPIRequestContent<TInvitesListUserInvitesAPI>,
    signal: AbortSignal
): TAPIResponse<TInvitesListUserInvitesAPI> => {
    return request<TInvitesListUserInvitesAPI>({
        method: 'GET',
        url: '/invites/user',
        signal,
        query
    });
};

export {
    addMember,
    cancelInvite,
    listEntityInvites,
    listUserInvites,
    respondInvite,
    sendInvite
};
