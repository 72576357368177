import {createThemePack} from '../../utils/create-theme-pack/create-theme-pack.js';

const STANDARD_THEME_PACK = createThemePack({
    key: 'standard',
    colors: {
        primary: '#064CCD',
        secondary: '#525865',
        tertiary: '#525865',
        neutral: '#919094',
        divergent: '#919094',
        error: '#CC1906',
        warning: '#F58220',
        success: '#06CC65',
        info: '#2B8DFE'
    }
});

export {STANDARD_THEME_PACK};
