import {logger} from '@mcal/core';
import type {ChangeEvent} from 'react';
import {useCallback, useMemo, useState} from 'react';

const log = logger.withCaller('useInputControl');

type TOnChange<TValue, TElem extends HTMLElement = HTMLElement> = (
    value: string,
    details: {e: ChangeEvent<TElem> | null; raw: TValue}
) => void;

interface IUseInputControlProps<
    TValue,
    TElem extends HTMLElement = HTMLElement
> {
    value?: string | TValue;
    defaultValue?: string | TValue;
    onChange?: TOnChange<TValue, TElem> | null;
    serializer?: ((value: string | TValue) => string) | null;
    parser?: ((value: string | TValue) => TValue) | null;
    disabled?: boolean;
    readOnly?: boolean;
}

interface IUseInputControl<TValue, TElem extends HTMLElement = HTMLElement> {
    value: TValue;
    setValue: (value: TValue, e?: ChangeEvent<TElem> | null) => void;
    defaultValue: TValue;
    onChange: (e: ChangeEvent<TElem>) => void;
}

function useInputControl<TValue, TElem extends HTMLElement = HTMLElement>({
    value: externalValue = undefined,
    defaultValue: externalDefaultValue = '',
    onChange: onChangeHandler = null,
    serializer = null,
    parser = null,
    disabled = false,
    readOnly = false
}: IUseInputControlProps<TValue, TElem>): IUseInputControl<TValue, TElem> {
    const isControlled = typeof externalValue !== 'undefined';

    const defaultValue = useMemo<TValue>(() => {
        if (parser) {
            return parser(externalDefaultValue);
        } else {
            return externalDefaultValue as TValue;
        }
    }, [externalDefaultValue, parser]);

    const [raw, setRaw] = useState<TValue>(defaultValue);

    const value = useMemo<TValue>(() => {
        if (isControlled) {
            if (parser) {
                return parser(externalValue);
            } else {
                return externalValue as TValue;
            }
        } else {
            return raw;
        }
    }, [externalValue, isControlled, parser, raw]);

    const setValue = useCallback(
        (v: TValue, e: ChangeEvent<TElem> | null = null) => {
            if (disabled || readOnly) {
                return;
            }

            if (onChangeHandler) {
                if (serializer) {
                    onChangeHandler(serializer(v), {e, raw: v});
                } else {
                    if (typeof v === 'string') {
                        onChangeHandler(v, {e, raw: v as TValue});
                    } else {
                        log.error(
                            'RECEIVED NON-STRING VALUE AND NO SERIALIZER WAS PROVIDED'
                        )();
                    }
                }
            }

            if (!isControlled) {
                setRaw(v);
            }
        },
        [disabled, isControlled, onChangeHandler, readOnly, serializer]
    );

    const onChange = useCallback(
        (e: ChangeEvent<TElem>) => {
            if ('value' in e.target) {
                if (parser) {
                    setValue(parser(e.target.value as string | TValue), e);
                } else {
                    setValue(e.target.value as TValue, e);
                }
            }
        },
        [parser, setValue]
    );

    return useMemo(() => {
        return {value, setValue, defaultValue, onChange};
    }, [defaultValue, onChange, setValue, value]);
}

export type {IUseInputControlProps, TOnChange};
export {useInputControl};
