import type {FC, ReactNode} from 'react';
import {I18nextProvider} from 'react-i18next';
import {i18n} from '../../modules/i18n/i18n.js';

interface II18nStoreProps {
    children: ReactNode;
}

const I18nStore: FC<II18nStoreProps> = ({children}) => {
    return <I18nextProvider i18n={i18n}>{children}</I18nextProvider>;
};

export type {II18nStoreProps};
export {I18nStore};
