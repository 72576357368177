import {getColorPalette} from '@mcal/core-react';
import {styled} from '../../utils/styled/styled.js';
import {Button} from '../button/button.js';
import {Label} from '../label/label.js';

const StyledRootSidebarRight = styled('div')({
    height: '100%',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column'
});

const StyledContent = styled('div')(({theme}) => ({
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    justifyContent: 'space-between',
    padding: '24px 32px',
    gap: '20px',
    borderTop: `1px solid ${theme.palette.neutral.divide}`
}));

interface IStyledSelectThemeProps {
    active?: boolean;
}

const StyledSelectTheme = styled('div')<IStyledSelectThemeProps>(
    ({theme, active}) => ({
        outline: active ? '2px solid blue' : '',
        height: '40px',
        overflow: 'hidden',
        borderRadius: theme.shape.corner.rounded.c0.borderRadius
    })
);

const StyledSelectThemeText = styled(Label)<IStyledSelectThemeProps>(
    ({theme, active}) => ({
        color: active
            ? theme.palette.neutral.base
            : theme.palette.neutral.accent,
        margin: 0,
        marginTop: theme.size.font.s2
    })
);

const StyledLoggedOutButton = styled(Button)(({theme}) => ({
    padding: '0',
    fontSize: '16px',
    color: getColorPalette(theme, 'info', 'high').base,
    ':hover,:focus,:active': {
        background: 'none'
    }
}));

export {
    StyledContent,
    StyledLoggedOutButton,
    StyledRootSidebarRight,
    StyledSelectTheme,
    StyledSelectThemeText
};
