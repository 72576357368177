import type {PayloadAction} from '@reduxjs/toolkit';
import {createSlice} from '@reduxjs/toolkit';
import {
    changeLanguage,
    getLanguages
} from '../../services/locize/languages/languages.thunks.js';
import {getAsyncThunkMatcher} from '../../utils/get-async-thunk-matcher/get-async-thunk-matcher.js';
import {getStatusReducer} from '../../utils/get-status-reducer/get-status-reducer.js';
import {resolveStatus} from '../../utils/resolve-status/resolve-status.js';
import {getStatusMap, initialState} from './app.state.js';
import {loadApp} from './app.thunks.js';

const appSlice = createSlice({
    name: 'app',
    initialState,
    reducers: {
        setLoadFontsStatus(state, action: PayloadAction<'LOADING' | 'IDLE'>) {
            state.sources.loadFonts.status = action.payload;

            state.status = resolveStatus(state.sources, getStatusMap(state));
        },
        navigationHappened: () => {}
    },
    extraReducers: (builder) => {
        builder.addCase(loadApp.pending, (state, {meta}) => {
            state.sources.loadApp.status = 'LOADING';

            if (meta.arg.fontsConfig) {
                state.config.fonts = {
                    ...state.config.fonts,
                    ...meta.arg.fontsConfig
                };
            }
        });
        builder.addCase(loadApp.fulfilled, (state) => {
            state.sources.loadApp.status = 'IDLE';
        });
        builder.addCase(loadApp.rejected, (state) => {
            state.sources.loadApp.status = 'FAILED';
        });

        builder.addCase(getLanguages.fulfilled, (state, {payload}) => {
            state.languages = payload;
        });

        builder.addCase(changeLanguage.pending, (state) => {
            state.sources.changeLanguage.status = 'LOADING';
        });
        builder.addCase(changeLanguage.fulfilled, (state) => {
            state.sources.changeLanguage.status = 'IDLE';
        });
        builder.addCase(changeLanguage.rejected, (state) => {
            state.sources.changeLanguage.status = 'FAILED';
        });

        builder.addMatcher(
            getAsyncThunkMatcher([loadApp, getLanguages, changeLanguage]),

            getStatusReducer(getStatusMap)
        );
    }
});

export {appSlice};
