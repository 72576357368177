import type {PayloadAction} from '@reduxjs/toolkit';
import {createAction, createSlice} from '@reduxjs/toolkit';
import {initialState} from './sockets.state.js';

const subscribeToElevatorStatus = createAction(
    'sockets/subscribeToElevatorStatus',
    (id: string) => {
        return {
            payload: id
        };
    }
);

const subscribeToSiteStatus = createAction(
    'sockets/subscribeToSiteStatus',
    (id: string) => {
        return {
            payload: id
        };
    }
);

const socketsSlice = createSlice({
    name: 'sockets',
    initialState,
    reducers: {
        resetSlice() {
            return initialState;
        },
        addElevatorSubscription(state, action: PayloadAction<string>) {
            if (
                state.remotes.elevatorStatusSubscriptions.current.includes(
                    action.payload
                )
            ) {
                return;
            }

            state.remotes.elevatorStatusSubscriptions.current.push(
                action.payload
            );
        },
        addSiteSubscription(state, action: PayloadAction<string>) {
            if (
                state.remotes.siteStatusSubscriptions.current.includes(
                    action.payload
                )
            ) {
                return;
            }

            state.remotes.siteStatusSubscriptions.current.push(action.payload);
        }
    }
});

export {socketsSlice, subscribeToElevatorStatus, subscribeToSiteStatus};
