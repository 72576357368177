import type {TNestedPartial} from '../../../defines/common/utility.types.js';
import {assign} from '../assign/assign.js';
import {forEach} from '../for-each/for-each.js';

type TR = Record<string, unknown>;

function deepMerge<T extends object>(
    ...sources: [T, ...TNestedPartial<T>[]]
): T {
    const segments: TR = {};

    for (const source of sources) {
        forEach(source, ({token, value, skip, isIterable}) => {
            if (Array.isArray(value)) {
                segments[token] = value;
                skip();
            } else if (typeof value !== 'object' || value === null) {
                segments[token] = value;
            } else {
                if (!isIterable) {
                    segments[token] = {};
                }
            }
        });
    }

    const output: TR = {};

    Object.entries(segments).forEach(([token, value]) => {
        assign(output, token, value);
    });

    return output as T;
}

export {deepMerge};
