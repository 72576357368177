import {useTheme} from '@mcal/core-react';
import type {FC} from 'react';
import {useMemo} from 'react';

interface IColors {
    colorA: string;
}

interface INormalSvgProps {
    fill: string;
}

const NormalSvg: FC<INormalSvgProps> = ({fill}) => {
    const {type} = useTheme();

    const {colorA} = useMemo<IColors>(() => {
        if (type === 'dark') {
            return {
                colorA: '#37D683'
            };
        } else {
            return {
                colorA: '#0A8245'
            };
        }
    }, [type]);
    return (
        <svg
            xmlns={'http://www.w3.org/2000/svg'}
            width={'6px'}
            height={'6px'}
            viewBox={'0 0 6 6'}
            fill={fill}
        >
            <circle cx={'3'} cy={'3'} r={'3'} fill={fill || colorA} />
        </svg>
    );
};

export {NormalSvg};
