import {createSelector} from '@reduxjs/toolkit';
import type {IPartialState} from '../../defines/redux.types.js';
import type {
    ISessionSliceState,
    TSessionsSliceStatus
} from './session.state.js';

const sessionSelector = (state: IPartialState): ISessionSliceState => {
    if (!state.session) {
        throw new Error('Session slice is not available.');
    }

    return state.session;
};

const selectSessionStatus = createSelector(
    [sessionSelector],
    (session): TSessionsSliceStatus => {
        return session.status;
    }
);

const selectSessionExpiration = createSelector(
    [sessionSelector],
    (session): number | null => {
        return session.exp;
    }
);

const selectSkipOnboarding = createSelector(
    [sessionSelector],
    (session): boolean | null => {
        return session.skipOnboarding;
    }
);

export {selectSessionExpiration, selectSessionStatus, selectSkipOnboarding};
