import {useTheme} from '@mcal/core-react';
import type {FC} from 'react';
import {useMemo} from 'react';

interface IColors {
    colorA: string;
}

interface IUploadSvgProps {
    height?: number | string;
    width?: number | string;
    fill?: string;
}

const UploadSvg: FC<IUploadSvgProps> = ({fill, ...props}) => {
    const {type} = useTheme();

    const {colorA} = useMemo<IColors>(() => {
        if (type === 'dark') {
            return {
                colorA: '#999DA5'
            };
        } else {
            return {
                colorA: '#555C69'
            };
        }
    }, [type]);

    return (
        <svg
            xmlns={'http://www.w3.org/2000/svg'}
            width={'20'}
            height={'20'}
            viewBox={'0 0 20 20'}
            fill={'none'}
            {...props}
        >
            <mask
                id={'mask0_251_992'}
                maskUnits={'userSpaceOnUse'}
                x={'0'}
                y={'0'}
                width={'20'}
                height={'20'}
            >
                <rect width={'20'} height={'20'} fill={'#D9D9D9'} />
            </mask>
            <g mask={'url(#mask0_251_992)'}>
                <path
                    d={
                        'M9.25 13V5.875L7.0625 8.0625L6 7L10 3L14 7L12.9375 8.0625L10.75 5.875V13H9.25ZM5.4941 16C5.08137 16 4.72917 15.8531 4.4375 15.5594C4.14583 15.2656 4 14.9125 4 14.5V13H5.5V14.5H14.5V13H16V14.5C16 14.9125 15.853 15.2656 15.5591 15.5594C15.2652 15.8531 14.9119 16 14.4992 16H5.4941Z'
                    }
                    fill={colorA}
                />
            </g>
        </svg>
    );
};

export {UploadSvg};
