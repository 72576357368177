import {useTheme} from '@mcal/core-react';
import type {FC} from 'react';
import {useMemo} from 'react';

interface IColors {
    colorA: string;
    colorB: string;
}

interface IGraphicSvgProps {
    height?: number | string;
    width?: number | string;
    fill?: string;
}

const GraphicSvg: FC<IGraphicSvgProps> = ({fill, ...props}) => {
    const {type} = useTheme();

    const {colorA, colorB} = useMemo<IColors>(() => {
        if (type === 'dark') {
            return {
                colorA: '#D9D9D9',
                colorB: '#37D683'
            };
        } else {
            return {
                colorA: '#D9D9D9',
                colorB: '#0A8245'
            };
        }
    }, [type]);
    return (
        <svg
            xmlns={'http://www.w3.org/2000/svg'}
            width={'20'}
            height={'20'}
            viewBox={'0 0 20 20'}
            fill={fill}
            {...props}
        >
            <mask
                id={'mask0_1108_312'}
                maskUnits={'userSpaceOnUse'}
                x={'0'}
                y={'0'}
                width={'20'}
                height={'20'}
            >
                <rect
                    y={'20'}
                    width={'20'}
                    height={'20'}
                    transform={'rotate(-90 0 20)'}
                    fill={colorA}
                />
            </mask>
            <g>
                <path
                    d={
                        'M15 13.875L5 13.875L5 12.375L15 12.375L15 13.875ZM18 10.75L2 10.75L2 9.25L18 9.25L18 10.75ZM12 17L8 17L8 15.5L12 15.5L12 17ZM15 7.625L5 7.625L5 6.125L15 6.125L15 7.625ZM12 4.5L8 4.5L8 3L12 3L12 4.5Z'
                    }
                    fill={fill || colorB}
                />
            </g>
        </svg>
    );
};

export {GraphicSvg};
