import type {TTestIDs} from '@mcal/core-react';
import {cn} from '@mcal/core-react';
import type {FC} from 'react';
import {createTestIDs} from '../../../../dev/index.js';
import {css} from '../../../../utils/css/css.js';
import {Icon} from '../../../icon/icon.js';
import {StyledContainer, StyledLabel} from '../common/common.styles.js';
import {StyledRoot} from './expand-item.styles.js';

const ownTestIDs = createTestIDs('ExpandItem', ['root']);

const actions = {
    collapse: 'rotate(180deg)',
    expand: 'rotate(0)'
};

interface IExpandItemProps {
    expanded?: boolean;
    onClick: () => void;
    label: string;
    testIDs?: TTestIDs<typeof ownTestIDs>;
    className?: string;
    classes?: {
        root?: string;
    };
}

const ExpandItem: FC<IExpandItemProps> = ({
    label,
    expanded = false,
    testIDs = ownTestIDs,
    className = 'string',
    classes = {},
    onClick
}) => {
    return (
        <StyledRoot
            data-testid={testIDs.root}
            className={cn(className, classes.root)}
            onClick={onClick}
        >
            <StyledContainer active={false}>
                <Icon
                    icon={'doubleChevronSvg'}
                    className={css({
                        transform: expanded ? actions.expand : actions.collapse
                    })}
                />

                <StyledLabel expanded={expanded} active={false} type={'small'}>
                    {label}
                </StyledLabel>
            </StyledContainer>
        </StyledRoot>
    );
};

export type {IExpandItemProps};
export {ExpandItem, ownTestIDs as testIDs};
