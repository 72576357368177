import type {
    TDSColorScales,
    TDSColorTone,
    TPaletteAccent,
    TPaletteAccentGroup,
    TThemeType
} from '../../defines/theme.types.js';

type TMap = {
    [k in TThemeType]: TPaletteAccentGroup<TDSColorTone>;
};

const map: TMap = {
    light: {
        high: {
            base: '40',
            onBase: '100',
            overlay: '100',
            state: {
                enabled: {
                    base: '30',
                    onBase: '100'
                },
                disabled: {
                    base: '50',
                    onBase: '90'
                },
                hovered: {
                    base: '30',
                    onBase: '100'
                },
                focused: {
                    base: '30',
                    onBase: '100'
                },
                activated: {
                    base: '30',
                    onBase: '100'
                },
                pressed: {
                    base: '30',
                    onBase: '100'
                },
                dragged: {
                    base: '30',
                    onBase: '100'
                }
            }
        },
        low: {
            base: '90',
            onBase: '10',
            overlay: '10',
            state: {
                enabled: {
                    base: '80',
                    onBase: '10'
                },
                disabled: {
                    base: '95',
                    onBase: '20'
                },
                hovered: {
                    base: '80',
                    onBase: '10'
                },
                focused: {
                    base: '80',
                    onBase: '10'
                },
                activated: {
                    base: '80',
                    onBase: '10'
                },
                pressed: {
                    base: '80',
                    onBase: '10'
                },
                dragged: {
                    base: '80',
                    onBase: '10'
                }
            }
        }
    },
    dark: {
        high: {
            base: '80',
            onBase: '20',
            overlay: '20',
            state: {
                enabled: {
                    base: '90',
                    onBase: '20'
                },
                disabled: {
                    base: '70',
                    onBase: '30'
                },
                hovered: {
                    base: '90',
                    onBase: '20'
                },
                focused: {
                    base: '90',
                    onBase: '20'
                },
                activated: {
                    base: '90',
                    onBase: '20'
                },
                pressed: {
                    base: '90',
                    onBase: '20'
                },
                dragged: {
                    base: '90',
                    onBase: '20'
                }
            }
        },
        low: {
            base: '30',
            onBase: '90',
            overlay: '90',
            state: {
                enabled: {
                    base: '40',
                    onBase: '90'
                },
                disabled: {
                    base: '20',
                    onBase: '80'
                },
                hovered: {
                    base: '40',
                    onBase: '90'
                },
                focused: {
                    base: '40',
                    onBase: '90'
                },
                activated: {
                    base: '40',
                    onBase: '90'
                },
                pressed: {
                    base: '40',
                    onBase: '90'
                },
                dragged: {
                    base: '40',
                    onBase: '90'
                }
            }
        }
    }
};

function createAccent(
    scales: TDSColorScales,
    accent: TPaletteAccent,
    type: TThemeType
): TPaletteAccentGroup {
    const aTokens = scales[accent];
    const nTokens = scales.neutral;

    const {high, low} = map[type];

    return {
        high: {
            base: aTokens[high.base],
            onBase: aTokens[high.onBase],
            overlay: aTokens[high.overlay],
            state: {
                enabled: {
                    base: aTokens[high.state.enabled.base],
                    onBase: aTokens[high.state.enabled.onBase]
                },
                disabled: {
                    base: nTokens[high.state.disabled.base],
                    onBase: nTokens[high.state.disabled.onBase]
                },
                hovered: {
                    base: aTokens[high.state.hovered.base],
                    onBase: aTokens[high.state.hovered.onBase]
                },
                focused: {
                    base: aTokens[high.state.focused.base],
                    onBase: aTokens[high.state.focused.onBase]
                },
                activated: {
                    base: aTokens[high.state.activated.base],
                    onBase: aTokens[high.state.activated.onBase]
                },
                pressed: {
                    base: aTokens[high.state.pressed.base],
                    onBase: aTokens[high.state.pressed.onBase]
                },
                dragged: {
                    base: aTokens[high.state.dragged.base],
                    onBase: aTokens[high.state.dragged.onBase]
                }
            }
        },
        low: {
            base: aTokens[low.base],
            onBase: aTokens[low.onBase],
            overlay: aTokens[low.overlay],
            state: {
                enabled: {
                    base: aTokens[low.state.enabled.base],
                    onBase: aTokens[low.state.enabled.onBase]
                },
                disabled: {
                    base: nTokens[low.state.disabled.base],
                    onBase: nTokens[low.state.disabled.onBase]
                },
                hovered: {
                    base: aTokens[low.state.hovered.base],
                    onBase: aTokens[low.state.hovered.onBase]
                },
                focused: {
                    base: aTokens[low.state.focused.base],
                    onBase: aTokens[low.state.focused.onBase]
                },
                activated: {
                    base: aTokens[low.state.activated.base],
                    onBase: aTokens[low.state.activated.onBase]
                },
                pressed: {
                    base: aTokens[low.state.pressed.base],
                    onBase: aTokens[low.state.pressed.onBase]
                },
                dragged: {
                    base: aTokens[low.state.dragged.base],
                    onBase: aTokens[low.state.dragged.onBase]
                }
            }
        }
    };
}

export {createAccent};
