import type {TAlignment} from '@mcal/core-react';
import {animated} from '@react-spring/web';
import {styled} from '../../utils/styled/styled.js';

const StyledRoot = styled('div')(({theme}) => ({
    position: 'relative',
    top: 0,
    left: 0,
    height: '100%',
    width: '100%',
    zIndex: theme.depth.local.z3
}));

type TBackgroundSize = 'cover' | 'contain';

type TBackgroundClip = 'border-box' | 'padding-box' | 'content-box';

type TBackgroundRepeat =
    | 'repeat-x'
    | 'repeat-y'
    | 'repeat'
    | 'space'
    | 'round'
    | 'no-repeat';

interface IStyledImageProps {
    alignment: TAlignment;
    size: TBackgroundSize;
    repeat: TBackgroundRepeat;
    clip: TBackgroundClip;
}

const StyledImage = styled(animated.div)<IStyledImageProps>(
    ({theme, alignment, repeat, size, clip}) => ({
        position: 'absolute',
        top: 0,
        left: 0,
        height: '100%',
        width: '100%',
        backgroundPosition: alignment.replace('-', ' '),
        backgroundRepeat: repeat,
        backgroundSize: size,
        backgroundClip: clip,
        userSelect: 'none',
        willChange: 'opacity',
        zIndex: theme.depth.local.z4
    })
);

const StyledLoading = styled(animated.div)(({theme}) => ({
    position: 'absolute',
    display: 'inline-block',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    willChange: 'opacity',
    zIndex: theme.depth.local.z5
}));

export type {
    IStyledImageProps,
    TBackgroundClip,
    TBackgroundRepeat,
    TBackgroundSize
};
export {StyledImage, StyledLoading, StyledRoot};
