import {useTheme} from '@mcal/core-react';
import type {FC} from 'react';
import {useMemo} from 'react';

interface IColors {
    colorA: string;
}

interface IApartmentSvgProps {
    height?: number | string;
    width?: number | string;
    fill?: string;
}

const ApartmentSvg: FC<IApartmentSvgProps> = ({fill, ...props}) => {
    const {type} = useTheme();

    const {colorA} = useMemo<IColors>(() => {
        if (type === 'dark') {
            return {
                colorA: '#999DA5'
            };
        } else {
            return {
                colorA: '#555C69'
            };
        }
    }, [type]);
    return (
        <svg
            xmlns={'http://www.w3.org/2000/svg'}
            width={'20'}
            height={'20'}
            viewBox={'0 0 20 20'}
            fill={fill}
            {...props}
        >
            <path
                d={
                    'M4.4945 17C4.0815 17 3.72917 16.8531 3.4375 16.5594C3.14583 16.2656 3 15.9125 3 15.5V7.5C3 7.0875 3.14664 6.73438 3.43992 6.44063C3.73319 6.14688 4.08575 6 4.49758 6H5.99517V4.5C5.99517 4.0875 6.14181 3.73438 6.43508 3.44063C6.72836 3.14688 7.08144 3 7.49433 3H11.9986C12.4115 3 12.7649 3.14688 13.059 3.44063C13.353 3.73438 13.5 4.0875 13.5 4.5V9H15.5C15.9125 9 16.2656 9.14688 16.5594 9.44063C16.8531 9.73438 17 10.0875 17 10.5V15.5C17 15.9125 16.8531 16.2656 16.5594 16.5594C16.2656 16.8531 15.9125 17 15.5 17H11V14H9V17H4.4945ZM4.5 15.5H6V14H4.5V15.5ZM4.5 12.25H6V10.75H4.5V12.25ZM4.5 9H6V7.5H4.5V9ZM7.5 12.25H9V10.75H7.5V12.25ZM7.5 9H9V7.5H7.5V9ZM7.5 6H9V4.5H7.5V6ZM10.5 12.25H12V10.75H10.5V12.25ZM10.5 9H12V7.5H10.5V9ZM10.5 6H12V4.5H10.5V6ZM14 15.5H15.5V14H14V15.5ZM14 12.25H15.5V10.75H14V12.25Z'
                }
                fill={fill || colorA}
            />
        </svg>
    );
};

export {ApartmentSvg};
