interface IMessageDescriptor {
    CODE: string;
}

interface IMessages {
    NORMAL: {
        GENERAL: Record<string, IMessageDescriptor>;
        SERVER: Record<string, IMessageDescriptor>;
        CLIENT: Record<string, IMessageDescriptor>;
    };
    ERROR: {
        GENERAL: Record<string, IMessageDescriptor>;
        SERVER: Record<string, IMessageDescriptor>;
        CLIENT: Record<string, IMessageDescriptor>;
    };
}

const MESSAGES = {
    NORMAL: {
        GENERAL: {},
        SERVER: {
            OK: {
                CODE: 'NS001'
            },
            LOGGED_OUT: {
                CODE: 'NS002'
            },
            LOGGED_IN: {
                CODE: 'NS003'
            },
            LOGGED_IN_CHALLENGE: {
                CODE: 'NS004'
            },
            FORGOT_PASSWORD_OTP_SENT: {
                CODE: 'NS005'
            },
            FORGOT_PASSWORD_UPDATED: {
                CODE: 'NS006'
            },
            SIGN_UP_START: {
                CODE: 'NS007'
            },
            MFA_ASSOCIATED: {
                CODE: 'NS008'
            },
            MFA_CREATED: {
                CODE: 'NS009'
            },
            MFA_TOGGLED: {
                CODE: 'NS010'
            },
            TOKEN_REFRESHED: {
                CODE: 'NS011'
            },
            OTP_EMAIL_SENT: {
                CODE: 'NS012'
            },
            OTP_SMS_SENT: {
                CODE: 'NS013'
            },
            USER_PASSWORD_UPDATED: {
                CODE: 'NS014'
            },
            USER_EMAIL_VERIFIED: {
                CODE: 'NS015'
            },
            USER_PASSWORD_RESET: {
                CODE: 'NS016'
            },
            USER_PROFILE_UPDATED: {
                CODE: 'NS017'
            },
            USER_SETTINGS_UPDATED: {
                CODE: 'NS018'
            },
            USER_PHONE_NUMBER_VERIFIED: {
                CODE: 'NS019'
            },
            USER_PHOTO_UPDATED: {
                CODE: 'NS020'
            },
            SIGN_UP_VERIFIED: {
                CODE: 'NS021'
            },
            SIGN_UP_COMPLETED: {
                CODE: 'NS022'
            }
        },
        CLIENT: {
            OK: {
                CODE: 'NC001'
            },
            PAGINATION_LIMIT_REACHED: {
                CODE: 'NC002'
            }
        }
    },
    ERROR: {
        GENERAL: {
            UNKNOWN: {
                CODE: 'EG001'
            }
        },
        SERVER: {
            INVALID_QUERY: {
                CODE: 'ES001'
            },
            EMAIL_EXISTS: {
                CODE: 'ES002'
            },
            PHONE_NUMBER_EXISTS: {
                CODE: 'ES003'
            },
            USER_NOT_FOUND: {
                CODE: 'ES004'
            },
            EMAIL_NOT_VERIFIED: {
                CODE: 'ES005'
            },
            PHONE_NUMBER_NOT_VERIFIED: {
                CODE: 'ES006'
            },
            INVALID_CREDENTIALS: {
                CODE: 'ES007'
            },
            FORBIDDEN: {
                CODE: 'ES008'
            },
            UNAUTHORIZED: {
                CODE: 'ES009'
            },
            NOT_IMPLEMENTED: {
                CODE: 'ES010'
            },
            INTERNAL_ERROR: {
                CODE: 'ES011'
            },
            LOG_IN_FAILED: {
                CODE: 'ES012'
            },
            OTP_ALREADY_SENT: {
                CODE: 'ES013'
            },
            INVALID_OTP: {
                CODE: 'ES014'
            },
            INVALID_PASSWORD: {
                CODE: 'ES015'
            },
            BAD_REQUEST: {
                CODE: 'ES016'
            },
            INVALID_EMAIL: {
                CODE: 'ES017'
            },
            INVALID_PHONE_NUMBER: {
                CODE: 'ES018'
            },
            INVALID_DATETIME: {
                CODE: 'ES019'
            },
            TOKEN_EXPIRED: {
                CODE: 'ES020'
            },
            NO_TOKEN_PROVIDED: {
                CODE: 'ES021'
            },
            INVALID_TOKEN_PROVIDED: {
                CODE: 'ES022'
            },
            SITE_NOT_FOUND: {
                CODE: 'ES0023'
            },
            ELEVATOR_NOT_FOUND: {
                CODE: 'ES0024'
            },
            SERVICE_COMPANY_NOT_FOUND: {
                CODE: 'ES0025'
            },
            MFA_ALREADY_CREATED: {
                CODE: 'NS026'
            },
            MFA_ASSOCIATION_FAILED: {
                CODE: 'NS027'
            },
            ELEVATOR_GROUP_NAME_EXISTS: {
                CODE: 'ES028'
            },
            MFA_NOT_CONFIGURED: {
                CODE: 'NS029'
            },
            PHOTO_NOT_FOUND: {
                CODE: 'ES030'
            },
            MFA_NOT_CREATED: {
                CODE: 'ES031'
            },
            ELEVATOR_STATE_NOT_FOUND: {
                CODE: 'ES0032'
            },
            ELEVATOR_GROUP_STATE_NOT_FOUND: {
                CODE: 'ES0033'
            },
            SITE_STATE_NOT_FOUND: {
                CODE: 'ES0034'
            },
            SERVICE_COMPANY_STATE_NOT_FOUND: {
                CODE: 'ES0035'
            },
            ELEVATOR_GROUP_NOT_FOUND: {
                CODE: 'ES0036'
            },
            ELEVATOR_TECHNICAL_PARAMETERS_NOT_FOUND: {
                CODE: 'ES0037'
            },
            INVITE_NOT_FOUND: {
                CODE: 'ES0038'
            },
            SITE_NAME_EXISTS: {
                CODE: 'ES0039'
            },
            SERVICE_COMPANY_EXISTS: {
                CODE: 'ES0040'
            },
            SITE_GENERAL_SETTINGS_NOT_FOUND: {
                CODE: 'ES0041'
            },
            SITE_PROFILE_NOT_FOUND: {
                CODE: 'ES0042'
            },
            INVALID_DESTINATION_LAYOUT: {
                CODE: 'ES0043'
            },
            INVALID_TECHNICAL_PARAMETERS_LAYOUT: {
                CODE: 'ES0044'
            },
            ELEVATOR_DEVICE_NOT_FOUND: {
                CODE: 'ES0045'
            },
            ELEVATOR_PROFILE_NOT_FOUND: {
                CODE: 'ES0046'
            },
            ELEVATOR_STOPS_NOT_FOUND: {
                CODE: 'ES0047'
            },
            ELEVATOR_DESTINATIONS_NOT_FOUND: {
                CODE: 'ES0048'
            },
            ELEVATOR_DEVICES_NOT_FOUND: {
                CODE: 'ES0049'
            },
            SITE_ACTIVITIES_NOT_FOUND: {
                CODE: 'ES0050'
            },
            SEARCH_TERM_TOO_SHORT: {
                CODE: 'ES0051'
            },
            KPI_NOT_FOUND: {
                CODE: 'ES0052'
            },
            ELEVATOR_CONFIGURATION_NOT_FOUND: {
                CODE: 'ES0053'
            },
            ELEVATOR_REMOTE_CONTROL_DISABLED: {
                CODE: 'ES0054'
            },
            ELEVATOR_EMERGENCY_NOT_FOUND: {
                CODE: 'ES0055'
            },
            ELEAVATOR_GROUPS_NOT_FOUND: {
                CODE: 'ES0056'
            },
            ELEAVATOR_ANOMALIES_NOT_FOUND: {
                CODE: 'ES0057'
            },
            ELEAVATOR_ISSUES_NOT_FOUND: {
                CODE: 'ES0058'
            },
            ELEAVATOR_TRIPS_NOT_FOUND: {
                CODE: 'ES0059'
            },
            ELEAVATOR_GENERAL_SETTINGS_NOT_FOUND: {
                CODE: 'ES0060'
            },
            ELEAVATOR_DEVICE_SHADOW_NOT_FOUND: {
                CODE: 'ES0061'
            },
            ELEAVATORS_NOT_FOUND: {
                CODE: 'ES0062'
            },
            ELEVATOR_SYSTEM_CONFIG_NOT_FOUND: {
                CODE: 'ES0063'
            },
            ELEVATOR_DEVICE_CONFIG_NOT_FOUND: {
                CODE: 'ES0064'
            },
            ELEVATOR_SENSORS_NOT_FOUND: {
                CODE: 'ES0065'
            },
            SERVICE_COMPANY_DOCUMENT_NOT_FOUND: {
                CODE: 'ES0066'
            },
            TECHNICIAN_DOCUMENT_NOT_FOUND: {
                CODE: 'ES0067'
            }
        },
        CLIENT: {
            ALREADY_AUTHENTICATED: {
                CODE: 'EC001'
            },
            NOT_AUTHENTICATED: {
                CODE: 'EC002'
            },
            INTERNAL_ERROR: {
                CODE: 'EC003'
            }
        }
    }
} as const satisfies IMessages;

export type {IMessageDescriptor, IMessages};
export {MESSAGES};
