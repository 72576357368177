import type {
    IAPI,
    TAPIResponseError,
    TAxiosProgressEvent,
    TBinaryPayload,
    TRequestMethod,
    TRequestStatus
} from '@mcal/core';
import {ArrayUtils, URLUtils, logger} from '@mcal/core';
import {request} from '../../utils/request/request.js';
import {AxiosError} from '../axios/axios.js';

const log = logger.withCaller('ObjectURLs');

interface IObjectURLImplementation {
    create: (obj: Blob) => string;
    revoke: (url: string) => void;
    prefix: string;
}

interface ILease {
    id: string;
    cb: TCb | null;
}

interface IResourceMeta {
    baseURL?: string;
    locale: string;
}

interface IBaseOptions extends IResourceMeta {
    withCredentials?: boolean;
}

interface IRequestOptions extends IBaseOptions {
    signal?: AbortSignal;
    onDownloadProgress?: (e: TAxiosProgressEvent) => void;
}

interface ISubscriptionOptions extends IBaseOptions {
    progress?: boolean;
    invariant?: boolean;
}

interface IURLDescriptor {
    value: string;
    leases: ILease[];
    keep: boolean;
}

interface IRequestDescriptor {
    result: Promise<string | null>;
    leases: ILease[];
    abort: (keep?: boolean) => boolean;
}

type TAPIBlob = IAPI<TRequestMethod, string, void, TBinaryPayload>;

interface IGlobalStats {
    refs: number;
    requests: number;
    keys: number;
}

interface IURLStats {
    leases: number;
    keys: number;
}

interface ISnapshot {
    objectURL: string | null;
    status: TRequestStatus;
    progress: number | null;
}

type TCb = () => void;
type TSubscribe = (cb: TCb) => TCb;
type TGetSnapshot = () => ISnapshot;

interface IStoreAPI {
    subscribe: TSubscribe;
    getSnapshot: TGetSnapshot;
    identifier: string;
}

class ObjectURLs {
    private implementation: IObjectURLImplementation | null;
    private urls: Map<string, IURLDescriptor>;
    private requests: Map<string, IRequestDescriptor>;
    private keys: Map<string, string[]>;

    constructor(implementation?: IObjectURLImplementation) {
        this.implementation = implementation || null;
        this.urls = new Map<string, IURLDescriptor>();
        this.requests = new Map<string, IRequestDescriptor>();
        this.keys = new Map<string, string[]>();
    }

    setImplementation(implementation: IObjectURLImplementation): void {
        log.debug('SETTING IMPLEMENTATION')();

        this.implementation = implementation;
    }

    private create(
        identifier: string,
        obj: Blob,
        keep: boolean
    ): IURLDescriptor {
        const match = this.urls.get(identifier);

        if (match) {
            log.debug(
                `OBJECT WITH IDENTIFIER ${identifier} ALREADY EXISTS, RETURNING URL`
            )();

            return match;
        }

        let url: string;

        if (this.implementation) {
            url = this.implementation.create(obj);
        } else {
            log.warn(
                'NO IMPLEMENTATION PROVIDED, USING identifier AS FALLBACK URL'
            )();

            url = identifier;
        }

        log.debug(
            `CREATING NEW OBJECT WITH IDENTIFIER ${identifier}. URL: ${url}`
        )();

        const keys = this.keys.get(url) || [];

        this.keys.set(url, [...keys, identifier]);

        const descriptor: IURLDescriptor = {
            value: url,
            leases: [],
            keep
        };

        this.urls.set(identifier, descriptor);
        this.urls.set(url, descriptor);

        return descriptor;
    }

    private add(identifier: string, url: string): IURLDescriptor {
        const match = this.urls.get(url);

        if (match) {
            log.debug(
                `OBJECT WITH IDENTIFIER ${identifier} ALREADY EXISTS, RETURNING DESCRIPTOR`
            )();

            return match;
        }

        log.debug(
            `ADDING NEW OBJECT WITH IDENTIFIER ${identifier}. URL: ${url}`
        )();

        const keys = this.keys.get(url) || [];

        this.keys.set(url, [...keys, identifier]);

        const descriptor: IURLDescriptor = {
            value: url,
            leases: [],
            keep: true
        };

        this.urls.set(identifier, descriptor);
        this.urls.set(url, descriptor);

        return descriptor;
    }

    alias(identifier: string, alias: string): string | null {
        const url = this.urls.get(identifier);

        if (!url) {
            log.debug(
                `CANNOT ALIAS OBJECT WITH IDENTIFIER ${identifier}, URL NOT FOUND`
            )();

            return null;
        } else {
            log.debug(
                `ALIASING OBJECT WITH IDENTIFIER ${identifier} TO ${alias}. URL: ${url.value}`
            )();

            const keys = this.keys.get(url.value) || [];

            this.keys.set(url.value, [...keys, alias]);

            this.urls.set(alias, url);

            return url.value;
        }
    }

    revoke(identifier: string): boolean {
        const url = this.urls.get(identifier);

        if (!url) {
            log.debug(
                `CANNOT REVOKE OBJECT WITH IDENTIFIER ${identifier}, URL NOT FOUND`
            )();

            return false;
        } else {
            log.debug(
                `REVOKING OBJECT WITH IDENTIFIER ${identifier}. URL: ${url.value}`
            )();

            if (this.implementation) {
                this.implementation.revoke(url.value);
            } else {
                log.warn(
                    'NO IMPLEMENTATION PROVIDED, SKIPPING EXTERNAL REVOKE'
                )();
            }

            (this.keys.get(url.value) || []).forEach((key) => {
                this.urls.delete(key);
            });

            this.keys.delete(url.value);

            this.urls.delete(url.value);

            url.leases.forEach((lease) => {
                if (lease.cb) {
                    lease.cb();
                }
            });

            return true;
        }
    }

    get(identifier: string): string | null {
        log.debug(`GETTING URL FOR OBJECT WITH IDENTIFIER ${identifier}`)();

        const match = this.urls.get(identifier);

        if (match) {
            return match.value;
        } else {
            return null;
        }
    }

    set(identifier: string, obj: Blob, keep: boolean = false): string {
        const {value} = this.create(identifier, obj, keep);

        return value;
    }

    has(identifier: string): boolean {
        log.debug(`CHECKING IF OBJECT WITH IDENTIFIER ${identifier} EXISTS`)();

        return this.urls.has(identifier);
    }

    clear(): void {
        log.debug('CLEARING ALL OBJECTS AND REVOKING ALL URLS')();

        this.urls.forEach((url) => {
            this.revoke(url.value);
        });
    }

    request(
        identifier: string,
        src: string,
        options: IRequestOptions
    ): Promise<string | null> | string | null {
        const url = this.urls.get(identifier);

        if (url) {
            return url.value;
        }

        if (this.isObjectURL(src)) {
            const result = this.add(identifier, src);

            return result.value;
        }

        const request = this.requests.get(identifier);

        if (request) {
            return request.result;
        } else {
            log.debug(`REQUESTING ${src}`)();

            const controller = new AbortController();

            const leases: ILease[] = [];

            const ref: Pick<IRequestDescriptor, 'abort' | 'leases'> = {
                abort: (keep?: boolean): boolean => {
                    if (!leases.length && !keep) {
                        log.debug(`ABORTING ${src}`)();

                        controller.abort();

                        this.requests.delete(identifier);

                        return true;
                    }

                    return false;
                },
                leases
            };

            const descriptor: IRequestDescriptor = {
                ...ref,

                result: ObjectURLs.request(src, {
                    baseURL: options.baseURL,
                    locale: options.locale,
                    withCredentials: options.withCredentials,
                    onDownloadProgress: options.onDownloadProgress,
                    signal: controller.signal
                })
                    .then((blob) => {
                        const descriptorA = this.create(
                            identifier,
                            blob,
                            false
                        );
                        const descriptorB = ref as IRequestDescriptor;

                        ArrayUtils.addOnce(
                            descriptorA.leases,
                            [...descriptorB.leases],
                            'id'
                        );

                        this.requests.delete(identifier);

                        return descriptorA.value;
                    })
                    .catch((response: TAPIResponseError<TAPIBlob>) => {
                        if (response.error.code !== AxiosError.ERR_CANCELED) {
                            this.requests.delete(identifier);
                        }

                        return Promise.reject(null);
                    })
            };

            if (options.signal) {
                options.signal.addEventListener('abort', () => {
                    descriptor.abort();
                });
            }

            this.requests.set(identifier, descriptor);

            return descriptor.result;
        }
    }

    lease(identifier: string, ref: string | TCb): boolean {
        const url = this.urls.get(identifier);

        if (url) {
            log.debug(`LEASING URL FOR ${identifier}`)();

            if (typeof ref === 'string') {
                ArrayUtils.addOnce(
                    url.leases,
                    {
                        id: ref,
                        cb: null
                    },
                    'id'
                );
            } else {
                ArrayUtils.addOnce(
                    url.leases,
                    {
                        id: '',
                        cb: ref
                    },
                    'cb'
                );
            }

            return true;
        }

        const request = this.requests.get(identifier);

        if (request) {
            log.debug(`LEASING REQUEST FOR ${identifier}`)();

            if (typeof ref === 'string') {
                ArrayUtils.addOnce(
                    request.leases,
                    {
                        id: ref,
                        cb: null
                    },
                    'id'
                );
            } else {
                ArrayUtils.addOnce(
                    request.leases,
                    {
                        id: '',
                        cb: ref
                    },
                    'cb'
                );
            }

            return true;
        }

        return false;
    }

    release(identifier: string, ref: string | TCb, keep?: boolean): boolean {
        log.debug(`RELEASING ${identifier}`)();

        const url = this.urls.get(identifier);

        if (url) {
            if (typeof ref === 'string') {
                ArrayUtils.remove(url.leases, ref, 'id');
            } else {
                ArrayUtils.remove(url.leases, ref, 'cb');
            }

            if (!url.leases.length && !keep && !url.keep) {
                return this.revoke(url.value);
            }

            return false;
        }

        const request = this.requests.get(identifier);

        if (request) {
            if (typeof ref === 'string') {
                ArrayUtils.remove(request.leases, ref, 'id');
            } else {
                ArrayUtils.remove(request.leases, ref, 'cb');
            }

            return request.abort();
        }

        return false;
    }

    // SHOULD ADHERE TO https://react.dev/reference/react/useSyncExternalStore
    createStoreAPI(src: string, options: ISubscriptionOptions): IStoreAPI {
        const identifier = ObjectURLs.createIdentifier(
            src,
            options.invariant
                ? null
                : {
                      baseURL: options.baseURL,
                      locale: options.locale
                  }
        );

        let snapshot: ISnapshot = {
            objectURL: null,
            status: 'IDLE',
            progress: null
        };

        return {
            subscribe: (cb: TCb): TCb => {
                const onDownloadProgressHandler = (
                    e: TAxiosProgressEvent
                ): void => {
                    const progress: number | null = e.total
                        ? Math.round(e.loaded * 100) / e.total
                        : null;

                    if (snapshot.progress !== progress) {
                        snapshot = {
                            ...snapshot,
                            progress
                        };

                        cb();
                    }
                };

                const response = this.request(identifier, src, {
                    baseURL: options.baseURL,
                    locale: options.locale,
                    withCredentials: options.withCredentials,
                    onDownloadProgress: options.progress
                        ? onDownloadProgressHandler
                        : undefined
                });

                if (!response || typeof response === 'string') {
                    if (snapshot.objectURL !== response) {
                        snapshot = {
                            ...snapshot,
                            objectURL: response
                        };
                    }

                    if (snapshot.status !== 'IDLE') {
                        snapshot = {
                            ...snapshot,
                            status: 'IDLE'
                        };
                    }
                } else {
                    if (snapshot.status !== 'LOADING') {
                        snapshot = {
                            ...snapshot,
                            status: 'LOADING'
                        };
                    }

                    response
                        .then((url) => {
                            if (url) {
                                if (snapshot.status !== 'IDLE') {
                                    snapshot = {
                                        ...snapshot,
                                        status: 'IDLE'
                                    };

                                    cb();
                                }
                            } else {
                                if (snapshot.status !== 'FAILED') {
                                    snapshot = {
                                        ...snapshot,
                                        status: 'FAILED'
                                    };

                                    cb();
                                }
                            }
                        })
                        .catch(() => {
                            if (snapshot.status !== 'FAILED') {
                                snapshot = {
                                    ...snapshot,
                                    status: 'FAILED'
                                };

                                cb();
                            }
                        });
                }

                this.lease(identifier, cb);

                return () => {
                    this.release(identifier, cb);
                };
            },

            getSnapshot: (): ISnapshot => {
                const match = this.urls.get(identifier);

                const objectURL = match ? match.value : null;

                if (snapshot.objectURL !== objectURL) {
                    snapshot = {
                        ...snapshot,
                        objectURL
                    };
                }

                return snapshot;
            },

            identifier
        };
    }

    isObjectURL(value: unknown): boolean {
        const implementation = this.implementation;

        const prefix = implementation && implementation.prefix;

        if (prefix) {
            return typeof value === 'string' && value.startsWith(prefix);
        } else {
            return false;
        }
    }

    stats(identifier: string): IURLStats;
    stats(): IGlobalStats;
    stats(identifier?: string): IGlobalStats | IURLStats {
        if (identifier) {
            const url = this.urls.get(identifier);

            if (url) {
                const keys = this.keys.get(url.value) || [];

                return {
                    leases: url.leases.length,
                    keys: keys.length
                };
            } else {
                const request = this.requests.get(identifier);

                if (request) {
                    return {
                        leases: request.leases.length,
                        keys: 0
                    };
                } else {
                    return {
                        leases: 0,
                        keys: 0
                    };
                }
            }
        } else {
            return {
                refs: this.urls.size,
                requests: this.requests.size,
                keys: this.keys.size
            };
        }
    }

    static async request(src: string, options: IRequestOptions): Promise<Blob> {
        const {
            baseURL,
            locale,
            withCredentials = false,
            signal = undefined,
            onDownloadProgress = undefined
        } = options;

        const response = await request<TAPIBlob>({
            method: 'GET',
            url: src,
            baseURL,
            responseType: 'blob',
            query: {locale},
            withCredentials,
            signal,
            onDownloadProgress
        });

        if (response.data) {
            if (response.data instanceof Blob) {
                return response.data;
            } else {
                const {headers} = response;

                if (typeof headers['content-type'] === 'string') {
                    const type = headers['content-type'];

                    return new Blob([response.data], {type});
                } else {
                    const type = 'application/octet-stream';

                    return new Blob([response.data], {type});
                }
            }
        }

        return Promise.reject(response);
    }

    static createIdentifier(src: string, meta: IResourceMeta | null): string {
        if (meta) {
            const base = meta.baseURL ? URLUtils.join(meta.baseURL, src) : src;

            return URLUtils.encodeQuery(base, {
                locale: meta.locale
            });
        } else {
            return src;
        }
    }
}

const objectURLs = new ObjectURLs();

export type {
    IBaseOptions,
    IObjectURLImplementation,
    IResourceMeta,
    ISnapshot,
    IStoreAPI,
    TAPIBlob,
    TGetSnapshot,
    TSubscribe
};
export {ObjectURLs, objectURLs};
