import type {IShapeValueDescriptor} from '../../defines/common.types.js';
import type {TDSMargin, TDSPadding} from '../../defines/theme.types.js';

function resolveShapeValue(
    base: TDSPadding,
    value: TDSPadding | IShapeValueDescriptor<TDSPadding> | undefined | null
): Required<IShapeValueDescriptor<TDSPadding>>;

function resolveShapeValue(
    base: TDSMargin,
    value: TDSMargin | IShapeValueDescriptor<TDSMargin> | undefined | null
): Required<IShapeValueDescriptor<TDSMargin>>;

function resolveShapeValue(
    base: string,
    value: string | IShapeValueDescriptor<string> | undefined | null
): Record<string, string> {
    if (typeof value === 'string') {
        return {
            top: value,
            right: value,
            bottom: value,
            left: value
        };
    } else {
        return {
            top: base,
            right: base,
            bottom: base,
            left: base,
            ...(value || {})
        };
    }
}

export {resolveShapeValue};
