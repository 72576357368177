import {MESSAGES} from '@mcal/core';
import type {PayloadAction} from '@reduxjs/toolkit';
import {createSlice} from '@reduxjs/toolkit';
import {
    logIn,
    logInChallenge
} from '../../services/app-node-auth/log-in/log-in.thunks.js';
import {
    logOut,
    refreshToken
} from '../../services/app-node-auth/session/session.thunks.js';
import {complete} from '../../services/app-node-auth/sign-up/sign-up.thunks.js';
import {getUser} from '../../services/app-node-auth/user/user.thunks.js';
import {initialState} from './session.state.js';

const sessionSlice = createSlice({
    name: 'session',
    initialState,
    reducers: {
        setSkipOnboarding(state, action: PayloadAction<boolean | null>) {
            state.skipOnboarding = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(refreshToken.pending, (state) => {
            state.sources.refresh.status = 'LOADING';
        });
        builder.addCase(refreshToken.fulfilled, (state, {payload}) => {
            state.sources.refresh.status = 'IDLE';
            state.status = 'AUTHENTICATED';
            state.exp = payload.meta.exp;
        });
        builder.addCase(refreshToken.rejected, (state, {payload}) => {
            if (payload && payload.status === 401) {
                state.sources.refresh.status = 'IDLE';
                state.status = 'NOT-AUTHENTICATED';
                state.exp = null;
            } else {
                state.sources.refresh.status = 'FAILED';
            }
        });

        builder.addCase(logIn.pending, (state) => {
            state.sources.logIn.status = 'LOADING';
        });
        builder.addCase(logIn.fulfilled, (state, {payload}) => {
            state.sources.logIn.status = 'IDLE';
            if (payload.meta.code === MESSAGES.NORMAL.SERVER.LOGGED_IN.CODE) {
                state.status = 'AUTHENTICATED';
            }
            state.exp = payload.meta.exp;
        });
        builder.addCase(logIn.rejected, (state) => {
            state.sources.logIn.status = 'FAILED';
        });

        builder.addCase(logInChallenge.pending, (state) => {
            state.sources.logIn.status = 'LOADING';
        });
        builder.addCase(logInChallenge.fulfilled, (state, {payload}) => {
            state.sources.logIn.status = 'IDLE';
            if (payload.meta.code === MESSAGES.NORMAL.SERVER.LOGGED_IN.CODE) {
                state.status = 'AUTHENTICATED';
            }
            state.exp = payload.meta.exp;
        });
        builder.addCase(logInChallenge.rejected, (state) => {
            state.sources.logIn.status = 'FAILED';
        });

        builder.addCase(logOut.pending, (state) => {
            state.sources.logOut.status = 'LOADING';
        });
        builder.addCase(logOut.fulfilled, (state) => {
            state.sources.logOut.status = 'IDLE';
            state.status = 'NOT-AUTHENTICATED';
            state.exp = null;
        });
        builder.addCase(logOut.rejected, (state) => {
            state.sources.logOut.status = 'FAILED';
        });

        builder.addCase(complete.fulfilled, (state, {payload}) => {
            state.status = 'AUTHENTICATED';
            state.exp = payload.meta.exp;
        });

        builder.addCase(getUser.fulfilled, (state) => {
            // IF THE GET USER THUNK IS SUCCESSFUL IT MEANS THE SESSION IS VALID
            state.status = 'AUTHENTICATED';
        });
    }
});

export {sessionSlice};
