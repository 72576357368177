import {serialize} from '../serialize/serialize.js';

interface IFromErrorOptions {
    stack?: boolean;
    name?: boolean;
    message?: boolean;
}

function fromError(
    input: unknown,
    options: IFromErrorOptions | null = null
): string {
    if (!input) {
        return '';
    }

    if (input instanceof Error) {
        let output: string = '';

        const name = !options || options.name;
        const message = !options || options.message;
        const stack = !options || options.stack;

        if (name) {
            const str = input.name || 'Unknown Error';

            if (!message) {
                output += str;
            } else {
                output += `${str}: `;
            }
        }

        if (message) {
            output += input.message || 'Unknown Message';
        }

        if (stack) {
            if (input.stack) {
                output += `\n${input.stack}`;
            }
        }

        return output;
    } else {
        return serialize(input);
    }
}

export {fromError};
