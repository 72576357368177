import type {IAvatarBaseProps} from '@mcal/core-react';
import {ProfileContext, cn, getUserInitials} from '@mcal/core-react';
import type {FC} from 'react';
import {useContext, useMemo} from 'react';
import {createTestIDs} from '../../dev/index.js';
import {ImageBackground} from '../image-background/image-background.js';
import {StyledLabel, StyledRoot} from './avatar.styles.js';

const ownTestIDs = createTestIDs('Avatar', ['root', 'label']);

interface IAvatarProps extends IAvatarBaseProps<typeof ownTestIDs> {
    className?: string;
    classes?: {
        root?: string;
        label?: string;
    };
}

const Avatar: FC<IAvatarProps> = ({
    line1,
    line2,
    src,
    currentUser = false,
    size = 'medium',
    color = 'primary',
    emphasis = 'high',
    className = '',
    classes = {},
    testIDs = {}
}) => {
    const profile = useContext(ProfileContext);

    const initials = useMemo(() => {
        if (currentUser) {
            return getUserInitials({
                line1,
                line2,
                firstName: profile.firstName,
                lastName: profile.lastName
            });
        } else {
            return getUserInitials({
                line1,
                line2,
                firstName: null,
                lastName: null
            });
        }
    }, [currentUser, line1, line2, profile.firstName, profile.lastName]);

    const avatar = useMemo(() => {
        if (currentUser) {
            return profile.photo;
        } else {
            return src;
        }
    }, [currentUser, profile.photo, src]);

    return (
        <StyledRoot
            data-testid={testIDs.root || ownTestIDs.root}
            className={cn(className, classes.root)}
            size={size}
            color={color}
            emphasis={emphasis}
        >
            {avatar ? (
                <ImageBackground
                    src={avatar}
                    withCredentials={true}
                    invariant={true}
                />
            ) : (
                <StyledLabel
                    data-testid={testIDs.label || ownTestIDs.label}
                    className={classes.label}
                    size={size}
                    color={color}
                    emphasis={emphasis}
                >
                    {initials}
                </StyledLabel>
            )}
        </StyledRoot>
    );
};

export type {IAvatarProps};
export {Avatar, ownTestIDs as testIDs};
