import type {
    EEntityType,
    IElevatorSummary,
    IEntityInviteEntry,
    IResolvedPermissions,
    IServiceCompanyGeneralSettings,
    IServiceCompanyProfile,
    IServiceCompanyState,
    IServiceCompanySummary,
    ISiteSummary,
    ITeamMembership,
    IUserMembership,
    TMileageTotalKPI,
    TTechnicalParametersTemplate
} from '@mcal/core';
import {
    defaultServiceCompanyGeneralSettings,
    defaultServiceCompanyProfile,
    defaultServiceCompanyState,
    defaultServiceCompanySummary,
    defaultServiceCompanyTemplates
} from '@mcal/core';
import type {
    ISliceRemote,
    TEntitySliceStatus
} from '../../defines/redux.types.js';
import {createRemote} from '../../utils/create-remote/create-remote.js';

type TTeam = ITeamMembership<EEntityType.ServiceCompany>[];

type TInvites = IEntityInviteEntry<EEntityType.ServiceCompany>[];

interface IServiceCompanySliceState {
    status: TEntitySliceStatus;
    remotes: {
        serviceCompanySummary: ISliceRemote<IServiceCompanySummary>;
        serviceCompanyState: ISliceRemote<IServiceCompanyState>;
        serviceCompanyProfile: ISliceRemote<IServiceCompanyProfile>;
        serviceCompanyGeneralSettings: ISliceRemote<IServiceCompanyGeneralSettings>;
        sites: ISliceRemote<ISiteSummary[]>;
        templates: ISliceRemote<TTechnicalParametersTemplate[]>;
        kpis: ISliceRemote<{
            mileageTotal: TMileageTotalKPI | null;
        }>;
        team: ISliceRemote<TTeam>;
        memberships: ISliceRemote<IUserMembership[]>;
        invites: ISliceRemote<TInvites>;
        elevators: ISliceRemote<IElevatorSummary[]>;
        permissions: ISliceRemote<IResolvedPermissions>;
    };
}

const initialState = {
    status: 'INIT',
    remotes: {
        serviceCompanySummary: createRemote(defaultServiceCompanySummary),
        serviceCompanyState: createRemote(defaultServiceCompanyState),
        serviceCompanyProfile: createRemote(defaultServiceCompanyProfile),
        serviceCompanyGeneralSettings: createRemote(
            defaultServiceCompanyGeneralSettings
        ),
        templates: createRemote(defaultServiceCompanyTemplates),
        sites: createRemote([]),
        elevators: createRemote([]),
        team: createRemote([]),
        memberships: createRemote([]),
        invites: createRemote([]),
        kpis: createRemote({
            mileageTotal: null
        }),
        permissions: createRemote({
            permissions: [],
            grants: [],
            access: [],
            isOwner: false,
            isPlatformMember: false,
            trace: []
        })
    }
} satisfies IServiceCompanySliceState as IServiceCompanySliceState;

export type {IServiceCompanySliceState, TInvites};
export {initialState};
