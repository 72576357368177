import type {
    IBoxBaseProps,
    IShapeValueDescriptor,
    TDSMargin,
    TDSPadding
} from '@mcal/core-react';
import {styled} from '../../utils/styled/styled.js';

interface IStyledRootProps {
    margin: Required<IShapeValueDescriptor<TDSMargin>>;
    padding: Required<IShapeValueDescriptor<TDSPadding>>;
    elevation: NonNullable<IBoxBaseProps<never>['elevation']>;
    corner: NonNullable<IBoxBaseProps<never>['corner']>;
    level: NonNullable<IBoxBaseProps<never>['level']> | undefined;
    cursor?: string;
}

const StyledRoot = styled('div')<IStyledRootProps>(
    ({theme, margin, padding, elevation, corner, level, cursor}) => ({
        marginTop: theme.shape.margin[margin.top],
        marginRight: theme.shape.margin[margin.right],
        marginBottom: theme.shape.margin[margin.bottom],
        marginLeft: theme.shape.margin[margin.left],

        paddingTop: theme.shape.padding[padding.top],
        paddingRight: theme.shape.padding[padding.right],
        paddingBottom: theme.shape.padding[padding.bottom],
        paddingLeft: theme.shape.padding[padding.left],

        borderRadius: theme.shape.corner.rounded[corner].borderRadius,
        boxShadow: theme.elevation[elevation].shadow,
        backgroundColor: level
            ? theme.palette.surface.base[level]
            : 'transparent',

        cursor: cursor
    })
);

export {StyledRoot};
