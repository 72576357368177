function toRgb(input: string, alpha?: number): string {
    if (input.startsWith('#')) {
        let v = input.substring(1);

        if (v.length === 3) {
            v = v
                .split('')
                .map((i) => i + i)
                .join('');
        }

        const r = parseInt(v.substring(0, 2), 16);
        const g = parseInt(v.substring(2, 4), 16);
        const b = parseInt(v.substring(4, 6), 16);

        return typeof alpha !== 'undefined'
            ? `rgba(${r},${g},${b},${alpha})`
            : `rgb(${r},${g},${b})`;
    }

    if (input.startsWith('rgb')) {
        const v = input
            .substring(input.indexOf('(') + 1, input.indexOf(')'))
            .split(',');

        const r = v[0].trim();
        const g = v[1].trim();
        const b = v[2].trim();

        return typeof alpha !== 'undefined'
            ? `rgba(${r},${g},${b},${alpha})`
            : `rgb(${r},${g},${b})`;
    }

    return input;
}

export {toRgb};
