import {useTheme} from '@mcal/core-react';
import type {FC} from 'react';
import {useMemo} from 'react';

interface IColors {
    colorA: string;
}

interface IThrashSvgProps {
    height?: number | string;
    width?: number | string;
    fill?: string;
}

const ThrashSvg: FC<IThrashSvgProps> = ({fill, ...props}) => {
    const {type} = useTheme();

    const {colorA} = useMemo<IColors>(() => {
        if (type === 'dark') {
            return {
                colorA: '#999DA5'
            };
        } else {
            return {
                colorA: '#555C69'
            };
        }
    }, [type]);

    return (
        <svg
            xmlns={'http://www.w3.org/2000/svg'}
            width={'20'}
            height={'20'}
            viewBox={'0 0 20 20'}
            fill={'none'}
            {...props}
        >
            <mask
                id={'mask0_251_986'}
                maskUnits={'userSpaceOnUse'}
                x={'0'}
                y={'0'}
                width={'20'}
                height={'20'}
            >
                <rect width={'20'} height={'20'} fill={'#D9D9D9'} />
            </mask>
            <g mask={'url(#mask0_251_986)'}>
                <path
                    d={
                        'M8.33333 13.2292L10 11.5625L11.6667 13.2292L12.7292 12.1667L11.0625 10.5L12.7292 8.83333L11.6667 7.77083L10 9.4375L8.33333 7.77083L7.27083 8.83333L8.9375 10.5L7.27083 12.1667L8.33333 13.2292ZM6.5 17C6.08333 17 5.72917 16.8542 5.4375 16.5625C5.14583 16.2708 5 15.9167 5 15.5V5.5H4V4H8V3H12V4H16V5.5H15V15.5C15 15.9167 14.8542 16.2708 14.5625 16.5625C14.2708 16.8542 13.9167 17 13.5 17H6.5Z'
                    }
                    fill={fill || colorA}
                />
            </g>
        </svg>
    );
};

export {ThrashSvg};
