import type {TObj, TObjTokens} from '../../../defines/common/utility.types.js';
import {parseToken} from '../parse-token/parse-token.js';

type TRef = TObj<unknown>;

interface IAssignOptions {
    knownOnly?: boolean;
}

function assign<T extends object>(
    target: T,
    token: TObjTokens<T>,
    value: unknown,
    options: IAssignOptions = {}
): T {
    const {knownOnly = false} = options;

    const keys = parseToken(token);

    if (!keys.length) {
        return target;
    }

    let output = target;

    if (typeof keys[0] === 'string') {
        if (
            Array.isArray(output) ||
            typeof output !== 'object' ||
            output === null
        ) {
            output = {} as T;
        }
    } else {
        if (!Array.isArray(output)) {
            output = [] as T;
        }
    }

    let ref = output as TRef;

    for (let i = 0; i < keys.length; i++) {
        const key = keys[i];

        if (knownOnly && !(key in ref)) {
            return output;
        }

        if (i === keys.length - 1) {
            ref[key] = value;
            return output;
        }

        const curr = ref[key];

        if (typeof keys[i + 1] === 'string') {
            if (
                Array.isArray(curr) ||
                typeof curr !== 'object' ||
                curr === null
            ) {
                ref[key] = {};
            }
        } else {
            if (!Array.isArray(curr)) {
                ref[key] = [];
            }
        }

        ref = ref[key] as TRef;
    }

    return output;
}

export {assign};
