import {useTheme} from '@mcal/core-react';
import type {FC} from 'react';
import {useMemo} from 'react';

interface IColors {
    colorA: string;
}

interface IHomeSvgProps {
    height?: number | string;
    width?: number | string;
    fill?: string;
}

const HomeSvg: FC<IHomeSvgProps> = ({fill, ...props}) => {
    const {type} = useTheme();

    const {colorA} = useMemo<IColors>(() => {
        if (type === 'dark') {
            return {
                colorA: '#999DA5'
            };
        } else {
            return {
                colorA: '#555C69'
            };
        }
    }, [type]);

    return (
        <svg
            xmlns={'http://www.w3.org/2000/svg'}
            width={'12'}
            height={'14'}
            viewBox={'0 0 12 14'}
            fill={fill || colorA}
            {...props}
        >
            <path
                d={
                    'M0 12.0625V5.0625C0 4.82849 0.0520833 4.60678 0.15625 4.3974C0.260417 4.18802 0.409722 4.00694 0.604167 3.85417L5.10417 0.479167C5.38194 0.284722 5.68056 0.1875 6 0.1875C6.31944 0.1875 6.61806 0.284722 6.89583 0.479167L11.3958 3.85417C11.5903 4.00694 11.7396 4.18802 11.8438 4.3974C11.9479 4.60678 12 4.82849 12 5.0625V12.0625C12 12.5437 11.8286 12.9557 11.4859 13.2984C11.1432 13.6411 10.7312 13.8125 10.25 13.8125H8.25C8.0375 13.8125 7.85938 13.7406 7.71562 13.5969C7.57187 13.4531 7.5 13.275 7.5 13.0625V9.0625C7.5 8.85 7.4283 8.67188 7.2849 8.52813C7.14149 8.38438 6.96381 8.3125 6.75183 8.3125H5.25548C5.04349 8.3125 4.86458 8.38438 4.71875 8.52813C4.57292 8.67188 4.5 8.85 4.5 9.0625V13.0625C4.5 13.275 4.42813 13.4531 4.28438 13.5969C4.14062 13.7406 3.9625 13.8125 3.75 13.8125H1.75C1.26875 13.8125 0.856771 13.6411 0.514063 13.2984C0.171354 12.9557 0 12.5437 0 12.0625Z'
                }
                fill={fill || colorA}
            />
        </svg>
    );
};

export {HomeSvg};
