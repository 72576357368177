import type {IConsole, IConsoleDispatchOptions, ILog} from '@mcal/core';

const browserConsole: IConsole = {
    dispatch(log: ILog, {styles}: IConsoleDispatchOptions) {
        const fn = console[log.method] || console.log;

        const colors = `color: ${log.color}; backgroundColor: ${log.backgroundColor}`;

        if (typeof log.message === 'string') {
            if (styles) {
                return fn.bind(
                    null,
                    ...[
                        '%c' + log.tag + '%c' + log.caller + '%c' + log.message,
                        colors + ' font-family: monospace; white-space: pre;',
                        'color: #888888; font-family: monospace; white-space: pre;',
                        'color: #aaaaaa; font-family: monospace; white-space: pre;'
                    ]
                );
            } else {
                return fn.bind(null, log.tag + log.caller, log.message);
            }
        } else {
            if (styles) {
                return fn.bind(
                    null,
                    ...[
                        '%c' + log.tag + '%c' + log.caller + '%c[object]',
                        colors + ' font-family: monospace; white-space: pre;',
                        'color: #888888; font-family: monospace; white-space: pre;',
                        'color: #aaaaaa; font-family: monospace; white-space: pre;',
                        '\n\n',
                        log.message,
                        '\n\n'
                    ]
                );
            } else {
                return fn.bind(
                    null,
                    log.tag + log.caller + '[object]',
                    log.message
                );
            }
        }
    }
};

export {browserConsole};
