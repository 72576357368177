import type {TObjKey} from '@mcal/core';
import {ObjectUtils, logger} from '@mcal/core';
import type {
    ISliceRemote,
    IStateWithRemotes,
    TRemoteKeys,
    TRemoteUpdateTargets
} from '../../defines/redux.types.js';
import {ESliceRemoteStatus} from '../../defines/redux.types.js';

const log = logger.withCaller('pendingRemoteUpdate');

interface IPendingMeta<TArg> {
    arg: TArg;
    requestId: string;
    requestStatus: 'pending';
}

const updateMetadata = (remote: ISliceRemote<object>, key: TObjKey): void => {
    if (remote.status === ESliceRemoteStatus.Ready) {
        remote.status = ESliceRemoteStatus.Updating;
    } else {
        if (remote.status !== ESliceRemoteStatus.Updating) {
            remote.status = ESliceRemoteStatus.Loading;
        }
    }

    const updates = Object.keys(remote.updates).length;

    if (updates > 1) {
        log.warn(`${updates} CONCURRENT UPDATES FOR ${String(key)}`)();
    }
};

type TTargets<TState extends IStateWithRemotes, TArg> = (
    | TRemoteUpdateTargets<TState, TArg>
    | TRemoteKeys<TState>
)[];

interface IPendingRemoteUpdateConfig<TState extends IStateWithRemotes, TArg> {
    state: TState;
    meta: IPendingMeta<TArg>;
    targets: TTargets<TState, TArg>;
}

function pendingRemoteUpdate<TState extends IStateWithRemotes, TArg>({
    state,
    meta,
    targets
}: IPendingRemoteUpdateConfig<TState, TArg>): void {
    const {requestId, arg} = meta;

    for (const target of targets) {
        if (typeof target === 'object') {
            // CASE: OPTIMISTIC UPDATE

            const remote = state.remotes[target.key];

            // HERE WE DEEPLY CLONE THE CURRENT STATE SO THAT MUTATIONS DO NOT AFFECT THE ROLLBACK STATE
            const rollback = ObjectUtils.deepClone(remote.current);

            if ('mutator' in target) {
                // HERE WE EXPECT THE MUTATOR TO MUTATE THE CURRENT STATE
                target.mutator(arg, remote.current);
            } else if ('replacer' in target) {
                // HERE WE EXPECT THE REPLACER TO RETURN A NEW COMPLETE OBJECT
                remote.current = target.replacer(arg, remote.current);
            } else {
                // HERE WE MUTATE THE CURRENT STATE DEEPLY WITH THE OPTIMISTIC UPDATE
                remote.current = ObjectUtils.deepAssign(
                    remote.current,
                    target.merger(arg, remote.current),
                    {
                        knownOnly: true,
                        skipUndefined: true
                    }
                );
            }

            remote.updates[requestId] = {
                requestId,
                timestamp: Date.now(),
                rollback,
                startingStatus: remote.status
            };

            updateMetadata(remote, target.key);
        } else {
            // CASE: PESSIMISTIC UPDATE

            const remote = state.remotes[target];

            updateMetadata(remote, target);
        }
    }
}

export {pendingRemoteUpdate};
