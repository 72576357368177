import type {
    TChronosVariants,
    TDecimalSeparator,
    TLocale,
    TMeasureSystem,
    TMeasureUnits
} from '@mcal/core';
import {
    defaultChronosVariants,
    defaultDecimalSeparator,
    defaultLocale,
    defaultMeasureSystem,
    measureUnitsSet
} from '@mcal/core';
import type {FC, ReactNode} from 'react';
import {createContext, useMemo} from 'react';

interface IFormatsContext {
    decimalSeparator: TDecimalSeparator;
    measureUnits: TMeasureUnits;
    chronosVariants: TChronosVariants;
    locale: TLocale;
}

const defaultFormatsContext: IFormatsContext = {
    decimalSeparator: defaultDecimalSeparator,
    measureUnits: measureUnitsSet[defaultMeasureSystem],
    chronosVariants: defaultChronosVariants,
    locale: defaultLocale
};

const FormatsContext = createContext<IFormatsContext>(defaultFormatsContext);

interface IFormatsStoreProps {
    formats?: {
        decimalSeparator?: TDecimalSeparator;
        measureSystem?: TMeasureSystem;
        chronosVariants?: Partial<TChronosVariants>;
        locale?: TLocale;
    };
    children: ReactNode;
}

const FormatsStore: FC<IFormatsStoreProps> = ({formats = {}, children}) => {
    const decimalSeparator = useMemo<TDecimalSeparator>(() => {
        return (
            formats.decimalSeparator || defaultFormatsContext.decimalSeparator
        );
    }, [formats.decimalSeparator]);

    const measureUnits = useMemo<TMeasureUnits>(() => {
        return {
            ...defaultFormatsContext.measureUnits,
            ...measureUnitsSet[formats.measureSystem || defaultMeasureSystem]
        };
    }, [formats.measureSystem]);

    const chronosVariants = useMemo<TChronosVariants>(() => {
        return {
            ...defaultFormatsContext.chronosVariants,
            ...formats.chronosVariants
        };
    }, [formats.chronosVariants]);

    const value = useMemo<IFormatsContext>(() => {
        return {
            decimalSeparator,
            measureUnits,
            chronosVariants,
            locale: formats.locale || defaultFormatsContext.locale
        };
    }, [chronosVariants, decimalSeparator, formats.locale, measureUnits]);

    return (
        <FormatsContext.Provider value={value}>
            {children}
        </FormatsContext.Provider>
    );
};

export type {IFormatsContext, IFormatsStoreProps};
export {FormatsContext, FormatsStore, defaultFormatsContext};
