function parse(input: 'true'): true;
function parse(input: 'false'): false;
function parse(input: 'true' | 'false'): boolean;
function parse(input: 'null'): null;
function parse(input: 'undefined'): undefined;
function parse(input: string): boolean | null | undefined | number | string;

function parse(input: string): boolean | null | undefined | number | string {
    switch (input.trim()) {
        case 'true':
            return true;

        case 'false':
            return false;

        case 'null':
            return null;

        case 'undefined':
            return undefined;

        case '':
            return '';

        default: {
            const number = Number(input);

            if (!isNaN(number)) {
                return number;
            }

            return input;
        }
    }
}

export {parse};
