import type {
    TAPIRequestContent,
    TAPIResponse,
    TSessionLogOutAPI,
    TSessionRefreshTokenAPI
} from '@mcal/core';
import {request} from '../../../utils/request/request.js';

const refreshToken = (
    {query}: TAPIRequestContent<TSessionRefreshTokenAPI>,
    signal: AbortSignal
): TAPIResponse<TSessionRefreshTokenAPI> => {
    return request<TSessionRefreshTokenAPI>({
        method: 'POST',
        url: '/session/refresh-token',
        signal,
        query
    });
};

const logOut = (
    {query}: TAPIRequestContent<TSessionLogOutAPI>,
    signal: AbortSignal
): TAPIResponse<TSessionLogOutAPI> => {
    return request<TSessionLogOutAPI>({
        method: 'POST',
        url: '/session/log-out',
        signal,
        query
    });
};

export {logOut, refreshToken};
