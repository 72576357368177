import type {TComponentSize} from '../../defines/common.types.js';

function toSize<T>(
    values: T[],
    size: TComponentSize,
    map: TComponentSize[] = ['small', 'medium', 'large']
): T {
    return values[map.indexOf(size)];
}

export {toSize};
