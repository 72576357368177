import type {TRequestStatus} from '@mcal/core';
import type {ISliceSource} from '../../defines/redux.types.js';

type TStatus<TKeys extends TRequestStatus, TValues extends string> =
    | Exclude<TRequestStatus, TKeys>
    | TValues;

function resolveStatus<TKeys extends TRequestStatus, TValues extends string>(
    sources: Record<string, ISliceSource<unknown>>,
    map: Partial<Record<TKeys, TValues>> = {}
): TStatus<TKeys, TValues> {
    const statuses = Object.values(sources).map((source) => source.status);

    if (statuses.includes('LOADING')) {
        if ('LOADING' in map) {
            return map.LOADING as TStatus<TKeys, TValues>;
        } else {
            return 'LOADING' as TStatus<TKeys, TValues>;
        }
    }

    if (statuses.includes('FAILED')) {
        if ('FAILED' in map) {
            return map.FAILED as TStatus<TKeys, TValues>;
        } else {
            return 'FAILED' as TStatus<TKeys, TValues>;
        }
    }

    if (statuses.includes('INIT')) {
        if ('INIT' in map) {
            return map.INIT as TStatus<TKeys, TValues>;
        } else {
            return 'INIT' as TStatus<TKeys, TValues>;
        }
    }

    if ('IDLE' in map) {
        return map.IDLE as TStatus<TKeys, TValues>;
    } else {
        return 'IDLE' as TStatus<TKeys, TValues>;
    }
}

export {resolveStatus};
