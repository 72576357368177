import {ThemeProvider} from '@emotion/react';
import {logger} from '@mcal/core';
import type {FC, ReactNode} from 'react';
import {createContext, useCallback, useContext, useMemo, useState} from 'react';
import type {
    ITheme,
    IThemePack,
    TThemeType
} from '../../defines/theme.types.js';
import {DEFAULT_THEME_PACK, DEFAULT_THEME_TYPE} from '../../themes/index.js';

const log = logger.withCaller('ThemeStore');

interface IThemeContext {
    isRoot: boolean | null;
    packs: IThemePack[];
    currentPack: IThemePack;
    changePack: (newThemePack: IThemePack) => void;
    currentType: TThemeType;
    changeType: (newThemeType: TThemeType) => void;
}

const defaultValues: {
    packs: IThemePack[];
    pack: IThemePack;
    type: TThemeType;
} = {
    packs: [DEFAULT_THEME_PACK],
    pack: DEFAULT_THEME_PACK,
    type: DEFAULT_THEME_TYPE
};

const ThemeContext = createContext<IThemeContext>({
    isRoot: null,
    packs: defaultValues.packs,
    currentPack: defaultValues.pack,
    changePack: () => {
        log.warn('TRYING TO CHANGE THEME PACK OUTSIDE OF THE THEME STORE.')();
    },
    currentType: defaultValues.type,
    changeType: () => {
        log.warn('TRYING TO CHANGE THEME TYPE OUTSIDE OF THE THEME STORE.')();
    }
});

interface IThemeStoreProps {
    root?: boolean | 'auto';
    packs?: IThemePack[];
    defaultPack?: IThemePack;
    defaultType?: TThemeType;
    themePack?: IThemePack;
    themeType?: TThemeType;
    theme?: ITheme;
    invert?: boolean;
    children: ReactNode;
}

const ThemeStore: FC<IThemeStoreProps> = ({
    root = 'auto',
    packs = defaultValues.packs,
    defaultPack = defaultValues.pack,
    defaultType = defaultValues.type,
    themePack,
    themeType,
    theme,
    invert,
    children
}) => {
    const sup = useContext(ThemeContext);

    const isRoot = root === true || (root === 'auto' && sup.isRoot === null);

    const [selectedPack, setSelectedPack] = useState<IThemePack>(defaultPack);

    const [selectedType, setSelectedType] = useState<TThemeType>(defaultType);

    const changePack = useCallback(
        (newThemePack: IThemePack) => {
            if (typeof themePack !== 'undefined') {
                log.warn(
                    'CANNOT CHANGE THEME PACK WHEN "pack" PROP IS PASSED. USE "defaultPack" INSTEAD'
                )();
            } else {
                if (newThemePack) {
                    setSelectedPack(newThemePack);
                } else {
                    log.warn('RECEIVED INVALID THEME PACK')();
                }
            }
        },
        [themePack]
    );

    const changeType = useCallback(
        (newThemeType: TThemeType) => {
            if (typeof themeType !== 'undefined') {
                log.warn(
                    'CANNOT CHANGE THEME TYPE WHEN "type" PROP IS PASSED. USE "defaultType" INSTEAD'
                )();
            } else {
                if (newThemeType) {
                    setSelectedType(newThemeType);
                } else {
                    log.warn('RECEIVED INVALID THEME TYPE')();
                }
            }
        },
        [setSelectedType, themeType]
    );

    const currentPack = useMemo<IThemePack>(() => {
        return themePack || (!isRoot && sup.currentPack) || selectedPack;
    }, [isRoot, selectedPack, sup.currentPack, themePack]);

    const currentType = useMemo<TThemeType>(() => {
        const type = themeType || (!isRoot && sup.currentType) || selectedType;

        const map: {[k in TThemeType]: TThemeType} = {
            light: 'dark',
            dark: 'light'
        };

        return invert ? map[type] : type;
    }, [invert, isRoot, selectedType, sup.currentType, themeType]);

    const currentTheme = useMemo<ITheme>(() => {
        return theme || currentPack.themes[currentType];
    }, [currentPack.themes, currentType, theme]);

    if (isRoot) {
        return (
            <ThemeContext.Provider
                value={{
                    isRoot,
                    packs,
                    currentPack,
                    changePack,
                    currentType,
                    changeType
                }}
            >
                <ThemeProvider theme={currentTheme}>{children}</ThemeProvider>
            </ThemeContext.Provider>
        );
    } else {
        return <ThemeProvider theme={currentTheme}>{children}</ThemeProvider>;
    }
};

export type {IThemeContext, IThemeStoreProps};
export {ThemeContext, ThemeStore};
