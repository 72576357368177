type TObj = Record<string, unknown>;

function addOnce<T>(
    array: T[],
    item: T | T[],
    key?: keyof T extends string ? keyof T : never
): T[] {
    const parts = Array.isArray(item) ? item : [item];

    parts.forEach((part) => {
        if (key && typeof part === 'object' && part !== null) {
            const index = array.findIndex((i) => {
                return (i as TObj)[key] === (part as TObj)[key];
            });

            if (index === -1) {
                array.push(part);
            }
        } else {
            if (!array.includes(part)) {
                array.push(part);
            }
        }
    });

    return array;
}

export {addOnce};
