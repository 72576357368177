import type {TObj} from '../../../defines/common/utility.types.js';
import {assign} from '../assign/assign.js';
import {forEach} from '../for-each/for-each.js';

function deepClone<T extends object>(source: T): T {
    let output: TObj<unknown> = {};

    forEach(
        source,
        ({token, value, isIterable}) => {
            if (Array.isArray(value)) {
                if (!isIterable) {
                    output = assign(output, token, []);
                }
            } else if (typeof value !== 'object' || value === null) {
                output = assign(output, token, value);
            } else if (!isIterable) {
                output = assign(output, token, {});
            }
        },
        {skipRefs: false}
    );

    return output as T;
}

export {deepClone};
