function join(...segments: string[]): string {
    let output: string = segments[0] || '';

    for (let i = 1; i < segments.length; i++) {
        const segment = segments[i];

        if (segment.includes(':')) {
            output = segment;
            continue;
        }

        if (segment.startsWith('/')) {
            if (output.endsWith('/')) {
                output += segment.substring(1);
            } else {
                output += segment;
            }
        } else {
            if (output.endsWith('/') || !output.length) {
                output += segment;
            } else {
                output += '/' + segment;
            }
        }
    }

    if (output.endsWith('/') && output.length > 1) {
        return output.slice(0, -1);
    } else {
        return output;
    }
}

export {join};
