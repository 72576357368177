import type {
    TAPIResponseSuccess,
    TSessionLogOutAPI,
    TSessionRefreshTokenAPI
} from '@mcal/core';
import {createAsyncThunk} from '@reduxjs/toolkit';
import type {
    TAPIAsyncThunkArgs,
    TAPIAsyncThunkConfig
} from '../../../defines/redux.types.js';
import {getState} from '../../../utils/get-state/get-state.js';
import * as services from './session.services.js';

const refreshToken = createAsyncThunk<
    TAPIResponseSuccess<TSessionRefreshTokenAPI>,
    TAPIAsyncThunkArgs<TSessionRefreshTokenAPI>,
    TAPIAsyncThunkConfig<TSessionRefreshTokenAPI>
>('session/refreshToken', async (_, thunkAPI) => {
    const user = getState(thunkAPI, 'user');

    return await services
        .refreshToken(
            {
                query: {locale: user.remotes.userGeneralSettings.current.locale}
            },
            thunkAPI.signal
        )
        .then((response) => {
            if (response.error) {
                return thunkAPI.rejectWithValue(response);
            } else {
                return thunkAPI.fulfillWithValue(response);
            }
        });
});

const logOut = createAsyncThunk<
    TAPIResponseSuccess<TSessionLogOutAPI>,
    TAPIAsyncThunkArgs<TSessionLogOutAPI>,
    TAPIAsyncThunkConfig<TSessionLogOutAPI>
>('session/logOut', async (_, thunkAPI) => {
    const user = getState(thunkAPI, 'user');

    return await services
        .logOut(
            {
                query: {locale: user.remotes.userGeneralSettings.current.locale}
            },
            thunkAPI.signal
        )
        .then((response) => {
            if (response.error) {
                return thunkAPI.rejectWithValue(response);
            } else {
                return thunkAPI.fulfillWithValue(response);
            }
        });
});

export {logOut, refreshToken};
