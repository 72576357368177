import type {
    IAuthMFA,
    IUserAuthSettings,
    IUserGeneralSettings,
    IUserInviteEntry,
    IUserProfile,
    IUserSummary,
    TAffinityProvider,
    TRequestStatus
} from '@mcal/core';
import {EAuthMFAType} from '@mcal/core';
import {createSelector} from '@reduxjs/toolkit';
import type {IPartialState, ISliceRemote} from '../../defines/redux.types.js';
import type {IUserSliceState} from './user.state.js';

const userSelector = (state: IPartialState): IUserSliceState => {
    if (!state.user) {
        throw new Error('User slice is not available.');
    }

    return state.user;
};

const selectGetUserStatus = createSelector(
    [userSelector],
    (user): TRequestStatus => {
        return user.sources.getUser.status;
    }
);

const selectUserSummary = createSelector(
    [userSelector],
    (user): IUserSummary => {
        return user.remotes.userSummary.current;
    }
);

const selectUserSummaryRemote = createSelector(
    [userSelector],
    (user): ISliceRemote<IUserSummary> => {
        return user.remotes.userSummary;
    }
);

const selectInvites = createSelector(
    [userSelector],
    (user): IUserInviteEntry<TAffinityProvider>[] => {
        return user.remotes.invites.current;
    }
);

const selectUserProfile = createSelector(
    [userSelector],
    (user): IUserProfile => {
        return user.remotes.userProfile.current;
    }
);

const selectUserGeneralSettings = createSelector(
    [userSelector],
    (user): IUserGeneralSettings => {
        return user.remotes.userGeneralSettings.current;
    }
);

const selectUserAuthSettings = createSelector(
    [userSelector],
    (user): IUserAuthSettings => {
        return user.remotes.userAuthSettings.current;
    }
);
const selectSoftwareMFA = createSelector(
    [userSelector],
    (user): IAuthMFA | undefined => {
        return user.remotes.userAuthSettings.current.mfa.find(
            (mfa) => mfa.type === EAuthMFAType.Software
        );
    }
);

const selectUserAuthSettingsRemote = createSelector(
    [userSelector],
    (user): ISliceRemote<IUserAuthSettings> => {
        return user.remotes.userAuthSettings;
    }
);

const selectUserIsLogged = createSelector([userSelector], (user): boolean => {
    return !!user.remotes.userSummary.current.email;
});

interface IUserEmail {
    value: string;
    isVerified: boolean;
}

const selectUserEmail = createSelector([userSelector], (user): IUserEmail => {
    return {
        value: user.remotes.userSummary.current.email,
        isVerified: user.isEmailVerified
    };
});

interface IUserPhoneNumber {
    value: string | null;
    isVerified: boolean;
}

const selectUserPhoneNumber = createSelector(
    [userSelector],
    (user): IUserPhoneNumber => {
        return {
            value: user.remotes.userSummary.current.phoneNumber,
            isVerified: user.isPhoneNumberVerified
        };
    }
);

interface IUserPasswordStatus {
    updatedAt: number;
}

const selectUserPasswordStatus = createSelector(
    [userSelector],
    (user): IUserPasswordStatus => {
        const {flows} = user.remotes.userAuthSettings.current;

        const match = flows.find(({type}) => type === 'email-password');

        if (match) {
            return {
                updatedAt: match.updatedAt
            };
        } else {
            return {
                updatedAt: -1
            };
        }
    }
);

export type {IUserEmail, IUserPasswordStatus, IUserPhoneNumber};
export {
    selectGetUserStatus,
    selectInvites,
    selectSoftwareMFA,
    selectUserAuthSettings,
    selectUserAuthSettingsRemote,
    selectUserEmail,
    selectUserGeneralSettings,
    selectUserIsLogged,
    selectUserPasswordStatus,
    selectUserPhoneNumber,
    selectUserProfile,
    selectUserSummary,
    selectUserSummaryRemote
};
