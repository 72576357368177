import type {
    EEntityType,
    IElevatorSummary,
    IEntityInviteEntry,
    IServiceCompanyPendingRecord,
    IServiceCompanySummary,
    ISiteSummary,
    ITeamMembership,
    ITechnicianPendingRecord,
    ITechnicianProfile,
    IUserMembership,
    IUserSummary,
    TFloorDistributionKPI,
    TMileageDensityKPI,
    TMileageTotalKPI
} from '@mcal/core';

import type {ISliceRemote} from '../../defines/redux.types.js';
import {createRemote} from '../../utils/create-remote/create-remote.js';

type TPlatformSliceStatus = 'INIT' | 'IDLE' | 'LOADING' | 'FAILED';

type TTeam = ITeamMembership<EEntityType.Platform>[];

type TInvites = IEntityInviteEntry<EEntityType.Platform>[];

interface IPlatformSliceState {
    status: TPlatformSliceStatus;
    remotes: {
        sites: ISliceRemote<ISiteSummary[]>;
        serviceCompanies: ISliceRemote<IServiceCompanySummary[]>;
        pendingServiceCompanies: ISliceRemote<IServiceCompanySummary[]>;
        serviceCompaniesPendingRecords: ISliceRemote<
            IServiceCompanyPendingRecord[]
        >;
        elevators: ISliceRemote<IElevatorSummary[]>;
        team: ISliceRemote<TTeam>;
        memberships: ISliceRemote<IUserMembership[]>;
        technicians: ISliceRemote<ITechnicianProfile[]>;
        pendingTechnicians: ISliceRemote<ITechnicianProfile[]>;
        techniciansPendingRecord: ISliceRemote<ITechnicianPendingRecord[]>;
        invites: ISliceRemote<TInvites>;
        kpis: ISliceRemote<{
            mileageTotal: TMileageTotalKPI | null;
            mileageDensity: TMileageDensityKPI | null;
            floorDistribution: TFloorDistributionKPI | null;
        }>;
        users: ISliceRemote<IUserSummary[]>;
    };
}

const initialState = {
    status: 'INIT',
    remotes: {
        sites: createRemote([]),
        serviceCompanies: createRemote([]),
        pendingServiceCompanies: createRemote([]),
        serviceCompaniesPendingRecords: createRemote([]),
        elevators: createRemote([]),
        team: createRemote([]),
        invites: createRemote([]),
        memberships: createRemote([]),
        technicians: createRemote([]),
        pendingTechnicians: createRemote([]),
        techniciansPendingRecord: createRemote([]),
        kpis: createRemote({
            mileageTotal: null,
            mileageDensity: null,
            floorDistribution: null
        }),
        users: createRemote([])
    }
} satisfies IPlatformSliceState as IPlatformSliceState;

export type {IPlatformSliceState, TInvites, TPlatformSliceStatus, TTeam};
export {initialState};
