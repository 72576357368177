import type {UnknownAction} from '@reduxjs/toolkit';
import type {TAsyncThunk} from '../../defines/redux.types.js';

type TAsyncThunkPendingAction = ReturnType<TAsyncThunk['pending']>;
type TAsyncThunkRejectedAction = ReturnType<TAsyncThunk['rejected']>;
type TAsyncThunkFulfilledAction = ReturnType<TAsyncThunk['fulfilled']>;

type TMatchedAction =
    | TAsyncThunkPendingAction
    | TAsyncThunkRejectedAction
    | TAsyncThunkFulfilledAction;

interface IThunk {
    pending: {type: string};
    fulfilled: {type: string};
    rejected: {type: string};
}

function getAsyncThunkMatcher(
    thunks: IThunk[]
): (action: UnknownAction) => action is TMatchedAction {
    const types: string[] = [];

    for (const thunk of thunks) {
        types.push(thunk.pending.type);
        types.push(thunk.fulfilled.type);
        types.push(thunk.rejected.type);
    }

    return (action: UnknownAction): action is TMatchedAction => {
        return types.includes(action.type);
    };
}

export {getAsyncThunkMatcher};
