import {useTheme} from '@mcal/core-react';
import type {FC} from 'react';
import {useMemo} from 'react';

interface IColors {
    colorA: string;
}

interface IEmergencySvgProps {
    height?: number | string;
    width?: number | string;
    fill?: string;
}

const EmergencySvg: FC<IEmergencySvgProps> = ({fill, ...props}) => {
    const {type} = useTheme();

    const {colorA} = useMemo<IColors>(() => {
        if (type === 'dark') {
            return {
                colorA: '#111215'
            };
        } else {
            return {
                colorA: '#FCFCFC'
            };
        }
    }, [type]);
    return (
        <svg
            xmlns={'http://www.w3.org/2000/svg'}
            width={'16'}
            height={'16'}
            viewBox={'0 0 16 16'}
            {...props}
        >
            <path
                d={
                    'M8 16C7.77778 16 7.58333 15.9653 7.41667 15.8958C7.25 15.8264 7.09028 15.7153 6.9375 15.5625L0.4375 9.0625C0.284722 8.90972 0.173611 8.75 0.104167 8.58333C0.0347222 8.41667 0 8.22222 0 8C0 7.77778 0.0347222 7.58333 0.104167 7.41667C0.173611 7.25 0.282653 7.09235 0.431292 6.94371L6.9375 0.4375C7.09028 0.284722 7.25 0.173611 7.41667 0.104167C7.58333 0.0347222 7.77778 0 8 0C8.22222 0 8.41667 0.0347222 8.58333 0.104167C8.75 0.173611 8.90972 0.284722 9.0625 0.4375L15.5625 6.9375C15.7153 7.09028 15.8264 7.25 15.8958 7.41667C15.9653 7.58333 16 7.77778 16 8C16 8.22222 15.9653 8.41667 15.8958 8.58333C15.8264 8.75 15.7153 8.90972 15.5625 9.0625L9.05629 15.5687C8.90765 15.7173 8.75 15.8264 8.58333 15.8958C8.41667 15.9653 8.22222 16 8 16ZM7.9956 8.5C8.20687 8.5 8.38542 8.42824 8.53125 8.28471C8.67708 8.14118 8.75 7.96333 8.75 7.75115V4.75575C8.75 4.54358 8.67855 4.36458 8.53565 4.21875C8.39273 4.07292 8.21565 4 8.0044 4C7.79313 4 7.61458 4.07176 7.46875 4.21529C7.32292 4.35882 7.25 4.53667 7.25 4.74885V7.74425C7.25 7.95642 7.32145 8.13542 7.46435 8.28125C7.60727 8.42708 7.78435 8.5 7.9956 8.5ZM7.9956 11C8.20687 11 8.38542 10.9285 8.53125 10.7856C8.67708 10.6427 8.75 10.4656 8.75 10.2544C8.75 10.0431 8.67855 9.86458 8.53565 9.71875C8.39273 9.57292 8.21565 9.5 8.0044 9.5C7.79313 9.5 7.61458 9.57145 7.46875 9.71435C7.32292 9.85727 7.25 10.0344 7.25 10.2456C7.25 10.4569 7.32145 10.6354 7.46435 10.7812C7.60727 10.9271 7.78435 11 7.9956 11Z'
                }
                fill={fill || colorA}
            />
        </svg>
    );
};

export {EmergencySvg};
