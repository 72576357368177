import type {
    IRequest,
    TAPIRequest,
    TAPIResponse,
    TAPIUnknown,
    TAxiosError,
    TAxiosResponse,
    TRequestBody,
    TResponseBody,
    TResponseError,
    TResponseSuccess
} from '@mcal/core';
import {MESSAGES, URLUtils} from '@mcal/core';
import {axios} from '../../modules/axios/axios.js';
import {getCommonHeaders} from '../get-common-headers/get-common-headers.js';
import {getRequestError} from '../get-request-error/get-request-error.js';

function request<TAPI extends TAPIUnknown>(
    request: TAPIRequest<TAPI>
): TAPIResponse<TAPI> {
    const {method, url, query, body, ...rest} = request as IRequest;

    return axios
        .request<TResponseBody, TAxiosResponse, TRequestBody>({
            ...rest,
            method,
            url: URLUtils.encodeQuery(url, query),
            data: body
        })
        .then((axiosResponse: TAxiosResponse): TResponseSuccess => {
            const headers = getCommonHeaders(axiosResponse.headers);

            if ('data' in axiosResponse.data) {
                return {
                    headers,
                    meta: axiosResponse.data.meta,
                    data: axiosResponse.data.data,
                    error: null,
                    status: axiosResponse.status
                };
            } else {
                if ('meta' in axiosResponse.data) {
                    return {
                        headers,
                        meta: axiosResponse.data.meta,
                        error: null,
                        status: axiosResponse.status
                    };
                } else {
                    return {
                        headers,
                        meta: null,
                        data: axiosResponse.data,
                        error: null,
                        status: axiosResponse.status
                    };
                }
            }
        })
        .catch((axiosError: TAxiosError): TResponseError => {
            const error = getRequestError(axiosError);
            const {response} = axiosError;

            if (response) {
                const headers = getCommonHeaders(response.headers);

                if ('data' in response.data) {
                    return {
                        headers,
                        error,
                        status: response.status,
                        meta: response.data.meta,
                        data: response.data.data
                    };
                } else {
                    return {
                        headers,
                        error,
                        status: response.status,
                        meta: response.data.meta
                    };
                }
            } else {
                return {
                    headers: getCommonHeaders({}),
                    error,
                    status: -1,
                    meta: {
                        code: MESSAGES.ERROR.GENERAL.UNKNOWN.CODE
                    },
                    data: null
                };
            }
        }) as TAPIResponse<TAPI>;
}

export {request};
