import type {TObj} from '../../../defines/common/utility.types.js';
import {assign} from '../../object-utils/index.js';
import {parse} from '../../string-utils/index.js';

function decodeQuery<T extends object = Record<string, unknown>>(
    url: string
): T {
    const output: TObj<unknown> = {};

    let pairs: string[];

    if (url.includes('?')) {
        pairs = url.split('?')[1].split('&');
    } else if (url.includes('&')) {
        pairs = url.split('&');
    } else if (url.includes('=')) {
        pairs = [url];
    } else {
        return output as T;
    }

    pairs.forEach((pair) => {
        const [token, raw] = pair.split('=');

        const str = decodeURIComponent(raw);

        let value: unknown;

        if (str === '[]') {
            value = [];
        } else if (str === '{}') {
            value = {};
        } else {
            value = parse(str);
        }

        assign(output, decodeURIComponent(token), value);
    });

    return output as T;
}

export {decodeQuery};
