import type {ISliceSource} from '../../defines/redux.types.js';

type TSignUpSliceStatus =
    | 'INIT'
    | 'FINISHED'
    | 'START'
    | 'VERIFY-EMAIL'
    | 'COMPLETE';

interface ISignUpSliceState {
    status: TSignUpSliceStatus;
    sources: {
        start: ISliceSource<null>;
        verifyEmail: ISliceSource<null>;
        complete: ISliceSource<null>;
    };
}

const initialState = {
    status: 'INIT',
    sources: {
        start: {
            status: 'INIT',
            prev: null
        },
        verifyEmail: {
            status: 'INIT',
            prev: null
        },
        complete: {
            status: 'INIT',
            prev: null
        }
    }
} satisfies ISignUpSliceState as ISignUpSliceState;

export type {ISignUpSliceState, TSignUpSliceStatus};
export {initialState};
