import type {TLanguages} from '@mcal/core';
import type {ISliceSource} from '../../defines/redux.types.js';

type TAppSliceStatus = 'INIT' | 'LOADING' | 'UPDATING' | 'IDLE' | 'FAILED';

interface IFontsConfig {
    load: 'ALL' | 'NONE' | 'ESSENTIAL' | 'AUTO';
    unload: 'ALL' | 'NONE';
}

interface IAppSliceState {
    status: TAppSliceStatus;
    languages: TLanguages;
    config: {
        fonts: IFontsConfig;
    };
    sources: {
        loadApp: ISliceSource<null>;
        loadFonts: ISliceSource<null>;
        changeLanguage: ISliceSource<null>;
    };
}

const initialState = {
    status: 'INIT',
    languages: {},
    config: {
        fonts: {
            load: 'ALL',
            unload: 'NONE'
        }
    },
    sources: {
        loadApp: {
            status: 'INIT',
            prev: null
        },
        loadFonts: {
            status: 'INIT',
            prev: null
        },
        changeLanguage: {
            status: 'IDLE',
            prev: null
        }
    }
} satisfies IAppSliceState as IAppSliceState;

const getStatusMap = (
    state: IAppSliceState
): {
    LOADING?: 'UPDATING';
} => {
    if (state.status === 'IDLE' || state.status === 'UPDATING') {
        return {
            LOADING: 'UPDATING'
        };
    } else {
        return {};
    }
};

export type {IAppSliceState, IFontsConfig, TAppSliceStatus};
export {getStatusMap, initialState};
