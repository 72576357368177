import type {ITextBaseProps} from '@mcal/core-react';
import {
    cn,
    getColorPalette,
    resolveShapeValue,
    useFontsStyles,
    useTheme
} from '@mcal/core-react';
import type {FC} from 'react';
import {createTestIDs} from '../../dev/index.js';
import {StyledRoot} from './text.styles.js';

const ownTestIDs = createTestIDs('Text', ['root']);

interface ITextProps extends ITextBaseProps<typeof ownTestIDs> {
    className?: string;
    classes?: {
        root?: string;
    };
}

const Text: FC<ITextProps> = ({
    variant = 'p',
    component,
    typeface,
    styling = 'normal',
    size,
    weight = 400,
    stretch,
    align,
    decoration,
    transform = 'none',
    color,
    emphasis = 'high',
    lineHeight,
    letterSpacing,
    margin,
    shouldWrap,
    pre = false,
    o = null,
    children,
    className = '',
    classes = {},
    testIDs = {},
    ...rest
}) => {
    const theme = useTheme();

    const base = theme.typography.variant[variant];

    const Component = component || base.component;

    const {fontFamily} = useFontsStyles(typeface || base.typeface);

    return (
        <StyledRoot
            {...rest}
            data-testid={testIDs.root || ownTestIDs.root}
            className={cn(className, classes.root)}
            as={Component}
            $fontFamily={fontFamily}
            $fontStyle={styling}
            $fontSize={size ? theme.size.font[size] : base.size}
            $fontWeight={weight}
            $fontStretch={stretch || base.stretch}
            $textAlign={align || base.align}
            $textDecoration={decoration || base.decoration}
            $textTransform={transform}
            $color={
                color
                    ? getColorPalette(theme, color, emphasis).base
                    : base.color
            }
            $lineHeight={lineHeight || base.lineHeight}
            $letterSpacing={letterSpacing || base.letterSpacing}
            $margin={resolveShapeValue('m0', margin)}
            $shouldWrap={
                typeof shouldWrap === 'boolean' ? shouldWrap : base.shouldWrap
            }
            $pre={pre}
            $o={o}
        >
            {children}
        </StyledRoot>
    );
};

export type {ITextProps};
export {Text, ownTestIDs as testIDs};
