import {getColorPalette} from '@mcal/core-react';
import {NavLink} from 'react-router-dom';
import {styled} from '../../utils/styled/styled.js';

const StyledRoot = styled(NavLink)(({theme}) => ({
    color: getColorPalette(theme, 'info', 'high').base,
    cursor: 'pointer',
    textDecoration: 'none'
}));

export {StyledRoot};
