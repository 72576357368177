import {logger} from '@mcal/core';
import type {IThemePack, TDSPaletteInput} from '../../defines/theme.types.js';
import {createTheme} from '../create-theme/create-theme.js';
import type {ITokensOverrides} from '../create-tokens/create-tokens.js';
import {createTokens} from '../create-tokens/create-tokens.js';

const log = logger.withCaller('createThemePack');

const cache: Map<string, IThemePack> = new Map();

interface ICreateThemePackOptions {
    key: string;
    colors: TDSPaletteInput;
    overrides?: ITokensOverrides;
    skipCache?: boolean;
    skipCacheCreation?: boolean;
}

function createThemePack({
    key,
    colors,
    overrides,
    skipCache,
    skipCacheCreation
}: ICreateThemePackOptions): IThemePack {
    const cached = cache.get(key);

    if (cached && !skipCache) {
        log.warn(
            `THEME PACK WITH KEY "${key}" ALREADY EXISTS. RETURNING CACHED VERSION`
        )();

        return cached;
    }

    const tokens = createTokens(colors, overrides);

    const pack = {
        key,
        themes: {
            light: createTheme({
                key: `${key}-light`,
                type: 'light',
                tokens
            }),
            dark: createTheme({
                key: `${key}-dark`,
                type: 'dark',
                tokens
            })
        }
    };

    if (!skipCacheCreation) {
        cache.set(key, pack);
    }

    return pack;
}

export type {ICreateThemePackOptions};
export {createThemePack};
