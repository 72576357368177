import {useTheme} from '@mcal/core-react';
import type {FC} from 'react';
import {useMemo} from 'react';

interface IColors {
    colorA: string;
}

interface IBackSvgProps {
    height?: number | string;
    width?: number | string;
}

const BackSvg: FC<IBackSvgProps> = ({...props}) => {
    const {type} = useTheme();

    const {colorA} = useMemo<IColors>(() => {
        if (type === 'dark') {
            return {
                colorA: '#F5F5F6'
            };
        } else {
            return {
                colorA: '#33373F'
            };
        }
    }, [type]);
    return (
        <>
            <svg
                width={'24'}
                height={'24'}
                viewBox={'0 0 24 24'}
                fill={'none'}
                xmlns={'http://www.w3.org/2000/svg'}
                {...props}
            >
                <path
                    d={
                        'M7.825 13L13.425 18.6L12 20L4 12L12 4L13.425 5.4L7.825 11H20V13H7.825Z'
                    }
                    fill={colorA}
                />
            </svg>
        </>
    );
};

export {BackSvg};
