import type {IMcallinnLogoBaseProps} from '@mcal/core-react';
import type {FC} from 'react';
import {McallinnLogoFullSvg} from './assets/mcallinn-logo-full.svg.js';
import {McallinnLogoIconSvg} from './assets/mcallinn-logo-icon.svg.js';

interface IMcallinnLogoProps extends IMcallinnLogoBaseProps {}

const variants = {
    full: McallinnLogoFullSvg,
    icon: McallinnLogoIconSvg
} as const;

const McallinnLogo: FC<IMcallinnLogoProps> = ({variant = 'full'}) => {
    const Component = variants[variant];

    return <Component />;
};

export type {IMcallinnLogoProps};
export {McallinnLogo};
