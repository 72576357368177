import {createThemePack} from '../../utils/create-theme-pack/create-theme-pack.js';

const CODISTICA_THEME_PACK = createThemePack({
    key: 'codistica',
    colors: {
        primary: '#8cc63f',
        secondary: '#4c8bad',
        tertiary: '#e88b0e',
        neutral: '#080d13',
        divergent: '#172839',
        error: '#bb2222',
        warning: '#eeee22',
        success: '#1acc06',
        info: '#1495da'
    }
});

export {CODISTICA_THEME_PACK};
