import type {
    IShapeValueDescriptor,
    TDSMargin,
    TDSOpacity
} from '@mcal/core-react';
import type {TCSSObject} from '../../defines/styles.types.js';
import {styled} from '../../utils/styled/styled.js';

interface IStyledRootProps {
    $fontFamily: TCSSObject['fontFamily'];
    $fontStyle: TCSSObject['fontStyle'];
    $fontSize: TCSSObject['fontSize'];
    $fontWeight: TCSSObject['fontWeight'];
    $fontStretch: TCSSObject['fontStretch'];
    $textAlign: TCSSObject['textAlign'];
    $textDecoration: TCSSObject['textDecoration'];
    $textTransform: TCSSObject['textTransform'];
    $color: TCSSObject['color'];
    $lineHeight: TCSSObject['lineHeight'];
    $letterSpacing: TCSSObject['letterSpacing'];
    $margin: Required<IShapeValueDescriptor<TDSMargin>>;
    $shouldWrap: boolean;
    $pre: boolean;
    $o: TDSOpacity | null;
}

const StyledRoot = styled('p')<IStyledRootProps>(({
    theme,
    $fontFamily,
    $fontStyle,
    $fontSize,
    $fontWeight,
    $fontStretch,
    $textAlign,
    $textDecoration,
    $textTransform,
    $color,
    $lineHeight,
    $letterSpacing,
    $margin,
    $shouldWrap,
    $pre,
    $o
}) => {
    const map = $pre
        ? {
              wrap: 'pre-wrap',
              noWrap: 'pre'
          }
        : {
              wrap: 'normal',
              noWrap: 'nowrap'
          };

    return {
        fontFamily: $fontFamily,
        fontStyle: $fontStyle,
        fontSize: $fontSize,
        fontWeight: $fontWeight,
        fontStretch: $fontStretch,
        textAlign: $textAlign,
        textDecoration: $textDecoration,
        textTransform: $textTransform,
        color: $color,
        lineHeight: $lineHeight,
        letterSpacing: $letterSpacing,

        marginTop: theme.shape.margin[$margin.top],
        marginRight: theme.shape.margin[$margin.right],
        marginBottom: theme.shape.margin[$margin.bottom],
        marginLeft: theme.shape.margin[$margin.left],

        whiteSpace: $shouldWrap ? map.wrap : map.noWrap,

        opacity: $o ? theme.opacity[$o] : undefined,

        '& svg': {
            color: $color,
            fill: $color
        }
    };
});

export {StyledRoot};
