import {getEntries} from '../../../get-entries/get-entries.js';

function getFilteredEntries(value: unknown): [string | number, unknown][] {
    if (typeof value === 'string') {
        return [];
    } else {
        return getEntries(value);
    }
}

export {getFilteredEntries};
