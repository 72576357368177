const PLATFORM_ID = 'PLATFORM-0000-0000-0000-000000000000';

type TAuthJwtTokenType = 'Bearer' | 'Refresh';

interface IAuthJwtTokenPayload {
    sub: string;
    jti: string;
    iat: number;
    nbf: number;
    exp: number;
    aud: string;
    iss: string;
    typ: TAuthJwtTokenType;
}

type TAuthChallengeName =
    | 'SOFTWARE_TOKEN_MFA'
    | 'SMS_MFA'
    | 'ADMIN_NO_SRP_AUTH'
    | 'CUSTOM_CHALLENGE'
    | 'DEVICE_PASSWORD_VERIFIER'
    | 'DEVICE_SRP_AUTH'
    | 'MFA_SETUP'
    | 'NEW_PASSWORD_REQUIRED'
    | 'PASSWORD_VERIFIER'
    | 'SELECT_MFA_TYPE'
    | 'EMAIL_OTP';

type TAuthResponse =
    | {session: string; challengeName: TAuthChallengeName}
    | {session: null; challengeName: null};

type TAuthFlowType = 'email-password' | 'email-otp' | 'sms-otp' | 'federation';

type TAuthFederationProvider =
    | 'google'
    | 'facebook'
    | 'twitter'
    | 'github'
    | 'linkedin'
    | 'microsoft'
    | 'apple';

interface IAuthFlow {
    type: TAuthFlowType;
    enabled: boolean;
    updatedAt: number;
    provider: TAuthFederationProvider | null;
}

enum EAuthMFAType {
    Email = 'email-otp',
    SMS = 'sms-otp',
    Software = 'software-totp'
}

type TAuthMFAStatus = 'ENABLED' | 'NOT-CONFIGURED' | 'DISABLED';

interface IAuthMFA {
    type: EAuthMFAType;
    status: TAuthMFAStatus;
    preferred: boolean;
    updatedAt: number;
}

const COOKIE_NAMES = {
    REFRESH_TOKEN: 'rF42',
    ACCESS_TOKEN: 'aC42',
    ID_TOKEN: 'iD42'
} as const;

const COOKIE_PATHS = {
    REFRESH_TOKEN: '/session/refresh-token',
    ACCESS_TOKEN: '/',
    ID_TOKEN: '/'
} as const;

const TOKENS = {
    REFRESH_TOKEN_EXP_MAX_AGE: 3600,
    ACCESS_TOKEN_MAX_AGE: 120,
    ID_TOKEN_EXP_MAX_AGE: 120
} as const;

const OTPS = {
    VERIFY_EMAIL_MAX_AGE: 120,
    FORGOT_PASSWORD_MAX_AGE: 120,
    VERIFY_PHONE_NUMBER_MAX_AGE: 120,
    RESEND_THRESHOLD: 60
} as const;

const INVITES = {
    INVITE_MAX_AGE: 240
} as const;

export type {
    IAuthFlow,
    IAuthJwtTokenPayload,
    IAuthMFA,
    TAuthChallengeName,
    TAuthFederationProvider,
    TAuthFlowType,
    TAuthJwtTokenType,
    TAuthMFAStatus,
    TAuthResponse
};
export {
    COOKIE_NAMES,
    COOKIE_PATHS,
    EAuthMFAType,
    INVITES,
    OTPS,
    PLATFORM_ID,
    TOKENS
};
