import {useTheme} from '@mcal/core-react';
import type {FC} from 'react';
import {useMemo} from 'react';

interface IColors {
    colorA: string;
}

interface IAddSvgProps {
    height?: number | string;
    width?: number | string;
    fill?: string;
}

const AddSvg: FC<IAddSvgProps> = ({fill, ...props}) => {
    const {type} = useTheme();

    const {colorA} = useMemo<IColors>(() => {
        if (type === 'dark') {
            return {
                colorA: '#999DA5'
            };
        } else {
            return {
                colorA: '#555C69'
            };
        }
    }, [type]);

    return (
        <svg
            xmlns={'http://www.w3.org/2000/svg'}
            width={'10'}
            height={'10'}
            viewBox={'0 0 10 10'}
            fill={fill}
            {...props}
        >
            <path
                d={
                    'M4.25 5.75H0V4.25H4.25V0H5.75V4.25H10V5.75H5.75V10H4.25V5.75Z'
                }
                fill={fill || colorA}
            />
        </svg>
    );
};

export {AddSvg};
