import type {ISliceRemote} from '../../defines/redux.types.js';
import {createRemote} from '../../utils/create-remote/create-remote.js';

interface ISocketsSliceState {
    remotes: {
        elevatorStatusSubscriptions: ISliceRemote<Array<string>>;
        siteStatusSubscriptions: ISliceRemote<Array<string>>;
    };
}

const initialState: ISocketsSliceState = {
    remotes: {
        elevatorStatusSubscriptions: createRemote([]),
        siteStatusSubscriptions: createRemote([])
    }
};

export type {ISocketsSliceState};
export {initialState};
