import {ColorUtils} from '@mcal/core';
import type {TDSElevation} from '../../defines/theme.types.js';

type TMap = Record<TDSElevation, {shape: string; opacity: number}>;

const map: TMap = {
    e0: {
        shape: '0px 0px 0px',
        opacity: 0
    },
    e1: {
        shape: '0px 2px 3px',
        opacity: 0.2
    },
    e2: {
        shape: '0px 4px 6px',
        opacity: 0.18
    },
    e3: {
        shape: '0px 6px 9px',
        opacity: 0.16
    },
    e4: {
        shape: '0px 8px 12px',
        opacity: 0.14
    },
    e5: {
        shape: '0px 10px 15px',
        opacity: 0.12
    }
};

function createShadow(level: TDSElevation, color: string): string {
    const {shape, opacity} = map[level];
    return `${shape} ${ColorUtils.toRgb(color, opacity)}`;
}

export {createShadow};
