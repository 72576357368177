import type {TTestIDs} from '@mcal/core-react';
import {
    cn,
    elevatorSelectors,
    serviceCompanySelectors,
    siteSelectors,
    useSelector
} from '@mcal/core-react';
import type {FC, ReactNode} from 'react';
import {Fragment, useCallback, useMemo} from 'react';
import {useLocation} from 'react-router-dom';
import {createTestIDs} from '../../dev/index.js';
import {HomeSvg} from '../../icons/home.svg.js';
import {Text} from '../text/text.js';
import {StyledLink, StyledRoot} from './breadcrumbs.styles.js';

interface IBreadcrumbSibling {
    label: string;
    path: string;
}

interface IBreadcrumbCurrent extends IBreadcrumbSibling {
    key: string;
    siblings: IBreadcrumbSibling[];
    isLast: boolean;
    isHome: boolean;
}

interface IRenderElementProps {
    current: IBreadcrumbCurrent;
}

interface IBreadcrumbSegment {
    current: Omit<IBreadcrumbCurrent, 'isLast' | 'isHome'>;
    index: number;
}

const ownTestIDs = createTestIDs('Breadcrumb', ['root']);

interface IBreadcrumbProps {
    className?: string;
    classes?: {
        root?: string;
    };
    renderElement?: (props: IRenderElementProps) => ReactNode;
    testIDs?: TTestIDs<typeof ownTestIDs>;
}

const Breadcrumbs: FC<IBreadcrumbProps> = ({
    classes = {},
    className,
    renderElement,
    testIDs = {}
}) => {
    const location = useLocation();

    const serviceCompany = useSelector(
        serviceCompanySelectors.selectServiceCompanySummary
    );
    const site = useSelector(siteSelectors.selectSiteSummary);
    const elevator = useSelector(elevatorSelectors.selectElevatorSummary);

    // Special rules for breadcrumb generation
    const SPECIAL_PATHS = useMemo(
        () => ({
            'service-companies': {
                path: (id: string) => `/service-companies/${id}`,
                label: serviceCompany.fiscalName
            },
            site: {
                path: (id: string) => `/site/${id}`,
                label: site.name
            }
        }),
        [serviceCompany.fiscalName, site.name]
    );

    const isUUID = useCallback((str: string): boolean => {
        //NOTE: This is a check for UUIDs and the special MOCKSITE and MOCKISP0 values
        // otherwise it won't work on current development env data
        const uuidRegex =
            /^(([0-9a-f]{8})|(MOCKSITE|MOCKISP0))-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i;

        return uuidRegex.test(str);
    }, []);

    const formatDisplayText = useCallback(
        (segment: string): string => {
            if (isUUID(segment)) {
                if (segment === elevator.entityId) {
                    return elevator.name;
                }

                return '';
            }

            return segment
                .split('-')
                .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                .join(' ');
        },
        [elevator.entityId, elevator.name, isUUID]
    );

    const generateBreadcrumbs = useCallback(
        (currentPath: string): IBreadcrumbSegment[] => {
            const segments = currentPath.split('/').filter(Boolean);
            const breadcrumbs: IBreadcrumbSegment[] = [];
            let currentIndex = 0;

            // Home breadcrumb
            breadcrumbs.push({
                current: {
                    key: 'home',
                    path: '/',
                    label: 'Home',
                    siblings: [{label: 'Home', path: '/'}]
                },
                index: currentIndex++
            });

            if (segments.length === 0) {
                return breadcrumbs;
            }

            // Analyze the path to identify special patterns
            const baseType = segments[0];

            if (baseType in SPECIAL_PATHS && segments.length >= 2) {
                const id = segments[1];

                if (isUUID(id)) {
                    const specialPath =
                        SPECIAL_PATHS[baseType as keyof typeof SPECIAL_PATHS];

                    // Handle "explore" case
                    const explorePath = `${specialPath.path(id)}/overview`;

                    breadcrumbs.push({
                        current: {
                            key: segments[1],
                            path: explorePath,
                            label: specialPath.label,
                            siblings: [
                                {
                                    label: specialPath.label,
                                    path: explorePath
                                }
                            ]
                        },
                        index: currentIndex++
                    });

                    // Add breadcrumbs for the remaining sub paths
                    for (let i = 2; i < segments.length; i++) {
                        const subPath =
                            '/' + segments.slice(0, i + 1).join('/');

                        if (segments[i] !== 'overview') {
                            breadcrumbs.push({
                                current: {
                                    key: segments[i],
                                    path: subPath,
                                    label: formatDisplayText(segments[i]),
                                    siblings: [
                                        {
                                            label: formatDisplayText(
                                                segments[i]
                                            ),
                                            path: subPath
                                        }
                                    ]
                                },
                                index: currentIndex++
                            });
                        }
                    }
                }
            } else {
                // Standard handling for non-special paths
                for (let i = 0; i < segments.length; i++) {
                    const path = '/' + segments.slice(0, i + 1).join('/');

                    breadcrumbs.push({
                        current: {
                            key: segments[i],
                            path,
                            label: formatDisplayText(segments[i]),
                            siblings: [
                                {
                                    label: formatDisplayText(segments[i]),
                                    path
                                }
                            ]
                        },
                        index: currentIndex++
                    });
                }
            }

            return breadcrumbs;
        },
        [SPECIAL_PATHS, formatDisplayText, isUUID]
    );

    const breadcrumbs = useMemo(() => {
        return generateBreadcrumbs(location.pathname);
    }, [generateBreadcrumbs, location.pathname]);

    const DefaultElement = useCallback(
        ({current}: {current: IBreadcrumbCurrent}) => {
            return (
                <StyledLink to={current.path}>
                    {current.isHome && <HomeSvg />}
                    {current.label}
                </StyledLink>
            );
        },
        []
    );

    return (
        <StyledRoot
            data-testid={testIDs.root || ownTestIDs.root}
            className={cn(className, classes.root)}
        >
            {breadcrumbs.map(({current, index}) => {
                const isLast = index === breadcrumbs.length - 1;
                const isHome = current.key === 'home';

                const enrichedCurrent: IBreadcrumbCurrent = {
                    ...current,
                    isLast,
                    isHome
                };

                return (
                    <Fragment key={current.path}>
                        {renderElement ? (
                            renderElement({current: enrichedCurrent})
                        ) : (
                            <DefaultElement current={enrichedCurrent} />
                        )}

                        {isLast ? '' : <Text size={'s3'}>{' / '}</Text>}
                    </Fragment>
                );
            })}
        </StyledRoot>
    );
};

export type {
    IBreadcrumbCurrent,
    IBreadcrumbProps,
    IBreadcrumbSegment,
    IBreadcrumbSibling,
    IRenderElementProps
};
export {Breadcrumbs, ownTestIDs as testIDs};
