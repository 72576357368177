import {logger} from '@mcal/core';
import type {Middleware} from '@reduxjs/toolkit';
import type {
    INotification,
    INotificationOption
} from '../../defines/platform.types.js';
import type {
    IDispatch,
    IDispatchExtNotification,
    IPartialState
} from '../../defines/redux.types.js';
import {notificationsActions} from '../../slices/notifications/notifications.index.js';

const log = logger.withCaller('notificationsMiddleware');

interface IRegistry {
    [k: string]: {
        notification: INotification;
        promise: {
            resolve: (value: INotificationOption | null) => void;
            reject: () => void;
        };
    };
}

const notificationsMiddleware: Middleware<
    IDispatchExtNotification,
    IPartialState,
    IDispatch // WE ASSUME THIS MIDDLEWARE TO BE USED ALONG ALL BASE MIDDLEWARES
> = () => (next) => {
    const registry: IRegistry = {};

    return (action) => {
        const result = next(action);

        if (notificationsActions.send.match(action)) {
            const notification = action.payload;

            return new Promise((resolve, reject) => {
                registry[notification.id] = {
                    notification,
                    promise: {
                        resolve,
                        reject
                    }
                };
            });
        } else if (notificationsActions.remove.match(action)) {
            const match = registry[action.payload.id];

            if (match) {
                const {notification, promise} = match;

                if (action.payload.reason) {
                    const option = notification.options.find(({key}) => {
                        return key === action.payload.reason;
                    });

                    if (option) {
                        promise.resolve(option);
                    } else {
                        log.error(
                            'PROVIDED REMOVAL REASON NOT FOUND IN NOTIFICATION OPTIONS'
                        )();

                        promise.reject();
                    }
                } else {
                    promise.resolve(null);
                }
            } else {
                log.error('NO NOTIFICATION FOUND IN REGISTRY')();
            }

            delete registry[action.payload.id];

            return result;
        } else {
            return result;
        }
    };
};

export {notificationsMiddleware};
