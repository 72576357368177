import {
    associateSoftwareTOTP,
    createSoftwareTOTP,
    toggleMFA
} from '../../services/app-node-auth/mfa/mfa.thunks.js';
import {
    createTechnicianProfile,
    getUser,
    updateEmail,
    updateGeneralSettings,
    updatePassword,
    updatePhoneNumber,
    updateProfile,
    updateProfilePhoto,
    uploadTechnicianProfileDocuments,
    verifyEmail,
    verifyPhoneNumber
} from '../../services/app-node-auth/user/user.thunks.js';
import {
    listUserInvites,
    respondInvite
} from '../../services/app-node-invites/invites/invites.thunks.js';
import * as userRemotes from './user.remotes.js';
import * as userSelectors from './user.selectors.js';
import {userSlice} from './user.slice.js';

const userActions = userSlice.actions;

const userThunks = {
    updateEmail,
    updatePassword,
    updatePhoneNumber,
    updateProfile,
    uploadTechnicianProfileDocuments,
    updateProfilePhoto,
    updateGeneralSettings,
    verifyEmail,
    verifyPhoneNumber,
    createTechnicianProfile,
    getUser,
    createSoftwareTOTP,
    associateSoftwareTOTP,
    toggleMFA,
    listUserInvites,
    respondInvite
};

export {userActions, userRemotes, userSelectors, userSlice, userThunks};
