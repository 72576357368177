import {ColorUtils} from '@mcal/core';
import type {TComponentColor} from '@mcal/core-react';
import {getColorPalette} from '@mcal/core-react';
import {styled} from '../../utils/styled/styled.js';

interface IStatusContainer {
    inline?: boolean;
    color: TComponentColor;
    withBackground?: boolean;
    rounded?: boolean;
    emphasis?: 'low' | 'high';
    level?: 'base' | 'onBase';
    opacity?: number;
    padding: string;
    $width?: string;
}

const StyledRoot = styled('div')<IStatusContainer>(
    ({rounded, $width, padding, inline}) => ({
        display: inline ? 'inline-flex' : 'flex',
        width: $width || 'max-content',
        height: $width || 'max-content',
        padding: padding,
        justifyContent: 'center',
        alignItems: 'center',
        flexShrink: '0',
        borderRadius: rounded ? '9999px' : '4px'
    }),
    ({
        theme,
        color,
        withBackground,
        emphasis = 'high',
        level = 'base',
        opacity
    }) => {
        if (!withBackground) {
            return {};
        }

        if (color === 'error') {
            return {
                backgroundColor: theme.palette.accent.error[emphasis][level]
            };
        }

        return {
            backgroundColor: ColorUtils.toRgb(
                getColorPalette(theme, color, emphasis)[level],
                opacity || theme.opacity.o1
            )
        };
    }
);

export {StyledRoot};
