import type {IFlexBaseProps} from '@mcal/core-react';
import type {FC} from 'react';
import {createTestIDs} from '../../dev/index.js';
import {StyledRoot} from './flex.styles.js';

const ownTestIDs = createTestIDs('Flex', ['root']);

interface IFlexProps extends IFlexBaseProps<typeof ownTestIDs> {
    onClick?: () => void;
}

const Flex: FC<IFlexProps> = ({
    children,
    display = 'flex',
    flexDirection = 'row',
    flexWrap = 'nowrap',
    justifyContent = 'center',
    alignItems = 'center',
    alignContent = 'center',
    order = 0,
    flexGrow = 1,
    flexShrink = 0,
    flexBasis = 'inherit',
    alignSelf = 'stretch',
    gap = 'inherit',
    testIDs = {},
    level = undefined,
    ...rest
}) => {
    return (
        <StyledRoot
            display={display}
            flexDirection={flexDirection}
            flexWrap={flexWrap}
            justifyContent={justifyContent}
            alignItems={alignItems}
            alignContent={alignContent}
            order={order}
            flexGrow={flexGrow}
            flexShrink={flexShrink}
            flexBasis={flexBasis}
            alignSelf={alignSelf}
            gap={gap}
            level={level}
            {...rest}
            data-testid={testIDs.root || ownTestIDs.root}
        >
            {children}
        </StyledRoot>
    );
};

export type {IFlexProps};
export {Flex};
