import {isLowerCase} from '../is-lower-case/is-lower-case.js';
import {isUpperCase} from '../is-upper-case/is-upper-case.js';

function splitByWord(input: string): string[] {
    const output: string[] = [];

    const isSep = (s: string): boolean => [' ', '-', '_'].indexOf(s) >= 0;

    let acc = '';

    let prev = '';
    let curr = input.charAt(0);
    let next = input.charAt(1);

    for (let i = 0; i < input.length; i++) {
        if (isSep(curr)) {
            if (acc) {
                output.push(acc);
                acc = '';
            }

            prev = curr;
            curr = next;
            next = input.charAt(i + 2);

            continue;
        }

        if (prev && !isSep(prev) && isLowerCase(prev) && isUpperCase(curr)) {
            if (acc) {
                output.push(acc);
                acc = '';
            }
        } else if (
            next &&
            !isSep(next) &&
            isLowerCase(next) &&
            isUpperCase(curr)
        ) {
            if (acc) {
                output.push(acc);
                acc = '';
            }
        }

        acc += curr;

        prev = curr;
        curr = next;
        next = input.charAt(i + 2);
    }

    if (acc) {
        output.push(acc);
    }

    return output;
}

export {splitByWord};
