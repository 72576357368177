import type {
    TAPIResponseSuccess,
    TElevatorGroupDeleteAPI,
    TElevatorGroupGetAPI,
    TElevatorGroupGetStateAPI,
    TElevatorGroupTeamDeleteAPI,
    TElevatorGroupTeamGetAPI,
    TElevatorGroupTeamUpdateAPI,
    TElevatorGroupUpdateProfileAPI,
    TElevatorGroupsCreateAPI,
    TElevatorGroupsListAPI,
    TSiteGetAPI,
    TSiteGetActivityAPI,
    TSiteGetStateAPI,
    TSiteTeamDeleteAPI,
    TSiteTeamGetAPI,
    TSiteTeamUpdateAPI,
    TSiteUpdateProfileAPI,
    TSitesCreateAPI,
    TSitesListAPI
} from '@mcal/core';
import {MESSAGES} from '@mcal/core';
import {createAsyncThunk} from '@reduxjs/toolkit';
import type {
    TAPIAsyncThunkArgs,
    TAPIAsyncThunkConfig
} from '../../../defines/redux.types.js';
import {notificationsActions} from '../../../slices/notifications/notifications.index.js';
import {getFulfillValue} from '../../../utils/get-fulfill-value/get-fulfill-value.js';
import {getState} from '../../../utils/get-state/get-state.js';
import {getUsersMemberships} from '../../app-node-auth/users/users.thunks.js';
import * as services from './sites.services.js';

const addElevatorGroup = createAsyncThunk<
    TAPIResponseSuccess<TElevatorGroupsCreateAPI>,
    TAPIAsyncThunkArgs<TElevatorGroupsCreateAPI>,
    TAPIAsyncThunkConfig<TElevatorGroupsCreateAPI>
>('sites/addElevatorGroup', async (args, thunkAPI) => {
    const user = getState(thunkAPI, 'user');

    return await services
        .addElevatorGroup(
            {
                query: {
                    locale: user.remotes.userGeneralSettings.current.locale
                },
                body: {
                    data: {
                        siteId: args.siteId,
                        name: args.name
                    }
                }
            },
            thunkAPI.signal
        )
        .then((response) => {
            if (response.error) {
                void thunkAPI.dispatch(
                    notificationsActions.send({
                        title: 'Error',
                        message: 'Failed to update elevators profile'
                    })
                );

                return thunkAPI.rejectWithValue(response);
            } else {
                void thunkAPI.dispatch(
                    notificationsActions.send({
                        title: 'Success',
                        message: 'Elevator group add it successfully'
                    })
                );

                return thunkAPI.fulfillWithValue(response);
            }
        });
});

const updateElevatorGroupProfile = createAsyncThunk<
    TAPIResponseSuccess<TElevatorGroupUpdateProfileAPI>,
    TAPIAsyncThunkArgs<TElevatorGroupUpdateProfileAPI>,
    TAPIAsyncThunkConfig<TElevatorGroupUpdateProfileAPI>
>('sites/updateElevatorGroupProfile', async (args, thunkAPI) => {
    const user = getState(thunkAPI, 'user');

    return await services
        .updateElevatorGroupProfile(
            {
                query: {
                    locale: user.remotes.userGeneralSettings.current.locale
                },
                body: {
                    data: {
                        elevatorGroupId: args.elevatorGroupId,
                        profile: args.profile
                    }
                }
            },
            thunkAPI.signal
        )
        .then((response) => {
            if (response.error) {
                void thunkAPI.dispatch(
                    notificationsActions.send({
                        title: 'Error',
                        message: 'Failed to update elevator group profile'
                    })
                );

                return thunkAPI.rejectWithValue(response);
            } else {
                void thunkAPI.dispatch(
                    notificationsActions.send({
                        title: 'Success',
                        message: 'Elevator group profile update it successfully'
                    })
                );

                return thunkAPI.fulfillWithValue(response);
            }
        });
});

const deleteElevatorGroup = createAsyncThunk<
    TAPIResponseSuccess<TElevatorGroupDeleteAPI>,
    TAPIAsyncThunkArgs<TElevatorGroupDeleteAPI>,
    TAPIAsyncThunkConfig<TElevatorGroupDeleteAPI>
>('sites/deleteElevatorGroup', async (args, thunkAPI) => {
    const user = getState(thunkAPI, 'user');

    return await services
        .deleteElevatorGroup(
            {
                query: {
                    locale: user.remotes.userGeneralSettings.current.locale
                },
                body: {
                    data: {
                        elevatorGroupId: args.elevatorGroupId
                    }
                }
            },
            thunkAPI.signal
        )
        .then((response) => {
            if (response.error) {
                void thunkAPI.dispatch(
                    notificationsActions.send({
                        title: 'Error',
                        message: 'Failed to delete elevators group'
                    })
                );

                return thunkAPI.rejectWithValue(response);
            } else {
                void thunkAPI.dispatch(
                    notificationsActions.send({
                        title: 'Success',
                        message: 'Delete elevators group sucessfully'
                    })
                );

                return thunkAPI.fulfillWithValue(response);
            }
        });
});

const listSites = createAsyncThunk<
    TAPIResponseSuccess<TSitesListAPI>,
    TAPIAsyncThunkArgs<TSitesListAPI>,
    TAPIAsyncThunkConfig<TSitesListAPI>
>('sites/listSites', async (args, thunkAPI) => {
    const user = getState(thunkAPI, 'user');
    return await services
        .listSites(
            {
                query: {
                    locale: user.remotes.userGeneralSettings.current.locale,
                    serviceCompanyId: args.serviceCompanyId
                }
            },
            thunkAPI.signal
        )
        .then((response) => {
            if (response.error) {
                return thunkAPI.rejectWithValue(response);
            } else {
                return thunkAPI.fulfillWithValue(response);
            }
        });
});

const getSite = createAsyncThunk<
    TAPIResponseSuccess<TSiteGetAPI>,
    TAPIAsyncThunkArgs<TSiteGetAPI>,
    TAPIAsyncThunkConfig<TSiteGetAPI>
>('sites/getSite', async (args, thunkAPI) => {
    const user = getState(thunkAPI, 'user');

    return await services
        .getSite(
            {
                query: {
                    locale: user.remotes.userGeneralSettings.current.locale,
                    siteId: args.siteId
                }
            },
            thunkAPI.signal
        )
        .then((response) => {
            if (response.error) {
                return thunkAPI.rejectWithValue(response);
            } else {
                return thunkAPI.fulfillWithValue(response);
            }
        });
});

const getSiteState = createAsyncThunk<
    TAPIResponseSuccess<TSiteGetStateAPI>,
    TAPIAsyncThunkArgs<TSiteGetStateAPI>,
    TAPIAsyncThunkConfig<TSiteGetStateAPI>
>('sites/getSiteState', async (args, thunkAPI) => {
    const user = getState(thunkAPI, 'user');

    return await services
        .getSiteState(
            {
                query: {
                    locale: user.remotes.userGeneralSettings.current.locale,
                    siteId: args.siteId
                }
            },
            thunkAPI.signal
        )
        .then((response) => {
            if (response.error) {
                return thunkAPI.rejectWithValue(response);
            } else {
                return thunkAPI.fulfillWithValue(response);
            }
        });
});

const getSiteTeam = createAsyncThunk<
    TAPIResponseSuccess<TSiteTeamGetAPI>,
    TAPIAsyncThunkArgs<TSiteTeamGetAPI>,
    TAPIAsyncThunkConfig<TSiteTeamGetAPI>
>('sites/getSiteTeam', async (args, thunkAPI) => {
    const user = getState(thunkAPI, 'user');

    return await services
        .getSiteTeam(
            {
                query: {
                    locale: user.remotes.userGeneralSettings.current.locale,
                    siteId: args.siteId,
                    affinityId: args.affinityId
                }
            },
            thunkAPI.signal
        )
        .then((response) => {
            if (response.error) {
                return thunkAPI.rejectWithValue(response);
            } else {
                void thunkAPI.dispatch(
                    getUsersMemberships({
                        entityId: args.siteId,
                        affinityId: args.affinityId,
                        members: response.data.map(({userId}) => userId)
                    })
                );

                return thunkAPI.fulfillWithValue(response);
            }
        });
});

const updateSiteTeam = createAsyncThunk<
    TAPIResponseSuccess<TSiteTeamUpdateAPI>,
    TAPIAsyncThunkArgs<TSiteTeamUpdateAPI>,
    TAPIAsyncThunkConfig<TSiteTeamUpdateAPI>
>('sites/updateSiteTeam', async (args, thunkAPI) => {
    const user = getState(thunkAPI, 'user');

    return await services
        .updateSiteTeam(
            {
                query: {
                    locale: user.remotes.userGeneralSettings.current.locale
                },
                body: {
                    data: {
                        siteId: args.siteId,
                        affinityId: args.affinityId,
                        userId: args.userId,
                        role: args.role
                    }
                }
            },
            thunkAPI.signal
        )
        .then((response) => {
            if (response.error) {
                return thunkAPI.rejectWithValue(response);
            } else {
                return thunkAPI.fulfillWithValue(response);
            }
        });
});

const deleteSiteTeam = createAsyncThunk<
    TAPIResponseSuccess<TSiteTeamDeleteAPI>,
    TAPIAsyncThunkArgs<TSiteTeamDeleteAPI>,
    TAPIAsyncThunkConfig<TSiteTeamDeleteAPI>
>('sites/deleteSiteTeam', async (args, thunkAPI) => {
    const user = getState(thunkAPI, 'user');

    return await services
        .deleteSiteTeam(
            {
                query: {
                    locale: user.remotes.userGeneralSettings.current.locale
                },
                body: {
                    data: {
                        siteId: args.siteId,
                        affinityId: args.affinityId,
                        userId: args.userId
                    }
                }
            },
            thunkAPI.signal
        )
        .then((response) => {
            if (response.error) {
                return thunkAPI.rejectWithValue(response);
            } else {
                return thunkAPI.fulfillWithValue(response);
            }
        });
});

const listElevatorGroups = createAsyncThunk<
    TAPIResponseSuccess<TElevatorGroupsListAPI>,
    TAPIAsyncThunkArgs<TElevatorGroupsListAPI>,
    TAPIAsyncThunkConfig<TElevatorGroupsListAPI>
>('elevatorGroups/listElevatorGroups', async (args, thunkAPI) => {
    const user = getState(thunkAPI, 'user');

    return await services
        .listElevatorGroups(
            {
                query: {
                    locale: user.remotes.userGeneralSettings.current.locale,
                    scope: args.scope,
                    target: args.target
                }
            },
            thunkAPI.signal
        )
        .then((response) => {
            if (response.error) {
                return thunkAPI.rejectWithValue(response);
            } else {
                return thunkAPI.fulfillWithValue(response);
            }
        });
});

const getElevatorGroup = createAsyncThunk<
    TAPIResponseSuccess<TElevatorGroupGetAPI>,
    TAPIAsyncThunkArgs<TElevatorGroupGetAPI>,
    TAPIAsyncThunkConfig<TElevatorGroupGetAPI>
>('elevatorGroups/getElevatorGroup', async (args, thunkAPI) => {
    const user = getState(thunkAPI, 'user');

    return await services
        .getElevatorGroup(
            {
                query: {
                    locale: user.remotes.userGeneralSettings.current.locale,
                    elevatorGroupId: args.elevatorGroupId
                }
            },
            thunkAPI.signal
        )
        .then((response) => {
            if (response.error) {
                return thunkAPI.rejectWithValue(response);
            } else {
                return thunkAPI.fulfillWithValue(response);
            }
        });
});

const getElevatorGroupState = createAsyncThunk<
    TAPIResponseSuccess<TElevatorGroupGetStateAPI>,
    TAPIAsyncThunkArgs<TElevatorGroupGetStateAPI>,
    TAPIAsyncThunkConfig<TElevatorGroupGetStateAPI>
>('elevatorGroups/getElevatorGroupState', async (args, thunkAPI) => {
    const user = getState(thunkAPI, 'user');

    return await services
        .getElevatorGroupState(
            {
                query: {
                    locale: user.remotes.userGeneralSettings.current.locale,
                    elevatorGroupId: args.elevatorGroupId
                }
            },
            thunkAPI.signal
        )
        .then((response) => {
            if (response.error) {
                return thunkAPI.rejectWithValue(response);
            } else {
                return thunkAPI.fulfillWithValue(response);
            }
        });
});

const getElevatorGroupTeam = createAsyncThunk<
    TAPIResponseSuccess<TElevatorGroupTeamGetAPI>,
    TAPIAsyncThunkArgs<TElevatorGroupTeamGetAPI>,
    TAPIAsyncThunkConfig<TElevatorGroupTeamGetAPI>
>('elevatorGroups/getElevatorGroupTeam', async (args, thunkAPI) => {
    const user = getState(thunkAPI, 'user');

    return await services
        .getElevatorGroupTeam(
            {
                query: {
                    locale: user.remotes.userGeneralSettings.current.locale,
                    elevatorGroupId: args.elevatorGroupId,
                    affinityId: args.affinityId
                }
            },
            thunkAPI.signal
        )
        .then((response) => {
            if (response.error) {
                return thunkAPI.rejectWithValue(response);
            } else {
                return thunkAPI.fulfillWithValue(response);
            }
        });
});

const updateElevatorGroupTeam = createAsyncThunk<
    TAPIResponseSuccess<TElevatorGroupTeamUpdateAPI>,
    TAPIAsyncThunkArgs<TElevatorGroupTeamUpdateAPI>,
    TAPIAsyncThunkConfig<TElevatorGroupTeamUpdateAPI>
>('elevatorGroups/updateElevatorGroupTeam', async (args, thunkAPI) => {
    const user = getState(thunkAPI, 'user');

    return await services
        .updateElevatorGroupTeam(
            {
                query: {
                    locale: user.remotes.userGeneralSettings.current.locale
                },
                body: {
                    data: {
                        elevatorGroupId: args.elevatorGroupId,
                        affinityId: args.affinityId,
                        userId: args.userId,
                        role: args.role
                    }
                }
            },
            thunkAPI.signal
        )
        .then((response) => {
            if (response.error) {
                return thunkAPI.rejectWithValue(response);
            } else {
                return thunkAPI.fulfillWithValue(response);
            }
        });
});

const deleteElevatorGroupTeam = createAsyncThunk<
    TAPIResponseSuccess<TElevatorGroupTeamDeleteAPI>,
    TAPIAsyncThunkArgs<TElevatorGroupTeamDeleteAPI>,
    TAPIAsyncThunkConfig<TElevatorGroupTeamDeleteAPI>
>('elevatorGroups/deleteElevatorGroupTeam', async (args, thunkAPI) => {
    const user = getState(thunkAPI, 'user');

    return await services
        .deleteElevatorGroupTeam(
            {
                query: {
                    locale: user.remotes.userGeneralSettings.current.locale
                },
                body: {
                    data: {
                        elevatorGroupId: args.elevatorGroupId,
                        affinityId: args.affinityId,
                        userId: args.userId
                    }
                }
            },
            thunkAPI.signal
        )
        .then((response) => {
            if (response.error) {
                return thunkAPI.rejectWithValue(response);
            } else {
                return thunkAPI.fulfillWithValue(response);
            }
        });
});

const loadMoreSiteActivity = createAsyncThunk<
    TAPIResponseSuccess<TSiteGetActivityAPI>,
    TAPIAsyncThunkArgs<TSiteGetActivityAPI, {firstLoad?: boolean}>,
    TAPIAsyncThunkConfig<TSiteGetActivityAPI>
>('sites/loadMoreSiteActivity', async (args, thunkAPI) => {
    const user = getState(thunkAPI, 'user');
    const site = getState(thunkAPI, 'site');

    if (
        site.remotes.activity.current.data.length &&
        site.remotes.activity.current.data[0].siteId === args.siteId &&
        (!site.remotes.activity.current.nextCursor || args.firstLoad)
    ) {
        return thunkAPI.fulfillWithValue(
            getFulfillValue<TSiteGetActivityAPI>({
                meta: {
                    code: MESSAGES.NORMAL.CLIENT.PAGINATION_LIMIT_REACHED.CODE
                },
                data: {
                    currentCursor:
                        site.remotes.activity.current.currentCursor || '',
                    nextCursor: site.remotes.activity.current.nextCursor,
                    from: null,
                    to: null,
                    data: []
                }
            })
        );
    }

    return await services
        .getSiteActivity(
            {
                query: {
                    locale: user.remotes.userGeneralSettings.current.locale,
                    siteId: args.siteId,
                    cursor: args.firstLoad
                        ? null
                        : site.remotes.activity.current.nextCursor,
                    limit: 10
                }
            },
            thunkAPI.signal
        )
        .then((response) => {
            if (response.error) {
                return thunkAPI.rejectWithValue(response);
            } else {
                return thunkAPI.fulfillWithValue(response);
            }
        });
});

const createSite = createAsyncThunk<
    TAPIResponseSuccess<TSitesCreateAPI>,
    TAPIAsyncThunkArgs<TSitesCreateAPI>,
    TAPIAsyncThunkConfig<TSitesCreateAPI>
>('/', async (args, thunkAPI) => {
    const user = getState(thunkAPI, 'user');

    return await services
        .createSite(
            {
                query: {
                    locale: user.remotes.userGeneralSettings.current.locale
                },
                body: {
                    data: {
                        serviceCompanyId: args.serviceCompanyId,
                        name: args.name,
                        address: args.address
                    }
                }
            },
            thunkAPI.signal
        )
        .then((response) => {
            if (response.error) {
                void thunkAPI.dispatch(
                    notificationsActions.send({
                        title: 'Error',
                        message: 'Failed to create site'
                    })
                );

                return thunkAPI.rejectWithValue(response);
            } else {
                void thunkAPI.dispatch(
                    notificationsActions.send({
                        title: 'Success',
                        message: 'Create site successfully'
                    })
                );

                return thunkAPI.fulfillWithValue(response);
            }
        });
});

const updateSiteProfile = createAsyncThunk<
    TAPIResponseSuccess<TSiteUpdateProfileAPI>,
    TAPIAsyncThunkArgs<TSiteUpdateProfileAPI>,
    TAPIAsyncThunkConfig<TSiteUpdateProfileAPI>
>('sites/updateSiteProfile', async (args, thunkAPI) => {
    const user = getState(thunkAPI, 'user');

    return await services
        .updateSiteProfile(
            {
                query: {
                    locale: user.remotes.userGeneralSettings.current.locale
                },
                body: {
                    data: {
                        siteId: args.siteId,
                        profile: args.profile
                    }
                }
            },
            thunkAPI.signal
        )
        .then((response) => {
            if (response.error) {
                void thunkAPI.dispatch(
                    notificationsActions.send({
                        title: 'Error',
                        message: 'Failed to update site profile'
                    })
                );

                return thunkAPI.rejectWithValue(response);
            } else {
                void thunkAPI.dispatch(
                    notificationsActions.send({
                        title: 'Success',
                        message: 'Site updated successfully'
                    })
                );

                return thunkAPI.fulfillWithValue(response);
            }
        });
});
export {
    addElevatorGroup,
    createSite,
    deleteElevatorGroup,
    deleteElevatorGroupTeam,
    deleteSiteTeam,
    getElevatorGroup,
    getElevatorGroupState,
    getElevatorGroupTeam,
    getSite,
    getSiteState,
    getSiteTeam,
    listElevatorGroups,
    listSites,
    loadMoreSiteActivity,
    updateElevatorGroupProfile,
    updateElevatorGroupTeam,
    updateSiteProfile,
    updateSiteTeam
};
