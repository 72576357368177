import type {
    TAPIRequestContent,
    TAPIResponse,
    TMFASoftwareTOTPAssociateAPI,
    TMFASoftwareTOTPCreateAPI,
    TMFAToggleAPI
} from '@mcal/core';
import {request} from '../../../utils/request/request.js';

const createSoftwareTOTP = (
    {query}: TAPIRequestContent<TMFASoftwareTOTPCreateAPI>,
    signal: AbortSignal
): TAPIResponse<TMFASoftwareTOTPCreateAPI> => {
    return request<TMFASoftwareTOTPCreateAPI>({
        method: 'POST',
        url: '/mfa/software-totp/create',
        signal,
        query
    });
};

const associateSoftwareTOTP = (
    {query, body}: TAPIRequestContent<TMFASoftwareTOTPAssociateAPI>,
    signal: AbortSignal
): TAPIResponse<TMFASoftwareTOTPAssociateAPI> => {
    return request<TMFASoftwareTOTPAssociateAPI>({
        method: 'POST',
        url: '/mfa/software-totp/associate',
        signal,
        query,
        body
    });
};

const toggleMFA = (
    {query, body}: TAPIRequestContent<TMFAToggleAPI>,
    signal: AbortSignal
): TAPIResponse<TMFAToggleAPI> => {
    return request<TMFAToggleAPI>({
        method: 'POST',
        url: '/mfa/toggle',
        signal,
        query,
        body
    });
};

export {associateSoftwareTOTP, createSoftwareTOTP, toggleMFA};
