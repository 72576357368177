import type {
    ISafeArea,
    TAlignment,
    TDSDepth,
    TPosition
} from '@mcal/core-react';
import {cn} from '@mcal/core-react';
import type {ElementType, ReactNode} from 'react';
import {forwardRef} from 'react';
import type {TCSSObject} from '../../defines/styles.types.js';
import {useBreakpoints} from '../../hooks/use-breakpoints/use-breakpoints.js';
import {usePosition} from '../../hooks/use-position/use-position.js';
import {css} from '../../utils/css/css.js';

interface IPositionerProps {
    component?: ElementType;
    children: ReactNode;
    position?: TPosition;
    alignment?: TAlignment;
    display?: TCSSObject['display'];
    z?: TDSDepth | null;
    safeArea?: Partial<ISafeArea>;
    className?: string;
    classes?: {
        root?: string;
    };
}

const Positioner = forwardRef<HTMLElement, IPositionerProps>(
    (
        {
            component: Component = 'div',
            children,
            position,
            alignment,
            display,
            z,
            safeArea,
            className = '',
            classes = {},
            ...rest
        },
        ref
    ) => {
        const cls = usePosition({position, alignment, display, z, safeArea});

        const cssStyle = useBreakpoints({
            base: css({width: '100%', height: '100%'}),
            m: undefined
        });

        return (
            <Component
                {...rest}
                ref={ref}
                className={cn(cls, className, classes.root, cssStyle)}
            >
                {children}
            </Component>
        );
    }
);

Positioner.displayName = 'Positioner';

export type {IPositionerProps};
export {Positioner};
