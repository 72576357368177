import type {TTestIDs} from '@mcal/core-react';
import {cn} from '@mcal/core-react';
import type {FC, ReactNode} from 'react';
import {forwardRef} from 'react';
import {createTestIDs} from '../../dev/index.js';
import {StyledRoot} from './sidebar-popup.styles.js';

const ownTestIDs = createTestIDs('Menu', ['root']);

interface ISidebarPopupProps {
    children: ReactNode;
    className?: string;
    classes?: {
        root?: string;
    };
    testIDs?: TTestIDs<typeof ownTestIDs>;
}

const SidebarPopup: FC<ISidebarPopupProps> = forwardRef<
    HTMLDivElement,
    ISidebarPopupProps
>(({children, className = '', classes = {}, testIDs = {}, ...rest}, ref) => {
    return (
        <StyledRoot
            data-testid={testIDs.root || ownTestIDs.root}
            className={cn(className, classes.root)}
            ref={ref}
            {...rest}
        >
            {children}
        </StyledRoot>
    );
});

SidebarPopup.displayName = 'SidebarPopup';

export type {ISidebarPopupProps};
export {SidebarPopup, ownTestIDs as testIDs};
