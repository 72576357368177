import type {IRequestError, TAxiosError} from '@mcal/core';

function getRequestError(error: TAxiosError): IRequestError {
    return {
        code: error.code || '',
        status: typeof error.status !== 'undefined' ? error.status : -1,
        isAxiosError: error.isAxiosError,
        name: error.name,
        message: error.message
    };
}

export {getRequestError};
