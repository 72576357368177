import type {
    TAPIResponseSuccess,
    TMFASoftwareTOTPAssociateAPI,
    TMFASoftwareTOTPCreateAPI,
    TMFAToggleAPI
} from '@mcal/core';
import {createAsyncThunk} from '@reduxjs/toolkit';
import type {
    TAPIAsyncThunkArgs,
    TAPIAsyncThunkConfig
} from '../../../defines/redux.types.js';
import {getState} from '../../../utils/get-state/get-state.js';
import * as services from './mfa.services.js';

const createSoftwareTOTP = createAsyncThunk<
    TAPIResponseSuccess<TMFASoftwareTOTPCreateAPI>,
    TAPIAsyncThunkArgs<TMFASoftwareTOTPCreateAPI>,
    TAPIAsyncThunkConfig<TMFASoftwareTOTPCreateAPI>
>('mfa/software-totp/create', async (_, thunkAPI) => {
    const user = getState(thunkAPI, 'user');

    return await services
        .createSoftwareTOTP(
            {
                query: {locale: user.remotes.userGeneralSettings.current.locale}
            },
            thunkAPI.signal
        )
        .then((response) => {
            if (response.error) {
                return thunkAPI.rejectWithValue(response);
            } else {
                return thunkAPI.fulfillWithValue(response);
            }
        });
});

const associateSoftwareTOTP = createAsyncThunk<
    TAPIResponseSuccess<TMFASoftwareTOTPAssociateAPI>,
    TAPIAsyncThunkArgs<TMFASoftwareTOTPAssociateAPI>,
    TAPIAsyncThunkConfig<TMFASoftwareTOTPAssociateAPI>
>('mfa/software-totp/associate', async (args, thunkAPI) => {
    const user = getState(thunkAPI, 'user');

    return await services
        .associateSoftwareTOTP(
            {
                query: {
                    locale: user.remotes.userGeneralSettings.current.locale
                },
                body: {
                    data: {
                        totp: args.totp
                    }
                }
            },
            thunkAPI.signal
        )
        .then((response) => {
            if (response.error) {
                return thunkAPI.rejectWithValue(response);
            } else {
                return thunkAPI.fulfillWithValue(response);
            }
        });
});

const toggleMFA = createAsyncThunk<
    TAPIResponseSuccess<TMFAToggleAPI>,
    TAPIAsyncThunkArgs<TMFAToggleAPI>,
    TAPIAsyncThunkConfig<TMFAToggleAPI>
>('mfa/toggle', async (args, thunkAPI) => {
    const user = getState(thunkAPI, 'user');

    return await services
        .toggleMFA(
            {
                query: {
                    locale: user.remotes.userGeneralSettings.current.locale
                },
                body: {
                    data: {
                        type: args.type,
                        enable: args.enable
                    }
                }
            },
            thunkAPI.signal
        )
        .then((response) => {
            if (response.error) {
                return thunkAPI.rejectWithValue(response);
            } else {
                return thunkAPI.fulfillWithValue(response);
            }
        });
});

export {associateSoftwareTOTP, createSoftwareTOTP, toggleMFA};
