import type {
    TAPIRequestContent,
    TAPIResponse,
    TForgotPasswordCompleteAPI,
    TForgotPasswordStartAPI
} from '@mcal/core';
import {request} from '../../../utils/request/request.js';

const start = (
    {query, body}: TAPIRequestContent<TForgotPasswordStartAPI>,
    signal: AbortSignal
): TAPIResponse<TForgotPasswordStartAPI> => {
    return request<TForgotPasswordStartAPI>({
        method: 'POST',
        url: '/forgot-password/start',
        signal,
        query,
        body
    });
};

const complete = (
    {query, body}: TAPIRequestContent<TForgotPasswordCompleteAPI>,
    signal: AbortSignal
): TAPIResponse<TForgotPasswordCompleteAPI> => {
    return request<TForgotPasswordCompleteAPI>({
        method: 'POST',
        url: '/forgot-password/complete',
        signal,
        query,
        body
    });
};

export {complete, start};
