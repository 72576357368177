import type {IChronosTranslations} from '@mcal/core';
import {defaultChronosTranslations} from '@mcal/core';
import type {FC, ReactNode} from 'react';
import {createContext, useMemo} from 'react';

interface ITranslationsContext {
    chronosTranslations: IChronosTranslations;
}

const defaultTranslationsContext: ITranslationsContext = {
    chronosTranslations: defaultChronosTranslations
};

const TranslationsContext = createContext<ITranslationsContext>(
    defaultTranslationsContext
);

interface ITranslationsStoreProps {
    translations?: Partial<ITranslationsContext>;
    children: ReactNode;
}

const TranslationsStore: FC<ITranslationsStoreProps> = ({
    translations = {},
    children
}) => {
    const value = useMemo<ITranslationsContext>(() => {
        return {
            chronosTranslations:
                translations.chronosTranslations ||
                defaultTranslationsContext.chronosTranslations
        };
    }, [translations.chronosTranslations]);

    return (
        <TranslationsContext.Provider value={value}>
            {children}
        </TranslationsContext.Provider>
    );
};

export type {ITranslationsContext, ITranslationsStoreProps};
export {TranslationsContext, TranslationsStore, defaultTranslationsContext};
