import {escapeKey} from '../../../escape-key/escape-key.js';

function getNextToken(prefix: string, key: string | number): string {
    if (typeof key === 'string') {
        return prefix + (prefix ? '.' : '') + escapeKey(key);
    } else {
        return prefix + `[${key}]`;
    }
}

export {getNextToken};
