import {createAsyncThunk} from '@reduxjs/toolkit';
import type {IAsyncThunkConfig} from '../../defines/redux.types.js';
import type {II18nConfig} from '../../modules/i18n/i18n.js';
import type {Socket} from '../../modules/socket/socket.js';
import {getUser} from '../../services/app-node-auth/user/user.thunks.js';
import {
    getLanguages,
    initI18n
} from '../../services/locize/languages/languages.thunks.js';
import {getState} from '../../utils/get-state/get-state.js';
import type {IFontsConfig} from './app.state.js';

interface IAppConfig {
    i18nConfig: II18nConfig;
    fontsConfig?: Partial<IFontsConfig>;
    socket?: Socket;
}

const loadApp = createAsyncThunk<void, IAppConfig, IAsyncThunkConfig>(
    'app/loadApp',
    async (args, thunkAPI) => {
        await thunkAPI.dispatch(getUser()).then((res) => {
            if (
                !res.payload ||
                (res.payload.error && res.payload.status !== 401)
            ) {
                throw new Error('Failed to load app');
            }

            return res.payload.status;
        });

        const user = getState(thunkAPI, 'user');

        await Promise.all([
            thunkAPI
                .dispatch(
                    initI18n({
                        ...args.i18nConfig,
                        options: {
                            ...args.i18nConfig.options,
                            lng: user.remotes.userGeneralSettings.current.locale
                        }
                    })
                )
                .then(async (res) => {
                    if (res.meta.requestStatus === 'rejected') {
                        throw new Error('Failed to load app');
                    } else {
                        await thunkAPI.dispatch(getLanguages());

                        if (args.socket) {
                            args.socket.connect();
                        }
                    }
                })
        ]);
    }
);

export type {IAppConfig};
export {loadApp};
