interface IOptions {
    type: 'linear';
    colorA: string;
    colorB: string;
    angle: string | number;
}

function createGradient({type, colorA, colorB, angle}: IOptions): string {
    switch (type) {
        case 'linear':
            return `linear-gradient(${angle}deg, ${colorA} 0%, ${colorB} 100%)`;
        default:
            return `linear-gradient(${angle}deg, ${colorA} 0%, ${colorB} 100%)`;
    }
}

export {createGradient};
