import type {
    IElevationDescriptor,
    TDSElevation
} from '../../defines/theme.types.js';
import {createShadow} from '../create-shadow/create-shadow.js';

function createElevationDescriptor(
    level: TDSElevation,
    shadowColor: string
): IElevationDescriptor {
    return {
        shadow: createShadow(level, shadowColor)
    };
}

export {createElevationDescriptor};
