import type {IDSAllTokens, ISize} from '../../../defines/theme.types.js';

const size: ISize = {
    root: {
        s0: '0.333rem',
        s1: '0.444rem',
        s2: '0.556rem',
        s3: '0.778rem',
        s4: '1rem',
        s5: '1.333rem',
        s6: '1.778rem',
        s7: '2.389rem',
        s8: '3.167rem',
        s9: '4.222rem',
        s10: '5.611rem',
        s11: '0.444rem',
        s12: '0.556rem',
        s13: '0.778rem',
        s14: '1rem',
        s15: '1.333rem',
        s16: '1.778rem',
        s17: '2.389rem',
        s18: '3.167rem',
        s19: '4.222rem',
        s20: '5.611rem'
    },
    parent: {
        s0: '0.333em',
        s1: '0.444em',
        s2: '0.556em',
        s3: '0.778em',
        s4: '1em',
        s5: '1.333em',
        s6: '1.778em',
        s7: '2.389em',
        s8: '3.167em',
        s9: '4.222em',
        s10: '5.611em',
        s11: '0.444rem',
        s12: '0.556rem',
        s13: '0.778rem',
        s14: '1rem',
        s15: '1.333rem',
        s16: '1.778rem',
        s17: '2.389rem',
        s18: '3.167rem',
        s19: '4.222rem',
        s20: '5.611rem'
    },
    fixed: {
        s0: '6px',
        s1: '8px',
        s2: '10px',
        s3: '14px',
        s4: '18px',
        s5: '24px',
        s6: '32px',
        s7: '43px',
        s8: '57px',
        s9: '76px',
        s10: '101px',
        s11: '0.444rem',
        s12: '0.556rem',
        s13: '0.778rem',
        s14: '1rem',
        s15: '1.333rem',
        s16: '1.778rem',
        s17: '2.389rem',
        s18: '3.167rem',
        s19: '4.222rem',
        s20: '5.611rem'
    },
    font: {
        s0: '0.333em',
        s1: '0.444em',
        s2: '0.556em',
        s3: '0.778em',
        s4: '1em',
        s5: '1.333em',
        s6: '1.778em',
        s7: '2.389em',
        s8: '3.167em',
        s9: '4.222em',
        s10: '5.611em',
        s11: '0.444rem',
        s12: '0.556rem',
        s13: '0.778rem',
        s14: '1rem',
        s15: '1.333rem',
        s16: '1.778rem',
        s17: '2.389rem',
        s18: '3.167rem',
        s19: '4.222rem',
        s20: '5.611rem'
    }
};

const tokens: Omit<IDSAllTokens, 'color'> = {
    size,
    opacity: {
        o0: 0,
        o1: 0.08,
        o2: 0.1,
        o3: 0.12,
        o4: 0.16,
        o5: 0.25,
        o6: 0.3,
        o7: 0.5,
        o8: 0.7,
        o9: 0.9,
        o10: 1
    },
    breakpoint: {
        xs: 575,
        s: 767,
        m: 991,
        l: 1199,
        xl: 1399,
        xxl: 1919
    },
    depth: {
        global: {
            z0: -5000,
            z1: -2000,
            z2: -100,
            z3: 0,
            z4: 100,
            z5: 300,
            z6: 500,
            z7: 1000,
            z8: 5000,
            z9: 10000,
            z10: 50000
        },
        local: {
            z0: -50,
            z1: -20,
            z2: -10,
            z3: 0,
            z4: 10,
            z5: 20,
            z6: 30,
            z7: 40,
            z8: 50,
            z9: 60,
            z10: 70
        }
    },
    corner: {
        rounded: {
            c0: {
                borderRadius: size.fixed.s0
            },
            c1: {
                borderRadius: size.fixed.s1
            },
            c2: {
                borderRadius: size.fixed.s2
            },
            c3: {
                borderRadius: size.fixed.s3
            },
            c4: {
                borderRadius: size.fixed.s4
            },
            c5: {
                borderRadius: size.fixed.s5
            },
            c6: {
                borderRadius: '100%'
            }
        }
    },
    padding: {
        p0: '0',
        p1: size.fixed.s1,
        p2: size.fixed.s2,
        p3: size.fixed.s3,
        p4: size.fixed.s4,
        p5: size.fixed.s5,
        p6: size.fixed.s6,
        p7: size.fixed.s7,
        p8: size.fixed.s8,
        p9: size.fixed.s9,
        p10: size.fixed.s10
    },
    margin: {
        m0: '0',
        m1: size.fixed.s1,
        m2: size.fixed.s2,
        m3: size.fixed.s3,
        m4: size.fixed.s4,
        m5: size.fixed.s5,
        m6: size.fixed.s6,
        m7: size.fixed.s7,
        m8: size.fixed.s8,
        m9: size.fixed.s9,
        m10: size.fixed.s10
    }
};

export {tokens};
