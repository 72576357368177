type TClassName = undefined | string | null;
type TFlag = boolean;
type TArgs = Array<TClassName | [TClassName, TFlag]>;

function cn(...args: TArgs): string | undefined {
    const segments = [];

    for (let i = 0; i < args.length; i++) {
        const className = args[i];

        if (!className) {
            continue;
        }

        if (Array.isArray(className)) {
            if (className[0] && className[1]) {
                segments.push(className[0]);
            }
        } else {
            segments.push(className);
        }
    }

    const output = segments.join(' ');

    if (output === '') {
        return undefined;
    } else {
        return output;
    }
}

export type {TClassName};
export {cn};
