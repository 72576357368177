import type {TLanguages} from '@mcal/core';
import {logger} from '@mcal/core';
import type {default as LocizeBackend} from 'i18next-locize-backend';
import type {TDefaultTFunction} from '../../../modules/i18n/i18n.js';
import {i18n, initI18n} from '../../../modules/i18n/i18n.js';

interface IBackendConnector {
    backend: LocizeBackend;
}

const getLanguages = (signal: AbortSignal): Promise<TLanguages> => {
    if (!i18n.isInitialized) {
        throw new Error('i18n is not initialized');
    }

    signal.addEventListener('abort', () => {
        logger.warn('getLanguages', 'OPERATION CANNOT BE ABORTED')();
    });

    return new Promise((resolve, reject) => {
        const {backend} = i18n.services.backendConnector as IBackendConnector;

        backend.getLanguages((err, data: TLanguages) => {
            if (err) {
                reject(err);
            } else {
                resolve(data);
            }
        });
    });
};

const changeLanguage = (
    {key}: {key: string},
    signal: AbortSignal
): Promise<TDefaultTFunction> => {
    if (!i18n.isInitialized) {
        throw new Error('i18n is not initialized');
    }

    signal.addEventListener('abort', () => {
        logger.warn('getLanguages', 'OPERATION CANNOT BE ABORTED')();
    });

    return i18n.changeLanguage(key);
};

export {changeLanguage, getLanguages, initI18n};
